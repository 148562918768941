import { ClearMissionMetadata, MissionMetadataActionTypes, SetMissionMetadata } from "../types/Actions";
import { DomainType, MissionMetadata, MissionType } from "../types/MissionType";

export const setMissionMetadata = (metadata: MissionMetadata | null): SetMissionMetadata => {

  let processedMetadata = metadata ? Object.entries(metadata).reduce<{ [key: string]: MissionType[] }>((buffer, [missionKey, metadata]) => {
    const currentMissionDomain = metadata.domain.charAt(0).toUpperCase() + metadata.domain.slice(1);
    !(buffer[currentMissionDomain]) && (buffer[currentMissionDomain] = [])

    buffer[currentMissionDomain]?.push(missionKey);
    return buffer;
  }, {}) : {};


  return ({
    type: "SET_MISSION_METADATA",
    metadata: metadata,
    domainMission: processedMetadata,
  });
}

export const clearMissionMetadata = (): ClearMissionMetadata => ({
  type: "CLEAR_MISSION_METADATA",
});
