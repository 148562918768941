import { Table, TableCell, TableHead, TableRow, Tooltip } from "@material-ui/core";
import Skeleton from '@material-ui/lab/Skeleton';
import React, { useState } from "react";
import { ReactFade } from "./ReactFade";

interface MahjongSummaryTableProps {
  tableData: Array<{ type: string, accuracy: number | null, avgTime: number | null, all_player_accuracy: number | undefined, all_player_avgTime: number | undefined }> | undefined,
  small?: boolean,
  isLoadingUserBehavior: boolean
}
interface RowProps {
  data: { type: string, accuracy: number | undefined | null, avgTime: number | undefined | null, all_player_accuracy: number | undefined, all_player_avgTime: number | undefined },
}

const dummyData = [
  {
    type: "摸牌",
    accuracy: undefined,
    avgTime: undefined,
    all_player_accuracy: undefined,
    all_player_avgTime: undefined
  },
  {
    type: "打牌",
    accuracy: undefined,
    avgTime: undefined,
    all_player_accuracy: undefined,
    all_player_avgTime: undefined
  },
  {
    type: "上",
    accuracy: undefined,
    avgTime: undefined,
    all_player_accuracy: undefined,
    all_player_avgTime: undefined
  },
  {
    type: "碰",
    accuracy: undefined,
    avgTime: undefined,
    all_player_accuracy: undefined,
    all_player_avgTime: undefined
  },
  {
    type: "槓",
    accuracy: undefined,
    avgTime: undefined,
    all_player_accuracy: undefined,
    all_player_avgTime: undefined
  },
  {
    type: "暗槓",
    accuracy: undefined,
    avgTime: undefined,
    all_player_accuracy: undefined,
    all_player_avgTime: undefined
  },
  {
    type: "食糊",
    accuracy: undefined,
    avgTime: undefined,
    all_player_accuracy: undefined,
    all_player_avgTime: undefined
  },
  {
    type: "自摸",
    accuracy: undefined,
    avgTime: undefined,
    all_player_accuracy: undefined,
    all_player_avgTime: undefined
  },
]

function Row(props: RowProps) {
  const [open, setOpen] = useState<boolean>(false);

  const handleRowOpen = () => {
    setOpen(!open);
  }

  return (
    <TableRow>
      <TableCell style={{ width: 30 }} >
        {/* <IconButton size="small" onClick={() => handleRowOpen()}>
          {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
        </IconButton> */}
      </TableCell>

      <TableCell style={{ width: 90 }} >{props.data.type}</TableCell>

      <TableCell style={{ width: 90 }} >
        <ReactFade in={props.data.accuracy !== undefined}>
          <div>
            {(props.data.accuracy !== undefined && props.data.accuracy !== null && !isNaN(props.data.accuracy) ? props.data.accuracy + '%' : '沒有資料')}
          </div>
        </ReactFade>
        <ReactFade in={props.data.accuracy === undefined}>
          <Skeleton width={65} />
        </ReactFade>
      </TableCell>

      <TableCell style={{ width: 90 }} >
        <ReactFade in={props.data.all_player_accuracy !== undefined}>
          {(props.data.all_player_accuracy !== undefined && props.data.all_player_accuracy !== null && !isNaN(props.data.all_player_accuracy) ? props.data.all_player_accuracy + '%' : '沒有資料')}
        </ReactFade>
        <ReactFade in={props.data.all_player_accuracy === undefined}>
          <Skeleton width={65} />
        </ReactFade>
      </TableCell>

      <TableCell style={{ width: 90 }} >
        <ReactFade in={props.data.avgTime !== undefined}>
          {(props.data.avgTime !== undefined && props.data.avgTime !== null && !isNaN(props.data.avgTime) ? props.data.avgTime + '秒' : '沒有資料')}
        </ReactFade>
        <ReactFade in={props.data.avgTime === undefined}>
          <Skeleton width={65} />
        </ReactFade>
      </TableCell>

      <TableCell style={{ width: 90 }} >
        <ReactFade in={props.data.all_player_avgTime !== undefined}>
          {(props.data.all_player_avgTime !== undefined && props.data.all_player_avgTime !== null && !isNaN(props.data.all_player_avgTime) ? props.data.all_player_avgTime + '秒' : '沒有資料')}
        </ReactFade>
        <ReactFade in={props.data.all_player_avgTime === undefined}>
          <Skeleton width={65} />
        </ReactFade>
      </TableCell>
    </TableRow >
  )
}

export default function MahjongSummaryTable(props: MahjongSummaryTableProps) {

  return (
    <Table size={props.small ? "small" : 'medium'} style={{ border: 2 }}>
      <TableHead>
        <TableRow style={{ backgroundColor: '#f2f2f2' }}>
          <TableCell style={{ width: 30 }} />
          <Tooltip title="用戶在麻雀賽局中 作出的不同行為" arrow placement="top"><TableCell>行動</TableCell></Tooltip>
          <Tooltip title="您在麻雀賽局中 作出某種行動時違反麻雀規則的幾率" arrow placement="top"><TableCell>您的錯誤率</TableCell></Tooltip>
          <Tooltip title="在近期全部用戶在麻雀賽局中 作出某種行動時違反麻雀規則的平均幾率" arrow placement="top"><TableCell>全用戶平均錯誤率</TableCell></Tooltip>
          <Tooltip title="您在麻雀賽局中 作出某種行動成功所花費的時間" arrow placement="top"><TableCell>您的平均反應時間</TableCell></Tooltip>
          <Tooltip title="在近期全部用戶在麻雀賽局中 作出某種行動成功所花費的時間" arrow placement="top"><TableCell>全用戶平均反應時間</TableCell></Tooltip>
        </TableRow>
      </TableHead>
      {
        props.tableData ?
          props.tableData.map(x => (<Row data={x} />)) :
          dummyData.map((x, index) => (<Row key={"mahjong-summary-table-" + index} data={x} />))
      }
    </ Table >
  )
}