import loggedInUserReducer from "./loggedInUser";
import { combineReducers } from "redux";
import userProfilesReducer from "./userProfiles";
import userRoleReducer from "./userRole";
import userRolesReducer from "./userRoles";
import userValiditiesReducer from "./userValidities";
import accountsReducer from "./accounts";
import accountInfosReducer from "./accountInfos";
import missionMetadataReducer from "./missionMetadata";

const rootReducer = combineReducers({
  loggedInUser: loggedInUserReducer,
  userProfiles: userProfilesReducer,
  userRole: userRoleReducer,
  userRoles: userRolesReducer,
  userValidities: userValiditiesReducer,
  accounts: accountsReducer,
  accountInfos: accountInfosReducer,
  missionMetadata: missionMetadataReducer
});

export type AppState = ReturnType<typeof rootReducer>;

export default rootReducer;
