import React, { useEffect, useState } from "react";
import { Bar, BarChart, Cell, ResponsiveContainer, XAxis, YAxis } from "recharts";
import { getColorsDarker } from "../resources/colors";

export const MissionScoreDistributionChart = (props: { data: any }) => {

  useEffect(() => {
    setChartData(processData(props.data));
  }, [props.data]);

  const [chartData, setChartData] = useState<any>();

  const processData = (data: any[]) => {
    return data.reduce((buffer, [score, value]) => {
      buffer.push({ "score": score, "value": value });
      return buffer;
    }, []);
  }

  const renderCell = () => {
    const colors = getColorsDarker(11);

    return chartData.map((entry: any, index: number) => (
      <Cell fill={colors[index]} />
    ))
  }

  return (
    <ResponsiveContainer width="100%" height={150}>
      { chartData &&
        <BarChart
          width={500}
          height={150}
          data={chartData}
        >
          <XAxis dataKey="score" interval={0} tick={{ fontSize: "14px", dx: -5 }} angle={270} tickFormatter={(v) => `${v}分`} textAnchor="end" />
          <YAxis hide domain={[0, 2200]} />

          <Bar dataKey="value" label={{ position: 'top', fontSize: '12px', fontWeight: "bold" }} isAnimationActive={false} >
            {
              renderCell()
            }
          </Bar>
        </BarChart>}
    </ResponsiveContainer>
  )
}