import { SetUserRolesAction, ClearUserRolesAction } from "../types/Actions";
import { UserRole } from "../types/UserRole";

export const setUserRoles = (userRoles: UserRole[]): SetUserRolesAction => ({
  type: "SET_USER_ROLES",
  userRoles,
});

export const clearUserRoles = (): ClearUserRolesAction => ({
  type: "CLEAR_USER_ROLES",
});
