import firebase from 'firebase';

const firebaseConfig = {
  apiKey: "AIzaSyDpk6kaW4tdMohuX3B7VmexS-CdTd9D9yI",
  authDomain: "neurogym-game.firebaseapp.com",
  databaseURL: "https://neurogym-game.firebaseio.com",
  projectId: "neurogym-game",
  storageBucket: "neurogym-game.appspot.com",
  messagingSenderId: "465520655234",
  appId: "1:465520655234:web:45913d5b5c4f2f00252d9b",
  measurementId: "G-984KDWSN0C"
};

const firebaseProdConfig = {
  apiKey: "AIzaSyA_hXjQmAcQM6xqcsowaAzAxS77Zo9rEo4",
  authDomain: "neurogym-prod.firebaseapp.com",
  databaseURL: "https://neurogym-prod.firebaseio.com",
  projectId: "neurogym-prod",
  storageBucket: "neurogym-prod.appspot.com",
  messagingSenderId: "727370382003",
  appId: "1:727370382003:web:e98553811507cf73654ec6",
  measurementId: "G-38F9PSRNZ7"
};

if (process.env.REACT_APP_ENVIRONMENT !== "test" && process.env.NODE_ENV === 'production') {
  firebase.initializeApp(firebaseProdConfig);
} else {
  firebase.initializeApp(firebaseConfig);
}

export default firebase;