// @ts-nocheck
/* eslint-disable */

import _ from "lodash";
import { GameLog } from "../types/GameLog";

export interface VirtualPlayer {
  // For simulation
  pointer: number
  hand: string[],
  offHandAction: string[][],
  offHandFlower: string[],
  discard: string[],

  // For analysis
  attackEffiency: number[],
  defesneEffiency: number[]
}

export interface VirtualBoard {
  // For simulation
  wind: number,
  round: number
  discardTile: string[]
}

const mahjongIndexing = [
  "00", "01", "02", "03", "04", "05", "06", "07", "08",
  "10", "11", "12", "13", "14", "15", "16", "17", "18",
  "20", "21", "22", "23", "24", "25", "26", "27", "28",
  "30", "31", "32", "33", "34", "35", "36"
];

const transformHand = (hand) => {
  const bufferArray = new Array(34).fill(0);
  hand.forEach(tile => {
    bufferArray[mahjongIndexing.indexOf(tile)] += 1;
  });
  return bufferArray;
}

export const simulateGameByLog = (gameLog: GameLog) => {
  // Init
  try {
    let turnPointer = gameLog.first_dealer;

    const virtualPlayers: VirtualPlayer[] = [...new Array(4).keys()].map(num => {
      return {
        pointer: 0,
        hand: [],
        offHandAction: [],
        offHandFlower: [],
        discard: [],
        attackEffiency: [],
        defenseEffiency: []
      }
    });

    const virtualBoard: VirtualBoard = {
      wind: gameLog.wind,
      round: gameLog.dealer - gameLog.first_dealer,
      discardTile: []
    };

    const maxTurn = _(gameLog.players).maxBy(p => p.actions ? p.actions.length : 0)!.actions.length;
    let playerArrayEnd: boolean[] = [false, false, false, false];

    // Init starting hand
    virtualPlayers.forEach((p, index) => {
      p.hand = gameLog.players[index].starting_hand;
      const flowers = p.hand.filter(tile => tile.charAt(0) === "4");
      flowers.forEach(fl => {
        p.offHandFlower.push(fl);
        p.hand.splice(p.hand.indexOf(fl), 1);
      });
    });

    for (let totalTurnPointer = 0; totalTurnPointer < maxTurn * 4; totalTurnPointer++) {
      // console.log(JSON.parse(JSON.stringify(virtualBoard)));
      // console.log(JSON.parse(JSON.stringify(virtualPlayers)));

      // Get action from the player
      if (playerArrayEnd.every(end => end === true)) break;

      const currentTurnPlayer = virtualPlayers[turnPointer];

      const currentTurnPlayerPointer = currentTurnPlayer.pointer;
      let currentTurnPlayerAction: any = gameLog.players[turnPointer].actions[currentTurnPlayerPointer];

      if (currentTurnPlayerAction === undefined) {
        console.log("A player ended the whole game");
        playerArrayEnd = [true, true, true, true];
        break;
      }

      // The step of obtaining tiles

      if (currentTurnPlayerAction.draw !== undefined) {
        currentTurnPlayer.hand.push(currentTurnPlayerAction.draw);
      }

      if (currentTurnPlayerAction.flower !== undefined) {
        currentTurnPlayer.offHandFlower.push(currentTurnPlayerAction.flower);

        currentTurnPlayer.pointer++;
        continue;
      }

      if (currentTurnPlayerAction.chow !== undefined) {
        currentTurnPlayer.offHandAction.push([currentTurnPlayerAction.combination[0], currentTurnPlayerAction.combination[1], currentTurnPlayerAction.combination[2]]);

        const remainder = Array.from(currentTurnPlayerAction.combination);
        remainder.splice(currentTurnPlayerAction.combination.indexOf(currentTurnPlayerAction.chow), 1);

        currentTurnPlayer.hand.splice(currentTurnPlayer.hand.indexOf(remainder[0]), 1);
        currentTurnPlayer.hand.splice(currentTurnPlayer.hand.indexOf(remainder[1]), 1);
      }
      else if (currentTurnPlayerAction.pong !== undefined) {
        currentTurnPlayer.offHandAction.push([currentTurnPlayerAction.pong, currentTurnPlayerAction.pong, currentTurnPlayerAction.pong]);

        currentTurnPlayer.hand.splice(currentTurnPlayer.hand.indexOf(currentTurnPlayerAction.pong), 1);
        currentTurnPlayer.hand.splice(currentTurnPlayer.hand.indexOf(currentTurnPlayerAction.pong), 1);
      }
      else if (currentTurnPlayerAction.gong !== undefined) {
        // Determine gong type

        // Light gong & dark gong
        if (currentTurnPlayer.hand.filter(tile => tile === currentTurnPlayerAction.gong).length === 3) {
          currentTurnPlayer.offHandAction.push([currentTurnPlayerAction.gong, currentTurnPlayerAction.gong, currentTurnPlayerAction.gong, currentTurnPlayerAction.gong]);

          currentTurnPlayer.hand.splice(currentTurnPlayer.hand.indexOf(currentTurnPlayerAction.gong), 1);
          currentTurnPlayer.hand.splice(currentTurnPlayer.hand.indexOf(currentTurnPlayerAction.gong), 1);
          currentTurnPlayer.hand.splice(currentTurnPlayer.hand.indexOf(currentTurnPlayerAction.gong), 1);
        }
        // Add gong
        else if (currentTurnPlayer.offHandAction.some(comb => comb[0] === currentTurnPlayerAction.gong)) {
          const existingPong = currentTurnPlayer.offHandAction.find(comb => comb[0] === currentTurnPlayerAction.gong);
          existingPong?.push(currentTurnPlayerAction.gong);
        }
        else {
          console.warn("Gong detected but it is neither any type of gong.");
        }

        currentTurnPlayer.pointer++;
        continue;
      }
      else if (currentTurnPlayerAction.discard !== undefined && currentTurnPlayerAction.draw === undefined) {
        currentTurnPlayer.hand.push(currentTurnPlayerAction.discard);
      }

      if (currentTurnPlayerAction.draw !== undefined && currentTurnPlayerAction.discard === undefined) {
        playerArrayEnd = [true, true, true, true];
        break;
      }

      // Evaulate actions here before discard
      const initMountain = new Array(34).fill(4);

      const hand = transformHand(currentTurnPlayer.hand);
      const knownDiscard = transformHand(virtualBoard.discardTile);
      const knownOffHand0 = transformHand(_(virtualPlayers[0].offHandAction).flatten().value());
      const knownOffHand1 = transformHand(_(virtualPlayers[1].offHandAction).flatten().value());
      const knownOffHand2 = transformHand(_(virtualPlayers[2].offHandAction).flatten().value());
      const knownOffHand3 = transformHand(_(virtualPlayers[3].offHandAction).flatten().value());

      const excludeAllKnownTiles = initMountain.map((tileCount, index) => tileCount - knownDiscard[index] - knownOffHand0[index] - knownOffHand1[index] - knownOffHand2[index] - knownOffHand3[index])

      const attackAnalysis = evaluateAttack(hand, excludeAllKnownTiles);

      const defenseInfo = {
        mySeat: turnPointer,
        river: [
          virtualPlayers[0].discard.map(t => mahjongIndexing.indexOf(t)),
          virtualPlayers[1].discard.map(t => mahjongIndexing.indexOf(t)),
          virtualPlayers[2].discard.map(t => mahjongIndexing.indexOf(t)),
          virtualPlayers[3].discard.map(t => mahjongIndexing.indexOf(t))
        ],
        fuuro: [
          _(virtualPlayers[0].offHandAction).flatten().map(t => mahjongIndexing.indexOf(t)).value(),
          _(virtualPlayers[1].offHandAction).flatten().map(t => mahjongIndexing.indexOf(t)).value(),
          _(virtualPlayers[2].offHandAction).flatten().map(t => mahjongIndexing.indexOf(t)).value(),
          _(virtualPlayers[3].offHandAction).flatten().map(t => mahjongIndexing.indexOf(t)).value()
        ],
        chang: virtualBoard.wind,
        ju: virtualBoard.round
      };

      const defenseAnalysis = evaluateDefense(hand, excludeAllKnownTiles, defenseInfo);


      if (currentTurnPlayerAction.discard !== undefined) {

        // Get the corresponding score
        const targetAttackRating = attackAnalysis.find(rating => rating.tileIndex === mahjongIndexing.indexOf(currentTurnPlayerAction.discard));
        const targetDefenseRating = defenseAnalysis.find(rating => rating.tileIndex === mahjongIndexing.indexOf(currentTurnPlayerAction.discard));

        currentTurnPlayer.attackEffiency.push(targetAttackRating ? targetAttackRating.rate : 0);
        currentTurnPlayer.defenseEffiency.push(targetDefenseRating ? isNaN(targetDefenseRating.rate) ? 1 : targetDefenseRating.rate : 0);

        if (currentTurnPlayerAction.to !== undefined) {
          turnPointer = parseInt(currentTurnPlayerAction.to);
        }
        else {
          virtualBoard.discardTile.push(currentTurnPlayerAction.discard);

          if (turnPointer === 3) {
            turnPointer = 0;
          }
          else {
            turnPointer++;
          }
        }

        currentTurnPlayer.discard.push(currentTurnPlayerAction.discard);
        currentTurnPlayer.hand.splice(currentTurnPlayer.hand.indexOf(currentTurnPlayerAction.discard), 1);
        currentTurnPlayer.pointer++;
      }
      else {
        console.warn("Discard tile not detected. Can not mantain equilibium.");
      }

    }

    return virtualPlayers.map(p => {
      return {
        attackEffiency: _(p.attackEffiency).mean(),
        defenseEffiency: _(p.defenseEffiency).mean()
      };
    });

  }
  catch (e) {
    return
    [
      {
        attackEffiency: "-",
        defenseEffiency: "-",
      },
      {
        attackEffiency: "-",
        defenseEffiency: "-",
      },
      {
        attackEffiency: "-",
        defenseEffiency: "-",
      },
      {
        attackEffiency: "-",
        defenseEffiency: "-",
      }
    ];

  }
}


export const Agari = { // 和了判定のみ // SYANTENで-1検査より高速
  isMentsu: function (m) {
    var a = (m & 7),
      b = 0,
      c = 0;
    if (a == 1 || a == 4) b = c = 1;
    else if (a == 2) b = c = 2;
    m >>= 3, a = (m & 7) - b;
    if (a < 0) return false;
    b = c, c = 0;
    if (a == 1 || a == 4) b += 1, c += 1;
    else if (a == 2) b += 2, c += 2;
    m >>= 3, a = (m & 7) - b;
    if (a < 0) return false;
    b = c, c = 0;
    if (a == 1 || a == 4) b += 1, c += 1;
    else if (a == 2) b += 2, c += 2;
    m >>= 3, a = (m & 7) - b;
    if (a < 0) return false;
    b = c, c = 0;
    if (a == 1 || a == 4) b += 1, c += 1;
    else if (a == 2) b += 2, c += 2;
    m >>= 3, a = (m & 7) - b;
    if (a < 0) return false;
    b = c, c = 0;
    if (a == 1 || a == 4) b += 1, c += 1;
    else if (a == 2) b += 2, c += 2;
    m >>= 3, a = (m & 7) - b;
    if (a < 0) return false;
    b = c, c = 0;
    if (a == 1 || a == 4) b += 1, c += 1;
    else if (a == 2) b += 2, c += 2;
    m >>= 3, a = (m & 7) - b;
    if (a < 0) return false;
    b = c, c = 0;
    if (a == 1 || a == 4) b += 1, c += 1;
    else if (a == 2) b += 2, c += 2;
    m >>= 3, a = (m & 7) - b;
    if (a != 0 && a != 3) return false;
    m >>= 3, a = (m & 7) - c;
    return a == 0 || a == 3;
  },
  isAtamaMentsu: function (nn, m) {
    if (nn == 0) {
      if ((m & (7 << 6)) >= (2 << 6) && this.isMentsu(m - (2 << 6))) return true;
      if ((m & (7 << 15)) >= (2 << 15) && this.isMentsu(m - (2 << 15))) return true;
      if ((m & (7 << 24)) >= (2 << 24) && this.isMentsu(m - (2 << 24))) return true;
    } else if (nn == 1) {
      if ((m & (7 << 3)) >= (2 << 3) && this.isMentsu(m - (2 << 3))) return true;
      if ((m & (7 << 12)) >= (2 << 12) && this.isMentsu(m - (2 << 12))) return true;
      if ((m & (7 << 21)) >= (2 << 21) && this.isMentsu(m - (2 << 21))) return true;
    } else if (nn == 2) {
      if ((m & (7 << 0)) >= (2 << 0) && this.isMentsu(m - (2 << 0))) return true;
      if ((m & (7 << 9)) >= (2 << 9) && this.isMentsu(m - (2 << 9))) return true;
      if ((m & (7 << 18)) >= (2 << 18) && this.isMentsu(m - (2 << 18))) return true;
    }
    return false;
  },
  cc2m: function (c, d) {
    return (c[d + 0] << 0) | (c[d + 1] << 3) | (c[d + 2] << 6) |
      (c[d + 3] << 9) | (c[d + 4] << 12) | (c[d + 5] << 15) |
      (c[d + 6] << 18) | (c[d + 7] << 21) | (c[d + 8] << 24);
  },
  isAgari: function (c) {
    var j = (1 << c[27]) | (1 << c[28]) | (1 << c[29]) | (1 << c[30]) | (1 << c[31]) | (1 << c[32]) | (1 << c[33]);
    if (j >= 0x10) return false; // 字牌が４枚
    // 国士無双 // １４枚のみ
    if (((j & 3) == 2) && (c[0] * c[8] * c[9] * c[17] * c[18] * c[26] * c[27] * c[28] * c[29] * c[30] * c[31] * c[32] * c[33] == 2)) return true;
    // 一般系
    if (j & 2) return false; // 字牌が１枚
    var n00 = c[0] + c[3] + c[6],
      n01 = c[1] + c[4] + c[7],
      n02 = c[2] + c[5] + c[8];
    var n10 = c[9] + c[12] + c[15],
      n11 = c[10] + c[13] + c[16],
      n12 = c[11] + c[14] + c[17];
    var n20 = c[18] + c[21] + c[24],
      n21 = c[19] + c[22] + c[25],
      n22 = c[20] + c[23] + c[26];
    var n0 = (n00 + n01 + n02) % 3;
    if (n0 == 1) return false; // 萬子が１枚余る
    var n1 = (n10 + n11 + n12) % 3;
    if (n1 == 1) return false; // 筒子が１枚余る
    var n2 = (n20 + n21 + n22) % 3;
    if (n2 == 1) return false; // 索子が１枚余る
    if ((n0 == 2) + (n1 == 2) + (n2 == 2) +
      (c[27] == 2) + (c[28] == 2) + (c[29] == 2) + (c[30] == 2) +
      (c[31] == 2) + (c[32] == 2) + (c[33] == 2) != 1) return false; // 頭の場所は１つ
    var nn0 = (n00 * 1 + n01 * 2) % 3,
      m0 = this.cc2m(c, 0);
    var nn1 = (n10 * 1 + n11 * 2) % 3,
      m1 = this.cc2m(c, 9);
    var nn2 = (n20 * 1 + n21 * 2) % 3,
      m2 = this.cc2m(c, 18);
    if (j & 4) return !(n0 | nn0 | n1 | nn1 | n2 | nn2) && this.isMentsu(m0) && this.isMentsu(m1) && this.isMentsu(m2); // 字牌が頭
    //		document.write("c="+c+"<br>");
    //		document.write("n="+n0+","+n1+","+n2+" nn="+nn0+","+nn1+","+nn2+"<br>");
    //		document.write("m="+m0+","+m1+","+m2+"<br>");
    if (n0 == 2) return !(n1 | nn1 | n2 | nn2) && this.isMentsu(m1) && this.isMentsu(m2) && this.isAtamaMentsu(nn0, m0); // 萬子が頭
    if (n1 == 2) return !(n2 | nn2 | n0 | nn0) && this.isMentsu(m2) && this.isMentsu(m0) && this.isAtamaMentsu(nn1, m1); // 筒子が頭
    if (n2 == 2) return !(n0 | nn0 | n1 | nn1) && this.isMentsu(m0) && this.isMentsu(m1) && this.isAtamaMentsu(nn2, m2); // 索子が頭
    return false;
  }
}

export const AgariPattern = () => {
  this.toitsu34 = [-1, -1, -1, -1, -1, -1, -1];
  this.v = [{
    atama34: -1,
    mmmm35: 0
  }, {
    atama34: -1,
    mmmm35: 0
  }, {
    atama34: -1,
    mmmm35: 0
  }, {
    atama34: -1,
    mmmm35: 0
  }]; // 一般形の面子の取り方は高々４つ
  // mmmm35=( 21(順子)+34(暗刻)+34(槓子)+1(ForZeroInvalid) )*0x01010101 | 0x80808080(喰い)
}

AgariPattern.prototype = {
  //	isKokushi:function(){return this.v[0].mmmm35==0xFFFFFFFF;},
  //	isChiitoi:function(){return this.v[3].mmmm35==0xFFFFFFFF;},

  cc2m: function (c, d) {
    return (c[d + 0] << 0) | (c[d + 1] << 3) | (c[d + 2] << 6) |
      (c[d + 3] << 9) | (c[d + 4] << 12) | (c[d + 5] << 15) |
      (c[d + 6] << 18) | (c[d + 7] << 21) | (c[d + 8] << 24);
  },
  getAgariPattern: function (c, n) {
    if (n != 34) return false;
    var e = this;
    var v = e.v;
    var j = (1 << c[27]) | (1 << c[28]) | (1 << c[29]) | (1 << c[30]) | (1 << c[31]) | (1 << c[32]) | (1 << c[33]);
    if (j >= 0x10) return false; // 字牌が４枚
    // 国士無双 // １４枚のみ
    if (((j & 3) == 2) && (c[0] * c[8] * c[9] * c[17] * c[18] * c[26] * c[27] * c[28] * c[29] * c[30] * c[31] * c[32] * c[33] == 2)) {
      var i, a = [0, 8, 9, 17, 18, 26, 27, 28, 29, 30, 31, 32, 33];
      for (i = 0; i < 13; ++i)
        if (c[a[i]] == 2) break;
      v[0].atama34 = a[i];
      v[0].mmmm35 = 0xFFFFFFFF;
      return true;
    }
    if (j & 2) return false; // 字牌が１枚
    var ok = false;
    // 七対子 // １４枚のみ
    if (!(j & 10) && (
      (c[0] == 2) + (c[1] == 2) + (c[2] == 2) + (c[3] == 2) + (c[4] == 2) + (c[5] == 2) + (c[6] == 2) + (c[7] == 2) + (c[8] == 2) +
      (c[9] == 2) + (c[10] == 2) + (c[11] == 2) + (c[12] == 2) + (c[13] == 2) + (c[14] == 2) + (c[15] == 2) + (c[16] == 2) + (c[17] == 2) +
      (c[18] == 2) + (c[19] == 2) + (c[20] == 2) + (c[21] == 2) + (c[22] == 2) + (c[23] == 2) + (c[24] == 2) + (c[25] == 2) + (c[26] == 2) +
      (c[27] == 2) + (c[28] == 2) + (c[29] == 2) + (c[30] == 2) + (c[31] == 2) + (c[32] == 2) + (c[33] == 2)) == 7) {
      v[3].mmmm35 = 0xFFFFFFFF;
      var i, n = 0;
      for (i = 0; i < 34; ++i)
        if (c[i] == 2) e.toitsu34[n] = i, n += 1;
      ok = true;
      // 二盃口へ
    }
    // 一般形
    var n00 = c[0] + c[3] + c[6],
      n01 = c[1] + c[4] + c[7],
      n02 = c[2] + c[5] + c[8];
    var n10 = c[9] + c[12] + c[15],
      n11 = c[10] + c[13] + c[16],
      n12 = c[11] + c[14] + c[17];
    var n20 = c[18] + c[21] + c[24],
      n21 = c[19] + c[22] + c[25],
      n22 = c[20] + c[23] + c[26];
    var k0 = (n00 + n01 + n02) % 3;
    if (k0 == 1) return ok; // 余る
    var k1 = (n10 + n11 + n12) % 3;
    if (k1 == 1) return ok; // 余る
    var k2 = (n20 + n21 + n22) % 3;
    if (k2 == 1) return ok; // 余る
    if ((k0 == 2) + (k1 == 2) + (k2 == 2) +
      (c[27] == 2) + (c[28] == 2) + (c[29] == 2) + (c[30] == 2) +
      (c[31] == 2) + (c[32] == 2) + (c[33] == 2) != 1) return ok; // 頭の場所は１つ
    if (j & 8) { // 字牌３枚
      if (c[27] == 3) v[0].mmmm35 <<= 8, v[0].mmmm35 |= 21 + 27 + 1;
      if (c[28] == 3) v[0].mmmm35 <<= 8, v[0].mmmm35 |= 21 + 28 + 1;
      if (c[29] == 3) v[0].mmmm35 <<= 8, v[0].mmmm35 |= 21 + 29 + 1;
      if (c[30] == 3) v[0].mmmm35 <<= 8, v[0].mmmm35 |= 21 + 30 + 1;
      if (c[31] == 3) v[0].mmmm35 <<= 8, v[0].mmmm35 |= 21 + 31 + 1;
      if (c[32] == 3) v[0].mmmm35 <<= 8, v[0].mmmm35 |= 21 + 32 + 1;
      if (c[33] == 3) v[0].mmmm35 <<= 8, v[0].mmmm35 |= 21 + 33 + 1;
    }
    var n0 = n00 + n01 + n02,
      kk0 = (n00 * 1 + n01 * 2) % 3,
      m0 = e.cc2m(c, 0);
    var n1 = n10 + n11 + n12,
      kk1 = (n10 * 1 + n11 * 2) % 3,
      m1 = e.cc2m(c, 9);
    var n2 = n20 + n21 + n22,
      kk2 = (n20 * 1 + n21 * 2) % 3,
      m2 = e.cc2m(c, 18);
    //		document.write("n="+n0+" "+n1+" "+n2+" k="+k0+" "+k1+" "+k2+" kk="+kk0+" "+kk1+" "+kk2+" mmmm="+v[0].mmmm35+"<br>");
    if (j & 4) { // 字牌が頭
      if (k0 | kk0 | k1 | kk1 | k2 | kk2) return ok;
      if (c[27] == 2) v[0].atama34 = 27;
      else if (c[28] == 2) v[0].atama34 = 28;
      else if (c[29] == 2) v[0].atama34 = 29;
      else if (c[30] == 2) v[0].atama34 = 30;
      else if (c[31] == 2) v[0].atama34 = 31;
      else if (c[32] == 2) v[0].atama34 = 32;
      else if (c[33] == 2) v[0].atama34 = 33;
      if (n0 >= 9) {
        if (e.GetMentsu(1, m1) && e.GetMentsu(2, m2) && e.GetMentsu9Fin(0, m0)) return true;
      } else if (n1 >= 9) {
        if (e.GetMentsu(2, m2) && e.GetMentsu(0, m0) && e.GetMentsu9Fin(1, m1)) return true;
      } else if (n2 >= 9) {
        if (e.GetMentsu(0, m0) && e.GetMentsu(1, m1) && e.GetMentsu9Fin(2, m2)) return true;
      } else if (e.GetMentsu(0, m0) && e.GetMentsu(1, m1) && e.GetMentsu(2, m2)) return true; // 一意
    } else if (k0 == 2) { // 萬子が頭
      if (k1 | kk1 | k2 | kk2) return ok;
      if (n0 >= 8) {
        if (e.GetMentsu(1, m1) && e.GetMentsu(2, m2) && e.GetAtamaMentsu8Fin(kk0, 0, m0)) return true;
      } else if (n1 >= 9) {
        if (e.GetMentsu(2, m2) && e.GetAtamaMentsu(kk0, 0, m0) && e.GetMentsu9Fin(1, m1)) return true;
      } else if (n2 >= 9) {
        if (e.GetAtamaMentsu(kk0, 0, m0) && e.GetMentsu(1, m1) && e.GetMentsu9Fin(2, m2)) return true;
      } else if (e.GetMentsu(1, m1) && e.GetMentsu(2, m2) && e.GetAtamaMentsu(kk0, 0, m0)) return true; // 一意
    } else if (k1 == 2) { // 筒子が頭
      if (k2 | kk2 | k0 | kk0) return ok;
      if (n1 >= 8) {
        if (e.GetMentsu(2, m2) && e.GetMentsu(0, m0) && e.GetAtamaMentsu8Fin(kk1, 1, m1)) return true;
      } else if (n2 >= 9) {
        if (e.GetMentsu(0, m0) && e.GetAtamaMentsu(kk1, 1, m1) && e.GetMentsu9Fin(2, m2)) return true;
      } else if (n0 >= 9) {
        if (e.GetAtamaMentsu(kk1, 1, m1) && e.GetMentsu(2, m2) && e.GetMentsu9Fin(0, m0)) return true;
      } else if (e.GetMentsu(2, m2) && e.GetMentsu(0, m0) && e.GetAtamaMentsu(kk1, 1, m1)) return true; // 一意
    } else if (k2 == 2) { // 索子が頭
      if (k0 | kk0 | k1 | kk1) return ok;
      if (n2 >= 8) {
        if (e.GetMentsu(0, m0) && e.GetMentsu(1, m1) && e.GetAtamaMentsu8Fin(kk2, 2, m2)) return true;
      } else if (n0 >= 9) {
        if (e.GetMentsu(1, m1) && e.GetAtamaMentsu(kk2, 2, m2) && e.GetMentsu9Fin(0, m0)) return true;
      } else if (n1 >= 9) {
        if (e.GetAtamaMentsu(kk2, 2, m2) && e.GetMentsu(0, m0) && e.GetMentsu9Fin(1, m1)) return true;
      } else if (e.GetMentsu(0, m0) && e.GetMentsu(1, m1) && e.GetAtamaMentsu(kk2, 2, m2)) return true; // 一意
    }
    v[0].mmmm35 = 0; // 一般形不発
    return ok;
  },

  // private:
  GetMentsu: function (col, m) { // ６枚以下は一意
    var e = this;
    var mmmm = e.v[0].mmmm35;
    var i, a = (m & 7),
      b = 0,
      c = 0;
    for (i = 0; i < 7; ++i) {
      switch (a) {
        case 4:
          mmmm <<= 16, mmmm |= ((21 + col * 9 + i + 1) << 8) | (col * 7 + i + 1), b += 1, c += 1;
          break;
        case 3:
          mmmm <<= 8, mmmm |= (21 + col * 9 + i + 1);
          break;
        case 2:
          mmmm <<= 16, mmmm |= (col * 7 + i + 1) * 0x0101, b += 2, c += 2;
          break;
        case 1:
          mmmm <<= 8, mmmm |= (col * 7 + i + 1), b += 1, c += 1;
          break;
        case 0:
          break;
        default:
          return false;
      }
      m >>= 3, a = (m & 7) - b, b = c, c = 0;
    }
    if (a == 3) mmmm <<= 8, mmmm |= (21 + col * 9 + 7 + 1);
    else if (a) return false; // ⑧
    m >>= 3, a = (m & 7) - b;
    if (a == 3) mmmm <<= 8, mmmm |= (21 + col * 9 + 8 + 1);
    else if (a) return false; // ⑨
    e.v[0].mmmm35 = mmmm;
    //		DBGPRINT((_T("GetMentsu col=%d mmmm=%X\r\n"),col,mmmm));
    return true;
  },
  GetAtamaMentsu: function (nn, col, m) { // ５枚以下は一意
    var e = this;
    var a = (7 << (24 - nn * 3));
    var b = (2 << (24 - nn * 3));
    if ((m & a) >= b && e.GetMentsu(col, m - b)) return e.v[0].atama34 = col * 9 + 8 - nn, true;
    a >>= 9, b >>= 9;
    if ((m & a) >= b && e.GetMentsu(col, m - b)) return e.v[0].atama34 = col * 9 + 5 - nn, true;
    a >>= 9, b >>= 9;
    if ((m & a) >= b && e.GetMentsu(col, m - b)) return e.v[0].atama34 = col * 9 + 2 - nn, true;
    return false;
  },
  GetMentsu9: function (mmmm, col, m, v) { // const // ９枚以上
    // 面子選択は四連刻（１２枚）三連刻（９枚以上）しかない
    var s = -1; // 三連刻
    var i, a = (m & 7),
      b = 0,
      c = 0;
    for (i = 0; i < 7; ++i) {
      if (m == 0x6DB) break; // 四連刻 // 三暗対々が高目 // １２枚のみ
      switch (a) {
        case 4:
          mmmm <<= 8, mmmm |= (col * 7 + i + 1), b += 1, c += 1; // nobreak // 平和二盃口が三暗刻より高目
          break;
        case 3: // 帯幺九系が高目、ロン平和一盃口以外は三暗刻が高目
          if (((m >> 3) & 7) >= 3 + b && ((m >> 6) & 7) >= 3 + c) s = i, b += 3, c += 3; // 三連刻
          else mmmm <<= 8, mmmm |= (21 + col * 9 + i + 1);
          break;
        case 2:
          mmmm <<= 16, mmmm |= (col * 7 + i + 1) * 0x0101, b += 2, c += 2;
          break;
        case 1:
          mmmm <<= 8, mmmm |= (col * 7 + i + 1), b += 1, c += 1;
          break;
        case 0:
          break;
        default:
          return 0;
      }
      m >>= 3, a = (m & 7) - b, b = c, c = 0;
    }
    if (i < 7) { // 四連刻を展開
      v[0] = (21 + col * 9 + i + 1) * 0x01010101 + 0x00010203;
      v[1] = (col * 7 + i + 1 + 1) * 0x010101 | (21 + col * 9 + i + 0 + 1) << 24;
      v[2] = (col * 7 + i + 0 + 1) * 0x010101 | (21 + col * 9 + i + 3 + 1) << 24;
      return 3;
    }
    if (a == 3) mmmm <<= 8, mmmm |= (21 + col * 9 + 7 + 1);
    else if (a) return 0; // ⑧
    m >>= 3, a = (m & 7) - b;
    if (a == 3) mmmm <<= 8, mmmm |= (21 + col * 9 + 8 + 1);
    else if (a) return 0; // ⑨

    if (s != -1) { // 三連刻を展開
      mmmm <<= 24;
      v[0] = mmmm | ((21 + col * 9 + s + 1) * 0x010101 + 0x000102);
      v[1] = mmmm | ((col * 7 + s + 1) * 0x010101);
      v[2] = 0;
      return 2;
    }
    v[0] = mmmm, v[1] = v[2] = 0;
    return 1;
  },
  GetMentsu9Fin: function (col, m) { // ９枚以上
    var e = this;
    var v = e.v;
    var mm = [0, 0, 0];
    if (!e.GetMentsu9(v[0].mmmm35, col, m, mm)) return false;
    var n = 0;
    if (mm[0]) v[n].atama34 = v[0].atama34, v[n].mmmm35 = mm[0], ++n;
    if (mm[1]) v[n].atama34 = v[0].atama34, v[n].mmmm35 = mm[1], ++n;
    if (mm[2]) v[n].atama34 = v[0].atama34, v[n].mmmm35 = mm[2], ++n;
    //		document.write("GetMentsu9Fin col="+col+" n="+n+"<br>");
    return n != 0;
  },
  GetAtamaMentsu8Fin: function (nn, col, m) { // ８枚以上
    var e = this;
    var v = e.v;
    var mmmm = v[0].mmmm35;
    var mm = [0, 0, 0];
    var a = (7 << (24 - nn * 3));
    var b = (2 << (24 - nn * 3));
    var n = 0;
    if ((m & a) >= b && e.GetMentsu9(mmmm, col, m - b, mm)) {
      if (mm[0]) v[n].atama34 = col * 9 + 8 - nn, v[n].mmmm35 = mm[0], ++n;
      if (mm[1]) v[n].atama34 = col * 9 + 8 - nn, v[n].mmmm35 = mm[1], ++n;
      if (mm[2]) v[n].atama34 = col * 9 + 8 - nn, v[n].mmmm35 = mm[2], ++n;
    }
    a >>= 9, b >>= 9;
    if ((m & a) >= b && e.GetMentsu9(mmmm, col, m - b, mm)) {
      if (mm[0]) v[n].atama34 = col * 9 + 5 - nn, v[n].mmmm35 = mm[0], ++n;
      if (mm[1]) v[n].atama34 = col * 9 + 5 - nn, v[n].mmmm35 = mm[1], ++n;
      if (mm[2]) v[n].atama34 = col * 9 + 5 - nn, v[n].mmmm35 = mm[2], ++n;
    }
    a >>= 9, b >>= 9;
    if ((m & a) >= b && e.GetMentsu9(mmmm, col, m - b, mm)) {
      if (mm[0]) v[n].atama34 = col * 9 + 2 - nn, v[n].mmmm35 = mm[0], ++n;
      if (mm[1]) v[n].atama34 = col * 9 + 2 - nn, v[n].mmmm35 = mm[1], ++n;
      if (mm[2]) v[n].atama34 = col * 9 + 2 - nn, v[n].mmmm35 = mm[2], ++n;
    }
    //		document.write("GetAtamaMentsu8Fin col="+col+" n="+n+"<br>");
    return n != 0;
  }
};

export const MPSZ = {
  aka: true,
  fromHai136: function (hai136) {
    var a = (hai136 >> 2);
    if (!this.aka) return ((a % 9) + 1) + "mpsz".substr(a / 9, 1);
    return (a < 27 && (hai136 % 36) == 16 ? "0" : ((a % 9) + 1)) + "mpsz".substr(a / 9, 1);
  },
  expand: function (t) {
    return t
      .replace(/(\d)(\d{0,8})(\d{0,8})(\d{0,8})(\d{0,8})(\d{0,8})(\d{0,8})(\d{8})(m|p|s|z)/g, "$1$9$2$9$3$9$4$9$5$9$6$9$7$9$8$9")
      .replace(/(\d?)(\d?)(\d?)(\d?)(\d?)(\d?)(\d)(\d)(m|p|s|z)/g, "$1$9$2$9$3$9$4$9$5$9$6$9$7$9$8$9") // 57???A???????
      .replace(/(m|p|s|z)(m|p|s|z)+/g, "$1")
      .replace(/^[^\d]/, "");
  },
  contract: function (t) {
    return t
      .replace(/\d(m|p|s|z)(\d\1)*/g, "$&:")
      .replace(/(m|p|s|z)([^:])/g, "$2")
      .replace(/:/g, "");
  },
  exsort: function (t) {
    return t
      .replace(/(\d)(m|p|s|z)/g, "$2$1$1,")
      .replace(/00/g, "50")
      .split(",").sort().join("")
      .replace(/(m|p|s|z)\d(\d)/g, "$2$1");
  },
  exextract136: function (t) {
    var s = t
      .replace(/(\d)m/g, "0$1")
      .replace(/(\d)p/g, "1$1")
      .replace(/(\d)s/g, "2$1")
      .replace(/(\d)z/g, "3$1");
    var i, c = new Array(136);
    for (i = 0; i < s.length; i += 2) {
      var n = s.substr(i, 2),
        k = -1;
      if (n % 10) {
        var b = (9 * Math.floor(n / 10) + ((n % 10) - 1)) * 4;
        k = (!c[b + 3] ? b + 3 : !c[b + 2] ? b + 2 : !c[b + 1] ? b + 1 : b);
      } else {
        k = (9 * n / 10 + 4) * 4 + 0; // aka5
      }
      if (c[k]) console.error("err n=" + n + " k=" + k + "<br>");
      c[k] = 1;
    }
    return c;
  },
  exextract34: function (t) { // Hand tiles to mountain array
    var s = t
      .replace(/(\d)m/g, "0$1")
      .replace(/(\d)p/g, "1$1")
      .replace(/(\d)s/g, "2$1")
      .replace(/(\d)z/g, "3$1");
    var mountain = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
    for (var i = 0; i < s.length; i += 2) {
      var strTile = s.substr(i, 2),
        index = -1;
      if (strTile % 10) {
        index = 9 * Math.floor(strTile / 10) + ((strTile % 10) - 1);
      } else {
        index = 9 * strTile / 10 + 4; // aka5
      }
      if (mountain[index] > 4) console.error("err n=" + strTile + " k=" + index + "<br>");
      mountain[index]++;
    }
    return mountain;
  },
  compile136: function (c) {
    var i, s = "";
    for (i = 0; i < 136; ++i)
      if (c[i]) s += MPSZ.fromHai136(i);
    return s;
  }
};

export const Syanten = { // singleton
  n_eval: 0,
  // input
  c: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
  // status
  n_mentsu: 0,
  n_tatsu: 0,
  n_toitsu: 0,
  n_jidahai: 0, // １３枚にしてから少なくとも打牌しなければならない字牌の数 -> これより向聴数は下がらない
  f_n4: 0, // 27bitを数牌、1bitを字牌で使用
  f_koritsu: 0, // 孤立牌
  // final result
  min_syanten: 8,

  updateResult: function () {
    var e = this;
    var ret_syanten = 8 - e.n_mentsu * 2 - e.n_tatsu - e.n_toitsu;
    var n_mentsu_kouho = e.n_mentsu + e.n_tatsu;
    if (e.n_toitsu) {
      n_mentsu_kouho += e.n_toitsu - 1;
    } else if (e.f_n4 && e.f_koritsu) {
      if ((e.f_n4 | e.f_koritsu) == e.f_n4) ++ret_syanten; // 対子を作成できる孤立牌が無い
    }
    if (n_mentsu_kouho > 4) ret_syanten += (n_mentsu_kouho - 4);
    if (ret_syanten != -1 && ret_syanten < e.n_jidahai) ret_syanten = e.n_jidahai;
    if (ret_syanten < e.min_syanten) e.min_syanten = ret_syanten;
  },


  // method
  init: function (a, n) {
    var e = this;
    e.c = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
    // status
    e.n_mentsu = 0;
    e.n_tatsu = 0;
    e.n_toitsu = 0;
    e.n_jidahai = 0;
    e.f_n4 = 0;
    e.f_koritsu = 0;
    // final result
    e.min_syanten = 8;

    var c = this.c;
    if (n == 136) {
      for (n = 0; n < 136; ++n)
        if (a[n]) ++c[n >> 2];
    } else if (n == 34) {
      for (n = 0; n < 34; ++n) c[n] = a[n];
    } else {
      for (n -= 1; n >= 0; --n) ++c[a[n] >> 2];
    }
  },
  count34: function () {
    var c = this.c;
    return c[0] + c[1] + c[2] + c[3] + c[4] + c[5] + c[6] + c[7] + c[8] +
      c[9] + c[10] + c[11] + c[12] + c[13] + c[14] + c[15] + c[16] + c[17] +
      c[18] + c[19] + c[20] + c[21] + c[22] + c[23] + c[24] + c[25] + c[26] +
      c[27] + c[28] + c[29] + c[30] + c[31] + c[32] + c[33];
  },

  i_anko: function (k) {
    this.c[k] -= 3, ++this.n_mentsu;
  },
  d_anko: function (k) {
    this.c[k] += 3, --this.n_mentsu;
  },
  i_toitsu: function (k) {
    this.c[k] -= 2, ++this.n_toitsu;
  },
  d_toitsu: function (k) {
    this.c[k] += 2, --this.n_toitsu;
  },
  i_syuntsu: function (k) {
    --this.c[k], --this.c[k + 1], --this.c[k + 2], ++this.n_mentsu;
  },
  d_syuntsu: function (k) {
    ++this.c[k], ++this.c[k + 1], ++this.c[k + 2], --this.n_mentsu;
  },
  i_tatsu_r: function (k) {
    --this.c[k], --this.c[k + 1], ++this.n_tatsu;
  },
  d_tatsu_r: function (k) {
    ++this.c[k], ++this.c[k + 1], --this.n_tatsu;
  },
  i_tatsu_k: function (k) {
    --this.c[k], --this.c[k + 2], ++this.n_tatsu;
  },
  d_tatsu_k: function (k) {
    ++this.c[k], ++this.c[k + 2], --this.n_tatsu;
  },
  i_koritsu: function (k) {
    --this.c[k], this.f_koritsu |= (1 << k);
  },
  d_koritsu: function (k) {
    ++this.c[k], this.f_koritsu &= ~(1 << k);
  },

  scanChiitoiKokushi: function () {
    var e = this;
    var syanten = e.min_syanten;
    var c = e.c;
    var n13 = // 幺九牌の対子候補の数
      (c[0] >= 2) + (c[8] >= 2) +
      (c[9] >= 2) + (c[17] >= 2) +
      (c[18] >= 2) + (c[26] >= 2) +
      (c[27] >= 2) + (c[28] >= 2) + (c[29] >= 2) + (c[30] >= 2) + (c[31] >= 2) + (c[32] >= 2) + (c[33] >= 2);
    var m13 = // 幺九牌の種類数
      (c[0] != 0) + (c[8] != 0) +
      (c[9] != 0) + (c[17] != 0) +
      (c[18] != 0) + (c[26] != 0) +
      (c[27] != 0) + (c[28] != 0) + (c[29] != 0) + (c[30] != 0) + (c[31] != 0) + (c[32] != 0) + (c[33] != 0);
    var n7 = n13 + // 対子候補の数
      (c[1] >= 2) + (c[2] >= 2) + (c[3] >= 2) + (c[4] >= 2) + (c[5] >= 2) + (c[6] >= 2) + (c[7] >= 2) +
      (c[10] >= 2) + (c[11] >= 2) + (c[12] >= 2) + (c[13] >= 2) + (c[14] >= 2) + (c[15] >= 2) + (c[16] >= 2) +
      (c[19] >= 2) + (c[20] >= 2) + (c[21] >= 2) + (c[22] >= 2) + (c[23] >= 2) + (c[24] >= 2) + (c[25] >= 2);
    var m7 = m13 + // 牌の種類数
      (c[1] != 0) + (c[2] != 0) + (c[3] != 0) + (c[4] != 0) + (c[5] != 0) + (c[6] != 0) + (c[7] != 0) +
      (c[10] != 0) + (c[11] != 0) + (c[12] != 0) + (c[13] != 0) + (c[14] != 0) + (c[15] != 0) + (c[16] != 0) +
      (c[19] != 0) + (c[20] != 0) + (c[21] != 0) + (c[22] != 0) + (c[23] != 0) + (c[24] != 0) + (c[25] != 0); { // 七対子
      var ret_syanten = 6 - n7 + (m7 < 7 ? 7 - m7 : 0);
      if (ret_syanten < syanten) syanten = ret_syanten;
    } { // 国士無双
      var ret_syanten = 13 - m13 - (n13 ? 1 : 0);
      if (ret_syanten < syanten) syanten = ret_syanten;
    }
    return syanten;
  },
  removeJihai: function (nc) { // 字牌
    var e = this;
    var c = e.c;
    var j_n4 = 0; // 7bitを字牌で使用
    var j_koritsu = 0; // 孤立牌
    var i;
    for (i = 27; i < 34; ++i) switch (c[i]) {
      case 4:
        ++e.n_mentsu, j_n4 |= (1 << (i - 27)), j_koritsu |= (1 << (i - 27)), ++e.n_jidahai;
        break;
      case 3:
        ++e.n_mentsu;
        break;
      case 2:
        ++e.n_toitsu;
        break;
      case 1:
        j_koritsu |= (1 << (i - 27));
        break;
    }
    if (e.n_jidahai && (nc % 3) == 2) --e.n_jidahai;

    if (j_koritsu) { // 孤立牌が存在する
      e.f_koritsu |= (1 << 27);
      if ((j_n4 | j_koritsu) == j_n4) e.f_n4 |= (1 << 27); // 対子を作成できる孤立牌が無い
    }
  },
  removeJihaiSanma19: function (nc) { // 字牌
    var e = this;
    var c = e.c;
    var j_n4 = 0; // 7+9bitを字牌で使用
    var j_koritsu = 0; // 孤立牌
    var i;
    for (i = 27; i < 34; ++i) switch (c[i]) {
      case 4:
        ++e.n_mentsu, j_n4 |= (1 << (i - 18)), j_koritsu |= (1 << (i - 18)), ++e.n_jidahai;
        break;
      case 3:
        ++e.n_mentsu;
        break;
      case 2:
        ++e.n_toitsu;
        break;
      case 1:
        j_koritsu |= (1 << (i - 18));
        break;
    }
    for (i = 0; i < 9; i += 8) switch (c[i]) {
      case 4:
        ++e.n_mentsu, j_n4 |= (1 << i), j_koritsu |= (1 << i), ++e.n_jidahai;
        break;
      case 3:
        ++e.n_mentsu;
        break;
      case 2:
        ++e.n_toitsu;
        break;
      case 1:
        j_koritsu |= (1 << i);
        break;
    }
    if (e.n_jidahai && (nc % 3) == 2) --e.n_jidahai;

    if (j_koritsu) { // 孤立牌が存在する
      e.f_koritsu |= (1 << 27);
      if ((j_n4 | j_koritsu) == j_n4) e.f_n4 |= (1 << 27); // 対子を作成できる孤立牌が無い
    }
  },
  scanNormal: function (init_mentsu) {
    var e = this;
    var c = e.c;
    e.f_n4 |= // 孤立しても対子(雀頭)になれない数牌
      ((c[0] == 4) << 0) | ((c[1] == 4) << 1) | ((c[2] == 4) << 2) | ((c[3] == 4) << 3) | ((c[4] == 4) << 4) | ((c[5] == 4) << 5) | ((c[6] == 4) << 6) | ((c[7] == 4) << 7) | ((c[8] == 4) << 8) |
      ((c[9] == 4) << 9) | ((c[10] == 4) << 10) | ((c[11] == 4) << 11) | ((c[12] == 4) << 12) | ((c[13] == 4) << 13) | ((c[14] == 4) << 14) | ((c[15] == 4) << 15) | ((c[16] == 4) << 16) | ((c[17] == 4) << 17) |
      ((c[18] == 4) << 18) | ((c[19] == 4) << 19) | ((c[20] == 4) << 20) | ((c[21] == 4) << 21) | ((c[22] == 4) << 22) | ((c[23] == 4) << 23) | ((c[24] == 4) << 24) | ((c[25] == 4) << 25) | ((c[26] == 4) << 26);
    this.n_mentsu += init_mentsu;
    e.Run(0);
  },

  Run: function (depth) { // ネストは高々１４回
    var e = this;
    ++e.n_eval;
    if (e.min_syanten == -1) return; // 和了は１つ見つければよい
    var c = e.c;
    for (; depth < 27 && !c[depth]; ++depth); // skip
    if (depth == 27) return e.updateResult();

    var i = depth;
    if (i > 8) i -= 9;
    if (i > 8) i -= 9; // mod_9_in_27
    switch (c[depth]) {
      case 4:
        // 暗刻＋順子|搭子|孤立
        e.i_anko(depth);
        if (i < 7 && c[depth + 2]) {
          if (c[depth + 1]) e.i_syuntsu(depth), e.Run(depth + 1), e.d_syuntsu(depth); // 順子
          e.i_tatsu_k(depth), e.Run(depth + 1), e.d_tatsu_k(depth); // 嵌張搭子
        }
        if (i < 8 && c[depth + 1]) e.i_tatsu_r(depth), e.Run(depth + 1), e.d_tatsu_r(depth); // 両面搭子
        e.i_koritsu(depth), e.Run(depth + 1), e.d_koritsu(depth); // 孤立
        e.d_anko(depth);
        // 対子＋順子系 // 孤立が出てるか？ // 対子＋対子は不可
        e.i_toitsu(depth);
        if (i < 7 && c[depth + 2]) {
          if (c[depth + 1]) e.i_syuntsu(depth), e.Run(depth), e.d_syuntsu(depth); // 順子＋他
          e.i_tatsu_k(depth), e.Run(depth + 1), e.d_tatsu_k(depth); // 搭子は２つ以上取る必要は無い -> 対子２つでも同じ
        }
        if (i < 8 && c[depth + 1]) e.i_tatsu_r(depth), e.Run(depth + 1), e.d_tatsu_r(depth);
        e.d_toitsu(depth);
        break;
      case 3:
        // 暗刻のみ
        e.i_anko(depth), e.Run(depth + 1), e.d_anko(depth);
        // 対子＋順子|搭子
        e.i_toitsu(depth);
        if (i < 7 && c[depth + 1] && c[depth + 2]) {
          e.i_syuntsu(depth), e.Run(depth + 1), e.d_syuntsu(depth); // 順子
        } else { // 順子が取れれば搭子はその上でよい
          if (i < 7 && c[depth + 2]) e.i_tatsu_k(depth), e.Run(depth + 1), e.d_tatsu_k(depth); // 嵌張搭子は２つ以上取る必要は無い -> 対子２つでも同じ
          if (i < 8 && c[depth + 1]) e.i_tatsu_r(depth), e.Run(depth + 1), e.d_tatsu_r(depth); // 両面搭子
        }
        e.d_toitsu(depth);
        // 順子系
        if (i < 7 && c[depth + 2] >= 2 && c[depth + 1] >= 2) e.i_syuntsu(depth), e.i_syuntsu(depth), e.Run(depth), e.d_syuntsu(depth), e.d_syuntsu(depth); // 順子＋他
        break;
      case 2:
        // 対子のみ
        e.i_toitsu(depth), e.Run(depth + 1), e.d_toitsu(depth);
        // 順子系
        if (i < 7 && c[depth + 2] && c[depth + 1]) e.i_syuntsu(depth), e.Run(depth), e.d_syuntsu(depth); // 順子＋他
        break;
      case 1:
        // 孤立牌は２つ以上取る必要は無い -> 対子のほうが向聴数は下がる -> ３枚 -> 対子＋孤立は対子から取る
        // 孤立牌は合計８枚以上取る必要は無い
        if (i < 6 && c[depth + 1] == 1 && c[depth + 2] && c[depth + 3] != 4) { // 延べ単
          e.i_syuntsu(depth), e.Run(depth + 2), e.d_syuntsu(depth); // 順子＋他
        } else {
          //				if (n_koritsu<8) e.i_koritsu(depth), e.Run(depth+1), e.d_koritsu(depth);
          e.i_koritsu(depth), e.Run(depth + 1), e.d_koritsu(depth);
          // 順子系
          if (i < 7 && c[depth + 2]) {
            if (c[depth + 1]) e.i_syuntsu(depth), e.Run(depth + 1), e.d_syuntsu(depth); // 順子＋他
            e.i_tatsu_k(depth), e.Run(depth + 1), e.d_tatsu_k(depth); // 搭子は２つ以上取る必要は無い -> 対子２つでも同じ
          }
          if (i < 8 && c[depth + 1]) e.i_tatsu_r(depth), e.Run(depth + 1), e.d_tatsu_r(depth);
        }
        break;
    }
  },
  calcSyanten(a, n, bSkipChiitoiKokushi) {
    //	var e=new SYANTEN(a,n);
    var e = Syanten;
    e.init(a, n);
    var nc = e.count34();
    if (nc > 14) return -2; // ネスト検査が爆発する
    if (!bSkipChiitoiKokushi && nc >= 13) e.min_syanten = e.scanChiitoiKokushi(nc); // １３枚より下の手牌は評価できない
    e.removeJihai(nc);
    //	e.removeJihaiSanma19(nc);
    var init_mentsu = Math.floor((14 - nc) / 3); // 副露面子を逆算
    e.scanNormal(init_mentsu);
    return e.min_syanten;
  },
  calcSyanten2(a, n) {
    //	var e=new SYANTEN(a,n);
    var e = Syanten;
    e.init(a, n);
    var nc = e.count34();
    if (nc > 14) return undefined; // ネスト検査が爆発する
    var syanten = [e.min_syanten, e.min_syanten];
    if (nc >= 13) syanten[0] = e.scanChiitoiKokushi(nc); // １３枚より下の手牌は評価できない
    e.removeJihai(nc);
    //	e.removeJihaiSanma19(nc);
    var init_mentsu = Math.floor((14 - nc) / 3); // 副露面子を逆算
    e.scanNormal(init_mentsu);
    syanten[1] = e.min_syanten;
    if (syanten[1] < syanten[0]) syanten[0] = syanten[1];
    return syanten;
  }

};

export const evaluateAttack = (hand, mountain) => { // as number[34]
  const restc = waitings => { // : number,
    let rest = 0;
    waitings.forEach(tileIndex => rest += mountain[tileIndex]);
    return rest;
  }
  const options = [];
  const syanten_org = Syanten.calcSyanten2(hand, 34)[0]; // 向听数：-1 和牌，0 听牌
  if (syanten_org == -1) return options; // 和牌
  else if (syanten_org == 0) { // 听牌
    for (let i = 0; i < 34; i++) { // 遍历打/摸
      if (!hand[i]) continue;
      hand[i]--; // 打
      const waitings = [];
      for (let j = 0; j < 34; j++) {
        if (i == j || hand[j] >= 4) continue;
        hand[j]++; // 摸
        if (Agari.isAgari(hand)) waitings.push(j);
        hand[j]--;
      }
      hand[i]++;
      if (waitings.length) options.push({ tileIndex: i, n: restc(waitings) });
    }
  } else {
    for (let i = 0; i < 34; i++) {
      if (!hand[i]) continue;
      hand[i]--; // 打
      const waitings = [];
      for (let j = 0; j < 34; ++j) {
        if (i == j || hand[j] >= 4) continue;
        hand[j]++; // 摸
        if (Syanten.calcSyanten2(hand, 34)[0] == syanten_org - 1) waitings.push(j);
        hand[j]--;
      }
      hand[i]++;
      if (waitings.length) options.push({ tileIndex: i, n: restc(waitings) });
    }
  }
  if (!options.length) return options;
  options.sort((a, b) => b.n - a.n);
  let maxn = options[0].n;
  options.forEach(option => option.rate = option.n / maxn);
  return options;
}

export const evaluateDefense = (hand, mountain, defenseInfo) => {

  const TILE_SAFETY_RATE = {
    Genbutsu: [1, 0], // 现物
    TankiZ: [0.9, 0], // 单骑字牌
    Suji19: [0.4, 0.2], // 筋牌19 仅单骑 看牌数
    Kyakufuu: [0.2, 0.3], // 客风 看牌数
    Suji28: [0.2, 0], // 筋牌28
    NakaSuji: [0.2, 0], // 两筋456
    Suji37: [0, 0], //筋牌37
    Yakuhai: [-0.2, 0], // 役牌
    Musuji19: [-0.5, 0], // 无筋19
    Katasuji: [-0.5, 0], // 半筋456
    Musuji2378: [-0.75, 0], // 无筋2378
    Musuji456: [-1, 0] //无筋456
  }

  const TILE_GROUP = {
    Z: new Array(34).fill(false).map((v, i) => i >= 27 ? true : false),
    N19: new Array(34).fill(false).map((v, i) => i < 27 && (i % 9 === 0 || i % 9 === 8) ? true : false),
    N28: new Array(34).fill(false).map((v, i) => i < 27 && (i % 9 === 1 || i % 9 === 7) ? true : false),
    N37: new Array(34).fill(false).map((v, i) => i < 27 && (i % 9 === 2 || i % 9 === 6) ? true : false),
    N456: new Array(34).fill(false).map((v, i) => i < 27 && (i % 9 >= 3 && i % 9 <= 5) ? true : false),
  }

  const findGenbutsuInRiver = (tileIndex, seat) => {
    for (const tile of defenseInfo.river[seat]) {
      if (tile === tileIndex) return true;
    }
    return false;
  }
  const isYakuhai = (tileIndex, seat) => {
    if (!TILE_GROUP.Z[tileIndex]) return false;
    const n = tileIndex - 27;
    return n >= 4 || n == (seat - defenseInfo.ju + 4) % 4 || n == defenseInfo.chang;
  };
  const playersDangerRate = [];
  const mySeat = defenseInfo.mySeat;
  for (let seat = 0; seat < 4; seat++) { // Evaluate danger rates
    playersDangerRate[seat] = 0;
    if (seat === mySeat) continue;
    playersDangerRate[seat] += Math.min(defenseInfo.river[seat].length / 24, 0.8); // For each discard + 1/24
    playersDangerRate[seat] += defenseInfo.fuuro[seat].length * 0.1; // For each tile of fuuro + 0.1
    playersDangerRate[seat] = Math.min(playersDangerRate[seat], 1);
  }
  const safetyRate = [];
  hand.forEach((count, tileIndex) => { // Evaluate hand tiles
    if (!count) return;
    safetyRate[tileIndex] = [];
    for (let seat = 0; seat < 4; seat++) {
      if (seat === mySeat) continue;
      safetyRate[tileIndex][seat] = 1;
      if (findGenbutsuInRiver(tileIndex, seat, mySeat)) { // Genbutsu
        safetyRate[tileIndex][seat] = TILE_SAFETY_RATE.Genbutsu[0];
        continue;
      }
      if (TILE_GROUP.Z[tileIndex]) {
        if (mountain[tileIndex] === 0) { // TankiZ
          safetyRate[tileIndex][seat] = TILE_SAFETY_RATE.TankiZ[0];
        } else {
          if (isYakuhai(tileIndex, seat)) { // Yakuhai
            safetyRate[tileIndex][seat] = TILE_SAFETY_RATE.Yakuhai[0];
          } else { // Kyakufuu
            safetyRate[tileIndex][seat] = TILE_SAFETY_RATE.Kyakufuu[0];
            safetyRate[tileIndex][seat] += TILE_SAFETY_RATE.Kyakufuu[1] * (3 - mountain[tileIndex]);
          }
        }
        continue;
      }
      if (TILE_GROUP.N19[tileIndex]) { // Suji19
        const suji = tileIndex + (tileIndex % 9 === 0 ? 3 : -3);
        const hasSuji = findGenbutsuInRiver(suji, seat, mySeat);
        if (hasSuji) {
          safetyRate[tileIndex][seat] = TILE_SAFETY_RATE.Suji19[0];
          safetyRate[tileIndex][seat] += TILE_SAFETY_RATE.Suji19[1] * (3 - mountain[suji]);
        } else { // Musuji19
          safetyRate[tileIndex][seat] = TILE_SAFETY_RATE.Musuji19[0];
        }
        continue;
      }
      if (TILE_GROUP.N28[tileIndex]) { // Suji28
        const suji = tileIndex + (tileIndex % 9 === 1 ? 3 : -3);
        const hasSuji = findGenbutsuInRiver(suji, seat, mySeat);
        if (hasSuji) {
          safetyRate[tileIndex][seat] = TILE_SAFETY_RATE.Suji28[0];
        } else { // Musuji28
          safetyRate[tileIndex][seat] = TILE_SAFETY_RATE.Musuji2378[0];
        }
        continue;
      }
      if (TILE_GROUP.N456[tileIndex]) { // Nakasuji 456
        const suji1 = tileIndex - 3;
        const suji2 = tileIndex + 3;
        const hasSuji1 = findGenbutsuInRiver(suji1, seat, mySeat);
        const hasSuji2 = findGenbutsuInRiver(suji2, seat, mySeat);
        if (hasSuji1 && hasSuji2) {
          safetyRate[tileIndex][seat] = TILE_SAFETY_RATE.NakaSuji[0];
        } else if (hasSuji1 || hasSuji2) { // Katasuji
          safetyRate[tileIndex][seat] = TILE_SAFETY_RATE.Katasuji[0];
        } else { // Musuji
          safetyRate[tileIndex][seat] = TILE_SAFETY_RATE.Musuji456[0];
        }
        continue;
      }
      if (TILE_GROUP.N37[tileIndex]) { // Suji37
        const suji = tileIndex + (tileIndex % 9 === 2 ? 3 : -3);
        const hasSuji = findGenbutsuInRiver(suji, seat, mySeat);
        if (hasSuji) {
          safetyRate[tileIndex][seat] = TILE_SAFETY_RATE.Suji37[0];
        } else { // Musuji37
          safetyRate[tileIndex][seat] = TILE_SAFETY_RATE.Musuji2378[0];
        }
        continue;
      }
    }
  })

  const options = [];
  safetyRate.forEach((tileRate, tileIndex) => {
    if (!tileRate) return;
    options.push({ tileIndex, rate: tileRate.reduce((a, v, i) => typeof v === "number" ? Math.min(a, (v - 1) * playersDangerRate[i] + 1) : a, 1) })
  })
  options.sort((a, b) => b.rate - a.rate);
  const highest = options[0].rate;
  const lowest = options[options.length - 1].rate
  options.forEach((el) => el.rate = (el.rate - lowest) / (highest - lowest));
  return options;
}

export const analyzeMahjongSkill = (mahjongData: GameLog[] | undefined, uid: string | undefined) => {
  if (!mahjongData || !uid) return [undefined, undefined];
  const data = mahjongData;

  const totalMatchAmount = data.length;
  const wonMatch = data.filter(match => match.winner === match.players.indexOf(match.players.find(player => player.user_id === uid)!));
  const lostMatch = data.filter(match => match.loser === match.players.indexOf(match.players.find(player => player.user_id === uid)!));

  const largeHandMatch = wonMatch.filter(match => match.score > 3);
  // const fastWinMatch = wonMatch.filter(match => {
  //   const currentPlayer = match.players.find(player => player.user_id === uid);
  //   return currentPlayer!.action ? currentPlayer!.action.length < 10 : true
  // });

  const calcWinAbility = (wonMatchAmount: number, totalMatchAmount: number) => {
    const realWinProb = Math.round(wonMatchAmount / totalMatchAmount * 100) * 3;
    return realWinProb > 100 ? 100 : realWinProb;
  }

  const calcWinScoreAverage = (wonMatch: GameLog[]) => {
    return Math.round(_(wonMatch).meanBy(match => match.score > 10 ? 10 : match.score < 0 ? 0 : match.score)) * 10;
  }

  const calcNotLoseAbility = (lostMatchAmount: number, totalMatchAmount: number) => {
    const realLoseProb = (lostMatchAmount / totalMatchAmount) * 3;
    return Math.round((1 - (realLoseProb > 1 ? 1 : realLoseProb)) * 100);
  }

  const calcNotLetOtherClaimAbility = (data: GameLog[]) => {
    const allActionDataOfCurrentPlayer = data.reduce<TurnActionType[]>((buffer, match) => {
      const currentPlayer = match.players.find(player => player.user_id === uid);

      if (currentPlayer?.actions) {
        buffer.push(...currentPlayer.actions);
      }
      return buffer;
    }, []);

    const allDiscardAction = allActionDataOfCurrentPlayer.filter(action => action.hasOwnProperty("discard"));
    const discardActionWithOtherClaimed = allDiscardAction.filter(action => action.hasOwnProperty("to"));

    return Math.round((1 - (discardActionWithOtherClaimed.length / allDiscardAction.length)) * 100);
  }

  const calcSpeedAbility = (wonMatch: GameLog[]) => {
    return Math.round(_(wonMatch).meanBy(match => {
      const currentPlayer = match.players.find(player => player.user_id === uid);
      const winRound = currentPlayer!.actions ? currentPlayer!.actions.length : 0;

      // Calculate the score, 24 round(巡) is the maximum round for one match
      const relativeScoreOfUsedActionToWin = (winRound / 18);
      return (1 - (relativeScoreOfUsedActionToWin > 1 ? 1 : relativeScoreOfUsedActionToWin)) * 100;
    }));
  }

  const calcClaimAbility = (wonMatch: GameLog[]) => {
    // Find average claim amount per match. 4 is most (suppose)
    const mean = Math.round(_(wonMatch).meanBy(match => {
      const currentPlayer = match.players.find(player => player.user_id === uid);
      const claimCount = currentPlayer!.actions ? currentPlayer!.actions.filter(action => action.hasOwnProperty("chow") || action.hasOwnProperty("pong") || action.hasOwnProperty("gong")).length : 0;
      const claimScoreSingle = (claimCount / 4) * (4 / 3);
      return Math.round(claimScoreSingle * 100);
    }));

    return mean > 100 ? 100 : mean;
  }

  // 摸完即刻打同一隻
  const calcDrawAndDiscardSameTileAbility = (data: GameLog[]) => {
    const allActionDataOfCurrentPlayer = data.reduce<TurnActionType[]>((buffer, match) => {
      const currentPlayer = match.players.find(player => player.user_id === uid);

      if (currentPlayer?.actions) {
        buffer.push(...currentPlayer.actions);
      }
      return buffer;
    }, []);

    const discardDifferentTileAmount = allActionDataOfCurrentPlayer.filter(action =>
      action.hasOwnProperty("discard") &&
      action.hasOwnProperty("draw") &&
      (action as any)["draw"] !== (action as any)["discard"]
    ).length;

    const discardSameTileAmount = allActionDataOfCurrentPlayer.filter(action =>
      action.hasOwnProperty("discard") &&
      !action.hasOwnProperty("draw")
    ).length;

    const discardSameTileProb = discardSameTileAmount / (discardDifferentTileAmount + discardSameTileAmount);
    return Math.round((1 - discardSameTileProb) * 100);
  }

  const calcLargeHandAbility = (largeHandMatchAmount: number, totalWinAmount: number) => {
    return Math.round(largeHandMatchAmount / totalWinAmount * 100);
  }


  const attackSkillWeightSchema = [
    { "name": "勝利能力評分", "func": () => calcWinAbility(wonMatch.length, totalMatchAmount), "scale": 0.5, value: 0 },
    { "name": "平均番數評分", "func": () => calcWinScoreAverage(wonMatch), "scale": 0.5, value: 0 }
  ];

  const defenceSkillWeightSchema = [
    { "name": "防守能力評分", "func": () => calcNotLoseAbility(lostMatch.length, totalMatchAmount), "scale": 0.5, value: 0 },
    { "name": "扣牌能力評分", "func": () => calcNotLetOtherClaimAbility(data), "scale": 0.5, value: 0 },
  ];

  const speedSkillWeightSchema = [
    { "name": "勝利速度評分", "func": () => calcSpeedAbility(wonMatch), "scale": 0.75, value: 0 },
    { "name": "鳴牌能力評分", "func": () => calcClaimAbility(wonMatch), "scale": 0.25, value: 0 },
  ];

  const luckSkillWeightSchema = [
    { "name": "大糊比率評分", "func": () => calcLargeHandAbility(largeHandMatch.length, wonMatch.length), "scale": 0.5, value: 0 },
    { "name": "入章幾率評分", "func": () => calcDrawAndDiscardSameTileAbility(data), "scale": 0.5, value: 0 }
  ];

  attackSkillWeightSchema.forEach((entry) => entry.value = isNaN(entry.func()) ? 0 : entry.func());
  defenceSkillWeightSchema.forEach((entry) => entry.value = isNaN(entry.func()) ? 0 : entry.func());
  speedSkillWeightSchema.forEach((entry) => entry.value = isNaN(entry.func()) ? 0 : entry.func());
  luckSkillWeightSchema.forEach((entry) => entry.value = isNaN(entry.func()) ? 0 : entry.func());

  const basic = [
    { "skill": "攻", data: Math.round(attackSkillWeightSchema.reduce<number>((buffer, value) => { buffer += (value.value * value.scale); return buffer; }, 0)) },
    { "skill": "防", data: Math.round(defenceSkillWeightSchema.reduce<number>((buffer, value) => { buffer += (value.value * value.scale); return buffer; }, 0)) },
    { "skill": "速", data: Math.round(speedSkillWeightSchema.reduce<number>((buffer, value) => { buffer += (value.value * value.scale); return buffer; }, 0)) },
    { "skill": "運", data: Math.round(luckSkillWeightSchema.reduce<number>((buffer, value) => { buffer += (value.value * value.scale); return buffer; }, 0)) },
  ];

  const details = [
    { "skill": "勝利能力", data: attackSkillWeightSchema[0].value },
    { "skill": "進攻力量", data: attackSkillWeightSchema[1].value },

    { "skill": "防守能力", data: defenceSkillWeightSchema[0].value },
    { "skill": "扣牌能力", data: defenceSkillWeightSchema[1].value },

    { "skill": "勝利速度", data: speedSkillWeightSchema[0].value },
    { "skill": "鳴牌能力", data: speedSkillWeightSchema[1].value },

    { "skill": "大牌能力", data: luckSkillWeightSchema[0].value },
    { "skill": "入章運氣", data: luckSkillWeightSchema[1].value },
  ];

  return [basic, details];
}

export const analyzeSingleMatchMahjongSkill = (mahjongData: GameLog | undefined, uid: string | undefined, attackAbility: number, defenseAbility: number) => {
  if (!mahjongData || !uid) return [undefined, undefined];
  const data = [mahjongData];

  const calcSpeedAbility = (wonMatch: GameLog[]) => {
    return Math.round(_(wonMatch).meanBy(match => {
      const currentPlayer = match.players.find(player => player.user_id === uid);
      const winRound = currentPlayer!.actions ? currentPlayer!.actions.length : 0;

      // Calculate the score, 24 round(巡) is the maximum round for one match
      const relativeScoreOfUsedActionToWin = (winRound / 18);
      return (1 - (relativeScoreOfUsedActionToWin > 1 ? 1 : relativeScoreOfUsedActionToWin)) * 100;
    }));
  }

  const calcClaimAbility = (wonMatch: GameLog[]) => {
    // Find average claim amount per match. 4 is most (suppose)
    const mean = Math.round(_(wonMatch).meanBy(match => {
      const currentPlayer = match.players.find(player => player.user_id === uid);
      const claimCount = currentPlayer!.actions ? currentPlayer!.actions.filter(action => action.hasOwnProperty("chow") || action.hasOwnProperty("pong") || action.hasOwnProperty("gong")).length : 0;
      const claimScoreSingle = (claimCount / 4) * (4 / 3);
      return Math.round(claimScoreSingle * 100);
    }));

    return mean > 100 ? 100 : mean;
  }

  // 摸完即刻打同一隻
  const calcDrawAndDiscardSameTileAbility = (data: GameLog[]) => {
    const allActionDataOfCurrentPlayer = data.reduce<TurnActionType[]>((buffer, match) => {
      const currentPlayer = match.players.find(player => player.user_id === uid);

      if (currentPlayer?.actions) {
        buffer.push(...currentPlayer.actions);
      }
      return buffer;
    }, []);

    const discardDifferentTileAmount = allActionDataOfCurrentPlayer.filter(action =>
      action.hasOwnProperty("discard") &&
      action.hasOwnProperty("draw") &&
      (action as any)["draw"] !== (action as any)["discard"]
    ).length;

    const discardSameTileAmount = allActionDataOfCurrentPlayer.filter(action =>
      action.hasOwnProperty("discard") &&
      !action.hasOwnProperty("draw")
    ).length;

    const discardSameTileProb = discardSameTileAmount / (discardDifferentTileAmount + discardSameTileAmount);
    return Math.round((1 - discardSameTileProb) * 100);
  }

  const calcLargeHandAbility = (largeHandMatchAmount: number, totalWinAmount: number) => {
    return Math.round(largeHandMatchAmount / totalWinAmount * 100);
  }

  const speedSkillWeightSchema = [
    { "name": "鳴牌能力評分", "func": () => calcClaimAbility(data), "scale": 1, value: 0 },
  ];

  const luckSkillWeightSchema = [
    { "name": "入章幾率評分", "func": () => calcDrawAndDiscardSameTileAbility(data), "scale": 1, value: 0 }
  ];

  speedSkillWeightSchema.forEach((entry) => entry.value = isNaN(entry.func()) ? 0 : entry.func());
  luckSkillWeightSchema.forEach((entry) => entry.value = isNaN(entry.func()) ? 0 : entry.func());

  const basic = [
    { "skill": "攻", data: Math.round(attackAbility * 100) },
    { "skill": "防", data: Math.round(defenseAbility * 100) },
    { "skill": "速", data: Math.round(speedSkillWeightSchema.reduce<number>((buffer, value) => { buffer += (value.value * value.scale); return buffer; }, 0)) },
    { "skill": "運", data: Math.round(luckSkillWeightSchema.reduce<number>((buffer, value) => { buffer += (value.value * value.scale); return buffer; }, 0)) },
  ];

  return basic;
}