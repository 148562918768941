import { Container, Divider, Paper, Typography } from '@material-ui/core';
import moment from 'moment';
import React, { useEffect, useState } from "react";
import { AxisDomain, CartesianGrid, Legend, ResponsiveContainer, Scatter, ScatterChart, Tooltip, XAxis, YAxis } from 'recharts';
import { actions, ActionType, actionTypeColors, MahjongGameBehaviorTrendChartData } from "../types/GameBehaviorLog";

const domainColors = ['#ff3333', '#9966cc', '#66cc33', '#ffcc33', '#33ccff', '#ff6699'];
export const playerColors = ['#FF6B6Bcc', '#ffa726cc', '#66bb6acc', '#29b6f6cc', '#F6511Dcc', '#7256a9cc', '#82ca9dcc', '#777b88cc', '#a18276cc', '#345995cc', '#15616dcc', '#FFCAB1cc', '#88292Fcc', '#ec407acc'];

interface MahjongGameBehaviorChartProps {
  data: MahjongGameBehaviorTrendChartData | undefined;
  selectedYear: number | undefined,
  selectedMonth: number | undefined,
  mode: 0 | 1
}

const mahjongGameBehaviorChartColor: { [key: string]: string } = {
  "s0": "#040804",
  "s1": "#081108",
  "s2": "#0f2310",
  "s3": "#173518",
  "s4": "#1e4620",
  "s5": "#265828",
  "s6": "#2e6930",
  "s7": "#357a38",
  "s8": "#3d8c40",
  "s9": "#449e48",
  "s10": "#4caf50",
  "other": "#999999",
  "tie": "#ffca28",
  "lose": "#f44336"
}

const mahjongGameBehaviorChartLoseColor: { [key: string]: string } = {
  "s0": "#6e1111",
  "s1": "#921616",
  "s2": "#b71c1c",
  "s3": "#c62828",
  "s4": "#d32f2f",
  "s5": "#e53935",
  "s6": "#f44336",
  "s7": "#ef5350",
  "s8": "#e57373",
  "s9": "#ef9a9a",
  "s10": "#ffcdd2",
}

function CustomTooltip(props: any) {
  if (props.active && props.payload && props.payload[0] && props.payload[0].payload) {

    const measureUnitKey = props.payload[0].payload.mode === 0 ? "平均反應時間" : "平均錯誤率";
    const measureUnit = props.payload[0].payload.mode === 0 ? "秒" : "%"

    return (
      <div>
        <Paper variant="outlined" style={{ width: '250px', textAlign: 'left' }} >
          <Container style={{ marginTop: "15px", marginBottom: "15px" }}>
            <Typography variant="h6" align="center" style={{ fontWeight: "bold", marginBottom: "10px" }}>{props.payload[0].payload.actionType}</Typography>
            <div style={{ marginTop: "10px", fontSize: "14px" }}>
              <Divider />
              <table style={{ width: "100%", marginTop: "10px", marginBottom: "10px" }}><tbody>
                <tr style={{ width: "100%" }}><td align="right" style={{ width: "50%", color: "#999999" }}>統計時間段&nbsp;</td><td style={{ width: "50%" }}>&nbsp;{moment.utc(props.payload[0].payload.time).local().format('YYYY年 MMM Do')}</td></tr>
                <tr style={{ width: "100%" }}><td align="right" style={{ width: "50%", color: "#999999" }}>{measureUnitKey}&nbsp;</td><td style={{ width: "50%" }}>&nbsp;{props.payload[0].payload.value + measureUnit}</td></tr>
              </tbody></table>
            </div>
          </Container>
        </Paper>
      </div>)
  } else {
    return (
      null
    )
  }
}

export default function MahjongGameBehaviorChart(props: MahjongGameBehaviorChartProps) {
  const [chartData, setChartData] = useState<MahjongGameBehaviorTrendChartData | undefined>();
  const [customTicks, setCustomTicks] = useState<number[]>([]);
  const [customDomain, setCustomDomain] = useState<[AxisDomain, AxisDomain]>();

  useEffect(() => {
    setChartData(props.data);
  }, [props.data]);

  useEffect(() => {
    setCustomTicks(generateTick());
    setCustomDomain(computeCustomDomain());
  }, [chartData]);

  const computeCustomDomain = (): [AxisDomain, AxisDomain] => {
    return props.selectedMonth ?
      [
        moment((props.selectedYear ? props.selectedYear.toString() : moment().format("YYYY")) + "-" + props.selectedMonth).startOf("month").valueOf() as AxisDomain,
        moment((props.selectedYear ? props.selectedYear.toString() : moment().format("YYYY")) + "-" + props.selectedMonth).endOf("month").valueOf() as AxisDomain
      ] :
      [
        moment(props.selectedYear ? props.selectedYear.toString() : moment().format("YYYY")).startOf("year").valueOf() as AxisDomain,
        moment(props.selectedYear ? props.selectedYear.toString() : moment().format("YYYY")).endOf("year").valueOf() as AxisDomain
      ]
  }

  const generateTick = () => {
    const start: moment.Moment = moment((props.selectedYear ? props.selectedYear.toString() : moment().format("YYYY")) + (props.selectedMonth ? `-${props.selectedMonth}` : ""));
    const resultArray = [];

    if (!props.selectedMonth) {
      for (const end = start.clone().add(1, 'year'); start.isBefore(end); start.add(1, 'month')) {
        resultArray.push(start.valueOf());
      }
    }
    else {
      for (const end = start.clone().add(1, 'month'); start.isBefore(end); start.add(1, 'day')) {
        resultArray.push(start.valueOf());
      }
    }

    return resultArray;
  }
  return (
    <>
      <ResponsiveContainer width="100%" height={350}>
        {chartData && chartData !== {} && (
          <ScatterChart
            width={1}
            height={288}
            margin={{
              top: 15, right: 30, left: 10, bottom: 65,
            }}
          >
            <CartesianGrid />
            <XAxis type="number" interval={0} dataKey={'time'} name="Time"
              domain={customDomain}
              tickFormatter={(unixTime) => props.selectedMonth ? moment(unixTime).format('MMM Do') : moment(unixTime).format('YYYY年 MMM')}
              ticks={customTicks}
              angle={-35}
              textAnchor="end"
            />
            <YAxis
              type="number"
              dataKey={'value'}
              name="value"
              domain={props.mode === 0 ? [0, 10] : [0, 100]}
              tickFormatter={(v) => props.mode === 0 ? (v + "秒") : (v + "%")}
              allowDecimals={false}
            />
            <Tooltip cursor={{ strokeDasharray: '3 3' }} content={<CustomTooltip />} />
            <Legend wrapperStyle={{ bottom: "30px" }} />
            {
              chartData &&
              Object.entries(chartData).map(([actionType, actionAvgData], index) => {
                return (
                  <Scatter
                    name={actions[actionType as ActionType]}
                    data={Object.entries(actionAvgData).map(([timestamp, avgData]) => {
                      return { time: parseInt(timestamp), value: avgData[props.mode === 0 ? "reactionTime" : "wrongRate"], actionType: actions[actionType as ActionType], mode: props.mode }
                    })}
                    fill={actionTypeColors[actionType as ActionType]}
                    line
                  />
                )
              })
            }
          </ScatterChart>
        )}
      </ResponsiveContainer>
    </>
  )
}