import React from 'react';
import { CSSTransition } from 'react-transition-group';

export const ReactFade = (props: { in: boolean, timeout?: number, children: React.ReactNode }) => {

  return (
    <CSSTransition
      in={props.in}
      timeout={{
        appear: 600,
        enter: 600,
        exit: 300,
      }}
      classNames="fade"
      unmountOnExit
    >
      <>
        {props.children}
      </>
    </CSSTransition>
  )
};