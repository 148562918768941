import { Button, Card, Divider, Grid, Typography, useMediaQuery, useTheme } from "@material-ui/core";
import React from "react";

export const PlanCard = (props: { planTitle: string, desc: string, content: any[], priceText: any, route: () => void }) => {

  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.down('xs'));

  return (
    <Grid item xs={12} sm={6} md={6} lg={3} xl={3} style={{ maxWidth: 300 }}>
      <Card className="plan-type-card" style={{ transition: "transform .25s", margin: "0 auto", width: 280, minHeight: 300, borderRadius: 10, boxShadow: "0 8px 20px -12px rgb(0 0 0 / 30%)", backgroundColor: "#f4f4ff" }}>
        <div style={{ margin: 20 }}>
          <Typography align="center" style={{ fontWeight: "bold", marginBottom: 10, fontSize: 24 }} color="primary">
            {props.planTitle}
          </Typography>

          <Typography align="center" style={{ color: "#888", fontWeight: "normal", marginBottom: 20, fontSize: 14 }} color="primary">
            {props.desc}
          </Typography>

          <Divider variant="middle" />

          <div style={{ marginLeft: 5, marginTop: 20, marginBottom: 20, minHeight: isXs ? undefined : 155, display: "flex", flexDirection: "column", textAlign: "left" }}>
            {props.content.map(line => <Typography style={{ fontSize: 18, color: "#11729c", margin: 2 }}>{line}</Typography>)}
          </div>

          <Typography align="center" style={{ fontWeight: "bold", marginBottom: 20, fontSize: 24, minHeight: 100, display: "flex", flexDirection: "column", alignContent: "center", justifyContent: "center" }}>
            {props.priceText}
          </Typography>

          <Button variant="contained" disableElevation fullWidth color="primary" onClick={props.route}>
            註冊
          </Button>
        </div>
      </Card>
    </Grid>
  )
}