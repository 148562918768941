import { SetAccountsAction, ClearAccountsAction } from "../types/Actions";
import { LoadableAccountData } from "../reducers/accounts";

export const setAccounts = (
  accounts: LoadableAccountData
): SetAccountsAction => ({
  type: "SET_ACCOUNTS",
  accounts,
});

export const clearAccounts = (): ClearAccountsAction => ({
  type: "CLEAR_ACCOUNTS",
});
