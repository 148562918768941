import { Card, CircularProgress, createStyles, Grid, makeStyles, MenuItem, TextField, Theme } from '@material-ui/core';
import Backdrop from "@material-ui/core/Backdrop";
import moment from 'moment';
import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { MahjongGameAnalysisTrendChartData } from '../types/GameBehaviorLog';
import { GameLog } from '../types/GameLog';
import MahjongGameAnalysisChart from './MahjongGameAnalysisChart';
import { Month, months, translationMonth } from "./PlaytimeTab";

const StyledVerticalTabContainer = styled.div`
  // flex-grow: 1;
  // display: flex;
`
const StyledTabPanel = styled.div`
  width: 100%;
`

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      justifyContent: "center"
    },
    scroller: {
      flexGrow: 0
    },
  }),
);

interface MahjongGameAnalysisChartTabsProps {
  uid: string,
  data: GameLog[] | undefined;
  mode: 0 | 1 | 2
}

function a11yProps(index: any) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index } = props;

  return (
    <StyledTabPanel
      hidden={value !== index}
    >
      {children}
    </StyledTabPanel>
  );
}

function usePrevious(value: any) {
  const ref = useRef(undefined);
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

export default function MahjongGameAnalysisChartTabs(props: MahjongGameAnalysisChartTabsProps) {
  const [rawChartData, setRawChartData] = useState<MahjongGameAnalysisTrendChartData[]>();

  const [chartData, setChartData] = useState<MahjongGameAnalysisTrendChartData[]>();

  const [loading, setLoading] = useState<boolean>(true);

  const [timeUnit, setTimeUnit] = useState<{ year: number, month: number } | undefined>({ year: moment().year(), month: moment().month() });

  const classes = useStyles();

  useEffect(() => {
    setLoading(false);
  }, [])

  useEffect(() => {
    if (rawChartData)
      setChartData(extractDataByTime(rawChartData));
  }, [rawChartData, timeUnit])

  useEffect(() => {
    if (props.data)
      setRawChartData(generateChartData(props.data ?? []));
  }, [props.data, props.mode])

  const generateChartData = (data: GameLog[]): MahjongGameAnalysisTrendChartData[] => {
    const reducer = data.reduce<{ [key: number]: MahjongGameAnalysisTrendChartData }>((buffer, log) => {
      if (!log) return buffer;

      // Get played date
      const playedDate: number = moment(log.game_start_time).startOf("day").valueOf();

      !(buffer[playedDate]) && (buffer[playedDate] = {
        time: playedDate,
        s0: 0,
        s1: 0,
        s2: 0,
        s3: 0,
        s4: 0,
        s5: 0,
        s6: 0,
        s7: 0,
        s8: 0,
        s9: 0,
        s10: 0,
        lose: 0,
        tie: 0,
        other_selfdraw: 0,
        other_eat: 0,
      });

      const currentPlayer = log.players.find((player: any) => player.user_id === props.uid);
      const currentPlayerIndex = log.players.indexOf(currentPlayer!);

      if (props.mode === 0) {
        // If lose
        if (log.loser === currentPlayerIndex) {
          buffer[playedDate].lose--;
        }
        // Other win
        else if (log.loser === -1 && log.winner !== -1 && log.winner !== currentPlayerIndex) {
          buffer[playedDate].other_selfdraw--;
        }
        else if (log.loser !== -1 && log.loser !== currentPlayerIndex && log.winner !== -1 && log.winner !== currentPlayerIndex) {
          buffer[playedDate].other_eat--;
        }
        // Tie
        else if (log.loser === -1 && log.winner === -1) {
          buffer[playedDate].tie--;
        }
      }

      if ((props.mode === 0 || props.mode === 1) && log.winner === currentPlayerIndex) {
        if (log.score === 0) buffer[playedDate].s0++;
        else if (log.score === 1) buffer[playedDate].s1++;
        else if (log.score === 2) buffer[playedDate].s2++;
        else if (log.score === 3) buffer[playedDate].s3++;
        else if (log.score === 4) buffer[playedDate].s4++;
        else if (log.score === 5) buffer[playedDate].s5++;
        else if (log.score === 6) buffer[playedDate].s6++;
        else if (log.score === 7) buffer[playedDate].s7++;
        else if (log.score === 8) buffer[playedDate].s8++;
        else if (log.score === 9) buffer[playedDate].s9++;
        else if (log.score >= 10) buffer[playedDate].s10++;
        else buffer[playedDate].other_eat++; // Placeholder, will not affect
      }

      if (props.mode === 2 && log.loser === currentPlayerIndex) {
        if (log.score === 0) buffer[playedDate].s0++;
        else if (log.score === 1) buffer[playedDate].s1++;
        else if (log.score === 2) buffer[playedDate].s2++;
        else if (log.score === 3) buffer[playedDate].s3++;
        else if (log.score === 4) buffer[playedDate].s4++;
        else if (log.score === 5) buffer[playedDate].s5++;
        else if (log.score === 6) buffer[playedDate].s6++;
        else if (log.score === 7) buffer[playedDate].s7++;
        else if (log.score === 8) buffer[playedDate].s8++;
        else if (log.score === 9) buffer[playedDate].s9++;
        else if (log.score >= 10) buffer[playedDate].s10++;
      }

      return buffer;
    }, {});

    // delete the object indexing key
    return Object.values(reducer);
  }

  const extractDataByTime = (data: MahjongGameAnalysisTrendChartData[]) => {
    if (!timeUnit) return;

    const startOfMonth = moment(timeUnit!.year + "-" + timeUnit!.month).startOf("month").valueOf();
    const endOfMonth = moment(timeUnit!.year + "-" + timeUnit!.month).endOf("month").valueOf();

    return data.filter(entry => entry.time < endOfMonth && entry.time > startOfMonth);
  }

  return (
    <StyledVerticalTabContainer>
      <Card style={{ position: "relative" }}>
        <Grid container>
          <Grid item>
            <TextField
              id="year"
              select
              label="年份"
              value={timeUnit?.year}
              onChange={(e) => setTimeUnit({ "year": parseInt(e.target.value), "month": timeUnit!.month })}
              margin="normal"
              style={{ width: 200, justifySelf: "flex-start", display: 'flex', margin: 40 }}
            >
              <MenuItem key={"2020"} value={"2020"}>2020年</MenuItem>
              <MenuItem key={"2021"} value={"2021"}>2021年</MenuItem>
              <MenuItem key={"2022"} value={"2022"}>2022年</MenuItem>
            </TextField>
          </Grid>
          <Grid item >
            <TextField
              id="month"
              select
              label="月份"
              value={timeUnit?.month}
              onChange={(e) => setTimeUnit({ "year": timeUnit!.year, "month": parseInt(e.target.value) as Month })}
              margin="normal"
              style={{ width: 200, justifySelf: "flex-start", display: 'flex', margin: 40 }}
            >
              {/* <MenuItem key={-1} value={-1}>全年</MenuItem> */}
              {months.map((option) => (
                <MenuItem key={option} value={option}>
                  {translationMonth[option]}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
        </Grid>

        {chartData && <MahjongGameAnalysisChart selectedYear={timeUnit?.year} selectedMonth={timeUnit?.month} data={chartData} mode={props.mode} />}
        {/* <DomainTaskTable data={tabData.filter(x => x.domain === domain)[0].missionScore} historyData={props.data} selectedTab={selectedTab} missionOnClick={missionOnClick} /> */}

        <Backdrop style={{ position: "absolute", zIndex: 999, background: "rgba(255,255,255,0.3)" }} open={loading}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </Card>
    </StyledVerticalTabContainer >
  )
}

//average={allTabData.filter(x => x.mission === domain)}