import React, { useEffect, useState } from "react";
import { getUserChilds, getUserGameFrequency, getUserMissionSummary } from "../api";
import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from "@material-ui/core";
import { StyledCircularProgress } from "../StyledComponents";

interface PlaytimeDialogProps {
  onClose: () => void,
  isOpen: boolean,
  isCenter: boolean,
  uid: string,
}

export default function PlaytimeDialog(props: PlaytimeDialogProps) {

  const [sum, setSum] = useState<number | null>();

  useEffect(() => {
    setSum(null);
    _getUserMissionFrequency2(props.uid);
  }, [props])

  const _getUserMissionFrequency2 = async (uid: string) => {
    if (props.isCenter === true) {
      let sum = 0;
      const childIds = await getUserChilds(uid);
      if (childIds !== null) {
        for await (const childId of childIds) {
          const temp1 = await _countUserMissionFrequency(childId);
          const temp2 = await getUserGameFrequency(childId);
          if (temp1 !== undefined) {
            sum += temp1;
          }
          if (temp2 !== undefined) {
            sum += temp2;
          }
        }
      }
      setSum(sum);
    } else {
      let sum1 = await _countUserMissionFrequency(uid);
      let sum2 = await getUserGameFrequency(uid);
      setSum(sum1 + sum2);
    }
  }

  const _countUserMissionFrequency = async (uid: string) => {
    const data = await getUserMissionSummary(uid);
    let sum = 0;
    if (data !== null) {
      const missionSummaryArr = Object.values(data);
      missionSummaryArr.forEach(x => {
        if (x.frequency !== undefined) {
          sum += x.frequency;
        }
      })
    }
    return sum;
  }

  return (
    <Dialog onClose={props.onClose} aria-labelledby="simple-dialog-title" open={props.isOpen}>
      <DialogTitle id="simple-dialog-title">所有用家遊玩次數</DialogTitle>
      <DialogContent style={{ padding: 30 }}>
        {sum === null ? <StyledCircularProgress /> : sum + "次"}
      </DialogContent>
      <DialogActions>
        <Button onClick={props.onClose} color="primary">
          確定
        </Button>
      </DialogActions>
    </Dialog>
  )
}