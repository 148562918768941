export interface UserRole {
  uid: string,
  role: RoleType,
}

//Check game Scripts/User/UserRole.cs UserRoleEnum;
export const Role = ["center", "trial_center", "center_user", "trial_center_user", "individual", "trial_individual", "admin", "unknown"] as const;
export type RoleType = typeof Role[number];

export const isTrialAccount = (role: RoleType) => {
  if (role.startsWith("trial")) {
    return true;
  } else {
    return false;
  }
}
