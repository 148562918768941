import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { loggedOut } from "../actions/loggedIn";
import RequestDemoTable from "../components/RequestDemoTable";
import { StyledContainer } from "../StyledComponents";
import { RequestDemo } from "../types/RequestDemo";

export default function RequestDemoPage() {

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(loggedOut());
  }, [])

  return (
    <StyledContainer maxWidth="md">
      <RequestDemoTable />
    </StyledContainer>
  )
}