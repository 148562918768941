import { Container, Grid, Typography } from "@material-ui/core";
import Button from '@material-ui/core/Button';
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import AssessmentIcon from '@material-ui/icons/Assessment';
import GroupIcon from '@material-ui/icons/Group';
import SportsEsportsIcon from '@material-ui/icons/SportsEsports';
import React from "react";
import { useSelector } from "react-redux";
import AppBar from "../components/AppBar";
import { AppState } from "../reducers";
import { StyledContainer } from "../StyledComponents";
import { RoleType, isTrialAccount } from "../types/UserRole";
import RecordVoiceOverIcon from '@material-ui/icons/RecordVoiceOver';

export default function HomePage() {

  const userRole = useSelector<AppState, RoleType | null>(state => state.userRole);

  return (
    <div>
      <AppBar title={"Home"} />
      <StyledContainer style={{ paddingTop: 90, paddingBottom: 80 }}>
        <div style={{ fontSize: 24, color: "red" }}>
          {(process.env.NODE_ENV !== 'production' || process.env.REACT_APP_ENVIRONMENT === "test") && "Every thing you made on this page will only be saved to the development server"}
        </div>
        <Grid container spacing={0} style={{ display: "flex", flex: 1 }}>
          {userRole === 'admin' &&
            <>
              <Grid item sm={6} md={3} xs={6} style={{ justifyContent: 'center', display: 'flex', alignItems: 'center' }}>
                <Grid container >
                  <Grid item sm={12} xl={12} md={12} xs={12} style={{ justifyContent: 'center', display: 'flex' }}>
                    <Button variant="contained" href='/accounts' color="primary" style={{ marginTop: 100, marginLeft: 15, height: 130, width: 180 }}>
                      <AccountBoxIcon color='secondary' style={{ fontSize: 120 }} />
                    </Button>
                  </Grid>
                  <Grid item sm={12} xl={12} md={12} xs={12} style={{ justifyContent: 'center', display: 'flex', height: 130, width: 180 }}>
                    <Typography align='center' color='primary' style={{ fontSize: 25 }}>帳戶</Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item sm={6} md={3} xs={6} style={{ justifyContent: 'center', display: 'flex', alignItems: 'center' }}>
                <Grid container >
                  <Grid item sm={12} xl={12} md={12} xs={12} style={{ justifyContent: 'center', display: 'flex' }}>
                    <Button variant="contained" href='/approvedemo' color="primary" style={{ marginTop: 100, marginLeft: 15, height: 130, width: 180 }}>
                      <SportsEsportsIcon color='secondary' style={{ fontSize: 120 }} />
                    </Button>
                  </Grid>
                  <Grid item sm={12} xl={12} md={12} xs={12} style={{ justifyContent: 'center', display: 'flex', height: 130, width: 180 }}>
                    <Typography align='center' color='primary' style={{ fontSize: 25 }}>試玩申請</Typography>
                  </Grid>
                </Grid>
              </Grid>
            </>}
          <Grid item sm={6} md={userRole === 'admin' ? 3 : ((userRole === 'center') ? 4 : (userRole !== 'trial_center_user' && userRole !== 'trial_individual') ? 6 : 12)} xs={6} style={{ justifyContent: 'center', display: 'flex', alignItems: 'center' }}>
            <Grid container >
              <Grid item sm={12} xl={12} md={12} xs={12} style={{ justifyContent: 'center', display: 'flex' }}>
                <Button variant="contained" href='/users' color="primary" style={{ marginTop: 100, marginLeft: 15, height: 130, width: 180 }}>
                  <GroupIcon color='secondary' style={{ fontSize: 120 }} />
                </Button>
              </Grid>
              <Grid item sm={12} xl={12} md={12} xs={12} style={{ justifyContent: 'center', display: 'flex', height: 130, width: 180 }}>
                <Typography align='center' color='primary' style={{ fontSize: 25 }}>用家</Typography>
              </Grid>
            </Grid>
          </Grid>
          {userRole !== null && !isTrialAccount(userRole) &&
            <Grid item sm={6} md={userRole === 'admin' ? 3 : ((userRole === 'center') ? 4 : 6)} xs={6} style={{ justifyContent: 'center', display: 'flex', alignItems: 'center' }} >
              <Grid container >
                <Grid item sm={12} xl={12} md={12} xs={12} style={{ justifyContent: 'center', display: 'flex' }}>
                  <Button variant="contained" href='/report' color="primary" style={{ marginTop: 100, marginLeft: 15, height: 130, width: 180 }}>
                    <AssessmentIcon color='secondary' style={{ fontSize: 120 }} />
                  </Button>
                </Grid>
                <Grid item sm={12} xl={12} md={12} xs={12} style={{ justifyContent: 'center', display: 'flex', height: 130, width: 180 }}>
                  <Typography align='center' color='primary' style={{ fontSize: 25 }}>報告</Typography>
                </Grid>
              </Grid>
            </Grid>}
          {(userRole === 'center' || userRole === 'trial_center') &&
            <Grid item sm={6} md={4} xs={6} style={{ justifyContent: 'center', display: 'flex', alignItems: 'center' }} >
              <Grid container >
                <Grid item sm={12} xl={12} md={12} xs={12} style={{ justifyContent: 'center', display: 'flex' }}>
                  <Button variant="contained" href='/announcement' color="primary" style={{ marginTop: 100, marginLeft: 15, height: 130, width: 180 }}>
                    <RecordVoiceOverIcon color='secondary' style={{ fontSize: 120 }} />
                  </Button>
                </Grid>
                <Grid item sm={12} xl={12} md={12} xs={12} style={{ justifyContent: 'center', display: 'flex', height: 130, width: 180 }}>
                  <Typography align='center' color='primary' style={{ fontSize: 25 }}>公告</Typography>
                </Grid>
              </Grid>
            </Grid>}
        </Grid>
      </StyledContainer >
      <AppBar title={"Home"} bottom={true} />
    </div >
  )
}