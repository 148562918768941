export interface GameBehaviorLog {
  players: Array<GameBehavior>,
  id: string,
  timestamp?: number
}

export interface GameBehavior {
  behaviors: ActionStatus[],
  user_id: string,
  id?: string
  timestamp?: number
}

export interface ActionStatus {
  status: StatusType,
  time?: number,
  type: ActionType,
}

export interface MahjongGameBehaviorTrendChartData {
  [key: string]: {
    [key: string]: {
      reactionTime: number;
      wrongRate: number;
    };
  };
}

export interface MahjongGameAnalysisTrendChartData {
  time: number,
  s0: number,
  s1: number,
  s2: number,
  s3: number,
  s4: number,
  s5: number,
  s6: number,
  s7: number,
  s8: number,
  s9: number,
  s10: number,
  tie: number,
  lose: number,
  other_selfdraw: number,
  other_eat: number
}

export type MahjongGameAnalysisTrendChartDataKeys = keyof MahjongGameAnalysisTrendChartData;

export const MahjongGameAnalysisTrendChartDataKeyTranslation = {
  time: "時間",
  s0: "雞糊",
  s1: "1番",
  s2: "2番",
  s3: "3番",
  s4: "4番",
  s5: "5番",
  s6: "6番",
  s7: "7番",
  s8: "8番",
  s9: "9番",
  s10: "爆棚",
  tie: "流局",
  lose: "出銃",
  other_selfdraw: "其他人自摸",
  other_eat: "其他人食糊"
}

type StatusType = "success" | "fail" | "miss";
export const actions = {
  "draw": "摸牌",
  "discard": "打牌",
  "chow": "上",
  "pong": "碰",
  "gong": "槓",
  "dark_gong": "暗槓",
  "eat": "食糊",
  "selfdraw": "自摸"
};

export type ActionType = keyof typeof actions;

export const actionTypeColors = {
  "draw": "#78c5d6",
  "discard": "#459ba8",
  "chow": "#79c267",
  "pong": "#c5d647",
  "gong": "#f5d63d",
  "dark_gong": "#f28c33",
  "eat": "#e868a2",
  "selfdraw": "#bf62a6"
}
