import { IconButtonProps } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import IconButton from '@material-ui/core/IconButton';
import React, { useEffect, useState } from 'react';

interface ButtonWithLoadingProps extends IconButtonProps {
  onClick: () => void,
  isLoading: boolean,
  disabled?: boolean,
}

const IconButtonWithLoading = (props: ButtonWithLoadingProps) => {

  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    setIsLoading(props.isLoading);
  }, [props]);

  return (
    <div style={{ position: 'relative' }}>
      <IconButton onClick={props.onClick} disabled={isLoading || props.disabled} style={props.style}>
        {props.children}
      </IconButton>
      {isLoading && <CircularProgress size={24} style={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
      }} />}
    </div>
  )
}

export default IconButtonWithLoading;