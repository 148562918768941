import { Backdrop, Button, Card, CardContent, CardMedia, Checkbox, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Divider, FormControl, FormControlLabel, FormGroup, Grid, InputLabel, List, ListItem, ListItemIcon, ListItemText, ListSubheader, MenuItem, Paper, Select, Tooltip, Typography } from "@material-ui/core";
import { FileCopy, Info, ListAltRounded, RotateLeft, Save } from "@material-ui/icons";
import chroma from "chroma-js";
import _ from "lodash";
import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { getMissionSchedule, saveMissionSchedule } from "../api";
import { AppState } from "../reducers";
import { GridCard } from "../StyledComponents";
import { getChineseDomain, getChineseMission, getChineseTheme, MissionMetadata, MissionType, ThemeType } from "../types/MissionType";
import SimpleSnackBar, { SnackBarProp } from "./SimpleSnackBar";

export const getChineseWeekday = (d: string) => {
  switch (d) {
    case "Mon": return "星期一";
    case "Tue": return "星期二";
    case "Wed": return "星期三";
    case "Thu": return "星期四";
    case "Fri": return "星期五";
    case "Sat": return "星期六";
    case "Sun": return "星期日";
  }
}

export const ScheduleMissionPage = (props: { targetUserId: string }) => {

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [weekday, setWeekday] = useState<string>("Mon");
  const [domain, setDomain] = useState<string>("Overall");
  const [theme, setTheme] = useState<string>("Mahjong");
  const [showHelperTooltip, setShowHelperTooltip] = useState<boolean>(false);

  const [isCopyPanelOpen, setIsCopyPanelOpen] = useState<boolean>(false);
  const [isOverviewPanelOpen, setIsOverviewPanelOpen] = useState<boolean>(false);

  const [selectedMissions, setSelectedMissions] = useState<any>();

  const selectedMissionsComponent = useRef<any>(null);
  const missionMetadata = useSelector<AppState, { metadata: MissionMetadata | null; domainMission: { [key: string]: MissionType[] } }>(state => state.missionMetadata);

  const closeErrorSnackBar = () => {
    setSnackBar({ ...snackBar, "open": false });
  }
  const [snackBar, setSnackBar] = useState<SnackBarProp>({
    open: false,
    onClose: closeErrorSnackBar,
    message: "",
    variant: undefined
  });

  const onReset = () => {
    if (!selectedMissionsComponent.current) return;
    selectedMissionsComponent.current.onReset();
  };

  const onCopy = () => {
    setIsCopyPanelOpen(true);
  }

  const onConfirmCopy = (weekdays: string[]) => {
    selectedMissionsComponent.current.onConfirmCopy(weekday, weekdays);
  }

  const onOverview = () => {
    setIsOverviewPanelOpen(true);
  }

  const onSave = async () => {
    setIsLoading(true);
    const success = await saveMissionSchedule(props.targetUserId, selectedMissions);
    setIsLoading(false);

    if (success)
      setSnackBar({ open: true, message: "儲存成功", variant: "success", onClose: closeErrorSnackBar });
    else
      setSnackBar({ open: true, message: "儲存失敗，請稍後再試", variant: "error", onClose: closeErrorSnackBar });

  }

  const changeTheme = (theme: string) => {
    setTheme(theme);
  }

  useEffect(() => {
    startGetMissionSchedule();
  }, [])

  const startGetMissionSchedule = async () => {
    const data = await getMissionSchedule(props.targetUserId);
    console.log(data);
    setSelectedMissions(data);
    selectedMissionsComponent.current.onInitSelectedMission(data);
    setIsLoading(false);
  }

  return (
    <>
      <Backdrop open={isLoading} style={{ position: "absolute", zIndex: 999, background: "rgba(255,255,255,0.3)" }}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <SimpleSnackBar open={snackBar.open} onClose={closeErrorSnackBar} message={snackBar.message} variant={snackBar.variant} autoClose={snackBar.autoClose} />
      <Grid container spacing={4}>

        <CopyPanel open={isCopyPanelOpen} origin={weekday} closeDialog={() => setIsCopyPanelOpen(false)} onConfirmCopy={onConfirmCopy} />

        <OverviewPanel open={isOverviewPanelOpen} closeDialog={() => setIsOverviewPanelOpen(false)} data={selectedMissions} />

        <Grid item xs={12} sm={12} md={2} lg={2} xl={2} style={{ position: "sticky" }}>
          <LeftSideMenu showTooltip={showHelperTooltip} weekday={weekday} setWeekday={(setWeekday)} selectedMissions={selectedMissions} onReset={onReset} onCopy={onCopy} onOverview={onOverview} onSave={onSave} />
        </Grid>
        <Grid item xs={12} sm={12} md={10} lg={10} xl={10}>
          <Grid container spacing={4}>
            <Grid item xs={3}>
              <Tooltip title="2. 選擇認知領域和遊戲主題，以便找到您的目標遊戲" arrow placement="top" open={showHelperTooltip}>
                <FormControl variant="outlined" style={{ width: "100%" }}>
                  <InputLabel>認知領域</InputLabel>
                  <Select
                    value={domain}
                    onChange={(e) => setDomain(e.target.value as string)}
                    label="認知領域"

                  >
                    <MenuItem value="Overall">全部</MenuItem>
                    <MenuItem value="Attention">集中力</MenuItem>
                    <MenuItem value="Executive">執行能力</MenuItem>
                    <MenuItem value="Memory">記憶力</MenuItem>
                    <MenuItem value="Perception">視覺空間</MenuItem>
                    <MenuItem value="Motor">眼手協調</MenuItem>
                    <MenuItem value="Language">語言</MenuItem>
                  </Select>
                </FormControl>
              </Tooltip>
            </Grid>

            <Grid item xs={3}>
              <FormControl variant="outlined" style={{ width: "100%" }}>
                <InputLabel>主題</InputLabel>
                <Select
                  value={theme}
                  onChange={(e) => changeTheme(e.target.value as string)}
                  label="主題"
                >
                  {/* <MenuItem value="Overall">全部</MenuItem> */}
                  <MenuItem value="Mahjong">麻雀</MenuItem>
                  <MenuItem value="Market">街市</MenuItem>
                  <MenuItem value="OldHK">老香港</MenuItem>
                  <MenuItem value="Outdoor">戶外</MenuItem>
                  <MenuItem value="Restaurant">酒樓</MenuItem>
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={6}>
              <div onMouseOver={() => setShowHelperTooltip(true)} onMouseOut={() => setShowHelperTooltip(false)} style={{ float: "right", marginRight: 20 }}>
                <Info />
              </div>
            </Grid>

          </Grid>

          <Tooltip title="3. 按下方框選取合適的遊戲" arrow placement="bottom-start" open={showHelperTooltip}>
            <div style={{ position: "relative", width: "100%", height: "90%" }}>
              <Grid container spacing={2} style={{ marginTop: 20, position: "absolute", top: 0, bottom: 0, left: 0, right: 0, overflow: "auto", alignContent: "flex-start" }}>
                <MissionGridCards missions={Object.keys(missionMetadata.metadata!)} weekday={weekday} domain={domain} theme={theme} onChangeSelectedMissions={setSelectedMissions} onError={(msg) => setSnackBar({ message: msg, open: true, onClose: closeErrorSnackBar, variant: "warning" })} ref={selectedMissionsComponent} />
              </Grid>
            </div>
          </Tooltip>
        </Grid>
      </Grid>
    </>
  )
}

const LeftSideMenu = (props: { weekday: string, showTooltip: boolean, setWeekday: (d: string) => void, onReset: () => void, onCopy: () => void, onSave: () => void, onOverview: () => void, selectedMissions: any }) => {

  const missionMetadata = useSelector<AppState, { metadata: MissionMetadata | null; domainMission: { [key: string]: MissionType[] } }>(state => state.missionMetadata);

  const getChineseMissionName = (key: string, theme: ThemeType): string => {
    let name: string = key;


    try {
      name = missionMetadata!.metadata![key]!.theme[theme];
    }
    catch (e) {
      try {
        name = missionMetadata!.metadata![key]!.theme["Mahjong"];
      }
      catch (ex) {
        name = key;
      }
    }

    return name;
  }

  const retrieveWeekDayMission = (weekday: string) => {
    if (props.selectedMissions?.[weekday] === undefined) return "尚未編排任務至此日！";

    const result = Object.entries(props.selectedMissions[weekday]).reduce<[string, string][]>((buffer, [missionKey, themes]: [string, any]) => {
      const missionThemes = Object.entries(themes).reduce<[string, string][]>((subBuffer, [themeKey, checked]: [string, any]) => {
        if (checked !== undefined && checked === true)
          subBuffer.push([missionKey, themeKey]);
        return subBuffer;
      }, []);
      return [...buffer, ...missionThemes];
    }, []);

    if (result.length > 0) {
      const list = result.map(([missionKey, theme]: [string, string]) => {
        return <div>{`${getChineseMissionName(missionKey, theme)} (${getChineseTheme(theme)})`}</div>
      });

      return (
        <div style={{ margin: "10px" }}>
          <div style={{ fontSize: 20, textAlign: "center" }}>
            已編排的任務
          </div>
          <Divider style={{ margin: "10px 0", backgroundColor: "#eee" }} />
          {list}
        </div>
      );
    }
    return "尚未編排任務至此日！";
  }

  const handleClickReset = (e: any) => {
    props.onReset();
  }

  const handleClickCopy = (e: any) => {
    props.onCopy();
  }

  const handleClickOverview = (e: any) => {
    props.onOverview();
  }

  const handleSave = () => {
    props.onSave();
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Paper style={{ borderRadius: 10, boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px" }}>
          <Tooltip title="4. 將鼠標懸停在日期上以預覽該日已經編排的任務" arrow placement="left" open={props.showTooltip}>
            <List
              component="nav"
              subheader={
                <Tooltip title="1. 先在下列日期選擇其中一日" arrow placement="left" open={props.showTooltip}>
                  <ListSubheader>
                    <Typography variant="h6" style={{ textAlign: "center", fontWeight: "bold", margin: "10px", color: "#093a4f" }}>
                      星期
                    </Typography>
                  </ListSubheader>
                </Tooltip>
              }
              style={{ paddingBottom: 0, paddingTop: "0.5px" }}
            >
              <Divider />
              <Tooltip title={retrieveWeekDayMission("Mon")} arrow placement="right">
                <ListItem button style={{ textAlign: "center" }} onClick={() => props.setWeekday("Mon")}>
                  <ListItemText disableTypography primary="星期一" style={props.weekday == "Mon" ? { fontWeight: "bold", color: "#093a4f", margin: 7, fontSize: 19 } : { fontWeight: "normal", color: "#000000", margin: 7, fontSize: 18 }} />
                </ListItem>
              </Tooltip>

              <Divider />
              <Tooltip title={retrieveWeekDayMission("Tue")} arrow placement="right">
                <ListItem button style={{ textAlign: "center" }} onClick={() => props.setWeekday("Tue")}>
                  <ListItemText disableTypography primary="星期二" style={props.weekday == "Tue" ? { fontWeight: "bold", color: "#093a4f", margin: 7, fontSize: 19 } : { fontWeight: "normal", color: "#000000", margin: 7, fontSize: 18 }} />
                </ListItem>
              </Tooltip>

              <Divider />
              <Tooltip title={retrieveWeekDayMission("Wed")} arrow placement="right">
                <ListItem button style={{ textAlign: "center" }} onClick={() => props.setWeekday("Wed")}>
                  <ListItemText disableTypography primary="星期三" style={props.weekday == "Wed" ? { fontWeight: "bold", color: "#093a4f", margin: 7, fontSize: 19 } : { fontWeight: "normal", color: "#000000", margin: 7, fontSize: 18 }} />
                </ListItem>
              </Tooltip>

              <Divider />
              <Tooltip title={retrieveWeekDayMission("Thu")} arrow placement="right">
                <ListItem button style={{ textAlign: "center" }} onClick={() => props.setWeekday("Thu")}>
                  <ListItemText disableTypography primary="星期四" style={props.weekday == "Thu" ? { fontWeight: "bold", color: "#093a4f", margin: 7, fontSize: 19 } : { fontWeight: "normal", color: "#000000", margin: 7, fontSize: 18 }} />
                </ListItem>
              </Tooltip>

              <Divider />
              <Tooltip title={retrieveWeekDayMission("Fri")} arrow placement="right">
                <ListItem button style={{ textAlign: "center" }} onClick={() => props.setWeekday("Fri")}>
                  <ListItemText disableTypography primary="星期五" style={props.weekday == "Fri" ? { fontWeight: "bold", color: "#093a4f", margin: 7, fontSize: 19 } : { fontWeight: "normal", color: "#000000", margin: 7, fontSize: 18 }} />
                </ListItem>
              </Tooltip>

              <Divider />
              <Tooltip title={retrieveWeekDayMission("Sat")} arrow placement="right">
                <ListItem button style={{ textAlign: "center" }} onClick={() => props.setWeekday("Sat")}>
                  <ListItemText disableTypography primary="星期六" style={props.weekday == "Sat" ? { fontWeight: "bold", color: "#093a4f", margin: 7, fontSize: 19 } : { fontWeight: "normal", color: "#000000", margin: 7, fontSize: 18 }} />
                </ListItem>
              </Tooltip>

              <Divider />
              <Tooltip title={retrieveWeekDayMission("Sun")} arrow placement="right">
                <ListItem button style={{ textAlign: "center" }} onClick={() => props.setWeekday("Sun")}>
                  <ListItemText disableTypography primary="星期日" style={props.weekday == "Sun" ? { fontWeight: "bold", color: "#093a4f", margin: 7, fontSize: 19 } : { fontWeight: "normal", color: "#000000", margin: 7, fontSize: 18 }} />
                </ListItem>
              </Tooltip>

            </List>
          </Tooltip>
        </Paper>
      </Grid>

      <Grid item xs={12}>
        <Paper style={{ borderRadius: 10, boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px" }}>
          <List
            component="nav"
            subheader={
              <ListSubheader>
                <Typography variant="h6" style={{ textAlign: "center", fontWeight: "bold", margin: "10px", color: "#093a4f" }}>
                  行動
                </Typography>
              </ListSubheader>
            }
            style={{ paddingBottom: 0, paddingTop: "0.5px" }}
          >
            <Divider />
            <Tooltip title="5. 按複製按鈕可以將目前日期的任務複製到你所選的其他日期" arrow placement="top" open={props.showTooltip}>
              <ListItem button>
                <div style={{ margin: "0 auto", display: "flex", alignItems: "center" }} onClick={handleClickCopy}>
                  <ListItemIcon>
                    <FileCopy />
                  </ListItemIcon>
                  <ListItemText primary="複製" />
                </div>
              </ListItem>
            </Tooltip>
            <Divider />

            <Tooltip title="6. 按總覽按鈕可以一次過觀看整個星期編排好的任務" arrow placement="right" open={props.showTooltip}>
              <ListItem button>
                <div style={{ margin: "0 auto", display: "flex", alignItems: "center" }} onClick={handleClickOverview}>
                  <ListItemIcon>
                    <ListAltRounded />
                  </ListItemIcon>
                  <ListItemText primary="總覽" />
                </div>
              </ListItem>
            </Tooltip>
            <Divider />

            <Tooltip title="7. 按重置按鈕可以將目前日期的任務編排清空" arrow placement="left" open={props.showTooltip}>
              <ListItem button>
                <div style={{ margin: "0 auto", display: "flex", alignItems: "center", color: "#E02828" }} onClick={handleClickReset}>
                  <ListItemIcon>
                    <RotateLeft style={{ color: "#E02828" }} />
                  </ListItemIcon>
                  <ListItemText primary="重置" />
                </div>
              </ListItem>
            </Tooltip>
            <Divider />

            <Tooltip title="8. 按儲存按鈕可以將編排好的任務儲存" arrow placement="right" open={props.showTooltip}>
              <ListItem button>
                <div style={{ margin: "0 auto", display: "flex", alignItems: "center", color: "#239139" }} onClick={handleSave}>
                  <ListItemIcon>
                    <Save style={{ color: "#239139" }} />
                  </ListItemIcon>
                  <ListItemText primary="儲存" />
                </div>
              </ListItem>
            </Tooltip>
          </List>
        </Paper>
      </Grid>
    </Grid >
  )
}

const MissionGridCards = forwardRef((props: { weekday: string, domain: string, theme: string, missions: string[], onChangeSelectedMissions: (selectedMissions: any) => void, onError: (message: string) => void }, ref: any) => {
  const [selectedMissions, setSelectedMissions] = useState<any>({});
  const missionMetadata = useSelector<AppState, { metadata: MissionMetadata | null; domainMission: { [key: string]: MissionType[] } }>(state => state.missionMetadata);

  useImperativeHandle(ref, () => ({

    onInitSelectedMission(selected: any) {
      setSelectedMissions(selected);
    },

    onReset() {
      setSelectedMissions((prev: any) => { return { ...prev, [props.weekday]: undefined } });
    },

    onConfirmCopy(origin: string, weekdays: string[]) {
      weekdays.forEach(day => {
        setSelectedMissions((prev: any) => {
          return {
            ...prev,
            [day]: JSON.parse(JSON.stringify(prev[origin]))
          }
        });
      })
    }
  }));

  const removeEmpty = (obj: any): any => {
    return Object.fromEntries(
      Object.entries(obj)
        .filter(([_, v]) => v != null)
        .map(([k, v]) => [k, v === Object(v) ? removeEmpty(v) : v])
    );
  }

  const handleCheckMission = (key: string, theme: ThemeType, checked: boolean) => {
    const temp = Object.assign({}, selectedMissions);

    if (checked === true) {


      if (!temp[props.weekday])
        temp[props.weekday] = {};

      if (!temp[props.weekday][key])
        temp[props.weekday][key] = {};

      const weekDayTotalSelectedMission = _(Object.values(Object.values(temp[props.weekday]))).flatten().filter((val: any) => Object.keys(val).length !== 0).value().length;

      if (weekDayTotalSelectedMission < 6)
        temp[props.weekday][key][theme] = true;
      else
        props.onError("每日只能選擇六個任務");
    }
    else {
      delete temp[props.weekday][key][theme];

      if (_.isEmpty(temp[props.weekday][key])) {
        delete temp[props.weekday][key];

        if (_.isEmpty(temp[props.weekday])) {
          delete temp[props.weekday];
        }
      }
    }

    setSelectedMissions((prev: any) => temp);
  }

  useEffect(() => {
    console.log(selectedMissions);
    props.onChangeSelectedMissions(selectedMissions);
  }, [selectedMissions]);

  return (
    <>
      {
        (missionMetadata !== null && missionMetadata.metadata !== null) &&
        props.missions.filter(mission =>
          (
            props.domain !== "Overall"
              ? (
                missionMetadata.metadata![mission] !== undefined
                && missionMetadata.metadata![mission].domain === props.domain.toLowerCase()
              )
              : true
          )
          &&
          (
            props.theme !== "Overall"
              ? (
                missionMetadata.metadata![mission] !== undefined
                && Object.keys(missionMetadata.metadata![mission].theme).includes(props.theme)
              )
              : true
          )
        ).map(mission => {
          return (
            <MissionCheckCard
              key={mission}
              weekday={props.weekday}
              missionKey={mission}
              theme={props.theme}
              checked={selectedMissions?.[props.weekday]?.[mission]?.[props.theme] === true}
              onCheck={handleCheckMission}
            />
          )
        }
        )
      }
    </>
  )
});

const MissionCheckCard = (props: { weekday: string, missionKey: string, theme: string, checked: boolean, onCheck: (key: string, theme: string, checked: boolean) => void }) => {

  const [imageIcon, setImageIcon] = useState<any>();
  const missionMetadata = useSelector<AppState, { metadata: MissionMetadata | null; domainMission: { [key: string]: MissionType[] } }>(state => state.missionMetadata);

  const getChineseMissionName = (key: string, theme: ThemeType): string => {
    let name: string = key;


    try {
      name = missionMetadata!.metadata![key]!.theme[theme];
    }
    catch (e) {
      try {
        name = missionMetadata!.metadata![key]!.theme["Mahjong"];
      }
      catch (ex) {
        name = key;
      }
    }

    return name;
  }

  useEffect(() => {
    try {
      setImageIcon(require(`../resources/minigame/${props.missionKey}.jpg`));
    }
    catch {
      setImageIcon(require(`../resources/minigame/TrainMode.jpg`));
    }
  }, [])

  const handleChecked = (check: boolean) => {
    props.onCheck(props.missionKey, props.theme, check);
  }

  return (
    <Grid item md={3} sm={6} xs={12}>
      <Card>
        <CardMedia
          component="img"
          alt={props.missionKey}
          height="80"
          image={imageIcon}
          title={props.missionKey}
        />
        <CardContent style={{ padding: 5, display: "flex", justifyContent: "space-between" }}>
          <Typography variant={"h6"} id="discrete-slider" gutterBottom style={{ marginTop: 5, marginLeft: 10 }} align={"left"}>
            {getChineseMissionName(props.missionKey, props.theme)}
          </Typography>
          <Checkbox
            checked={props.checked}
            onChange={(e, check) => handleChecked(check)}
            style={{ float: "right" }}
            color="primary"
          />
        </CardContent>
      </Card>
    </Grid>
  )
}

const CopyPanel = (props: { open: boolean, origin: string, closeDialog: () => void, onConfirmCopy: (weekdays: string[]) => void }) => {

  const [checkedDays, setCheckedDays] = useState<string[]>([])

  const handleCheck = (e: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
    if (checked)
      setCheckedDays(prev => { return [...prev, e.target.name] });
    else
      setCheckedDays(prev => prev.filter(d => d !== e.target.name));
  }

  useEffect(() => {
    setCheckedDays([]);
  }, [props.open]);

  return (
    <Dialog
      open={props.open}
      fullWidth
      onClose={props.closeDialog}
      maxWidth={"sm"}
    >
      <DialogTitle>{`從「${getChineseWeekday(props.origin)}」複製編排任務`}</DialogTitle>
      <DialogContent dividers>
        <FormControl>
          <FormGroup>
            <FormControlLabel
              control={<Checkbox color="primary" checked={checkedDays.includes("Mon")} onChange={handleCheck} name="Mon" />}
              label="星期一"
              disabled={props.origin === "Mon"}
            />
            <FormControlLabel
              control={<Checkbox color="primary" checked={checkedDays.includes("Tue")} onChange={handleCheck} name="Tue" />}
              label="星期二"
              disabled={props.origin === "Tue"}
            />
            <FormControlLabel
              control={<Checkbox color="primary" checked={checkedDays.includes("Wed")} onChange={handleCheck} name="Wed" />}
              label="星期三"
              disabled={props.origin === "Wed"}
            />
            <FormControlLabel
              control={<Checkbox color="primary" checked={checkedDays.includes("Thu")} onChange={handleCheck} name="Thu" />}
              label="星期四"
              disabled={props.origin === "Thu"}
            />
            <FormControlLabel
              control={<Checkbox color="primary" checked={checkedDays.includes("Fri")} onChange={handleCheck} name="Fri" />}
              label="星期五"
              disabled={props.origin === "Fri"}
            />
            <FormControlLabel
              control={<Checkbox color="primary" checked={checkedDays.includes("Sat")} onChange={handleCheck} name="Sat" />}
              label="星期六"
              disabled={props.origin === "Sat"}
            />
            <FormControlLabel
              control={<Checkbox color="primary" checked={checkedDays.includes("Sun")} onChange={handleCheck} name="Sun" />}
              label="星期日"
              disabled={props.origin === "Sun"}
            />
          </FormGroup>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" disableElevation onClick={() => { props.onConfirmCopy(checkedDays); props.closeDialog(); }} color="primary">
          確認複製
        </Button>
        <Button variant="contained" disableElevation onClick={props.closeDialog} color="primary">
          取消
        </Button>
      </DialogActions>
    </Dialog>
  )
}

const OverviewPanel = (props: { open: boolean, closeDialog: () => void, data: any }) => {
  const retrieveWeekDayMission = (weekday: string) => {
    if (props.data?.[weekday] === undefined) return [];

    const result = Object.entries(props.data[weekday]).reduce<[string, string][]>((buffer, [missionKey, themes]: [string, any]) => {
      const missionThemes = Object.entries(themes).reduce<[string, string][]>((subBuffer, [themeKey, checked]: [string, any]) => {
        if (checked !== undefined && checked === true)
          subBuffer.push([missionKey, themeKey]);
        return subBuffer;
      }, []);
      return [...buffer, ...missionThemes];
    }, []);

    return result;
  }

  return (
    <Dialog
      open={props.open}
      fullWidth
      onClose={props.closeDialog}
      maxWidth={"xl"}
    >
      <DialogTitle>{`每週任務總覽`}</DialogTitle>
      <DialogContent dividers>
        <Grid container spacing={2} justify="center" alignItems="stretch">
          <OverviewPanelWeekdayCard day="星期一" data={retrieveWeekDayMission("Mon")} />
          <OverviewPanelWeekdayCard day="星期二" data={retrieveWeekDayMission("Tue")} />
          <OverviewPanelWeekdayCard day="星期三" data={retrieveWeekDayMission("Wed")} />
          <OverviewPanelWeekdayCard day="星期四" data={retrieveWeekDayMission("Thu")} />
          <OverviewPanelWeekdayCard day="星期五" data={retrieveWeekDayMission("Fri")} />
          <OverviewPanelWeekdayCard day="星期六" data={retrieveWeekDayMission("Sat")} />
          <OverviewPanelWeekdayCard day="星期日" data={retrieveWeekDayMission("Sun")} />
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" disableElevation onClick={props.closeDialog} color="primary">
          關閉
        </Button>
      </DialogActions>
    </Dialog>
  )
}

const OverviewPanelWeekdayCard = (props: { day: string, data: [string, string][] }) => {

  const [domainData, setDomainData] = useState<any>();
  const missionMetadata = useSelector<AppState, { metadata: MissionMetadata | null; domainMission: { [key: string]: MissionType[] } }>(state => state.missionMetadata);

  const getDomainColor = (domain: string) => {
    switch (domain) {
      case "Attention":
        return "#cc534b"
      case "Executive":
        return "#6e579d"
      case "Memory":
        return "#3b703f";
      case "Motor":
        return "#4aa5d2"
      case "Perception":
        return "#eab245"
      case "Language":
        return "#e2813b"
      default:
        return "#777"
    }
  }

  const OverviewPanelWeekdayCardDomain = (props: { domain: string, domainMissions: [string, string][] }) => (
    <Grid item xs={12} style={{ maxWidth: "97%" }}>
      <div style={{ minHeight: 93.5, height: "100%", borderRadius: 5, backgroundColor: chroma(getDomainColor(props.domain)).brighten(2).desaturate(0.5).hex(), overflow: "hidden", display: "flex" }}>
        <div style={{ fontWeight: "bold", width: 25, backgroundColor: chroma(getDomainColor(props.domain)).brighten(1.5).desaturate(0.5).hex(), fontSize: 20, display: "flex", justifyContent: "center", alignItems: "center", padding: "8px 5px", writingMode: "vertical-rl", textOrientation: "upright" }}>
          {getChineseDomain(props.domain)}
        </div>
        <div style={{ margin: 10, display: "flex", flexDirection: "column", justifyContent: "center" }}>
          {
            props.domainMissions.map(([key, theme]) => {
              return (
                <div style={{ fontSize: 18 }}>
                  {`${getChineseMission(key)} (${getChineseTheme(theme)})`}
                </div>
              )
            })
          }
        </div>
      </div>
    </Grid>
  );

  useEffect(() => {
    setDomainData(
      props.data.reduce<any>((buffer, [key, theme]) => {
        const domain: string | undefined = missionMetadata.metadata![key]?.domain;
        if (!domain) {
          !("其他" in buffer) && (buffer["其他"] = []);
          buffer["其他"].push([key, theme]);
        }

        !(domain in buffer) && (buffer[domain] = []);
        buffer[domain].push([key, theme]);

        return buffer;
      }, {})
    );
  }, [props.data]);

  return (
    <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
      <GridCard title={props.day} style={{ height: "100%" }}>
        <Grid container spacing={1} style={{ minHeight: 200, margin: "5px 0" }}>
          {domainData?.["attention"] && <OverviewPanelWeekdayCardDomain domain="Attention" domainMissions={domainData?.["attention"] ?? []} />}
          {domainData?.["executive"] && <OverviewPanelWeekdayCardDomain domain="Executive" domainMissions={domainData?.["executive"] ?? []} />}
          {domainData?.["memory"] && <OverviewPanelWeekdayCardDomain domain="Memory" domainMissions={domainData?.["memory"] ?? []} />}
          {domainData?.["motor"] && <OverviewPanelWeekdayCardDomain domain="Motor" domainMissions={domainData?.["motor"] ?? []} />}
          {domainData?.["perception"] && <OverviewPanelWeekdayCardDomain domain="Perception" domainMissions={domainData?.["perception"] ?? []} />}
          {domainData?.["language"] && <OverviewPanelWeekdayCardDomain domain="Language" domainMissions={domainData?.["language"] ?? []} />}
          {domainData?.["其他"] && <OverviewPanelWeekdayCardDomain domain="其他" domainMissions={domainData?.["其他"] ?? []} />}
        </Grid>
      </GridCard>
    </Grid>
  )
}