import {
  SetUserProfilesAction,
  ClearUserProfilesAction,
} from "../types/Actions";
import { LoadableProfileData } from "../reducers/userProfiles";

export const setUserProfiles = (
  userProfiles: LoadableProfileData
): SetUserProfilesAction => ({
  type: "SET_USER_PROFILES",
  userProfiles,
});

export const clearUserProfiles = (): ClearUserProfilesAction => ({
  type: "CLEAR_USER_PROFILES",
});
