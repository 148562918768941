import { Backdrop, CircularProgress } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router";
import { getGameLogById } from "../api";
import AppBar from "../components/AppBar";
import { MahjongGameRecord } from "../components/MahjongGameRecord";
import SimpleSnackBar, { SnackBarProp } from "../components/SimpleSnackBar";
import { AppState } from "../reducers";
import { StyledContainer } from "../StyledComponents";
import { GameLog } from "../types/GameLog";

export const MahjongGameRecordPage = () => {
  const loggedInUser = useSelector<AppState, firebase.User | null>(state => state.loggedInUser);

  const { matchId } = useParams<{ matchId: string }>();

  const [loading, setLoading] = useState<boolean>(true);
  const [gameLog, setGameLog] = useState<GameLog | undefined>();

  useEffect(() => {
    if (matchId && loggedInUser) {
      fetchGameLog();
    }
  }, []);

  useEffect(() => {
    if (!loading && loggedInUser) {
      fetchGameLog();
    }
  }, [loggedInUser]);

  const fetchGameLog = async () => {
    const log = await getGameLogById(matchId);

    setLoading(false);
    setGameLog(log);
  }

  const closeErrorSnackBar = () => {
    setErrorSnackBar({ ...errorSnackBar, "open": false });
  }

  const [errorSnackBar, setErrorSnackBar] = useState<SnackBarProp>({
    open: false,
    variant: "error",
    onClose: closeErrorSnackBar,
    message: "",
  });

  return (
    <div>
      <AppBar title={"麻雀賽局記錄： "} />
      <SimpleSnackBar open={errorSnackBar.open} onClose={closeErrorSnackBar} message={errorSnackBar.message} variant="error" />
      {(loggedInUser) ?
        <StyledContainer style={{ paddingTop: 90, paddingBottom: 80 }}>
          <Backdrop open={loading}>
            <CircularProgress color="inherit" />
          </Backdrop>
          {
            !loading && gameLog === undefined ?
              <SimpleSnackBar open={true} onClose={closeErrorSnackBar} message={"麻雀賽局不存在。如你在賽局結算後遇到此問題，請稍後再進入本頁面"} variant="warning" autoClose={99999} />
              :
              !loading && gameLog !== undefined ?
                <MahjongGameRecord match={gameLog!} opened forceFetch />
                :
                <></>
          }
        </StyledContainer>
        :
        <SimpleSnackBar open={true} onClose={closeErrorSnackBar} message={"You don't have permission to view this page."} variant="warning" autoClose={99999} />}
      <AppBar title={"麻雀賽局記錄"} bottom={true} />
    </div>
  )
}