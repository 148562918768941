import { Container, Divider, Paper, Typography } from '@material-ui/core';
import moment from 'moment';
import React, { useEffect, useState } from "react";
import { AxisDomain, Bar, BarChart, CartesianGrid, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { MahjongGameAnalysisTrendChartData, MahjongGameAnalysisTrendChartDataKeys, MahjongGameAnalysisTrendChartDataKeyTranslation } from "../types/GameBehaviorLog";

const domainColors = ['#ff3333', '#9966cc', '#66cc33', '#ffcc33', '#33ccff', '#ff6699'];
export const playerColors = ['#FF6B6Bcc', '#ffa726cc', '#66bb6acc', '#29b6f6cc', '#F6511Dcc', '#7256a9cc', '#82ca9dcc', '#777b88cc', '#a18276cc', '#345995cc', '#15616dcc', '#FFCAB1cc', '#88292Fcc', '#ec407acc'];

interface MahjongGameAnalysisChartProps {
  data: MahjongGameAnalysisTrendChartData[] | undefined;
  selectedYear: number | undefined,
  selectedMonth: number | undefined,
  mode: 0 | 1 | 2
}

const mahjongGameAnalysisChartColor: { [key: string]: string } = {
  "s0": "#ffff1c",
  "s1": "#c6ff50",
  "s2": "#90fd79",
  "s3": "#58f69e",
  "s4": "#00edbc",
  "s5": "#11c6c1",
  "s6": "#219ec7",
  "s7": "#3277cc",
  "s8": "#424fd1",
  "s9": "#5328d7",
  "s10": "#6300dc",
  "other_selfdraw": "#f28d71",
  "other_eat": "#999999",
  "tie": "#666666",
  "lose": "#f06a6a"
}

const mahjongGameAnalysisChartLoseColor: { [key: string]: string } = {
  "s0": "#f0d735",
  "s1": "#f0c628",
  "s2": "#efb51e",
  "s3": "#eda416",
  "s4": "#ea9313",
  "s5": "#e88713",
  "s6": "#e58213",
  "s7": "#e07116",
  "s8": "#da5f1a",
  "s9": "#d24d1e",
  "s10": "#ca3a22",
}

function CenterCustomTooltip(props: any) {
  if (props.active && props.payload && props.payload[0] && props.payload[0].payload) {
    return (
      <div>
        <Paper variant="outlined" style={{ width: '250px', textAlign: 'left' }} >
          <Container style={{ marginTop: "15px", marginBottom: "15px" }}>
            <Typography variant="h6" align="center" style={{ fontWeight: "bold", marginBottom: "10px" }}>
              {moment.utc(props.payload[0].payload.time).local().format('YYYY年 MMM Do')}
            </Typography>
            <div style={{ marginTop: "10px", fontSize: "14px" }}>
              <Divider />
              <table style={{ width: "100%", marginTop: "10px", marginBottom: "10px" }}><tbody>
                {Object.keys(MahjongGameAnalysisTrendChartDataKeyTranslation).map(k => {
                  if (k === "time" || props.payload[0].payload[k] === 0) return null;
                  return <tr style={{ width: "100%" }}><td align="right" style={{ width: "50%", color: props.mode === 2 ? mahjongGameAnalysisChartLoseColor[k] : mahjongGameAnalysisChartColor[k] }}>{MahjongGameAnalysisTrendChartDataKeyTranslation[k as MahjongGameAnalysisTrendChartDataKeys] ?? "key損毀"}&nbsp;</td><td style={{ width: "50%" }}>&nbsp;{Math.abs(props.payload[0].payload[k] as number) ?? "資料損毀"}</td></tr>
                })}
              </tbody></table>
            </div>
          </Container>
        </Paper>
      </div>)
  } else {
    return (
      null
    )
  }
}

export default function MahjongGameAnalysisChart(props: MahjongGameAnalysisChartProps) {
  const [chartData, setChartData] = useState<MahjongGameAnalysisTrendChartData[] | undefined>();
  const [customTicks, setCustomTicks] = useState<number[]>([]);
  const [customDomain, setCustomDomain] = useState<[AxisDomain, AxisDomain]>();

  useEffect(() => {
    setChartData(props.data);
  }, [props.data]);

  useEffect(() => {
    console.log(chartData);
    setCustomTicks(generateTick());
    setCustomDomain(computeCustomDomain());
  }, [chartData]);

  const renderBars = (mode: number) => {
    switch (mode) {
      case 0: return (
        [
          <Bar dataKey="s0" barSize={21} fill={mahjongGameAnalysisChartColor.s0} stackId="stack" />,
          <Bar dataKey="s1" barSize={21} fill={mahjongGameAnalysisChartColor.s1} stackId="stack" />,
          <Bar dataKey="s2" barSize={21} fill={mahjongGameAnalysisChartColor.s2} stackId="stack" />,
          <Bar dataKey="s3" barSize={21} fill={mahjongGameAnalysisChartColor.s3} stackId="stack" />,
          <Bar dataKey="s4" barSize={21} fill={mahjongGameAnalysisChartColor.s4} stackId="stack" />,
          <Bar dataKey="s5" barSize={21} fill={mahjongGameAnalysisChartColor.s5} stackId="stack" />,
          <Bar dataKey="s6" barSize={21} fill={mahjongGameAnalysisChartColor.s6} stackId="stack" />,
          <Bar dataKey="s7" barSize={21} fill={mahjongGameAnalysisChartColor.s7} stackId="stack" />,
          <Bar dataKey="s8" barSize={21} fill={mahjongGameAnalysisChartColor.s8} stackId="stack" />,
          <Bar dataKey="s9" barSize={21} fill={mahjongGameAnalysisChartColor.s9} stackId="stack" />,
          <Bar dataKey="s10" barSize={21} fill={mahjongGameAnalysisChartColor.s10} stackId="stack" />,
          <Bar dataKey="tie" barSize={21} fill={mahjongGameAnalysisChartColor.tie} stackId="stack" />,
          <Bar dataKey="other_eat" barSize={21} fill={mahjongGameAnalysisChartColor.other_eat} stackId="stack" />,
          <Bar dataKey="other_selfdraw" barSize={21} fill={mahjongGameAnalysisChartColor.other_selfdraw} stackId="stack" />,
          <Bar dataKey="lose" barSize={21} fill={mahjongGameAnalysisChartColor.lose} stackId="stack" />
        ]
      )

      case 1: return (
        [
          <Bar dataKey="s0" fill={mahjongGameAnalysisChartColor.s0} stackId="stack" />,
          <Bar dataKey="s1" fill={mahjongGameAnalysisChartColor.s1} stackId="stack" />,
          <Bar dataKey="s2" fill={mahjongGameAnalysisChartColor.s2} stackId="stack" />,
          <Bar dataKey="s3" fill={mahjongGameAnalysisChartColor.s3} stackId="stack" />,
          <Bar dataKey="s4" fill={mahjongGameAnalysisChartColor.s4} stackId="stack" />,
          <Bar dataKey="s5" fill={mahjongGameAnalysisChartColor.s5} stackId="stack" />,
          <Bar dataKey="s6" fill={mahjongGameAnalysisChartColor.s6} stackId="stack" />,
          <Bar dataKey="s7" fill={mahjongGameAnalysisChartColor.s7} stackId="stack" />,
          <Bar dataKey="s8" fill={mahjongGameAnalysisChartColor.s8} stackId="stack" />,
          <Bar dataKey="s9" fill={mahjongGameAnalysisChartColor.s9} stackId="stack" />,
          <Bar dataKey="s10" fill={mahjongGameAnalysisChartColor.s10} stackId="stack" />
        ]
      )

      case 2: return (
        [
          <Bar dataKey="s0" fill={mahjongGameAnalysisChartLoseColor.s0} stackId="stack" />,
          <Bar dataKey="s1" fill={mahjongGameAnalysisChartLoseColor.s1} stackId="stack" />,
          <Bar dataKey="s2" fill={mahjongGameAnalysisChartLoseColor.s2} stackId="stack" />,
          <Bar dataKey="s3" fill={mahjongGameAnalysisChartLoseColor.s3} stackId="stack" />,
          <Bar dataKey="s4" fill={mahjongGameAnalysisChartLoseColor.s4} stackId="stack" />,
          <Bar dataKey="s5" fill={mahjongGameAnalysisChartLoseColor.s5} stackId="stack" />,
          <Bar dataKey="s6" fill={mahjongGameAnalysisChartLoseColor.s6} stackId="stack" />,
          <Bar dataKey="s7" fill={mahjongGameAnalysisChartLoseColor.s7} stackId="stack" />,
          <Bar dataKey="s8" fill={mahjongGameAnalysisChartLoseColor.s8} stackId="stack" />,
          <Bar dataKey="s9" fill={mahjongGameAnalysisChartLoseColor.s9} stackId="stack" />,
          <Bar dataKey="s10" fill={mahjongGameAnalysisChartLoseColor.s10} stackId="stack" />
        ]
      )
    }
  }

  const computeCustomDomain = (): [AxisDomain, AxisDomain] => {
    return props.selectedMonth ?
      [
        moment((props.selectedYear ? props.selectedYear.toString() : moment().format("YYYY")) + "-" + props.selectedMonth).startOf("month").valueOf() as AxisDomain,
        moment((props.selectedYear ? props.selectedYear.toString() : moment().format("YYYY")) + "-" + props.selectedMonth).endOf("month").valueOf() as AxisDomain
      ] :
      [
        moment(props.selectedYear ? props.selectedYear.toString() : moment().format("YYYY")).startOf("year").valueOf() as AxisDomain,
        moment(props.selectedYear ? props.selectedYear.toString() : moment().format("YYYY")).endOf("year").valueOf() as AxisDomain
      ]
  }

  const generateTick = () => {
    const start: moment.Moment = moment((props.selectedYear ? props.selectedYear.toString() : moment().format("YYYY")) + (props.selectedMonth ? `-${props.selectedMonth}` : ""));
    const resultArray = [];

    if (!props.selectedMonth) {
      for (const end = start.clone().add(1, 'year'); start.isBefore(end); start.add(1, 'month')) {
        resultArray.push(start.valueOf());
      }
    }
    else {
      for (const end = start.clone().add(1, 'month'); start.isBefore(end); start.add(1, 'day')) {
        resultArray.push(start.valueOf());
      }
    }

    return resultArray;
  }
  return (
    <>
      {chartData && chartData !== [] && (
        <ResponsiveContainer width="100%" height={350}>
          <BarChart
            width={500}
            height={350}
            data={chartData}
            stackOffset="sign"
            margin={{
              top: 5, right: 30, left: 20, bottom: 50,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis
              type="number"
              interval={0}
              dataKey={'time'}
              name="Time"
              tickFormatter={(unixTime) => props.selectedMonth ? moment(unixTime).format('MMM Do') : moment(unixTime).format('YYYY年 MMM')}
              ticks={customTicks}
              domain={customDomain}
              angle={-35}
              textAnchor="end"
              axisLine={{ height: 5 }}
            />
            <YAxis
              tickFormatter={(amount) => Math.abs(amount)}
            />
            <Tooltip content={<CenterCustomTooltip />} />
            <Legend
              wrapperStyle={{ bottom: 20 }}
              formatter={(v) => MahjongGameAnalysisTrendChartDataKeyTranslation[v as MahjongGameAnalysisTrendChartDataKeys] ?? "資料損毀"}
            />
            {renderBars(props.mode)}
          </BarChart>
        </ResponsiveContainer>
      )}
    </>
  )
}