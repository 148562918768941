import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, Table, TableBody, TableCell, TableRow, Tooltip, Typography } from "@material-ui/core";
import CloseIcon from '@material-ui/icons/Close';
import GetAppIcon from '@material-ui/icons/GetApp';
import csv from "csv";
import moment from "moment";
import React, { useCallback, useState } from "react";
import { useDropzone } from "react-dropzone";
import { adminRegister } from "../api";
import { ImportGrid } from "../StyledComponents";
import SimpleSnackBar, { SnackBarProp } from "./SimpleSnackBar";
import ButtonWithLoading from "./ButtonWithLoading";

interface ImportCSVDialog {
  open: boolean,
  onClose: () => void,
}

export default function ImportCSVDialog(props: ImportCSVDialog) {

  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const [csvData, setCsvData] = useState<Array<any> | undefined>();

  const closeErrorSnackBar = () => {
    setErrorSnackBar({ ...errorSnackBar, open: false });
  };

  const [errorSnackBar, setErrorSnackBar] = useState<SnackBarProp>({
    open: false,
    onClose: closeErrorSnackBar,
    message: "",
  });

  const onDrop = useCallback(acceptedFiles => {
    const reader = new FileReader();
    reader.onabort = () => console.log("file reading was aborted");
    reader.onerror = () => console.log("file reading failed");
    reader.onload = () => {
      csv.parse(reader.result, (err: any, data: any) => {
        console.log("Parsed CSV data: ", data);
        setCsvData(data);
      });
    };
    acceptedFiles.forEach((file: Blob) => reader.readAsText(file));
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  const handleDialogClose = () => {
    setCsvData(undefined);
    props.onClose();
  }

  const handleImportCancel = () => {
    setCsvData(undefined);
  }

  const handleImportSubmit = async () => {
    if (csvData !== undefined) {
      setIsSubmitting(true);
      const result = await Promise.all(
        csvData.map(async (item, index) => {
          try {
            if (index !== 0) {
              return await adminRegister({
                displayName: item[0],
                email: item[1],
                email_confirmation: item[1],
                password: item[1],
                password_confirmation: item[1],
                name: item[2],
                phone: item[3],
                referralCode: item[4],
                occupation: item[5],
                gender: item[6],
                user_role: item[7],
                register_using: 'email',
                is_dementia_mode: false,
                avatar_url: "",
                user_id: "-1",
                is_valid: true,
                valid_till: moment().add(14, "d").hour(23).minute(59).valueOf(),
              })
            }
          } catch (e) {
            console.log(e);
          }
        })
      )
      setIsSubmitting(false);
      setErrorSnackBar({
        ...errorSnackBar,
        message: "完成匯入帳戶！",
        open: true,
      });
    }
  }

  return (
    <Dialog open={props.open} onClose={handleDialogClose} fullWidth>
      <SimpleSnackBar
        open={errorSnackBar.open}
        onClose={closeErrorSnackBar}
        message={errorSnackBar.message}
      />
      <DialogTitle>
        <Grid container>
          <Grid item md={2} sm={2} xs={2} xl={2}>
            批量匯入
          </Grid>
          <Grid item md={9} sm={9} xs={9} xl={9}>
            <Tooltip title='下載檔案格式'>
              <IconButton color="inherit" style={{ display: "table-cell" }} target="_blank" href='https://drive.google.com/file/d/1oj3efQC-yDe_xsFqaPmoqnE6cffAcG87/view?usp=sharing' size='small'>
                <GetAppIcon />
              </IconButton>
            </Tooltip>
          </Grid>
          <Grid item md={1} sm={1} xs={1} xl={1}>
            <IconButton color="inherit" onClick={handleDialogClose} size='small'>
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <ImportGrid {...getRootProps()}>
          <input {...getInputProps()} />
          <Typography variant='subtitle1'>拖曳csv檔以匯入中心帳戶資料</Typography>
          <Typography variant='subtitle1'>或點擊此方格選擇csv檔</Typography>
        </ImportGrid>
        {csvData !== undefined &&
          <Box style={{ height: 300 }}>
            <div style={{ width: "100%", overflow: "scroll" }}>
              <Table >
                <TableBody>
                  {csvData.map(x =>
                    <TableRow>
                      {(x as string[]).map(y =>
                        <TableCell>{y}</TableCell>
                      )}
                    </TableRow>)}
                </TableBody>
              </Table></div>
          </Box>}
      </DialogContent>
      {csvData !== undefined &&
        <DialogActions>
          <Button
            onClick={handleImportCancel}
            color="primary"
            variant="contained"
          >
            取消資料
        </Button>
          <ButtonWithLoading
            onClick={handleImportSubmit}
            color="primary"
            variant="contained"
            isLoading={isSubmitting}

          >
            確定
        </ButtonWithLoading>
        </DialogActions>}
    </Dialog>
  );
}