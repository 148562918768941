import {
  SetUserValiditiesAction,
  ClearUserValiditiesAction,
} from "../types/Actions";
import { UserValidity } from "../types/UserValidity";

export const setUserValidities = (
  userValidities: UserValidity[]
): SetUserValiditiesAction => ({
  type: "SET_USER_VALIDITIES",
  userValidities,
});

export const clearUserValidities = (): ClearUserValiditiesAction => ({
  type: "CLEAR_USER_VALIDITIES",
});
