import { RoleType } from "../types/UserRole";
import { UserRoleActionTypes } from "../types/Actions";

const userRoleReducerDefaultState: null = null;

const userRoleReducer = (
  state: RoleType | null = userRoleReducerDefaultState,
  action: UserRoleActionTypes
): RoleType | null => {
  switch (action.type) {
    case "SET_USER_ROLE":
      return action.userRole;
    case "CLEAR_USER_ROLE":
      return null;
    default:
      return state;
  }
};
export default userRoleReducer;
