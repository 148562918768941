import React, { useEffect, useState } from "react";
import { CartesianGrid, Legend, Line, LineChart, AreaChart, Area, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { StyledGridHeader } from "../StyledComponents";
import { TextField, MenuItem, Container, Grid } from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { countPlaytime, countPlaytimeWithTypes, formatCalligraphyPlaytimeData } from "../pages/NewReportPage";
import { GameLog } from "../types/GameLog";
import moment from "moment";
import { CalligraphyRecordEntry, CalligraphyStrokeData } from "../types/CalligraphyData";
import value from "*.csv";
import CalligraphyTable from "./CalligraphyDataTable"
import { CalligraphyDataDetailEntry, CalligraphyDataDetailHistory } from "./CalligraphyDataTable"
import { colorScheme, translationCalligraphyType } from "./CalligraphyTab"

export interface CalligraphyTrendChartProps {
  data: CalligraphyTrendChartData[],
  dataKey: string,
  calligraphyType: string[],
  xLabel: string | undefined,
  yLabel: string | undefined,
  tooltipClassifier: string | undefined,
}

type CalligraphyTrendChartData = {
  date: string
} & {
  [key: string]: number
}

export const CalligraphyTrendChart = (props: CalligraphyTrendChartProps) => {
  return (
    <ResponsiveContainer width="100%" height={288}>
      <LineChart
        width={1}
        height={288}
        margin={{
          top: 15, right: 30, left: 10, bottom: 15,
        }}
        data={props.data}
      >
        <CartesianGrid />
        <XAxis dataKey={props.dataKey} name={props.dataKey} label={{ value: props.xLabel, position: "insideBottomRight", dy: 20 }} />
        <YAxis label={{ value: props.yLabel, position: "insideLeft", angle: -90, dy: 20 }} domain={props.yLabel == "%" ? [0, 100] : undefined} />
        <Tooltip cursor={{ strokeDasharray: '3 3' }} formatter={value => { return isNaN(value as any) ? "-" : Math.round((value as number) * 100) / 100 + (props.tooltipClassifier == undefined ? "" : props.tooltipClassifier) }} />
        <Legend />
        {
          props.calligraphyType.map((cType, index) => {
            return (<Line key={cType} name={(cType in translationCalligraphyType) ? translationCalligraphyType[cType] : cType} type={"monotone"} dataKey={cType} stroke={colorScheme[index]} dot={true} connectNulls={true} />)
          })
        }
      </LineChart>
    </ResponsiveContainer>
  );
}