import { CircularProgress, Container, Divider, makeStyles, Tooltip } from "@material-ui/core";
import Card from "@material-ui/core/Card";
import Grid from '@material-ui/core/Grid';
import Table from "@material-ui/core/Table/Table";
import Typography from "@material-ui/core/Typography";
import { Info } from "@material-ui/icons";
import ClearIcon from '@material-ui/icons/Clear';
import DoneIcon from '@material-ui/icons/Done';
import React from "react";
import styled from "styled-components";

export const StyledCorrectIcon = styled(DoneIcon)`
    color: green;
    vertical-align: text-top;
`

export const StyledWrongIcon = styled(ClearIcon)`
    color: red;
    vertical-align: text-top;
`

export const StyledReportCard = styled(Card)`
    min-height: 195px;
`

export const StyledReportTaskName = styled(Typography)`
    font-size: 16px;
    color: #6B6B6B; 
`

export const StyledReportImageAnswer = styled.img`
    height: 61px;
    vertical-align: text-top;
`

export const StyledContainer = styled(Container)`
    padding-top: 32px;
    text-align: left;
`

export const StyledSectionCard = styled(Card)`
    margin-bottom: 20px;
`

export const StyledGridRow = styled(Grid)`
      padding-top: 5px;
      padding-bottom: 5px;
`

export const StyledCircularProgress = styled(CircularProgress)`
    position: absolute;
    top: 50%;
    left: 50%;
    width: 40px;
    height: 40px;
    margin-top: -20px;
    margin-left: -20px;
`

export const StyledLoginCard = styled(Card)`
    padding: 20px;
`

export const StyledLoginForm = styled.form`
    display: flex;
    flex-direction: column;
    width: 100%;
`

export const StyledTotalScoreCard = styled(Card)`
  height:100%;
  margin-bottom: 20px;
  text-align: center
`
export const StyledCenteredLoginCard = styled(Card)`
  position: relative;
  width: 600px;
  min-width: 360px;
  height: 400px;
  margin: 0 auto;
  background-color: rgba(0,0,0,0);
`

export const StyledGridHeader = styled.div`
    padding-top: 30px;
    padding-bottom: 10px;
    font-size: 24px;
`
export const StyledGridItemHeader = styled.div`
    padding-bottom: 10px;
    font-size: 24px;
`

export const StyledGridHeader2 = styled.div`
    padding-left: 15px;
    padding-bottom: 10px;
    font-size: 16px;
    text-align: left;
`
export const ImportGrid = styled.div`
    height: 100px;
    width: 100%;
    border: 1px dashed;
    text-align: center;
`
export const StyledTable = styled(Table)`
  width: 100%;
  over-flow-x: auto;
`

export const gridCardStyles = makeStyles({
    gridCard: {
        background: '#FAFAFA',
        border: 0,
        borderRadius: 10,
        boxShadow: '0 0 15px 0px rgba(0, 0, 0, .2)',
    },
    blur: {
        background: '#FAFAFAAA',
        backdropFilter: "blur(2px)"
    }
});

export const GridItemHeader = (props: { title: React.ReactNode | string, desc?: string, headerAddon?: JSX.Element, backgroundImage?: string, centerTitle?: boolean }) => {
    return (
        <div
            style={{
                backgroundPosition: 'center',
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                background: props.backgroundImage ?
                    `
                linear-gradient(
                    rgba(255, 255, 255, 0.8), 
                    rgba(255, 255, 255, 0.8)
                ),
                url(${props.backgroundImage}) center / cover no-repeat
                ` :
                    undefined
            }}
        >
            <div
                style={{
                    textAlign: (props.centerTitle === undefined || props.centerTitle === false ? 'left' : 'center'),
                    padding: 25,
                    fontSize: 22,
                }}>
                {props.title}
                {
                    props.desc &&
                    <Tooltip title={props.desc} placement="top" arrow={true} color="#093a4f">
                        <Info style={{ marginLeft: 15, width: 20, height: 24, verticalAlign: -4, color: "#093a4f" }} />
                    </Tooltip>
                }
                {props.headerAddon}
            </div>
            <Divider />
        </div>
    )
}

export const GridCard = (props: { style?: any, title: React.ReactNode | string, desc?: string, headerAddon?: JSX.Element, centerTitle?: boolean, blur?: boolean, backgroundImage?: string, children: React.ReactNode }) => {

    const classes = gridCardStyles();

    return (
        <Card style={props.style} className={`${classes.gridCard} ${props.blur !== undefined && props.blur === true ? classes.blur : undefined}`}>
            <GridItemHeader title={props.title} desc={props.desc} headerAddon={props.headerAddon} centerTitle={props.centerTitle} backgroundImage={props.backgroundImage} />
            {props.children}
        </Card>
    )
}