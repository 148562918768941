import Avatar from '@material-ui/core/Avatar'
import CardContent from '@material-ui/core/CardContent'
import Divider from '@material-ui/core/Divider'
import Grid from '@material-ui/core/Grid'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import Tab from '@material-ui/core/Tab'
import Tabs from '@material-ui/core/Tabs'
import Typography from '@material-ui/core/Typography'
import moment from 'moment'
import * as qs from 'query-string'
import React, { createContext, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import styled from "styled-components"
import { setMissionMetadata } from '../actions/missionMetadata'
import { getAllMissionDataOfUser, getMahjongBehaviorAverage, getMissionDomain, getUserCalligraphyData, getUserGameLog, getUserGameLogRankMode, getUserScore, MahjongBehaviorAverageData } from '../api'
import SimpleAppBar from '../components/AppBar'
import CalligraphyTab from '../components/CalligraphyTab'
import MahjongTab from '../components/MahjongTab'
import MissionTab from '../components/MissionTab'
import PlaytimeTab from '../components/PlaytimeTab'
import { ReactFade } from '../components/ReactFade'
import SelectUserDropdown from '../components/SelectUserDropdown'
import SimpleSnackBar, { SnackBarProp } from "../components/SimpleSnackBar"
import { AppState } from '../reducers'
import { StyledCircularProgress } from '../StyledComponents'
import { CalligraphyRecordEntry } from '../types/CalligraphyData'
import { GameLog } from '../types/GameLog'
import { AllMissionDataType } from '../types/MissionData'
import { domains, DomainType, getChineseDomain, MissionMetadata, MissionType } from '../types/MissionType'
import { translation, UserProfile } from '../types/UserProfile'
import { isTrialAccount, RoleType } from '../types/UserRole'
import CenterMissionTab from './CenterMissionTab'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    avatarPos: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    avatarSize: {
      width: theme.spacing(18),
      height: theme.spacing(18),
    },
    grid: {
      flexGrow: 1,
    }
  }),
);

const StyledInfoContent = styled.div`
    font-size: 20px;
    color: #000000; 
    text-align: left;
    width: 100px;
`
const StyledReportHeader = styled.div`
    font-size: 16px;
    padding-bottom: 1px;
    text-align: left;
`
const StyledReportContent = styled.div`
    font-size: 16px;
    color: #757575; 
    padding-top: 8px;
    text-align: left;
`

const StyledGridRow = styled(Grid)`
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 40px;
    padding-right: 40px;
`

export const formatMinigameData = (data: AllMissionDataType[]) => {
  let tempData = data.map(row => {
    const temp = moment.utc(row.task_start_time).local().format('YYYY/MM/DD')
    return { "date": temp, "playtime": (row.task_end_time - row.task_start_time) < 0 ? 0 : (row.task_end_time - row.task_start_time) };
  })
  tempData = tempData.sort(function (a, b) {
    if (a.date < b.date) {
      return -1;
    }
    if (a.date > b.date) {
      return 1;
    }
    return 0;
  });
  return tempData.filter(d => d.playtime > 0);
}

export const MissionMetadataContext = createContext<MissionMetadata | undefined>(undefined);

export const formatCalligraphyPlaytimeData = (data: CalligraphyRecordEntry[]) => {
  let tempData = data.map(row => {
    const temp = moment.utc(row.timestamp).local().format('YYYY/MM/DD')
    return { "date": temp, "playtime": row.data.timeTaken, type: row.type };
  })
  tempData = tempData.sort(function (a, b) {
    if (a.date < b.date) {
      return -1;
    }
    if (a.date > b.date) {
      return 1;
    }
    return 0;
  });
  return tempData;
}

export const formatMahjongData = (data: GameLog[]) => {
  let tempData = data.map(row => {
    const temp = moment.utc(row.game_start_time).local().format('YYYY/MM/DD')
    return { "date": temp, "playtime": row.game_end_time - row.game_start_time };
  })
  tempData = tempData.sort(function (a, b) {
    if (a.date < b.date) {
      return -1;
    }
    if (a.date > b.date) {
      return 1;
    }
    return 0;
  });
  return tempData;
}

export const formatMinigameData1 = (data: AllMissionDataType[]) => {
  let tempData = data.map(row => {
    const temp = moment.utc(row.task_start_time).local().format('YYYY/MM')
    return { "date": temp, "playtime": (row.task_end_time - row.task_start_time) < 0 ? 0 : row.task_end_time - row.task_start_time, "key": row.key };
  })

  console.log(tempData);

  tempData = tempData.sort(function (a, b) {
    if (a.date < b.date) {
      return -1;
    }
    if (a.date > b.date) {
      return 1;
    }
    return 0;
  });
  return tempData;
}

export const formatMahjongData1 = (data: GameLog[]) => {
  let tempData = data.map(row => {
    const temp = moment.utc(row.game_start_time).local().format('YYYY/MM')
    return { "date": temp, "playtime": row.game_end_time - row.game_start_time };
  })
  tempData = tempData.sort(function (a, b) {
    if (a.date < b.date) {
      return -1;
    }
    if (a.date > b.date) {
      return 1;
    }
    return 0;
  });
  return tempData;
}

export const countPlaytime = (tempData: { "date": string, "playtime": number }[]) => {
  let arr: { "date": string, "playtime": number }[] = [{ "date": "", "playtime": 0 }];
  tempData.reduce<any>(function (res, value) {
    if (!res[value.date]) {
      res[value.date] = { date: value.date, playtime: 0 };
      arr.push(res[value.date])
    }
    res[value.date].playtime += value.playtime;
    return res;
  }, {});
  arr = arr.filter(x => x.date !== "" && x.date !== "1970/01/01");
  return arr;
}

export const countPlaytimeWithTypes = (tempData: { "date": string, "payload": number, "type": string }[], timeConvertFunc?: (input: number) => number, avg?: boolean) => {

  const allTypes = tempData.map(d => d.type);
  const distinctTypes: string[] = allTypes.filter((item, pos) => allTypes.indexOf(item) == pos);

  let arr: { "date": string, "type": { [key: string]: { "sum": number, "count": number } | number } }[] = [];
  tempData.reduce<any>(function (res, value) {
    if (!res[value.date]) {
      res[value.date] = { date: value.date, type: distinctTypes.reduce((a: { [key: string]: { "sum": number, "count": number } }, b: string) => (a[b] = { "sum": 0, "count": 0 }, a), {}) };
      arr.push(res[value.date])
    }

    res[value.date].type[value.type].sum += timeConvertFunc ? timeConvertFunc(value.payload) : value.payload;
    res[value.date].type[value.type].count++;

    return res;
  }, {});
  arr = arr.filter(x => x.date !== "" && x.date !== "1970/01/01");

  arr.forEach(entry => {
    Object.entries(entry.type).forEach(([key, value]) => {
      const castValue: { sum: number, count: number } = value as { sum: number, count: number };
      if (avg !== undefined && avg === true)
        entry.type[key] = Math.round(castValue.sum / castValue.count * 100) / 100;
      else
        entry.type[key] = castValue.sum;
    })
  });

  return arr;
}

export const computeProjectedScore = (score: number, level: number, maxLevel: number) => {
  const MINIMUM_LEVEL_SCORE_CEILING = 40;
  const MAXIMUM_LEVEL_SCORE_CEILING = 100;
  const levelCeilingScoreStep = (maxLv: number) => (MAXIMUM_LEVEL_SCORE_CEILING - MINIMUM_LEVEL_SCORE_CEILING) / maxLv;
  const currentLevelScoreCeiling = (currLv: number, maxLv: number) => MINIMUM_LEVEL_SCORE_CEILING + currLv * levelCeilingScoreStep(maxLv);

  return Math.round((score / 10) * currentLevelScoreCeiling(level, maxLevel));
}

export default function NewReportPage() {
  const history = useHistory();
  const dispatch = useDispatch();

  const closeErrorSnackBar = () => {
    setSnackBar({ ...snackBar, "open": false });
  }
  const [snackBar, setSnackBar] = useState<SnackBarProp>({
    open: false,
    onClose: closeErrorSnackBar,
    message: "",
    variant: undefined
  });
  const loggedInUser = useSelector<AppState, firebase.User | null>(state => state.loggedInUser);

  const loggedUserRole = useSelector<AppState, RoleType | null>(state => state.userRole);

  const [selectedUser, setSelectedUser] = useState<UserProfile>();

  const [missionData, setMissionData] = useState<AllMissionDataType[]>();

  const [centerMissionDataSnapshot, setCenterMissionDataSnapshot] = useState<AllMissionDataType[] | undefined>();

  const [calligraphyData, setCalligraphyData] = useState<CalligraphyRecordEntry[]>();

  const [domainsScore, setDomainsScore] = useState<{ "domain": string, "chineseDomain": string, "score": number }[]>();

  const [gameLog, setGameLog] = useState<Array<GameLog>>();

  const [gameLogRankMode, setGameLogRankMode] = useState<Array<GameLog>>();

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [selectedUserReport, setSelectedUserReport] = useState<string>();

  const [tabNumber, setTabNumber] = useState<number>(0);

  const [mahjongBehaviorAverage, setMahjongBehaviorAverage] = useState<MahjongBehaviorAverageData | undefined>();

  const [userScore, setUserScore] = useState<number>(0);

  const missionMetadata = useSelector<AppState, { metadata: MissionMetadata | null; domainMission: { [key: string]: MissionType[] } }>((state: any) => state.missionMetadata);

  const classes = useStyles();

  const thisYear = new Date().getFullYear();

  const heightChange = selectedUser !== undefined && selectedUser.user_id !== "-1";

  const onUserSelectChange = (selectedUserProfile: UserProfile) => {
    if (selectedUserProfile !== undefined) {
      setSelectedUser(selectedUserProfile);
    }
  };

  useEffect(() => {
    // Preload all mission data if the account is center account
    if (loggedUserRole === "center" || loggedUserRole === "trial_center") {

    }

    async function setMissionMD() {
      if (!missionMetadata.metadata)
        dispatch(setMissionMetadata(await getMissionDomain()));
    }
    setMissionMD();

    _setMahjongBehaviorAverage();
  }, [])

  useEffect(() => {
    console.log(missionMetadata.metadata);
  }, [missionMetadata])

  useEffect(() => {
    setIsLoading(true);

    if (selectedUser !== undefined)
      history.push({
        pathname: "/report",
        search: `?uid=${selectedUser?.user_id}`
      });

    const qsObject = qs.parse(window.location.search);
    let uid: string;
    if (qsObject.uid !== undefined && qsObject.uid !== null) {
      if (typeof (qsObject.uid) === "string") {
        uid = qsObject.uid;
        setSelectedUserReport(uid);
      }
    }
    if (selectedUser !== undefined) {
      _setGameLog();
      //_setGameLogRankMode();
      _setMissionData();
      _setCalligraphyData();
      _setUserScore();
    }
    setTabNumber(0);
  }, [selectedUser]);

  useEffect(() => {
    setIsLoading(false);
    _setDomainsScore();
  }, [missionData]);

  useEffect(() => {
    if (gameLog !== undefined) {
      getAvgMahjongGametime(gameLog);
    }
  }, [gameLog])

  const _setGameLog = async () => {
    if (selectedUser !== undefined) {
      const data = await getUserGameLog(selectedUser.user_id);
      setGameLog(data);
    }
  }

  const _setGameLogRankMode = async () => {
    if (selectedUser !== undefined) {
      const temp = await getUserGameLogRankMode(selectedUser.user_id);
      let tempData: GameLog[] = [];
      if (temp !== undefined) {
        temp.forEach(x => {
          if (x.players === undefined) {
            console.log(x);
          }
          x?.players?.forEach(player => {
            if (player.user_id === selectedUser.user_id) {
              tempData.push(x);
            };
          })
        })
      }
      tempData.sort((a, b) => {
        return b.game_start_time - a.game_start_time
      })
      setGameLogRankMode(tempData);
    }
  }

  const _setUserScore = async () => {
    if (selectedUser !== undefined)
      setUserScore(await getUserScore(selectedUser.user_id));
  }

  const _setMahjongBehaviorAverage = async () => {
    setMahjongBehaviorAverage(await getMahjongBehaviorAverage());
  }

  useEffect(() => {
    console.log(mahjongBehaviorAverage);
  }, [mahjongBehaviorAverage]);

  const _setDomainsScore = async () => {
    let scores: {
      "domain": string,
      "chineseDomain": string,
      "score": number
    } = { "domain": "", "chineseDomain": "", "score": 0 };
    let scoresLOL = await Promise.all(
      domains.map(async domain => {
        const score = await getDomainScore(domain);
        scores = {
          "domain": domain,
          "chineseDomain": getChineseDomain(domain),
          "score": score,
        };
        return scores;
      }))
    setDomainsScore(scoresLOL);
  }

  const _setMissionData = async () => {
    // const data = await getAllMissionData()
    if (selectedUser !== undefined) {
      const startCount = window.performance.now();
      let dataArr = await getAllMissionDataOfUser(selectedUser.user_id);
      const endCount = window.performance.now();
      setMissionData(dataArr);

      setSnackBar({
        ...snackBar,
        message: `已載入用家任務數據！（經過時間：${(Math.round(endCount - startCount) / 1000 * 100) / 100}秒）`,
        open: true,
        variant: "success",
      });
    }
  }

  const _setCalligraphyData = async () => {
    if (selectedUser !== undefined) {
      setCalligraphyData(await getUserCalligraphyData(selectedUser.user_id));
    }
  }

  const getMissionScore = async (mission: MissionType) => {
    const result: {
      "mission": MissionType,
      "totalScore": number,
      "score": number
    } = { "mission": "", "totalScore": 0, "score": 0 };
    if (selectedUser !== undefined) {
      if (missionData !== undefined) {
        const data = missionData.filter(x => x.key === mission)
        if (data !== null) {
          result.mission = mission;
          result.score = data.reduce((a, b) => a + b.score, 0);
          result.totalScore = data.reduce((a, b) => a + b.total_score, 0);
        }
        return result;
      }
    }
  }

  const getDomainScore = async (domain: DomainType) => {
    let domainScore = 0;
    let DomainTotalScore = 0;
    let TotalDomainScore = 0;
    console.log(missionMetadata);
    const arr: MissionType[] = missionMetadata.domainMission[domain] ?? [];
    const result = await Promise.all(arr.map(async x => {
      return await getMissionScore(x);
    }))
    if (result !== null) {
      result.forEach(element => {
        if (element !== undefined && element.score !== undefined && element.totalScore !== undefined) {
          TotalDomainScore += element.totalScore;
          DomainTotalScore += element.score;
        }
      })
    }
    domainScore = DomainTotalScore / TotalDomainScore * 100;
    if (isNaN(domainScore)) {
      domainScore = 0;
    }
    return domainScore;
  }

  const getLastPlaytime = (data: AllMissionDataType[]) => {
    console.log(data);
    const temp = data.map(row => {
      if (!row.task_start_time) {
        console.error(row);
      }
      return row.task_start_time;
    })
    const lastPlaytime = Math.max(...temp);
    return moment.utc(lastPlaytime).local().format('YYYY年 MMM Do HH:mm:ss');

  }

  const getAvgDailyPlaytime = (arr: { date: string, playtime: number }[]) => {
    const avgPlaytime = arr.reduce((a, b) => a + b.playtime, 0) / arr.length;
    return Math.round(Math.round(Math.round(avgPlaytime / 1000)) / 60 * 100) / 100;
  }

  const getAvgDailyGames = (counts: { [key: string]: number }) => {
    const temp = Object.values(counts);
    const avgGameNo = temp.reduce((a, b) => a + b, 0) / temp.length;
    return avgGameNo;
  }

  const _getAvgDailyGames = (data: any) => {
    let tempData = data.map((row: any) => {
      if (row.hasOwnProperty("task_start_time")) {
        const temp = moment.utc(row.task_start_time).local().format('YYYY/MM/DD');
        return temp as string;
      } else {
        const temp = moment.utc(row.game_start_time).local().format('YYYY/MM/DD');
        return temp as string;
      }
    })
    tempData = (tempData as string[]).sort(function (a, b) {
      if (a < b) {
        return -1;
      }
      if (a > b) {
        return 1;
      }
      return 0;
    });
    const counts: { [key: string]: number } = {};
    (tempData as string[]).forEach(function (x) {
      counts[x] = (counts[x] || 0) + 1;
    });
    return counts;
  }

  const _getAvgMonthlyGames = (data: any) => {
    let tempData = data.map((row: any) => {
      if (row.hasOwnProperty("task_start_time")) {
        const temp = moment.utc(row.task_start_time).local().format('YYYY/MM');
        return temp as string;
      } else {
        const temp = moment.utc(row.game_start_time).local().format('YYYY/MM');
        return temp as string;
      }
    })
    tempData = (tempData as string[]).sort(function (a, b) {
      if (a < b) {
        return -1;
      }
      if (a > b) {
        return 1;
      }
      return 0;
    });
    const counts: { [key: string]: number } = {};
    (tempData as string[]).forEach(function (x) {
      counts[x] = (counts[x] || 0) + 1;
    });
    return counts;
  }

  const getAvgMonthlyGames = (counts: { [key: string]: number }) => {
    const temp = Object.values(counts);
    const avgGameNo = temp.reduce((a, b) => a + b, 0) / temp.length;
    return avgGameNo;
  }

  const getAvgMahjongGametime = (data: GameLog[]) => {
    console.log();
    const gametimeArr = data.map(x => {
      return x.game_end_time - x.game_start_time;
    });
    const avgGametime = gametimeArr.reduce((a, b) => a + b, 0) / gametimeArr.length;
    return Math.round(Math.round(Math.round(avgGametime / 1000)) / 60 * 100) / 100;
  }

  const handleTabChange = (newValue: number) => {
    setTabNumber(newValue);
  }

  useEffect(() => {
    console.log("tab: " + tabNumber);
  }, [tabNumber]);

  return (

    <div>
      <SimpleAppBar title={'報告'} />
      <SimpleSnackBar open={snackBar.open} onClose={closeErrorSnackBar} message={snackBar.message} variant={snackBar.variant} autoClose={snackBar.autoClose} />
      {(loggedInUser !== undefined && loggedUserRole !== null && !isTrialAccount(loggedUserRole)) ?
        <div className={classes.grid} style={{ paddingTop: 50 }}>
          <Grid container spacing={1}>
            <Grid item sm={3} xs={12}>
              <Typography component="div" style={{ backgroundColor: '#d3ebee', height: heightChange && !isLoading ? '100%' : '120vh', flex: 1 }} >
                <StyledGridRow>
                  <CardContent>
                    {selectedUser !== undefined && selectedUser.user_id !== '-1' ?
                      <div className={classes.avatarPos}>
                        <Avatar src={selectedUser.avatar_url} className={classes.avatarSize} />
                      </div>
                      : null}
                    {loggedInUser !== null && selectedUserReport === undefined ? <SelectUserDropdown onUserSelectChange={onUserSelectChange} /> :
                      <SelectUserDropdown onUserSelectChange={onUserSelectChange} selectedUserId={selectedUserReport} />}
                  </CardContent>
                </StyledGridRow>
                {selectedUser !== undefined && selectedUser.user_id !== '-1' ?
                  <div>
                    <StyledGridRow style={{ display: "flex", flex: 1, flexDirection: "column", alignItems: "center" }}>
                      <StyledInfoContent>
                        性別： {selectedUser.gender}
                      </StyledInfoContent>
                      <StyledInfoContent>
                        年齡： {(selectedUser.year_of_birth !== null && selectedUser.year_of_birth !== undefined) ? thisYear - selectedUser.year_of_birth : null}
                      </StyledInfoContent>
                    </StyledGridRow>
                    {!isLoading &&
                      <>
                        <StyledGridRow>
                          <CardContent>
                            <StyledReportHeader>
                              個人生活習慣
                      </StyledReportHeader>
                            <Divider variant="fullWidth" />
                            {selectedUser.dementia_diagnosis !== undefined &&
                              <StyledReportContent>
                                腦退化症診斷結果： {translation[selectedUser.dementia_diagnosis]}
                              </StyledReportContent>}
                            <StyledReportContent>
                              職業（曾任/現在）： {selectedUser.occupation}
                            </StyledReportContent>
                            {selectedUser.use_smartphone_level !== undefined &&
                              <StyledReportContent>
                                使用智能電話/平板電腦的熟悉度： {translation[selectedUser.use_smartphone_level]}
                              </StyledReportContent>}
                            {selectedUser.education_level !== undefined &&
                              <StyledReportContent>
                                教育程度： {translation[selectedUser.education_level]}
                              </StyledReportContent>}
                            {selectedUser.mahjong_level !== undefined &&
                              <StyledReportContent>
                                打麻雀熟悉度： {translation[selectedUser.mahjong_level]}
                              </StyledReportContent>}
                            {selectedUser.smoke !== undefined &&
                              <StyledReportContent>
                                吸煙習慣： {translation[selectedUser.smoke]}
                              </StyledReportContent>}
                            {selectedUser.drink_alcohol !== undefined &&
                              <StyledReportContent>
                                飲酒習慣： {translation[selectedUser.drink_alcohol]}
                              </StyledReportContent>}
                          </CardContent>
                        </StyledGridRow>
                        <StyledGridRow>
                          <CardContent>
                            <StyledReportHeader >
                              遊戲時間
                          </StyledReportHeader>
                            <Divider variant="fullWidth" />
                            <StyledReportContent>
                              最後遊戲時間：<br />{missionData !== undefined && missionData.length !== 0 ? getLastPlaytime(missionData) : "沒有資料"}
                            </StyledReportContent>
                            <StyledReportContent>
                              平均每日遊戲時長：{missionData !== undefined && missionData.length !== 0 ? getAvgDailyPlaytime(countPlaytime(formatMinigameData(missionData))) + "分鐘" : "沒有資料"}
                            </StyledReportContent>
                            <StyledReportContent>
                              平均每日遊戲次數：{missionData !== undefined && missionData.length !== 0 ? Math.round(getAvgDailyGames(_getAvgDailyGames(missionData))) + "次" : "沒有資料"}
                            </StyledReportContent>
                            <StyledReportContent>
                              平均每月遊戲次數： {missionData !== undefined && missionData.length !== 0 ? Math.round(getAvgMonthlyGames(_getAvgMonthlyGames(missionData))) + "次" : "沒有資料"}
                            </StyledReportContent>
                            <StyledReportContent>
                              平均每局麻雀所需時間： {gameLog !== undefined && gameLog.length !== 0 ? getAvgMahjongGametime(gameLog) + "分鐘" : "沒有資料"}
                            </StyledReportContent>
                            <StyledReportContent>
                              平均每日麻雀局數： {gameLog !== undefined && gameLog.length !== 0 ? Math.round(getAvgDailyGames(_getAvgDailyGames(gameLog))) + "次" : "沒有資料"}
                            </StyledReportContent>
                            <StyledReportContent>
                              平均每月麻雀局數： {gameLog !== undefined && gameLog.length !== 0 ? Math.round(getAvgMonthlyGames(_getAvgMonthlyGames(gameLog))) + "次" : "沒有資料"}
                            </StyledReportContent>
                          </CardContent>
                        </StyledGridRow>
                      </>}
                  </div>
                  : null}
              </Typography>
            </Grid>
            {(selectedUser !== undefined && selectedUser.user_id !== '-1' && !isLoading && domainsScore !== undefined && missionData !== undefined) ?
              <Grid sm={9} xs={12} style={{ paddingTop: 30 }}>
                <Tabs
                  value={tabNumber}
                  onChange={(e, v) => handleTabChange(v)}
                  indicatorColor="primary"
                  textColor="primary"
                  centered
                >
                  <Tab label="遊戲成績" style={{ fontSize: 20 }} value={0} />
                  <Tab label="麻雀成績" style={{ fontSize: 20 }} value={1} />
                  <Tab label="書法數據" style={{ fontSize: 20 }} value={2} />
                  <Tab label="遊戲時間" style={{ fontSize: 20 }} value={3} />
                </Tabs>
                <ReactFade in={tabNumber === 0} timeout={750}>
                  <MissionTab domainScore={domainsScore} missionData={missionData} selectedUser={selectedUser} userScore={userScore} />
                </ReactFade>

                <ReactFade in={tabNumber === 1} timeout={750}>
                  <MahjongTab uid={selectedUser.user_id} mahjongData={gameLog} missionData={missionData} mahjongBehaviorAverage={mahjongBehaviorAverage} mahjongBehaviorTrendData={undefined} />
                </ReactFade>

                <ReactFade in={tabNumber === 2} timeout={750} >
                  <CalligraphyTab calligraphyData={calligraphyData == undefined ? [] : calligraphyData} />
                </ReactFade>

                <ReactFade in={tabNumber === 3} timeout={750} >
                  <PlaytimeTab missionData={missionData} mahjongData={gameLog} calligraphyData={calligraphyData} />
                </ReactFade>
              </Grid>
              : (loggedUserRole === "center" || loggedUserRole === "trial_center") ?
                <CenterMissionTab />
                : null
            }
          </Grid>
          {isLoading && <StyledCircularProgress />}
        </div> :
        <SimpleSnackBar open={true} onClose={closeErrorSnackBar} message={"此帳戶是試用帳戶，請聯絡我們以了解更多報告內容。"} variant="warning" autoClose={99999} />}
    </div >
  )
}