import Button from '@material-ui/core/Button';
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import AssessmentIcon from '@material-ui/icons/Assessment';
import BuildIcon from '@material-ui/icons/Build';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import SportsEsportsIcon from '@material-ui/icons/SportsEsports';
import moment from 'moment';
import MUIDataTable from 'mui-datatables';
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setAccountInfos } from '../actions/accountInfos';
import { setAccounts } from '../actions/accounts';
import { setMissionMetadata } from '../actions/missionMetadata';
import { setUserProfiles } from "../actions/userProfiles";
import { setUserRoles } from '../actions/userRoles';
import { deleteUserProfile, getAccountInfos, getAccounts, getAllUserProfiles, getDisplayName, getMissionDomain, getUserParents, getUserProfile, getUserProfiles, getUserRoles, updateFirebaseAccountInfo } from "../api";
import AddUserDialog from '../components/AddUserDialog';
import SimpleAppBar from "../components/AppBar";
import DeleteDialog from '../components/DeleteDialog';
import { EditDemoMissionDialog } from '../components/EditDemoMissionDialog';
import GamePreferenceDialog from "../components/GamePreferenceDialog";
import SimpleSnackBar, { SnackBarProp } from "../components/SimpleSnackBar";
import { AppState } from "../reducers";
import { LoadableAccountData } from '../reducers/accounts';
import { LoadableProfileData } from '../reducers/userProfiles';
import { StyledCircularProgress, StyledContainer } from "../StyledComponents";
import SubmitGamePreferenceSnackBar from "../SubmitGamePreferenceSnackBar";
import { AccountInfo } from '../types/AccountInfo';
import { MissionMetadata, MissionType } from '../types/MissionType';
import { calculateMocaScore, translation, UserProfile } from "../types/UserProfile";
import { RoleType, UserRole } from "../types/UserRole";
import { AccountData } from './AccountsPage';

interface Center {
  uid: string;
  name: string;
}

export interface LoadedData {
  kind: "loaded";
  data: (Center | null)[];
}

export interface LoadingData {
  kind: "loading";
}

export interface LoadingDataError {
  kind: "error";
  error?: string;
}

export type LoadableAllCenterData =
  | LoadedData
  | LoadingData
  | LoadingDataError
  ;

export default function UserProfilesPage() {

  const dispatch = useDispatch();

  const closeErrorSnackBar = () => {
    setSnackBar({ ...snackBar, "open": false });
  }

  const [snackBar, setSnackBar] = useState<SnackBarProp>({
    open: false,
    onClose: closeErrorSnackBar,
    message: "",
    variant: undefined
  });

  const loggedInUser = useSelector<AppState, firebase.User | null>(state => state.loggedInUser);
  const userRole = useSelector<AppState, RoleType | null>(state => state.userRole);
  const userProfiles = useSelector<AppState, LoadableProfileData>(state => state.userProfiles);
  const [openGamePreferenceDialog, setOpenGamePreferenceDialog] = useState(false);
  const [openSubmitGamePreferenceSnackBar, setOpenSubmitGamePreferenceSnackBar] = useState(false);
  const [selectedUser, setSelectedUser] = useState<UserProfile>();
  const [selectedUserId, setSelectedUserId] = useState<string>();
  const [isAddUserModalOpen, setIsAddUserModalOpen] = useState<boolean>(false);
  const [userParents, setUserParents] = useState<Array<{ "userId": string, "centerId": string }>>();
  const [userCenter, setUserCenter] = useState<Array<{ "userId": string, "center": string | null }>>();
  const [deleteDialogOpen, setDeleteDialogOpen] = useState<boolean>(false);
  const [isEditDemoMissionDialogOpen, setIsEditDemoMissionDialogOpen] = useState<boolean>(false);
  const [allCenter, setAllCenter] = useState<LoadableAllCenterData>();
  const accounts = useSelector<AppState, LoadableAccountData>(state => state.accounts);
  const userRoles = useSelector<AppState, UserRole[] | null>(state => state.userRoles);
  const accountInfos = useSelector<AppState, AccountInfo[] | null>(state => state.accountInfos);

  const missionMetadata = useSelector<AppState, { metadata: MissionMetadata | null; domainMission: { [key: string]: MissionType[] } }>(state => state.missionMetadata);

  function handleOpenGamePreferenceDialog(userProfile: UserProfile | undefined) {
    if (userProfiles !== undefined) {
      setSelectedUser(userProfile);
      setOpenGamePreferenceDialog(true);
    }
  }

  const handleOpenEditUserDialog = (userProfile: UserProfile | undefined) => {
    if (userProfiles !== undefined) {
      setSelectedUser(userProfile);
      setIsAddUserModalOpen(true);
    }
  }


  const handleGamePreferenceDialogClose = (submitted?: boolean) => {
    if (submitted === true) {
      setOpenSubmitGamePreferenceSnackBar(true);
    }
    setOpenGamePreferenceDialog(false);
  };

  const handleDeleteProfile = async (uid: string) => {
    try {
      if (loggedInUser !== null) {
        await deleteUserProfile(uid);
        await updateFirebaseAccountInfo({ uid: uid, disabled: true });
        setDeleteDialogOpen(false);
        setSnackBar({
          ...snackBar,
          open: true,
          message: "刪除成功！",
          variant: "success",
        });
        _getUserProfiles();
      }
    } catch (e) {
      console.log(e);
    }
  }

  const handleSubmitGamePreferenceSnackBarClose = () => {
    setSelectedUser(undefined);
    setOpenSubmitGamePreferenceSnackBar(false);
  };

  useEffect(() => {
    if (userRole === "admin") {
      setAllCenter({ kind: "loading" });
      _setAccounts();
    }

    async function setMissionMD() {
      if (!missionMetadata.metadata)
        dispatch(setMissionMetadata(await getMissionDomain()));
    }
    setMissionMD();
  }, [])

  useEffect(() => {
    _getUserProfiles();
  }, [isAddUserModalOpen])

  const _getUserProfiles = async () => {
    if (userProfiles.kind !== "loaded") {
      dispatch(setUserProfiles({ kind: "loading" }))
    }
    try {
      if (loggedInUser !== null) {
        if (userRole === "trial_center" || userRole === "center") {
          getUserProfiles(loggedInUser).then((data) => {
            const temp = translateData(data);
            dispatch(setUserProfiles({ kind: "loaded", data: temp }));
          })
        } else if (userRole === "admin") {
          getAllUserProfiles().then((data) => {
            const temp = translateData(data);
            dispatch(setUserProfiles({ kind: "loaded", data: temp }));
          })
          getUserParents().then((data) => {
            if (data !== null) {
              setUserParents(data);
            }
          })
        } else {
          const data = await getUserProfile(loggedInUser.uid);
          if (data !== null) {
            const temp = translateData([data]);
            dispatch(setUserProfiles({ kind: "loaded", data: temp }))
          }
        }
      }
    } catch (e) {
      dispatch(setUserProfiles({ kind: "error", error: e }))
    }
  }

  useEffect(() => {
    if (accounts.kind === "loaded" && userRoles != undefined) {
      const temp = userRoles.filter(x => (x.role === "trial_center" || x.role === "center"));
      let centerArr = temp.map(x => {
        const tempAccount = accounts.data.find(y => y.uid === x.uid);
        if (tempAccount !== undefined) {
          const tempDisplayName = tempAccount.displayName;
          if (tempDisplayName !== undefined && tempDisplayName !== "") {
            return { uid: tempAccount.uid, name: tempDisplayName };
          } else {
            return null;
          }
        } else {
          return null;
        }
      })
      centerArr.filter((x): x is Center => x !== null);
      setAllCenter({ kind: "loaded", data: centerArr });
    }
  }, [accounts, userRoles])

  const _setAccounts = async () => {
    dispatch(setAccounts({ kind: "loading" }));
    try {
      let data = await getAccounts() as AccountData[];
      data = data.map(x => {
        return {
          ...x,
          creationTime: moment.utc(x.metadata.creationTime).local().format('YYYY年 MMM Do HH:mm:ss'),
          lastSignInTime: moment.utc(x.metadata.lastSignInTime).local().format('YYYY年 MMM Do HH:mm:ss')
        }
      })
      dispatch(setAccounts({ kind: "loaded", data: data }));
      const _userRoles = await getUserRoles();
      dispatch(setUserRoles(_userRoles));
    } catch (e) {
      dispatch(setAccounts({ kind: "error", error: e }));
    }
  }

  useEffect(() => {
    if (userParents !== undefined) {
      _setUserCenter(userParents);
    }
  }, [userParents])

  const _setUserCenter = async (userParents: { "userId": string, "centerId": string }[]) => {
    let userCenters: { "userId": string, "center": string | null }[] = await Promise.all(userParents.map(async row => {
      try {
        const center = await getDisplayName(row.centerId);
        return { "userId": row.userId, "center": center };
      } catch (e) {
        return { "userId": row.userId, "center": "" };
      }
    }))
    setUserCenter(userCenters);
  }

  useEffect(() => {
    if (userProfiles !== undefined && userProfiles.kind === "loaded") {
      setProfileCenter(userProfiles.data);
    }
  }, [userCenter])

  const translateData = (data: UserProfile[]) => {
    let temp = data.map(x => {
      let temp2 = x;
      if (x.use_smartphone_level !== undefined) {
        temp2.use_smartphone_level = translation[x.use_smartphone_level];
      }
      if (x.education_level !== undefined) {
        temp2.education_level = translation[x.education_level];
      }
      if (x.mahjong_level !== undefined) {
        temp2.mahjong_level = translation[x.mahjong_level];
      }
      if (x.smoke !== undefined) {
        temp2.smoke = translation[x.smoke];
      }
      if (x.drink_alcohol !== undefined) {
        temp2.drink_alcohol = translation[x.drink_alcohol];
      }
      if (x.dementia_diagnosis !== undefined) {
        temp2.dementia_diagnosis = translation[x.dementia_diagnosis];
      }
      return temp2;
    })
    return temp;
  }

  const setProfileCenter = async (data: UserProfile[]) => {
    let temp = await Promise.all(data.map(async x => {
      let temp2 = x;
      temp2 = { ...x, "center": await getCenter(x.user_id) }
      return temp2;
    }))
    dispatch(setUserProfiles({ kind: "loaded", data: temp }));
  }

  const getCenter = async (userId: string) => {
    if (userCenter !== undefined && userRoles != null && accounts.kind === 'loaded') {
      const temp = userCenter.find(x => x.userId === userId);
      if (temp !== undefined && temp.center !== null) {
        return temp.center;
      } else {
        const role = userRoles.find(x => x.uid === userId);
        if (role !== undefined && (role.role === 'trial_center' || role.role === 'center')) {
          const tempAccount = accounts.data.find(x => x.uid === userId);
          if (tempAccount !== undefined) {
            return tempAccount.displayName;
          } else {
            return "";
          }
        } else {
          return "";
        }
      }
    }
    else {
      return "";
    }
  }

  const closeAddUserModal = () => {
    setSelectedUser(undefined);
    setIsAddUserModalOpen(false);
  }

  const handleDeleteDialogOpen = (userProfile: UserProfile | undefined) => {
    if (userProfile !== undefined) {
      setSelectedUser(userProfile);
      setDeleteDialogOpen(true);
    }
  }

  const handleDeleteDialogClose = () => {
    setDeleteDialogOpen(false);
  }

  const handleEditDemoMissionDialog = (uid: string) => {
    setSelectedUserId(uid);
    setIsEditDemoMissionDialogOpen(true);
  }

  const closeEditDemoMissionDialog = () => {
    setIsEditDemoMissionDialogOpen(false);
  }

  const handleAddUserModalOpen = async () => {
    if (userRole !== "admin") {
      const _accountInfos = await getAccountInfos();
      dispatch(setAccountInfos(_accountInfos));
      if (_accountInfos !== null && loggedInUser !== null) {

        if (userProfiles.kind !== 'loaded') {
          setSnackBar({
            ...snackBar,
            open: true,
            message: "系統讀取用戶數據中，請稍候。",
            variant: "info",
          });
          return;
        }

        const temp = _accountInfos.find(x => x.uid === loggedInUser.uid);
        if (temp !== undefined && (temp.quota === undefined || (temp.quota !== undefined && userProfiles.kind === 'loaded' && userProfiles.data.length < temp.quota))) {
          setIsAddUserModalOpen(true);
        } else {
          setSnackBar({
            ...snackBar,
            open: true,
            message: "閣下用家數量而超出上限！請聯絡本公司以增加限額。",
            variant: "warning",
          });
        }
      }
      else {
        if (_accountInfos === null) {
          setSnackBar({
            ...snackBar,
            open: true,
            message: "未能讀取用戶數據，請稍後再試。",
            variant: "error",
          });
        }

        if (loggedInUser === null) {
          setSnackBar({
            ...snackBar,
            open: true,
            message: "未能讀取已登入的帳號數據，請稍後再試。",
            variant: "error",
          });
        }
      }
    } else {
      setIsAddUserModalOpen(true);
    }
  }

  return (
    <div>
      <SimpleAppBar title={"用家"} />
      <StyledContainer maxWidth="lg" style={{ paddingTop: 90, paddingBottom: 80 }}>
        {(userRole === "admin" || userRole === "trial_center" || userRole === "center") &&
          <Button onClick={handleAddUserModalOpen} color="primary" variant="contained" >
            新增玩家
          </Button>}
        {userProfiles !== undefined && userProfiles.kind === "loading" && <StyledCircularProgress />}
        {userProfiles !== undefined && userProfiles.kind === "loaded" &&
          userRole === "admin" ?
          <MUIDataTable
            title={""}
            data={userProfiles.data}
            columns={[
              {
                label: ' ',
                name: 'user_id',
                options: {
                  filter: false,
                  sort: false,
                  viewColumns: false,
                  customBodyRender: (value) => (
                    <>
                      <Tooltip title="玩家詳細報告">
                        <IconButton
                          href={'/report?uid=' + value}
                          color="inherit"
                        >
                          <AssessmentIcon color='primary' />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="更改玩家遊戲設定">
                        <IconButton
                          onClick={() => handleOpenGamePreferenceDialog(userProfiles.data.find(x => x.user_id === value))}
                          color="inherit"
                        >
                          <BuildIcon color='primary' />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="更改玩家資料">
                        <IconButton
                          onClick={() => handleOpenEditUserDialog(userProfiles.data.find(x => x.user_id === value))}
                          color="inherit"
                        >
                          <EditIcon color='primary' />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="刪除玩家">
                        <IconButton
                          onClick={() => handleDeleteDialogOpen(userProfiles.data.find(x => x.user_id === value))}
                          color="inherit"
                        >
                          <DeleteIcon color='primary' />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="更改玩家任務內容">
                        <IconButton
                          onClick={() => handleEditDemoMissionDialog(value)}
                          color="inherit"
                        >
                          <SportsEsportsIcon color='primary' />
                        </IconButton>
                      </Tooltip>
                    </>
                  )
                }
              },
              {
                label: 'UID',
                name: 'user_id',
                options: {
                  filter: false,
                  sort: true,
                }
              },
              {
                label: '用家',
                name: 'name',
                options: {
                  filter: false,
                  sort: true,
                }
              },
              {
                label: '所屬團體',
                name: 'center',
                options: {
                  filter: true,
                  sort: true,
                }
              },
              {
                label: '性別',
                name: 'gender',
                options: {
                  filter: true,
                  sort: true,
                }
              },
              {
                label: '出生年份',
                name: 'year_of_birth',
                options: {
                  filter: true,
                  sort: true,
                }
              },
              {
                label: '腦退化症診斷結果',
                name: 'dementia_diagnosis',
                options: {
                  filter: true,
                  sort: true,
                  display: "false"
                }
              },
              {
                label: '簡化模式',
                name: 'is_dementia_mode',
                options: {
                  filter: true,
                  sort: true,
                  customBodyRender: (value) => value ? "Yes" : "No",
                  display: "false"
                }
              },
              {
                label: 'MOCA分數',
                name: 'moca_score',
                options: {
                  filter: false,
                  sort: true,
                  customBodyRender: (value) => value ? calculateMocaScore(value) : "無記錄"
                }
              },
              {
                label: '職業 (曾任/現任)',
                name: 'occupation',
                options: {
                  filter: true,
                  sort: true,
                  display: "false"
                }
              },
              {
                label: '使用智能電話/平板電腦的熟悉度',
                name: 'use_smartphone_level',
                options: {
                  filter: true,
                  sort: true,
                  display: "false"
                },
              },
              {
                label: '教育程度',
                name: 'education_level',
                options: {
                  filter: true,
                  sort: true,
                  display: "false"
                },
              },
              {
                label: '打麻雀熟悉度',
                name: 'mahjong_level',
                options: {
                  filter: true,
                  sort: true,
                  display: "false"
                },
              },
              {
                label: '吸煙習慣',
                name: 'smoke',
                options: {
                  filter: true,
                  sort: true,
                  display: "false"
                },
              },
              {
                label: '飲酒習慣',
                name: 'drink_alcohol',
                options: {
                  filter: true,
                  sort: true,
                  display: "false"
                },
              },
              {
                label: 'E-Health ID',
                name: 'eHealthId',
                options: {
                  filter: true,
                  sort: true,
                  display: "false"
                },
              },
            ]}
            options={{ selectableRows: 'none', elevation: 0, print: false, download: false }} /> :
          userProfiles.kind === "loaded" && userRole !== undefined &&
          <MUIDataTable
            title={""}
            data={userProfiles.data}
            columns={[
              {
                label: ' ',
                name: 'user_id',
                options: {
                  filter: false,
                  sort: false,
                  viewColumns: false,
                  customBodyRender: (value) => (
                    <>
                      <Tooltip title="玩家詳細報告">
                        <IconButton
                          href={'/report?uid=' + value}
                          color="inherit"
                        >
                          <AssessmentIcon color='primary' />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="更改玩家遊戲設定">
                        <IconButton
                          onClick={() => handleOpenGamePreferenceDialog(userProfiles.data.find(x => x.user_id === value))}
                          color="inherit"
                        >
                          <BuildIcon color='primary' />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="更改玩家任務內容">
                        <IconButton
                          onClick={() => handleEditDemoMissionDialog(value)}
                          color="inherit"
                        >
                          <SportsEsportsIcon color='primary' />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="更改玩家資料">
                        <IconButton
                          onClick={() => handleOpenEditUserDialog(userProfiles.data.find(x => x.user_id === value))}
                          color="inherit"
                        >
                          <EditIcon color='primary' />
                        </IconButton>
                      </Tooltip>
                      {(userRole === 'trial_center' || userRole === 'center') && <Tooltip title="刪除玩家">
                        <IconButton
                          onClick={() => handleDeleteDialogOpen(userProfiles.data.find(x => x.user_id === value))}
                          color="inherit"
                        >
                          <DeleteIcon color='primary' />
                        </IconButton>
                      </Tooltip>}
                    </>
                  )
                }
              },
              {
                label: 'UID',
                name: 'user_id',
                options: {
                  filter: false,
                  sort: true,
                }
              },
              {
                label: '用家',
                name: 'name',
                options: {
                  filter: false,
                  sort: true,
                }
              },
              {
                label: '性別',
                name: 'gender',
                options: {
                  filter: true,
                  sort: true,
                }
              },
              {
                label: '出生年份',
                name: 'year_of_birth',
                options: {
                  filter: true,
                  sort: true,
                }
              },
              {
                label: '腦退化症診斷結果',
                name: 'dementia_diagnosis',
                options: {
                  filter: true,
                  sort: true,
                  display: "false"
                }
              },
              {
                label: '簡化模式',
                name: 'is_dementia_mode',
                options: {
                  filter: true,
                  sort: true,
                  customBodyRender: (value) => value ? "Yes" : "No",
                  display: "false"
                }
              },
              {
                label: 'MOCA分數',
                name: 'moca_score',
                options: {
                  filter: false,
                  sort: true,
                  customBodyRender: (value) => value ? calculateMocaScore(value) : "無記錄"
                }
              },
              {
                label: '職業 (曾任/現任)',
                name: 'occupation',
                options: {
                  filter: true,
                  sort: true,
                  display: "false"
                }
              },
              {
                label: '使用智能電話/平板電腦的熟悉度',
                name: 'use_smartphone_level',
                options: {
                  filter: true,
                  sort: true,
                  display: "false"
                },
              },
              {
                label: '教育程度',
                name: 'education_level',
                options: {
                  filter: true,
                  sort: true,
                  display: "false"
                },
              },
              {
                label: '打麻雀熟悉度',
                name: 'mahjong_level',
                options: {
                  filter: true,
                  sort: true,
                  display: "false"
                },
              },
              {
                label: '吸煙習慣',
                name: 'smoke',
                options: {
                  filter: true,
                  sort: true,
                  display: "false"
                },
              },
              {
                label: '飲酒習慣',
                name: 'drink_alcohol',
                options: {
                  filter: true,
                  sort: true,
                  display: "false"
                },
              },
              {
                label: 'E-Health ID',
                name: 'eHealthId',
                options: {
                  filter: true,
                  sort: true,
                  display: "false"
                },
              },

            ]}
            options={{ selectableRows: 'none', elevation: 0, print: false, download: false }} />}

        {selectedUser !== undefined &&
          <DeleteDialog open={deleteDialogOpen} onClose={handleDeleteDialogClose} type="用家" selectedName={selectedUser.name} selectedId={selectedUser.user_id} handleDelete={handleDeleteProfile} />}
        <SimpleSnackBar open={snackBar.open} onClose={closeErrorSnackBar} message={snackBar.message} variant={snackBar.variant} />
      </StyledContainer >
      <SimpleAppBar title={"用家"} bottom={true} />
      {
        selectedUser !== undefined &&
        <GamePreferenceDialog userId={selectedUser.user_id} open={openGamePreferenceDialog} onClose={handleGamePreferenceDialogClose}></GamePreferenceDialog>
      }
      {
        selectedUserId !== undefined &&
        <EditDemoMissionDialog isOpen={isEditDemoMissionDialogOpen} onClose={closeEditDemoMissionDialog} uid={selectedUserId} isProfilePage={true} missionMetaData={missionMetadata.metadata} />
      }
      <SubmitGamePreferenceSnackBar open={openSubmitGamePreferenceSnackBar} onClose={handleSubmitGamePreferenceSnackBarClose}></SubmitGamePreferenceSnackBar>
      <AddUserDialog isOpen={isAddUserModalOpen} onClose={closeAddUserModal} user={selectedUser} allCenter={allCenter} />
    </div >
  );
}