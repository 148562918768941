import { Grid, Tab, Tabs, Tooltip as MaterialTooptip } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { Cell, LabelList, Legend, Pie, PieChart, PolarAngleAxis, PolarGrid, PolarRadiusAxis, Radar, RadarChart, ResponsiveContainer, Tooltip } from "recharts";
import { analyzeMahjongSkill } from "../helper/mahjong";
import { getColors } from "../resources/colors";
import { GridCard } from "../StyledComponents";
import { GameLog } from "../types/GameLog";
import MahjongGameAnalysisChartTabs from "./MahjongGameAnalysisChartTabs";

interface MahjongHistoryChartProps {
  mahjongData: GameLog[] | undefined,
  uid: string,
}

export default function MahjongHistoryChart(props: MahjongHistoryChartProps) {

  const [tableData, setTableData] = useState<Array<{ name: string, value: number }>>();

  const [mahjongSkillRadarData, setMahjongSkillRadarData] = useState<{ [key: string]: string | number }[]>();

  const [mahjongSkillDetailRadarData, setMahjongSkillDetailRadarData] = useState<{ [key: string]: string | number }[]>();

  const [mahjongGameAnalysisMode, setMahjongGameAnalysisMode] = useState<number>(0);

  useEffect(() => {
    if (props.mahjongData !== undefined) {
      let tempArr = props.mahjongData.map(x => {
        if (x.winner !== -1) {
          return { winner: x.players[x.winner]?.user_id ?? "unknown", score: x.score };
        } else {
          return { winner: '', score: 0 };
        }
      })
      tempArr = tempArr.filter(x => x.winner !== '');
      if (tempArr !== undefined && tempArr.length > 0) {
        let _tableData: Array<{ name: string, value: number }> = [];
        tempArr.filter(x => x.winner === props.uid).forEach(x => {
          const tempFind = _tableData.find(y => y.name === x.score.toString());
          if (tempFind === undefined) {
            _tableData.push({ name: x.score.toString(), value: 1 });
          } else {
            const objIndex = _tableData.findIndex((obj => obj.name === tempFind.name));
            _tableData[objIndex].value = _tableData[objIndex].value + 1;
          }
        })
        const collator = new Intl.Collator(undefined, { numeric: true, sensitivity: 'base' });
        _tableData = _tableData.map(x => { return x.name }).sort(collator.compare).map(x => { return { name: x, value: _tableData.filter(y => y.name === x)[0].value } });
        _tableData = _tableData.map(x => {
          return { name: x.name + '番', value: x.value };
        })
        _tableData.push({ name: '輸', value: tempArr.filter(x => x.winner !== props.uid).length });
        setTableData(_tableData);
      }
    }
    const analyzeResult = analyzeMahjongSkill(props.mahjongData, props.uid);
    setMahjongSkillRadarData(analyzeResult[0]);
    setMahjongSkillDetailRadarData(analyzeResult[1]);
  }, [])

  const mapScoreData = () => {
    const colors = getColors(tableData!.length);
    return tableData!.map((entry, index) => <Cell key={`cell-${index}`} fill={entry.name === '輸' ? '#A9A9A9' : colors[index]} />);
  }

  return (
    <>
      <Grid item lg={4} md={12} sm={12}>
        <GridCard title="麻雀遊戲表現統計" desc="以圓餅圖顯示用戶在有參與的麻雀遊戲結果的分佈">
          {tableData !== undefined ?
            <ResponsiveContainer width="100%" height={288}>
              <PieChart>
                <Tooltip />
                <Legend />
                <Pie dataKey="value" data={tableData} outerRadius={100} animationBegin={400}>
                  <LabelList dataKey="name" position="outside" />
                  {
                    mapScoreData()
                  }
                </Pie>
              </PieChart>
            </ResponsiveContainer> : '沒有資料'}
        </GridCard>
      </Grid>
      <Grid item lg={8} md={12} sm={12}>
        <GridCard title="雀力評價" desc="以雷達圖顯示用戶在麻雀遊戲中各方面的能力">
          {!mahjongSkillRadarData && !mahjongSkillDetailRadarData && "沒有資料"}
          <Grid container>
            {mahjongSkillRadarData &&
              <Grid item md={5}>
                <ResponsiveContainer width="100%" height={288}>
                  <RadarChart outerRadius={100} width={250} height={250} data={mahjongSkillRadarData}>
                    <PolarGrid />
                    <PolarAngleAxis dataKey="skill" />
                    <PolarRadiusAxis angle={0} domain={[0, 100]} />
                    <defs>
                      <radialGradient id="radarchartColorToRed">
                        <stop offset="0%" stop-color="#FF0D0D" />
                        <stop offset="10%" stop-color="#FF4E11" />
                        <stop offset="30%" stop-color="#FF8E15" />
                        <stop offset="50%" stop-color="#FAB733" />
                        <stop offset="80%" stop-color="#ACB334" />
                        <stop offset="100%" stop-color="#69B34C" />
                      </radialGradient>
                    </defs>
                    <Radar name="你" dataKey="data" stroke="#555555" fill="url(#radarchartColorToRed)" fillOpacity={0.6} />
                    <Tooltip />
                  </RadarChart>
                </ResponsiveContainer>
              </Grid>
            }
            {
              mahjongSkillDetailRadarData &&
              <Grid item md={7}>
                <ResponsiveContainer width="100%" height={288}>
                  <RadarChart outerRadius={100} width={250} height={250} data={mahjongSkillDetailRadarData}>
                    <PolarGrid />
                    <PolarAngleAxis
                      dataKey="skill"
                    />
                    <PolarRadiusAxis angle={0} domain={[0, 100]} />
                    <defs>
                      <radialGradient id="radarchartColorToRed">
                        <stop offset="0%" stop-color="#FF0D0D" />
                        <stop offset="10%" stop-color="#FF4E11" />
                        <stop offset="30%" stop-color="#FF8E15" />
                        <stop offset="50%" stop-color="#FAB733" />
                        <stop offset="80%" stop-color="#ACB334" />
                        <stop offset="100%" stop-color="#69B34C" />
                      </radialGradient>
                    </defs>
                    <Radar name="你" dataKey="data" stroke="#555555" fill="url(#radarchartColorToRed)" fillOpacity={0.6} />
                    <Tooltip />
                  </RadarChart>
                </ResponsiveContainer>
              </Grid>
            }
          </Grid>
        </GridCard>
      </Grid>
      <Grid item md={12}>
        <GridCard title="麻雀遊戲表現趨勢" desc="以折線圖顯示用戶在有參與麻雀遊戲的結果的時間趨勢">
          <Tabs
            value={mahjongGameAnalysisMode}
            onChange={(e, v) => setMahjongGameAnalysisMode(v)}
            indicatorColor="primary"
            textColor="primary"
          >
            <Tab label={<MaterialTooptip title={"顯示用戶在某時間段進行了多少場麻雀遊戲。包括食糊、流局、出銃以及其他人食糊嘅數量"} arrow placement="top"><span>綜合</span></MaterialTooptip>} value={0} style={{ width: 50 }} />
            <Tab label={<MaterialTooptip title={"顯示用戶在某時間段食糊的牌局數量"} arrow placement="top"><span>食糊</span></MaterialTooptip>} value={1} style={{ width: 50 }} />
            <Tab label={<MaterialTooptip title={"顯示用戶在某時間段出銃的牌局數量"} arrow placement="top"><span>出銃</span></MaterialTooptip>} value={2} style={{ width: 50 }} />
          </Tabs>
          <MahjongGameAnalysisChartTabs data={props.mahjongData} uid={props.uid} mode={mahjongGameAnalysisMode as 0 | 1 | 2} />
        </GridCard>
      </Grid>
    </>
  )
}