import { createStore } from 'redux';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web
import rootReducer from '../reducers';

const persistConfig = {
  // Root
  key: 'root',
  // Storage Method (React Native)
  storage,
  blacklist: ["missionMetadata"]
};


const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = createStore(persistedReducer, (window as any).__REDUX_DEVTOOLS_EXTENSION__ && (window as any).__REDUX_DEVTOOLS_EXTENSION__());

let persistor = persistStore(store);


export {
  store,
  persistor,
};
