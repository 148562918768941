import { Button, Card, Container, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, Grid, Typography } from "@material-ui/core";
import { Assignment, Build, Create, EventAvailable, FitnessCenter, ImportExport, PlaylistAddCheck, Public, Settings, SportsEsports, SupervisorAccount } from "@material-ui/icons";
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import StarIcon from '@material-ui/icons/Star';
import SwapVertIcon from '@material-ui/icons/SwapVert';
import React, { useEffect, useState } from "react";
import { PlanCard } from "../components/PlanCard";
import { PlanType } from "../components/PlanType";
import { ReactFade } from "../components/ReactFade";
import { ReactComponent as PlanCenter } from "../image/plan_center.svg";
import { ReactComponent as PlanIndividual } from "../image/plan_individual.svg";
import { ReactComponent as Pros1 } from "../image/pros_1.svg";
import { ReactComponent as Pros2 } from "../image/pros_2.svg";
import { ReactComponent as Pros3 } from "../image/pros_3.svg";
import top from '../image/top1.png';


const data = [
  [<Grid container spacing={0}>
    <Grid item sm={4} style={{ justifyContent: "flex-end", display: "flex" }}>
      <StarIcon style={{ color: 'orange' }} />
    </Grid>
    <Grid item sm={8} style={{ justifyContent: "start", display: "flex", alignSelf: "center", color: '#4B7B7D' }}>
      六大認知能力訓練遊戲
    </Grid>
  </Grid>, <><b>9</b><>個</></>, <><b>16</b><>個</></>, <><b>~50</b><>個</></>],
  [<Grid container spacing={0}>
    <Grid item sm={4} style={{ justifyContent: "flex-end", display: "flex" }}>
      <StarIcon style={{ color: 'orange' }} />
    </Grid>
    <Grid item sm={8} style={{ justifyContent: "start", display: "flex", alignSelf: "center", color: '#4B7B7D' }}>
      五大生活化主題
    </Grid>
  </Grid>, <><b>3</b><>個</></>, <><b>5</b><>個</></>, <><b>5</b><>個</></>],
  [<Grid container spacing={0}>
    <Grid item sm={4} style={{ justifyContent: "flex-end", display: "flex" }}>
      <SwapVertIcon style={{ color: 'indigo' }} />
    </Grid>
    <Grid item sm={8} style={{ justifyContent: "start", display: "flex", alignSelf: "center", color: '#4B7B7D' }}>
      個人化每日任務
    </Grid>
  </Grid>, < CheckIcon style={{ color: 'green' }} />, < CheckIcon style={{ color: 'green' }} />, < CheckIcon style={{ color: 'green' }} />],
  ["個人化訓練設定", < CloseIcon style={{ color: 'red' }} />, < CheckIcon style={{ color: 'green' }} />, < CheckIcon style={{ color: 'green' }} />],
  [<Grid container spacing={0}>
    <Grid item sm={4} style={{ justifyContent: "flex-end", display: "flex" }}>
      <SwapVertIcon style={{ color: 'indigo' }} />
    </Grid>
    <Grid item sm={8} style={{ justifyContent: "start", display: "flex", alignSelf: "center", color: '#4B7B7D' }}>
      自動難度調節
    </Grid>
  </Grid>, < CloseIcon style={{ color: 'red' }} />, < CheckIcon style={{ color: 'green' }} />, < CheckIcon style={{ color: 'green' }} />],
  ["多人遊戲", < CloseIcon style={{ color: 'red' }} />, < CheckIcon style={{ color: 'green' }} />, < CheckIcon style={{ color: 'green' }} />],
  ["數據分析報告", < CloseIcon style={{ color: 'red' }} />, < CloseIcon style={{ color: 'red' }} />, < CheckIcon style={{ color: 'green' }} />],
  ["帳戶管理", < CloseIcon style={{ color: 'red' }} />, < CloseIcon style={{ color: 'red' }} />, < CheckIcon style={{ color: 'green' }} />],
  ["獎勵制度", < CloseIcon style={{ color: 'red' }} />, < CloseIcon style={{ color: 'red' }} />, < CheckIcon style={{ color: 'green' }} />],
]

interface ContactUsDialogProps {
  onClose: () => void,
  isOpen: boolean,
}

const ContactUsDialog = (props: ContactUsDialogProps) => {
  return (
    <Dialog onClose={props.onClose} aria-labelledby="simple-dialog-title" open={props.isOpen}>
      <DialogTitle id="simple-dialog-title">請聯絡我們以索取詳情！</DialogTitle>
      <DialogContent style={{ padding: 30 }}>
        <Typography variant='subtitle1'>
          電郵：<a href="mailto: info@medmindtechnology.hk">info@medmindtechnology.hk</a>
        </Typography>
        <Typography variant='subtitle1'>
          電話：9033 7986（譚小姐）/ 9040 3834（許先生）
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.onClose} color="primary">
          確定
        </Button>
      </DialogActions>
    </Dialog >
  )
}

export default function PlansPage(props: any) {

  const [contactUsDialogOpen, setContactUsDialogOpen] = useState<boolean>(false);

  const [individualFullVersionPanelOpen, setIndividualFullVersionPanelOpen] = useState<boolean>(false);

  const [centerFullVersionPanelOpen, setCenterFullVersionPanelOpen] = useState<boolean>(false);

  const [chosenPlanType, setChosenPlanType] = useState<"individual" | "center" | null>(null);

  useEffect(() => { document.body.style.backgroundColor = '#d8eff6' }, [])

  const onContactUsOpen = () => {
    setContactUsDialogOpen(true);
  }

  const onContactUsClose = () => {
    setContactUsDialogOpen(false);
  }

  const renderTrialPrice = () => {
    return (
      <>
        <div style={{ display: "inline" }}>
          <span style={{ fontSize: 22 }}>
            {"免費"}
          </span>
        </div>

        <div style={{ color: "#888", fontSize: 16, fontWeight: "normal" }}>
          {"(應用程式將包含廣告)"}
        </div>
      </>
    )
  }

  const renderCenterTrialPrice = () => {
    return (
      <>
        <div style={{ display: "inline" }}>
          <span style={{ fontSize: 22 }}>
            {"免費"}
          </span>
        </div>

        <div style={{ color: "#888", fontSize: 16, fontWeight: "normal" }}>
          {"試用期：14天+"}
        </div>
      </>
    )
  }

  const renderCenterPrice = () => {
    return (
      <>
        <div style={{ display: "inline" }}>
          <span style={{ fontSize: 22 }}>
            {"請聯絡我們"}
          </span>
        </div>

        <div style={{ color: "#888", fontSize: 16, fontWeight: "normal" }}>
          {"9033 7986（譚小姐）"}
        </div>

        <div style={{ color: "#888", fontSize: 16, fontWeight: "normal" }}>
          {"9040 3834（許先生）"}
        </div>
      </>
    )
  }

  const renderIndividualPrice = () => {
    return (
      <>
        <div style={{ display: "inline" }}>
          <span style={{ fontSize: 16 }}>
            {"HKD $"}
          </span>
          <span style={{ fontSize: 22 }}>
            {"53 "}
          </span>
          <span style={{ color: "#888", fontSize: 16, fontWeight: "normal" }}>
            {"/ 一個月"}
          </span>
        </div>

        <div style={{ color: "#888", fontSize: 16, fontWeight: "normal" }}>
          {" 或 "}
        </div>

        <div style={{ display: "inline" }}>
          <span style={{ fontSize: 16 }}>
            {"HKD $"}
          </span>
          <span style={{ fontSize: 22 }}>
            {"488 "}
          </span>
          <span style={{ color: "#888", fontSize: 16, fontWeight: "normal" }}>
            {"/ 一年"}
          </span>
        </div>
      </>
    )
  };

  const renderIndividualPlanCards = () => (
    <Grid container style={{ marginTop: 20 }} justify="center" spacing={4} alignContent="center">
      <PlanCard
        planTitle="個人體驗版"
        desc="體驗「腦有記」的基本功能，透過涵蓋六大認知範疇的小遊戲，訓練您的認知能力。"
        content={[
          <Grid container alignItems="center"><FitnessCenter style={{ width: 18, height: 18, marginRight: 10 }} />認知訓練遊戲</Grid>,
          <Grid container alignItems="center"><SportsEsports style={{ width: 18, height: 18, marginRight: 10 }} />麻雀遊戲</Grid>,
          <Grid container alignItems="center"><EventAvailable style={{ width: 18, height: 18, marginRight: 10 }} />每日任務</Grid>
        ]}
        priceText={renderTrialPrice()}
        route={() => props.history.push("/requestdemo")}
      />

      <PlanCard
        planTitle="個人完整版"
        desc="發掘「腦有記」各種進階功能，透過多項主題的個人化訓練和數據分析系統，令您的遊戲體驗和認知能力更上一層。"
        content={[
          <Grid container alignItems="center"><PlaylistAddCheck style={{ width: 18, height: 18, color: "#89B98B", marginRight: 10 }} /><span style={{ color: "#89B98B" }}>{"所有個人體驗版的內容"}</span></Grid>,
          <Grid container alignItems="center"><ImportExport style={{ width: 18, height: 18, marginRight: 10 }} />個人化難度調節</Grid>,
          <Grid container alignItems="center"><Public style={{ width: 18, height: 18, marginRight: 10 }} />線上多人遊戲</Grid>,
          <Grid container alignItems="center"><Assignment style={{ width: 18, height: 18, marginRight: 10 }} />數據分析報告</Grid>,
          <Grid container alignItems="center"><Create style={{ width: 18, height: 18, marginRight: 10 }} />書法遊戲</Grid>
        ]}
        priceText={renderIndividualPrice()}
        route={() => setIndividualFullVersionPanelOpen(true)}
      />

      <Grid item xs={12}>
        <Button variant="contained" onClick={() => setChosenPlanType("center")} size="large">查看機構版計劃</Button>
      </Grid>
    </Grid>
  );

  const renderCenterPlanCards = () => (
    <Grid container style={{ marginTop: 20 }} justify="center" spacing={4} alignContent="center">

      <PlanCard
        planTitle="機構體驗版"
        desc="我們將開放試用期與貴機構有足夠時間體驗「腦有記」訓練系統，透過將日常生活遊戲化的方式訓練用戶的認知能力。"
        content={[
          <Grid container alignItems="center"><PlaylistAddCheck style={{ width: 18, height: 18, color: "#89B98B", marginRight: 10 }} /><span style={{ color: "#89B98B" }}>{"所有個人體驗版的內容"}</span></Grid>,
          <Grid container alignItems="center"><SupervisorAccount style={{ width: 18, height: 18, marginRight: 10 }} />管理用戶</Grid>,
          <Grid container alignItems="center"><ImportExport style={{ width: 18, height: 18, marginRight: 10 }} />自動難度調節</Grid>,
          <Grid container alignItems="center"><Public style={{ width: 18, height: 18, marginRight: 10 }} />本地/線上多人遊戲</Grid>
        ]}
        priceText={renderCenterTrialPrice()}
        route={() => props.history.push("/centerregister")}
      />

      <PlanCard
        planTitle="機構完整版"
        desc="我們將提供各種小遊戲定制服務和機構綜合報告系統，令貴機構用戶能享受遊戲同時亦能檢閱他們的遊戲表現。"
        content={[
          <Grid container alignItems="center"><PlaylistAddCheck style={{ width: 18, height: 18, color: "#89B98B", marginRight: 10 }} /><span style={{ color: "#89B98B" }}>{"所有機構體驗版的內容"}</span></Grid>,
          <Grid container alignItems="center"><Build style={{ width: 18, height: 18, marginRight: 10 }} />可定制小遊戲</Grid>,
          <Grid container alignItems="center"><Settings style={{ width: 18, height: 18, marginRight: 10 }} />可自訂小遊戲訓練參數</Grid>,
          <Grid container alignItems="center"><Assignment style={{ width: 18, height: 18, marginRight: 10 }} />機構用戶數據分析報告</Grid>,
          <Grid container alignItems="center"><Create style={{ width: 18, height: 18, marginRight: 10 }} />書法遊戲</Grid>
        ]}
        priceText={renderCenterPrice()}
        route={() => setCenterFullVersionPanelOpen(true)}
      />

      <Grid item xs={12}>
        <Button variant="contained" onClick={() => setChosenPlanType("individual")} size="large">查看個人版計劃</Button>
      </Grid>
    </Grid>
  );

  const renderPlanTypes = () => (
    <Grid container style={{ marginTop: 20 }} justify="center" spacing={4}>
      <PlanType
        planTitle="個人版"
        desc="腦有記個人版適合各個年齡層的個體用户使用，設有體驗、短期以及長期訂閱版本。系統讓用家因應個人能力調節訓練難度，讓用家建立獨一無二個人化訓練！"
        image={<PlanIndividual width="100%" height="100%" preserveAspectRatio="xMidYMax meet" />}
        onClickShowPlans={() => setChosenPlanType("individual")}
      />
      <PlanType
        planTitle="機構版"
        desc="腦有記機構版專為安老服務中心、復康院合等單位而設，是一個全方位的認知訓練平台。單位可透過平台為用户度身訂造訓練計劃。機構版可讓機構單位管理個別用户資料及監察個別用户的認知變化及訓練進度。"
        image={<PlanCenter width="100%" height="100%" preserveAspectRatio="xMidYMax meet" />}
        onClickShowPlans={() => setChosenPlanType("center")}
      />
    </Grid>
  );

  const IndividualFullVersionPanel = () => {
    return (
      <Dialog
        open={individualFullVersionPanelOpen}
        fullWidth
        disableBackdropClick={false}
        onClose={() => setIndividualFullVersionPanelOpen(false)}
        maxWidth={"sm"}
      >
        <DialogTitle>{"訂閱個人完整版"}</DialogTitle>
        <DialogContent dividers>
          <DialogContentText>
            <Typography>
              您好！感謝您有意購買「腦有記」個人完整版。為了提供最合適你的購買方案，我們建議您在購買前與我們聯繫。
              您可以先註冊一個體驗版賬號，並在應用程式裡的「購買完整版」參考完整版的最新價格。
              若您遇到任何困難，請聯絡我們。
            </Typography>
            <br />
            <Typography>
              電郵：<a href="mailto: info@medmindtechnology.hk">info@medmindtechnology.hk</a>
            </Typography>
            <Typography>
              電話：9033 7986（譚小姐）/ 9040 3834（許先生）
            </Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" disableElevation onClick={() => props.history.push("/requestdemo")} color="primary">
            註冊
        </Button>
          <Button variant="contained" disableElevation onClick={() => setIndividualFullVersionPanelOpen(false)} color="primary">
            關閉
        </Button>
        </DialogActions>
      </Dialog>
    );
  }

  const CenterFullVersionPanel = () => {
    return (
      <Dialog
        open={centerFullVersionPanelOpen}
        fullWidth
        disableBackdropClick={false}
        onClose={() => setCenterFullVersionPanelOpen(false)}
        maxWidth={"sm"}
      >
        <DialogTitle>{"訂閱機構完整版"}</DialogTitle>
        <DialogContent dividers>
          <DialogContentText>
            <Typography>
              您好！感謝您有意購買「腦有記」機構完整版。您可以先註冊一個體驗版賬號，我們將在您提交基本資料後聯絡貴機構討論最適合貴機構的購買方案。
              若您遇到任何困難，請聯絡我們。
            </Typography>
            <br />
            <Typography>
              電郵：<a href="mailto: info@medmindtechnology.hk">info@medmindtechnology.hk</a>
            </Typography>
            <Typography>
              電話：9033 7986（譚小姐）/ 9040 3834（許先生）
            </Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" disableElevation onClick={() => props.history.push("/centerregister")} color="primary">
            註冊
        </Button>
          <Button variant="contained" disableElevation onClick={() => setCenterFullVersionPanelOpen(false)} color="primary">
            關閉
        </Button>
        </DialogActions>
      </Dialog>
    );
  }

  return (
    <Container disableGutters maxWidth='xl' style={{ height: "100vh" }}>
      <div onClick={() => props.history.push("/")} style={{ cursor: "pointer", zIndex: -1, width: "100%", height: "20vw", backgroundImage: `url(${top})`, backgroundPosition: "left top", backgroundRepeat: "no-repeat", backgroundAttachment: "cover", backgroundSize: "100%" }}></div>

      <IndividualFullVersionPanel />
      <CenterFullVersionPanel />

      <Typography style={{ margin: "10px 0", fontVariant: "bold" }} color="primary" variant="h4">
        註冊賬號
      </Typography>

      <div style={{ margin: "0 auto", marginBottom: 20, fontSize: "26px", width: "80%" }}>腦有記是一個全方位的認知訓練平台，在註冊後您可使用系統內自訂賬戶名稱的功能， 並可享有以下的好處:</div>

      <Card style={{ transition: "transform .25s", margin: "0 auto", width: "80%", maxWidth: "1000px", borderRadius: 10, boxShadow: "0 8px 20px -12px rgb(0 0 0 / 30%)", backgroundColor: "rgba(240, 240, 240, 0.25)" }}>
        <div style={{ margin: 20 }}>
          <Grid container spacing={3} justify="center">
            <PlanType fullWidth image={<Pros1 width="100%" height="100%" preserveAspectRatio="xMidYMax meet" />} planTitle="簡單登入" desc="個人或機構可以自訂賬戶名稱、頭像和訓練內容" evaluate={false} />
            <PlanType fullWidth image={<Pros2 width="100%" height="100%" preserveAspectRatio="xMidYMax meet" />} planTitle="一目了然" desc="個人或機構可以一次過登入查看所有用家的賬戶資料" evaluate={false} />
            <PlanType fullWidth image={<Pros3 width="100%" height="100%" preserveAspectRatio="xMidYMax meet" />} planTitle="有效監察和跟進" desc="機構可以隨時隨地監察用户的認知訓練成果和認知能力變化，方便跟進和度身訂造針對性的訓練計劃" evaluate={false} />
          </Grid>
        </div>
      </Card>

      <Divider style={{ margin: "40px auto", width: "80%", }} />

      <Typography style={{ marginTop: 10 }} color="primary" variant="h4">
        我們提供的帳戶方案
      </Typography>

      <div style={{ position: "relative", width: "100%", marginTop: 0 }}>

        <ReactFade in={chosenPlanType === null} timeout={500}>
          <div style={{ position: "absolute", width: "100%", top: 0 }}>
            {renderPlanTypes()}
          </div>
        </ReactFade>

        <ReactFade in={chosenPlanType === "individual"} timeout={500}>
          <div style={{ position: "absolute", width: "100%", top: 0 }}>
            {renderIndividualPlanCards()}
          </div>
        </ReactFade>

        <ReactFade in={chosenPlanType === "center"} timeout={500}>
          <div style={{ position: "absolute", width: "100%", top: 0 }}>
            {renderCenterPlanCards()}
          </div>
        </ReactFade>

      </div>


      {/* <Grid container spacing={0} style={{ display: "flex", justifySelf: "center", marginTop: 20 }}>
        <Grid item xs={12} >
          <Grid container justify="center" spacing={0}>
            <Grid item xs={3} style={{ padding: 10, backgroundColor: '#d4e7b5', borderRadius: '30px 30px 0px 0px' }}></Grid>
            <Grid item xs={2} style={{ padding: 10, backgroundColor: '#89b98d', color: 'white', borderRadius: '30px 30px 0px 0px' }}>個人快速體驗版</Grid>
            <Grid item xs={2} style={{ padding: 10, backgroundColor: '#62a1ae', color: 'white', borderRadius: '30px 30px 0px 0px' }}>機構專用版</Grid>
          </Grid>
        </Grid>
        {data.map(x => (
          <Grid item xs={12} >
            <Grid container justify="center" spacing={0}>
              <Grid item xs={3} style={{ borderLeft: 1, borderRight: 0, borderTop: 0, borderBottom: 0, borderStyle: "solid", padding: 10, color: '#4B7B7D', backgroundColor: 'white' }}>{x[0]}</Grid>
              <Grid item xs={2} style={{ borderLeft: 1, borderRight: 0, borderTop: 0, borderBottom: 0, borderStyle: "solid", padding: 10, color: '#4B7B7D', backgroundColor: 'white' }}>{x[1]}</Grid>
              <Grid item xs={2} style={{ borderLeft: 1, borderRight: 1, borderTop: 0, borderBottom: 0, borderStyle: "solid", padding: 10, color: '#4B7B7D', backgroundColor: 'white' }}>{x[2]}</Grid>
              <Grid item xs={2} style={{ borderLeft: 1, borderRight: 1, borderTop: 1, borderBottom: 0, borderStyle: "solid", padding: 10 }}>{x[3]}</Grid>
            </Grid>
          </Grid>))}
        <Grid item xs={12} >
          <Grid container justify="center" spacing={0}>
            <Grid item xs={3} style={{ borderLeft: 1, borderRight: 0, borderTop: 0, borderBottom: 1, borderStyle: "solid", padding: 10, color: '#4B7B7D', backgroundColor: 'white' }}>書法遊戲</Grid>
            <Grid item xs={2} style={{ borderLeft: 1, borderRight: 0, borderTop: 0, borderBottom: 1, borderStyle: "solid", padding: 10, color: '#4B7B7D', backgroundColor: 'white' }}>< CloseIcon style={{ color: 'red' }} /></Grid>
            <Grid item xs={2} style={{ borderLeft: 1, borderRight: 1, borderTop: 0, borderBottom: 1, borderStyle: "solid", padding: 10, color: '#4B7B7D', backgroundColor: 'white' }}>< CloseIcon style={{ color: 'red' }} /></Grid>
            <Grid item xs={2} style={{ borderLeft: 1, borderRight: 1, borderTop: 1, borderBottom: 1, borderStyle: "solid", padding: 10 }}>< CheckIcon style={{ color: 'green' }} /></Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} >
          <Grid container justify="center" spacing={0}>
            <Grid item xs={3} style={{ padding: 10 }}></Grid>
            <Grid item xs={2} style={{ padding: 10 }}>
              <Button
                style={{ height: 'auto', backgroundColor: '#89b98d' }}
                href='/requestdemo'
                color="primary"
                fullWidth
                variant="contained"
              >
                <Typography variant='subtitle1'>
                  註冊
                </Typography>
              </Button>
            </Grid>
            <Grid item xs={2} style={{ padding: 10 }}>
              <Button
                style={{ height: 'auto', backgroundColor: '#62a1ae' }}
                href='/centerregister'
                color="primary"
                fullWidth
                variant="contained"
              >
                <Typography variant='subtitle1'>
                  註冊
                </Typography>
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {contactUsDialogOpen && <ContactUsDialog isOpen={contactUsDialogOpen} onClose={onContactUsClose} />} */}
      {/* </div > */}
    </Container>
  )
}