import {
  Dialog,
  DialogContent,
  DialogTitle,
  Theme,
  Typography,
  withStyles
} from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import EditIcon from "@material-ui/icons/Edit";
import MailIcon from "@material-ui/icons/Mail";
import MoreIcon from "@material-ui/icons/More";
import { AxiosError } from "axios";
import moment from "moment";
import MUIDataTable from "mui-datatables";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  generateRequestDemoEmail, getRequestDemo, getRequestDemos,

  postApproveDemo,
  sendEmail,

  verifyCenterEmail
} from "../api";
import SimpleAppBar from "../components/AppBar";
import ButtonWithLoading from "../components/ButtonWithLoading";
import IconButtonWithLoading from "../components/IconButtonWithLoading";
import RequestDemoTable from "../components/RequestDemoTable";
import SimpleSnackBar, { SnackBarProp } from "../components/SimpleSnackBar";
import { AppState } from "../reducers";
import {
  StyledCircularProgress,
  StyledContainer,
  StyledGridRow
} from "../StyledComponents";
import { ApproveStatus, RequestDemo } from "../types/RequestDemo";
import { RoleType } from "../types/UserRole";

interface LoadedData {
  kind: "loaded";
  data: RequestDemo[];
}

interface LoadingData {
  kind: "loading";
}

interface LoadingDataError {
  kind: "error";
  error?: string;
}

type LoadableData = LoadedData | LoadingData | LoadingDataError;

export default function ApproveDemoPage() {
  const closeErrorSnackBar = () => {
    setSnackBar({ ...snackBar, open: false });
  };

  const [requestDemos, setRequestDemos] = useState<LoadableData>();

  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const [snackBar, setSnackBar] = useState<SnackBarProp>({
    open: false,
    onClose: closeErrorSnackBar,
    message: "",
    variant: undefined,
  });

  const onApproveButtonClick = (id: string | undefined) => {
    if (id !== undefined) {
      setIsSubmitting(true);
      postApproveDemo(id)
        .then((data) => {
          verifyCenterEmail(id);
          console.log("ok");
          setSnackBar({
            ...snackBar,
            message: "Approved",
            open: true,
            variant: "success",
          });
        })
        .catch((error: AxiosError) => {
          console.log(error);
          if (error.response) {
            if (error.response.status === 500) {
              setSnackBar({
                ...snackBar,
                message:
                  "There is a problem with the backend server or mail server.",
                open: true,
                variant: "error",
              });
            } else {
              setSnackBar({
                ...snackBar,
                message: error.response.data.message,
                open: true,
                variant: "warning",
              });
            }
          }
        })
        .finally(() => {
          setIsSubmitting(false);
          _getRequestDemos();
        });
    }
  };

  const onResendButtonClick = async (id: string | undefined) => {
    if (id !== undefined) {
      setIsSubmitting(true);
      if (requestDemos !== undefined && requestDemos.kind === "loaded") {
        const temp = requestDemos.data.filter((x) => x.id === id)[0];
        if (temp.is_approved === false) {
          try {
            await sendEmail(generateRequestDemoEmail(temp.email, temp.name));
            setSnackBar({
              ...snackBar,
              message: "已重發申請電郵",
              open: true,
              variant: "success",
            });
          } catch (e) {
            if (e.response) {
              if (e.response.status === 500) {
                setSnackBar({
                  ...snackBar,
                  message:
                    "There is a problem with the backend server or mail server.",
                  open: true,
                  variant: "error",
                });
              } else {
                setSnackBar({
                  ...snackBar,
                  message: e.response.data.message,
                  open: true,
                  variant: "warning",
                });
              }
            } else {
              setSnackBar({
                ...snackBar,
                message: "Network Error.",
                open: true,
                variant: "warning",
              });
            }
          }
        } else if (temp.is_approved === true && temp.created_user_id === 0) {
          try {
            await postApproveDemo(id);
            setSnackBar({
              ...snackBar,
              message: "已重發確認電郵。",
              open: true,
              variant: "success",
            });
          } catch (e) {
            if (e.response) {
              if (e.response.status === 500) {
                setSnackBar({
                  ...snackBar,
                  message:
                    "There is a problem with the backend server or mail server.",
                  open: true,
                  variant: "error",
                });

              } else {
                setSnackBar({
                  ...snackBar,
                  message: e.response.message,
                  open: true,
                  variant: "warning",
                });
              }
            } else {
              setSnackBar({
                ...snackBar,
                message: "Network Error.",
                open: true,
                variant: "warning",
              });
            }
          }
        }
      }
      setIsSubmitting(false);
    }
  };

  const HtmlTooltip = withStyles((theme: Theme) => ({
    tooltip: {
      backgroundColor: "#f5f5f9",
      color: "rgba(0, 0, 0, 0.87)",
      maxWidth: 220,
      fontSize: theme.typography.pxToRem(12),
      border: "1px solid #dadde9",
    },
  }))(Tooltip);

  const loggedInUser = useSelector<AppState, firebase.User | null>(
    (state) => state.loggedInUser
  );

  const [requestModalOpen, setRequestModalOpen] = useState<boolean>(false);

  const [selectedRequestDemo, setSelectedRequestDemo] = useState<RequestDemo>();

  const [editing, setEditing] = useState<boolean>(false);

  const loggedUserRole = useSelector<AppState, RoleType | null>(
    (state) => state.userRole
  );

  useEffect(() => {
    _getRequestDemos();
  }, []);

  const _getRequestDemos = async () => {
    if (requestDemos === undefined || requestDemos.kind !== "loaded") {
      setRequestDemos({ kind: "loading" });
    }
    try {
      const data = await getRequestDemos();
      if (data !== undefined) {
        let temp = data.map((x) => {
          if (x.is_approved === false) {
            return { ...x, approveStatus: "待准許" as ApproveStatus };
          } else if (x.is_approved === true && x.created_user_at === 0) {
            return { ...x, approveStatus: "已准許" as ApproveStatus };
          } else {
            return { ...x, approveStatus: "已申請" as ApproveStatus };
          }
        });
        temp = temp.map((x) => {
          return {
            ...x,
            created_at: moment
              .utc(x.created_at)
              .local()
              .format("YYYY-MM-DD HH:mm:ss"),
          };
        });
        setRequestDemos({ kind: "loaded", data: temp });
      }
    } catch (e) {
      setRequestDemos({ kind: "error", error: e });
    }
  };

  useEffect(() => {
    console.log(requestDemos);
  }, [requestDemos]);

  const handleRequestModalOpen = async (data: RequestDemo | undefined) => {
    if (data !== undefined && data.id !== undefined) {
      const temp = await getRequestDemo(data.id);
      setRequestModalOpen(true);
      if (temp != null) {
        setSelectedRequestDemo({ ...temp, id: data.id });
      }
    }
  };

  const handleRequestModalClose = () => {
    setRequestModalOpen(false);
    setEditing(false);
    _getRequestDemos();
  };

  const handleEdit = () => {
    setEditing(true);
  };

  return (
    <div>
      <SimpleSnackBar
        open={snackBar.open}
        onClose={closeErrorSnackBar}
        message={snackBar.message}
        variant={snackBar.variant}
      />
      <SimpleAppBar title={"試玩申請"} />
      {loggedInUser && loggedUserRole === "admin" ? (
        <StyledContainer maxWidth="lg" style={{ paddingTop: 90, paddingBottom: 80 }}>
          {requestDemos !== undefined && requestDemos.kind === "loading" && (
            <StyledCircularProgress />
          )}
          {requestDemos !== undefined && requestDemos.kind === "loaded" && (
            <MUIDataTable
              title={""}
              data={requestDemos.data}
              columns={[
                {
                  label: "姓名",
                  name: "name",
                  options: {
                    filter: true,
                    sort: true,
                  },
                },
                {
                  label: "電郵",
                  name: "email",
                  options: {
                    filter: true,
                    sort: true,
                  },
                },
                {
                  label: "職位",
                  name: "user_position",
                  options: {
                    filter: true,
                    sort: true,
                  },
                },
                {
                  label: "公司名稱",
                  name: "company_name",
                  options: {
                    filter: true,
                    sort: true,
                  },
                },
                {
                  label: "聯絡電話",
                  name: "contact_number",
                  options: {
                    filter: true,
                    sort: true,
                  },
                },
                {
                  label: "公司地址",
                  name: "company_address",
                  options: {
                    filter: true,
                    sort: true,
                  },
                },
                {
                  label: "提交時間",
                  name: "created_at",
                  options: {
                    filter: false,
                    sort: true,
                  },
                },
                {
                  label: " ",
                  name: "id",
                  options: {
                    filter: false,
                    sort: false,
                    viewColumns: false,
                    customBodyRender: (value) => (
                      <Tooltip title="檢視申請詳情">
                        <IconButton
                          onClick={() =>
                            handleRequestModalOpen(
                              requestDemos.data.find((x) => x.id === value)
                            )
                          }
                          color="primary"
                        >
                          <MoreIcon />
                        </IconButton>
                      </Tooltip>
                    ),
                  },
                },
                {
                  label: "申請狀況",
                  name: "approveStatus",
                  options: {
                    filter: true,
                    sort: true,
                    viewColumns: false,
                    customBodyRender: (value, tableMeta) => {
                      return (
                        <>
                          {value === "待准許" &&
                            <ButtonWithLoading
                              isLoading={isSubmitting}
                              onClick={() => {
                                const temp = requestDemos.data.find((x) => x.email === tableMeta.rowData[1]);
                                if (temp !== undefined) {
                                  onApproveButtonClick(
                                    temp.id
                                  )
                                }
                              }
                              }
                              color="primary"
                            >
                              准許
                          </ButtonWithLoading>
                          }
                          {value === "已准許" &&
                            "已批准 (未申請帳戶）"}
                          {value === "已申請" ? (
                            <>
                              <HtmlTooltip
                                title={
                                  <React.Fragment>
                                    <Typography color="inherit">
                                      {"申請於 " +
                                        moment
                                          .utc(
                                            tableMeta.rowData[6]
                                          )
                                          .local()
                                          .format("YYYY-MM-DD HH:mm:ss")}
                                    </Typography>
                                  </React.Fragment>
                                }
                              >
                                <IconButton aria-label="delete">
                                  <CheckCircleOutlineIcon
                                    style={{ color: "green" }}
                                  />
                                </IconButton>
                              </HtmlTooltip>
                            </>
                          ) : (
                            <Tooltip title="重發電郵">
                              <IconButtonWithLoading
                                isLoading={isSubmitting}
                                style={{ marginLeft: 10 }}
                                onClick={() => {
                                  const temp = requestDemos.data.find((x) => x.email === tableMeta.rowData[1]);
                                  if (temp !== undefined) {
                                    onResendButtonClick(
                                      temp.id
                                    )
                                  }
                                }}
                              >
                                <MailIcon color="primary" />
                              </IconButtonWithLoading>
                            </Tooltip>
                          )}
                        </>
                      )
                    },
                  },
                },
              ]}
              options={{
                selectableRows: "none",
                elevation: 0,
                print: false,
                download: false,
              }}
            />
          )}
          <Dialog
            scroll="body"
            onClose={handleRequestModalClose}
            aria-labelledby="customized-dialog-title"
            open={requestModalOpen}
            fullWidth
          >
            {editing === false ? (
              <>
                <DialogTitle id="customized-dialog-title">
                  申請試用詳細資料
                  <IconButton
                    style={{ float: "right" }}
                    edge="start"
                    color="inherit"
                    onClick={handleEdit}
                    aria-label="close"
                  >
                    <EditIcon />
                  </IconButton>
                </DialogTitle>
                {selectedRequestDemo !== undefined && (
                  <DialogContent>
                    <StyledGridRow>
                      聯絡人姓名：{selectedRequestDemo.name}
                    </StyledGridRow>
                    <StyledGridRow>
                      推薦碼：{selectedRequestDemo.referralCode}
                    </StyledGridRow>
                    <StyledGridRow>
                      電郵地址：{selectedRequestDemo.email}
                    </StyledGridRow>
                    <StyledGridRow>
                      聯絡電話：{selectedRequestDemo.contact_number}
                    </StyledGridRow>
                    <StyledGridRow>
                      中心名稱：{selectedRequestDemo.company_name}
                    </StyledGridRow>
                    <StyledGridRow>
                      中心地址：{selectedRequestDemo.company_address}
                    </StyledGridRow>
                    <StyledGridRow>
                      中心類別：{selectedRequestDemo.company_type}
                    </StyledGridRow>
                    <StyledGridRow>
                      請註明中心類別：{selectedRequestDemo.company_type_other}
                    </StyledGridRow>
                    <StyledGridRow>
                      中心人數：{selectedRequestDemo.no_of_user}
                    </StyledGridRow>
                    {selectedRequestDemo.users_age_distribution !==
                      undefined && (
                        <>
                          <StyledGridRow>會員年齡：</StyledGridRow>
                          <StyledGridRow>
                            55-65:{" "}
                            {selectedRequestDemo.users_age_distribution
                              .group_55_65
                              ? selectedRequestDemo.users_age_distribution
                                .group_55_65
                              : 0}
                          %<span style={{ paddingRight: 15 }} />
                          65-75:{" "}
                            {selectedRequestDemo.users_age_distribution
                              .group_65_75
                              ? selectedRequestDemo.users_age_distribution
                                .group_65_75
                              : 0}
                          %<span style={{ paddingRight: 15 }} />
                          75-85:{" "}
                            {selectedRequestDemo.users_age_distribution
                              .group_75_85
                              ? selectedRequestDemo.users_age_distribution
                                .group_75_85
                              : 0}
                          %<span style={{ paddingRight: 15 }} />
                          85以上:{" "}
                            {selectedRequestDemo.users_age_distribution
                              .group_85_above
                              ? selectedRequestDemo.users_age_distribution
                                .group_85_above
                              : 0}
                          %
                        </StyledGridRow>
                        </>
                      )}
                    {selectedRequestDemo.users_mental_health_distribution !==
                      undefined && (
                        <>
                          <StyledGridRow>會員精神健康：</StyledGridRow>
                          <StyledGridRow>
                            健康：
                          {selectedRequestDemo.users_mental_health_distribution
                              .healthy
                              ? selectedRequestDemo
                                .users_mental_health_distribution.healthy
                              : 0}
                          %<span style={{ paddingRight: 15 }} />
                          懷疑認知障礙症：
                          {selectedRequestDemo.users_mental_health_distribution
                              .suspect
                              ? selectedRequestDemo
                                .users_mental_health_distribution.suspect
                              : 0}
                          %<span style={{ paddingRight: 15 }} />
                          初期認知障礙症：
                          {selectedRequestDemo.users_mental_health_distribution
                              .early
                              ? selectedRequestDemo
                                .users_mental_health_distribution.early
                              : 0}
                          %<span style={{ paddingRight: 15 }} />
                          </StyledGridRow>
                          <StyledGridRow>
                            中期認知障礙症：
                          {selectedRequestDemo.users_mental_health_distribution
                              .middle
                              ? selectedRequestDemo
                                .users_mental_health_distribution.middle
                              : 0}
                          %<span style={{ paddingRight: 15 }} />
                          晚期認知障礙症：
                          {selectedRequestDemo.users_mental_health_distribution
                              .late
                              ? selectedRequestDemo
                                .users_mental_health_distribution.late
                              : 0}
                          %
                        </StyledGridRow>
                        </>
                      )}
                    {/* <StyledGridRow>
                      已有服務：{selectedRequestDemo.current_services}
                    </StyledGridRow>
                    <StyledGridRow>
                      未來服務計劃：{selectedRequestDemo.future_services}
                    </StyledGridRow>
                    <StyledGridRow>
                      資助形式：{selectedRequestDemo.funding_source}
                    </StyledGridRow> */}
                    <StyledGridRow>
                      提交時間：
                      {moment
                        .utc(selectedRequestDemo.created_at)
                        .local()
                        .format("YYYY-MM-DD HH:mm:ss")}
                    </StyledGridRow>
                    <StyledGridRow>
                      {selectedRequestDemo.is_approved
                        ? selectedRequestDemo.created_user_at
                          ? "申請於" +
                          moment
                            .utc(selectedRequestDemo.created_user_at)
                            .local()
                            .format("YYYY-MM-DD HH:mm:ss")
                          : "已准許未申請"
                        : "未准許"}
                    </StyledGridRow>
                  </DialogContent>
                )}
              </>
            ) : (
              selectedRequestDemo !== undefined && (
                <RequestDemoTable
                  data={selectedRequestDemo}
                  onClose={handleRequestModalClose}
                />
              )
            )}
          </Dialog>
        </StyledContainer>
      ) : (
        <StyledContainer maxWidth="lg">
          <SimpleSnackBar
            open={true}
            onClose={closeErrorSnackBar}
            message={"You don't have permission to view this page."}
            variant="warning"
            autoClose={99999}
          />
        </StyledContainer>
      )}
      <SimpleAppBar title={"試玩申請"} bottom={true} />

    </div>
  );
}
