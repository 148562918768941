import { SetUserRoleAction, ClearUserRoleAction } from "../types/Actions";
import { RoleType } from "../types/UserRole";

export const setUserRole = (userRole: RoleType): SetUserRoleAction => ({
  type: "SET_USER_ROLE",
  userRole,
});

export const clearUserRole = (): ClearUserRoleAction => ({
  type: "CLEAR_USER_ROLE",
});
