export interface GameLog {
  dealer: number, //每局第一個莊
  dice: Array<number>,
  first_dealer: number, //一局麻雀第一個莊
  game_end_time: number,
  game_start_time: number,
  loser: number,  //-1自摸/平局
  remain_dealer: number, //林莊
  score: number,
  wind: number,
  winner: number,
  players: Array<GameLogHistory>,
  player_ids: { [key: string]: string },
  id: string,
}

export const windChinese = ['東', '南', '西', '北'];

interface GameLogHistory {
  actions: Array<TurnActionType>,
  name: string,
  starting_hand: Array<string>,
  user_id: string,
}
interface ChowTurn {
  chow: string,
  combination: Array<string>,
  discard: string,
}
interface PongTurn {
  pong: string,
  discard: string,
}
interface GongTurn {
  gong: string,
}
interface DiscardTurn {
  discard?: string,
  draw?: string,
  to?: number,
}
interface FlowerTurn {
  flower: string,
}
export type TurnActionType = ChowTurn | PongTurn | GongTurn | DiscardTurn | FlowerTurn;