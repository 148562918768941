import { Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, MenuItem, TextField } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getUserParent, updateUserProfile } from "../api";
import { LoadableAllCenterData } from "../pages/UserProfilesPage";
import { AppState } from "../reducers";
import { StyledCircularProgress } from "../StyledComponents";
import { Login } from "../types/Login";
import { calculateMocaScore, DementiaDiagnoses, DementiaDiagnosisType, EducationLevels, EducationLevelType, FamiliarLevels, FamiliarLevelType, HabitAnswers, HabitAnswerType, MocaScore, translation, UserProfile } from "../types/UserProfile";
import { RoleType } from "../types/UserRole";
import ButtonWithLoading from "./ButtonWithLoading";
import { EditMocaScorePanel } from "./EditMocaScorePanel";
import SimpleSnackBar, { SnackBarProp } from "./SimpleSnackBar";

interface AddUserDialogProps {
  user?: UserProfile;
  onClose: () => void,
  isOpen: boolean,
  allCenter?: LoadableAllCenterData,
}
export default function AddUserDialog(props: AddUserDialogProps) {

  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const userRole = useSelector<AppState, RoleType | null>(state => state.userRole);

  const blankUserProfile: UserProfile = {
    user_id: "",
    name: "",
    gender: "",
    avatar_url: "",
    year_of_birth: null,
    dementia_diagnosis: undefined,
    occupation: "",
    smoke: undefined,
    drink_alcohol: undefined,
    avg_walking_time: undefined,
    moca_score: undefined,
    is_dementia_mode: false,
  };

  const newLogin: Login = {
    email: "",
    password: "",
  }

  const [userProfile, setUserProfile] = useState<UserProfile>(blankUserProfile);
  const [login, setLogin] = useState<Login>(newLogin);
  const [nameError, setNameError] = useState<boolean>(false);
  const [centerError, setCenterError] = useState<boolean>(false);
  // const [genderError, setGenderError] = useState<boolean>(false);
  // const [yearOfBirthError, setYearOfBirthError] = useState<boolean>(false);
  const [emailError, setEmailError] = useState<boolean>(false);
  const [passwordError, setPasswordError] = useState<boolean>(false);

  const [mocaScore, setMocaScore] = useState<MocaScore | undefined | null>();
  const [editMocaScorePanelOpen, setEditMocaScorePanelOpen] = useState<boolean>(false);

  const closeErrorSnackBar = () => {
    setErrorSnackBar({ ...errorSnackBar, "open": false });
  }

  const [errorSnackBar, setErrorSnackBar] = useState<SnackBarProp>({
    open: false,
    onClose: closeErrorSnackBar,
    message: "",
  });


  useEffect(() => {
    console.log(props.isOpen);

    if (props.user !== undefined) {
      let temp = props.user;
      if (temp.use_smartphone_level !== undefined) {
        let value = Object.keys(translation).find(key => translation[key] === temp.use_smartphone_level) as FamiliarLevelType;
        if (value !== undefined) {
          temp.use_smartphone_level = value;
        }
      }
      if (temp.education_level !== undefined) {
        let value = Object.keys(translation).find(key => translation[key] === temp.education_level) as EducationLevelType;
        if (value !== undefined) {
          temp.education_level = value;
        }
      }
      if (temp.mahjong_level !== undefined) {
        let value = Object.keys(translation).find(key => translation[key] === temp.mahjong_level) as FamiliarLevelType;
        if (value !== undefined) {
          temp.mahjong_level = value;
        }
      }
      if (temp.smoke !== undefined) {
        let value = Object.keys(translation).find(key => translation[key] === temp.smoke) as HabitAnswerType;
        if (value !== undefined) {
          temp.smoke = value;
        }
      }
      if (temp.drink_alcohol !== undefined) {
        let value = Object.keys(translation).find(key => translation[key] === temp.drink_alcohol) as HabitAnswerType;
        if (value !== undefined) {
          temp.drink_alcohol = value;
        }
      }
      if (temp.dementia_diagnosis !== undefined) {
        let value = Object.keys(translation).find(key => translation[key] === temp.dementia_diagnosis) as DementiaDiagnosisType;
        if (value !== undefined) {
          temp.dementia_diagnosis = value;
        }
      }
      setUserProfile(temp);
    } else {
      setUserProfile(blankUserProfile);
    }
  }, [props]);

  useEffect(() => {
    // console.log(userProfile);
    if (userProfile.center !== undefined && userProfile.user_id !== "") {
      _getUserParent(userProfile.user_id);
      setMocaScore(userProfile.moca_score);
    }
  }, [userProfile])

  const _getUserParent = async (id: string) => {
    const center = await getUserParent(id);
    if (center !== null) {
      setUserProfile({ ...userProfile, center: center });
    }
  }

  function validateEmail(email: string) {
    const regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return regex.test(email.toLowerCase());
  }

  function validatePassword(pw: string) {
    const regex = /^.{6,}$/;
    return regex.test(pw);
  }

  const onConfirmMocaScore = (scores: MocaScore | null) => {
    setMocaScore(scores);
    setUserProfile(prev => { return { ...prev, moca_score: scores } });
  }

  const submitButtonClicked = async () => {
    // if (userProfile.name === "" || userProfile.gender === "" || userProfile.year_of_birth === null || login.email === null || login.password === null) {
    //   return;
    // }
    // event.preventDefault();
    let hasError = false;
    let hasValidationError = false;

    if (userProfile.name === "") {
      setNameError(true);
      hasError = true;
    }
    if (userRole === "admin" && userProfile.center === undefined) {
      setCenterError(true);
      hasError = true;
    }
    if (props.user === undefined && login.email === "") {
      setEmailError(true);
      hasError = true;
    }

    if (!props.user) {
      if (!validateEmail(login.email)) {
        setEmailError(true);
        hasValidationError = true;
        setErrorSnackBar({ ...errorSnackBar, "open": true, message: "電郵地址格式錯誤！" });
      }

      if (!validatePassword(login.password)) {
        setPasswordError(true);
        hasValidationError = true;
        setErrorSnackBar({ ...errorSnackBar, "open": true, message: "密碼必須由至少由六個字元組成！" });
      }
    }

    if (props.user === undefined && login.password === "") {
      setPasswordError(true);
      hasError = true;
    }

    if (userProfile.moca_score === undefined) {
      setErrorSnackBar({ ...errorSnackBar, "open": true, message: "請輸入MOCA分數。如果此用戶未進行MOCA測試，請在「編輯分數」頁面，按下「尚未進行MOCA測試」按鈕" });
      setIsSubmitting(false);
      return;
    }

    if (hasValidationError) return;

    if (hasError === false) {
      setIsSubmitting(true);
      try {
        if (userRole !== null) {
          await updateUserProfile(userProfile, userRole, login);
          console.log('success');
          props.onClose();
        }
      } catch (e) {
        setErrorSnackBar({ ...errorSnackBar, "open": true, message: "電郵地址已經被註冊！" });
      }
      setIsSubmitting(false);
      return;
    } else {
      setErrorSnackBar({
        open: true,
        onClose: closeErrorSnackBar,
        message: "請輸入帶*啲項目",
      })
      return;
    }
  }

  const onInputChange = (name: keyof UserProfile) => (event: React.ChangeEvent<HTMLInputElement>) => {
    console.log(event);
    setUserProfile({ ...userProfile, [name]: (name === "year_of_birth") ? Number(event.target.value) : (name === "is_dementia_mode") ? event.target.checked : event.target.value });
  }

  const onLoginInputChange = (name: keyof Login) => (event: React.ChangeEvent<HTMLInputElement>) => {
    setLogin({ ...login, [name]: event.target.value });
  }

  return (
    <Dialog onClose={props.onClose} aria-labelledby="simple-dialog-title" open={props.isOpen}>
      <SimpleSnackBar open={errorSnackBar.open} onClose={closeErrorSnackBar} message={errorSnackBar.message} />
      <DialogTitle id="simple-dialog-title">{props.user !== undefined ? "修改玩家資料" : "新增玩家"}</DialogTitle>
      <DialogContent>

        <TextField
          margin="dense"
          error={nameError}
          id="name"
          label="姓名*"
          type="text"
          value={userProfile.name}
          onChange={onInputChange("name")}
          fullWidth
        />

        <TextField
          margin="dense"
          id="gender"
          label="性別"
          select
          value={userProfile.gender}
          onChange={onInputChange("gender")}
          fullWidth
        >
          <MenuItem value={"M"}>男</MenuItem>
          <MenuItem value={"F"}>女</MenuItem>
        </TextField>

        <TextField
          margin="dense"
          id="year_of_birth"
          label="出生年份"
          type="number"
          value={userProfile.year_of_birth}
          onChange={onInputChange("year_of_birth")}
          fullWidth
        />
        {props.allCenter !== undefined && props.allCenter.kind === "loading" && <StyledCircularProgress />}
        {props.allCenter !== undefined && userRole === "admin" && props.allCenter.kind === "loaded" && <TextField
          margin="dense"
          id="center"
          label="所屬團體*"
          error={centerError}
          select
          value={userProfile.center}
          onChange={onInputChange("center")}
          fullWidth
        >
          {props.allCenter.data.map(x => {
            if (x !== null) {
              return (
                <MenuItem value={x.uid}>{x.name}</MenuItem>
              )
            }
          })}
        </TextField>}

        <div style={{ margin: "20px 0", height: 34, display: "flex", alignItems: "center", justifyContent: "space-between" }}>
          <div>
            <span style={{ fontSize: 18 }}>MOCA總分數： </span>
            <span style={{ fontSize: 18 }}>{mocaScore ? calculateMocaScore(mocaScore) : "無記錄"}</span>
          </div>
          <span><Button color="primary" variant="contained" size="small" onClick={() => setEditMocaScorePanelOpen(true)}>編輯分數</Button></span>
        </div>

        <TextField
          id="dementia_diagnosis"
          select
          label="腦退化症診斷結果"
          value={userProfile.dementia_diagnosis}
          onChange={onInputChange('dementia_diagnosis')}
          margin="normal"
          fullWidth
        >
          {DementiaDiagnoses.map(option => (
            <MenuItem key={option} value={option}>
              {translation[option]}
            </MenuItem>
          ))}
        </TextField>

        <FormControlLabel
          control={<Checkbox checked={userProfile.is_dementia_mode} onChange={onInputChange("is_dementia_mode")} name="is_dementia_mode" color='primary' />}
          label="需要簡化模式？"
          style={{ paddingTop: 10 }}
        />

        <TextField
          margin="dense"
          id="occupation"
          label="職業 (曾任/現任)"
          type="text"
          value={userProfile.occupation}
          onChange={onInputChange("occupation")}
          fullWidth
        />

        <TextField
          id="use_smartphone_level"
          select
          label="使用智能電話/平板電腦的熟悉度"
          value={userProfile.use_smartphone_level}
          onChange={onInputChange('use_smartphone_level')}
          margin="normal"
          fullWidth
        >

          {FamiliarLevels.map(option => (
            <MenuItem key={option} value={option}>
              {translation[option]}
            </MenuItem>
          ))}
        </TextField>

        <TextField
          id="education_level"
          select
          label="教育程度"
          value={userProfile.education_level}
          onChange={onInputChange('education_level')}
          margin="normal"
          fullWidth
        >
          {EducationLevels.map(option => (
            <MenuItem key={option} value={option}>
              {translation[option]}
            </MenuItem>
          ))}
        </TextField>


        <TextField
          id="mahjong_level"
          select
          label="打麻雀熟悉度"
          value={userProfile.mahjong_level}
          onChange={onInputChange('mahjong_level')}
          margin="normal"
          fullWidth
        >
          {FamiliarLevels.map(option => (
            <MenuItem key={option} value={option}>
              {translation[option]}
            </MenuItem>
          ))}
        </TextField>

        <TextField
          id="smoke"
          select
          label="吸煙習慣"
          value={userProfile.smoke}
          onChange={onInputChange('smoke')}
          margin="normal"
          fullWidth
        >
          {HabitAnswers.map(option => (
            <MenuItem key={option} value={option}>
              {translation[option]}
            </MenuItem>
          ))}
        </TextField>

        <TextField
          id="drink_alcohol"
          select
          label="飲酒習慣"
          value={userProfile.drink_alcohol}
          onChange={onInputChange('drink_alcohol')}
          margin="normal"
          fullWidth
        >
          {HabitAnswers.map(option => (
            <MenuItem key={option} value={option}>
              {translation[option]}
            </MenuItem>
          ))}
        </TextField>

        <TextField
          margin="dense"
          id="eHealthId"
          label="E-Health ID"
          type="text"
          value={userProfile.eHealthId}
          onChange={onInputChange("eHealthId")}
          fullWidth
        />

        {props.user === undefined &&
          <div>
            <div>個人登入帳戶 (讓用家可以在家自行登入，保留記錄)</div>
            <div>
              <TextField
                margin="dense"
                id="email"
                error={emailError}
                label="Email*"
                type="text"
                value={login.email}
                onChange={onLoginInputChange("email")}
                fullWidth
              />

              <TextField
                margin="dense"
                id="password"
                error={passwordError}
                label="密碼* (必須至少由6個字元組成)"
                type="password"
                value={login.password}
                onChange={onLoginInputChange("password")}
                fullWidth
              />
            </div>
          </div>
        }

      </DialogContent>
      <DialogActions>
        <Button onClick={props.onClose} color="primary">取消</Button>
        <ButtonWithLoading onClick={submitButtonClicked} color="primary" isLoading={isSubmitting}>儲存</ButtonWithLoading>
      </DialogActions>

      <EditMocaScorePanel open={editMocaScorePanelOpen} onClose={() => setEditMocaScorePanelOpen(false)} onConfirm={onConfirmMocaScore} />
    </Dialog >
  )
}