import { UserProfile } from "../types/UserProfile";
import { UserProfilesActionTypes } from "../types/Actions";

interface LoadedData {
  kind: "loaded";
  data: UserProfile[];
}

interface LoadingData {
  kind: "loading";
}

interface LoadingDataError {
  kind: "error";
  error?: string;
}

interface NotLoaded {
  kind: "notLoaded";
}

export type LoadableProfileData =
  | LoadedData
  | LoadingData
  | LoadingDataError
  | NotLoaded;

const getUserProfilesReducerDefaultState: LoadableProfileData = {
  kind: "loading",
};

const userProfilesReducer = (
  state: LoadableProfileData = getUserProfilesReducerDefaultState,
  action: UserProfilesActionTypes
): LoadableProfileData => {
  switch (action.type) {
    case "SET_USER_PROFILES":
      return action.userProfiles;
    case "CLEAR_USER_PROFILES":
      return { kind: "notLoaded" };
    default:
      return state;
  }
};
export default userProfilesReducer;
