import { DialogContent } from "@material-ui/core";
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import Divider from '@material-ui/core/Divider';
import Slider from '@material-ui/core/Slider';
import Typography from '@material-ui/core/Typography';
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { getGamePreference, postGamePreference } from "../api";
import { StyledCircularProgress } from "../StyledComponents";
import { GamePreference } from "../types/GamePreference";

const StyledPreferenceRow = styled.div`
    padding: 10px;
    min-width: 500px;
    max-width: 600px;
`

const StyledDialogContent = styled(DialogContent)`
    min-height: 50px;
    position: relative;
`
export interface GamePreferenceDialogProp {
  open: boolean,
  onClose: (submitted?: boolean) => void,
  userId: string,
}

export default function GamePreferenceDialog(props: GamePreferenceDialogProp) {


  //undefined is default state, null = no record found in db.
  const [gamePreference, setGamePreference] = useState<GamePreference | null>();

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { onClose, open, userId } = props;

  useEffect(() => {
    setGamePreference(undefined)
    setIsLoading(true);
    getGamePreference(userId).then(data => {
      setIsLoading(false);
      console.log(data);
      if (data !== null) {
        setGamePreference(data);
      } else {
        setGamePreference(null);
      }
    })
  }, [userId]);

  function handleClose() {
    onClose();
  }

  const saveButtonClicked = () => {
    console.log("saved");
    if (userId !== undefined && gamePreference !== undefined && gamePreference !== null) {
      setIsLoading(true);
      console.log(gamePreference);
      postGamePreference(userId, gamePreference).then(() => {
        onClose(true);
        setIsLoading(false);
      });
    }
  }

  const sliderOnChange = (preference: string, value: number | number[]) => {
    if (gamePreference !== undefined && gamePreference !== null) {
      console.log(preference);
      console.log(value);
      setGamePreference({ ...gamePreference, [preference]: value });
    }
  }


  const discardWaitingTimeMarks = [
    {
      value: 3,
      label: 3,
    },
    {
      value: 5,
      label: 5,
    },
    {
      value: 7,
      label: 7,
    },
    {
      value: 9,
      label: 9,
    },
    {
      value: 11,
      label: 11,
    },

  ]

  const booleanMarks = [
    {
      value: 0,
      label: "沒有",
    },
    {
      value: 1,
      label: "有",
    },
  ];

  const dragToDiscardMarks = [
    {
      value: 0,
      label: "點擊",
    },
    {
      value: 1,
      label: "拖放",
    }
  ]

  function booleanValueLabelFormat(value: number) {
    let mark = booleanMarks.find(mark => mark.value === value);
    if (mark !== undefined) {
      return mark.label;
    } else {
      return "";
    }
  }

  function dragToDiscardLabelFormat(value: number) {
    let mark = dragToDiscardMarks.find(mark => mark.value === value);
    if (mark !== undefined) {
      return mark.label;
    } else {
      return "";
    }
  }

  return (
    <Dialog onClose={handleClose} aria-labelledby="simple-dialog-title" open={open}>
      <DialogTitle id="simple-dialog-title">遊戲設定</DialogTitle>
      <StyledDialogContent>
        {isLoading ? <StyledCircularProgress /> : null}
        {gamePreference === null && <div style={{ textAlign: "center" }}>沒有遊戲設定。請先等待玩家完成入學試由系統計算設定。</div>}
        {gamePreference !== undefined && gamePreference !== null &&
          <div>
            <StyledPreferenceRow>
              <Typography id="discrete-slider-restrict" gutterBottom>
                遊戲提示
                        </Typography>
              <Slider
                defaultValue={gamePreference.show_hints}
                valueLabelDisplay="auto"
                valueLabelFormat={booleanValueLabelFormat}
                step={1}
                marks={booleanMarks}
                min={0}
                max={1}
                onChange={(event, value) => sliderOnChange("show_hints", value)}
              />
            </StyledPreferenceRow>
            <Divider />
            <StyledPreferenceRow>
              <Typography id="discrete-slider-restrict" gutterBottom>
                上碰槓提示
                        </Typography>
              <Slider
                defaultValue={gamePreference.action_auto_show}
                valueLabelDisplay="auto"
                valueLabelFormat={booleanValueLabelFormat}
                step={1}
                marks={booleanMarks}
                min={0}
                max={1}
                onChange={(event, value) => sliderOnChange("action_auto_show", value)}
              />
            </StyledPreferenceRow>
            <Divider />
            <StyledPreferenceRow>
              <Typography id="discrete-slider-restrict" gutterBottom>
                自動排牌
                        </Typography>
              <Slider
                defaultValue={gamePreference.tiles_auto_sort}
                valueLabelDisplay="auto"
                valueLabelFormat={booleanValueLabelFormat}
                step={1}
                marks={booleanMarks}
                min={0}
                max={1}
                onChange={(event, value) => sliderOnChange("tiles_auto_sort", value)}
              />
            </StyledPreferenceRow>
            <Divider />
            <StyledPreferenceRow>
              <Typography id="discrete-slider-restrict" gutterBottom>
                自動摸牌
                        </Typography>
              <Slider
                defaultValue={gamePreference.tile_auto_draw}
                valueLabelDisplay="auto"
                valueLabelFormat={booleanValueLabelFormat}
                step={1}
                marks={booleanMarks}
                min={0}
                max={1}
                onChange={(event, value) => sliderOnChange("tile_auto_draw", value)}
              />
            </StyledPreferenceRow>
            <Divider />
            <StyledPreferenceRow>
              <Typography id="discrete-slider-restrict" gutterBottom>
                新手模式
                        </Typography>
              <Slider
                defaultValue={gamePreference.start_tile_manipulation}
                valueLabelDisplay="auto"
                valueLabelFormat={booleanValueLabelFormat}
                step={1}
                marks={booleanMarks}
                min={0}
                max={1}
                onChange={(event, value) => sliderOnChange("start_tile_manipulation", value)}
              />
            </StyledPreferenceRow>
            <Divider />
            {/* <StyledPreferenceRow>
              <Typography id="discrete-slider-restrict" gutterBottom>
              新手模式
                        </Typography>
              <Slider
                defaultValue={gamePreference.draw_tile_manipulation}
                valueLabelDisplay="auto"
                valueLabelFormat={booleanValueLabelFormat}
                step={1}
                marks={booleanMarks}
                min={0}
                max={1}
                onChange={(event, value) => sliderOnChange("draw_tile_manipulation", value)}
              />
            </StyledPreferenceRow> */}
            <Divider />
            <StyledPreferenceRow>
              <Typography id="discrete-slider-restrict" gutterBottom>
                電腦出牌時間
                        </Typography>
              <Slider
                defaultValue={gamePreference.discard_waiting_time}
                valueLabelDisplay="auto"
                step={2}
                marks={discardWaitingTimeMarks}
                min={3}
                max={11}
                onChange={(event, value) => sliderOnChange("discard_waiting_time", value)}
              />
            </StyledPreferenceRow>
            <DialogActions>
              <Button onClick={handleClose} color="primary">
                取消
                            </Button>
              <Button onClick={saveButtonClicked} color="primary" disabled={isLoading}>
                儲存
                            </Button>
            </DialogActions>

          </div>}

      </StyledDialogContent>
    </Dialog>
  )
}