import { UserValidity } from "../types/UserValidity";
import { UserValiditiesActionTypes } from "../types/Actions";

const userValiditiesReducerDefaultState: null = null;

const userValiditiesReducer = (
  state: UserValidity[] | null = userValiditiesReducerDefaultState,
  action: UserValiditiesActionTypes
): UserValidity[] | null => {
  switch (action.type) {
    case "SET_USER_VALIDITIES":
      return action.userValidities;
    case "CLEAR_USER_VALIDITIES":
      return null;
    default:
      return state;
  }
};
export default userValiditiesReducer;
