import { Button, createStyles, Divider, Drawer, ListItemIcon, ListSubheader, makeStyles, Menu, Theme, useMediaQuery, useTheme } from "@material-ui/core";
import AppBar from '@material-ui/core/AppBar';
import IconButton from '@material-ui/core/IconButton';
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import MenuItem from '@material-ui/core/MenuItem';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { AccountBox, Announcement, Assessment, Assignment, Email, ExitToApp, Explore, Home, LibraryBooks, LiveHelp, Lock, SupervisorAccount } from "@material-ui/icons";
import AccountCircle from '@material-ui/icons/AccountCircle';
import MenuIcon from '@material-ui/icons/Menu';
import "firebase/auth";
import React, { useEffect, useState } from 'react';
import { Scrollbars } from 'react-custom-scrollbars-2';
import { useDispatch, useSelector } from 'react-redux';
import { Link, Redirect } from "react-router-dom";
import styled from "styled-components";
import { loggedIn, loggedOut } from '../actions/loggedIn';
import { clearUserProfiles } from "../actions/userProfiles";
import { clearUserRole, setUserRole } from "../actions/userRole";
import { getUserRole } from '../api';
import firebase from "../firebase";
import { useQueryParams } from "../helper/hooks";
import appbar from "../image/bar.png";
import logo_menu from "../image/logo_menu.png";
import logo_white from "../image/logo_white.png";
import { AppState } from '../reducers';
import { isTrialAccount, RoleType } from '../types/UserRole';

const StyledMenuIcon = styled(MenuIcon)`
  color: white;
`;

const StyledLink = styled(Link)`
  text-decoration: none;
  color: black;
`;

const StyledSideList = styled.div`
  width: 250px;
`;

const Root = styled.div`
  flex-grow: 1;
`

const StyledProfileText = styled.div`
  font-size:15px;
  padding-bottom:7px;
  padding-top:5px;
  padding-left:15px;
  padding-right:10px;
`
const StyledProfileNameText = styled.div`
  font-size:20px;
  padding-top:5px;
  padding-bottom:5px;
  padding-left:15px;
  padding-right:10px;
  font-weight: bold;
`

interface Props {
  title: string,
  bottom?: boolean,
}

interface MenuOpen {
  open: boolean,
}

interface MenuItem {
  name: string,
  routeName: string,
  icon: JSX.Element
}

const AboutUsMenuItems: Array<MenuItem> = [
  { name: "聯絡我們", routeName: "/contactus", icon: <Email color="primary" /> },
  { name: "常見問題", routeName: "/faq", icon: <LiveHelp color="primary" /> },
  { name: "教學", routeName: "/tutorials", icon: <LibraryBooks color="primary" /> },
];

const AccountSettings: Array<MenuItem> = [
  { name: "密碼設定", routeName: "/settings/changepassword", icon: <Lock color="primary" /> },
];

const AdminMenuItems: Array<MenuItem> = [
  { name: "帳戶", routeName: "/accounts", icon: <SupervisorAccount style={{ color: "#239139" }} /> },
  { name: "試玩申請", routeName: "/approvedemo", icon: <Assignment style={{ color: "#239139" }} /> },
  { name: "探索", routeName: "/discover", icon: <Explore style={{ color: "#239139" }} /> }
];

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    appBar: {
      backgroundImage: 'url(' + appbar + ')',
      // background: 'transparent',
      boxShadow: 'none'
    },
  }),
);

export default function SimpleAppBar(props: Props) {

  const theme = useTheme();
  const queryParams = useQueryParams();

  const smBreakPoint = useMediaQuery(theme.breakpoints.up('sm'));

  const classes = useStyles();

  const dispatch = useDispatch();

  const [generalMenuItems, setGeneralMenuItems] = useState<Array<MenuItem>>();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const open = Boolean(anchorEl);

  const [loggedInUser, setLoggedInUser] = useState<firebase.User | null>();

  const userRole = useSelector<AppState, RoleType | null>(state => state.userRole);

  useEffect(() => {
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        console.log(user);
        dispatch(loggedIn(user));
        setLoggedInUser(user);
      } else {
        console.log("no user");
        setLoggedInUser(null);
        dispatch(loggedOut());
        dispatch(clearUserProfiles());
        dispatch(clearUserRole());
      }
    });
  }, [])

  useEffect(() => {
    const _getUserRole = async () => {
      if (loggedInUser !== undefined && loggedInUser !== null) {
        const role = await getUserRole(loggedInUser.uid);
        dispatch(setUserRole(role.role));
      }
    }
    if (userRole === "unknown" || userRole === null) {
      _getUserRole();
    }
  }, [loggedInUser])

  useEffect(() => {
    let menuItem = [];

    if (userRole !== null) {
      console.log(userRole);
      menuItem.push({ name: "主頁", routeName: "/home", icon: <Home color="primary" /> });
      menuItem.push({ name: "用家", routeName: "/users", icon: <AccountBox color="primary" /> });
      if (!isTrialAccount(userRole)) {
        menuItem.push({ name: "報告", routeName: "/report", icon: <Assessment color="primary" /> });
      }
      if (userRole === "center" || userRole === "trial_center") {
        menuItem.push({ name: "公告", routeName: "/announcement", icon: <Announcement color="primary" /> });
      }
      // menuItem.push({ name: "遊戲報告", routeName: "/reports/game" });
      // menuItem.push({ name: "任務報告", routeName: "/reports/mission" });
      setGeneralMenuItems(menuItem);
    }
  }, [loggedInUser, userRole])


  const sideList = () => (
    <StyledSideList
      role="presentation"
      onClick={toggleLeftMenu(false)}
      onKeyDown={toggleLeftMenu(false)}
      style={{ height: "100%" }}
    >
      <List style={{ justifyContent: 'left', display: 'flex', marginTop: 10, marginBottom: 10 }}>
        <StyledLink to={"home"}>
          <ListItem>
            <img src={logo_menu} style={{ display: 'block', height: 45, width: 90, marginRight: 15 }}></img>
            <span style={{ color: "#093a4f", fontSize: "20px", fontWeight: "bold" }}>
              腦有記
              <br />
              網上綜合系統
            </span>
          </ListItem>
        </StyledLink>
      </List>
      <Divider />
      <Scrollbars style={{ width: 250, height: "calc(85% - 51px)" }}>
        {
          (AdminMenuItems !== undefined && userRole === "admin") &&
          (
            <>
              <List disablePadding>
                <ListSubheader style={{ background: "linear-gradient(0deg, rgba(255,255,255,0) 0%, rgba(255,255,255,0.75) 50%, rgba(255,255,255,1) 100%)", marginRight: 10, color: "#239139", fontWeight: "bold", fontSize: "1.3rem", marginTop: 15 }}>管理人員工具</ListSubheader>
                {AdminMenuItems.map((item, index) => (
                  <>
                    <StyledLink key={index} to={item.routeName}>
                      <ListItem button style={{ paddingTop: 0, paddingBottom: 0 }}>
                        <ListItemIcon>{item.icon}</ListItemIcon>
                        <ListItemText style={{ color: "#239139", lineHeight: 1 }} primary={item.name} />
                      </ListItem>
                    </StyledLink>
                  </>
                ))}
              </List>
              <Divider style={{ marginTop: 10 }} />
            </>
          )
        }
        {
          generalMenuItems !== undefined &&
          <List disablePadding>
            <ListSubheader style={{ background: "linear-gradient(0deg, rgba(255,255,255,0) 0%, rgba(255,255,255,0.75) 50%, rgba(255,255,255,1) 100%)", marginRight: 10, color: "#093a4f", fontWeight: "bold", fontSize: "1.3rem", marginTop: 15 }}>儀表板</ListSubheader>
            {generalMenuItems.map((item, index) => (
              <>
                <StyledLink key={index} to={item.routeName}>
                  <ListItem button style={{ paddingTop: 0, paddingBottom: 0 }}>
                    <ListItemIcon>{item.icon}</ListItemIcon>
                    <ListItemText style={{ color: "#093a4f", lineHeight: 1 }} primary={item.name} />
                  </ListItem>
                </StyledLink>
              </>
            ))}
          </List>
        }
        <Divider style={{ marginTop: 10 }} />
        <ListSubheader style={{ background: "linear-gradient(0deg, rgba(255,255,255,0) 0%, rgba(255,255,255,0.75) 50%, rgba(255,255,255,1) 100%)", marginRight: 10, color: "#093a4f", fontWeight: "bold", fontSize: "1.3rem", marginTop: 15 }}>相關資源</ListSubheader>
        {
          AboutUsMenuItems.map((item, index) => ([
            <StyledLink key={index} to={item.routeName}>
              <ListItem button style={{ paddingTop: 0, paddingBottom: 0 }}>
                <ListItemIcon>{item.icon}</ListItemIcon>
                <ListItemText style={{ color: "#093a4f", lineHeight: 1 }} primary={item.name} />
              </ListItem>
            </StyledLink>,
          ]
          ))
        }
        <Divider style={{ marginTop: 10 }} />

        <ListSubheader style={{ background: "linear-gradient(0deg, rgba(255,255,255,0) 0%, rgba(255,255,255,0.75) 50%, rgba(255,255,255,1) 100%)", marginRight: 10, color: "#093a4f", fontWeight: "bold", fontSize: "1.3rem", marginTop: 15 }}>賬號設定</ListSubheader>
        {
          AccountSettings.map((item, index) => ([
            <StyledLink key={index} to={item.routeName}>
              <ListItem button>
                <ListItemIcon>{item.icon}</ListItemIcon>
                <ListItemText style={{ color: "#093a4f", lineHeight: 1 }} primary={item.name} />
              </ListItem>
            </StyledLink>,
          ]
          ))
        }
        <Divider style={{ marginTop: 10 }} />
      </Scrollbars>

      <ListItem button onClick={logout} style={{ position: "absolute", bottom: 0 }}>
        <ListItemIcon><ExitToApp style={{ color: "#e02828" }} /></ListItemIcon>
        <ListItemText style={{ color: "#e02828" }} primary="登出"></ListItemText>
      </ListItem>

    </StyledSideList >
  );

  const logout = () => {
    console.log("logout");
    firebase.app().auth().signOut();
  }

  const [isLeftMenuOpen, setisLeftMenuOpen] = React.useState<boolean>(false);

  const toggleLeftMenu = (open: boolean) => () => {
    setisLeftMenuOpen(open);
  };

  const handleProfileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleProfileMenuClose = () => {
    setAnchorEl(null);
  };

  const redirectUrl = () => (
    <Redirect
      to={{
        pathname: `/login`,
        state: {
          from: window.location.pathname,
          token: queryParams.get("token") ? queryParams.get("token") : undefined,
          query_uid: queryParams.get("uid") ? queryParams.get("uid") : undefined
        }
      }}
    />
  )

  return (
    <Root>
      <AppBar position='fixed' style={{ marginTop: props.bottom ? '92vh' : 0 }}>
        <Toolbar >
          {!props.bottom ?
            <>
              <IconButton aria-label="menu" onClick={toggleLeftMenu(true)}>
                <StyledMenuIcon />
              </IconButton>
              <Typography variant="h6" color="inherit" style={{ flexGrow: 1, textAlign: 'left' }}>
                {props.title} {(process.env.NODE_ENV !== 'production' || process.env.REACT_APP_ENVIRONMENT === "test") && <span style={{ color: "red" }}>(DEVELOPMENT SERVER)</span>}
              </Typography>
              {loggedInUser !== null && loggedInUser !== undefined &&
                <>
                  {smBreakPoint && generalMenuItems !== undefined && generalMenuItems.map(x => {
                    if (x.name !== '主頁')
                      return (
                        <Button href={x.routeName} color='inherit' style={{ marginLeft: 70 }}>
                          <Typography variant="subtitle1" color="inherit" align='center'>
                            {x.name}
                          </Typography>
                        </Button>
                      )
                  })}
                  <IconButton
                    aria-label="account of current user"
                    aria-controls="menu-appbar"
                    aria-haspopup="true"
                    onClick={handleProfileMenuOpen}
                    color="inherit"
                    style={{ marginLeft: 70 }}
                  >
                    <AccountCircle fontSize={"large"} />
                  </IconButton>
                  <Menu
                    id="simple-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={open}
                    onClose={handleProfileMenuClose}
                  >
                    {loggedInUser.displayName !== null && [<StyledProfileNameText>{loggedInUser.displayName}</StyledProfileNameText>, <br />]}
                    <StyledProfileText>{"電郵： " + loggedInUser.email}</StyledProfileText><br />
                    <StyledProfileText>{"身份： " + userRole}</StyledProfileText>
                  </Menu>
                </>}
            </> :
            <>
              <img src={logo_white} style={{ display: 'block', height: '7vh', width: '11vh', margin: 7 }} />
              {smBreakPoint &&
                AboutUsMenuItems.map(x => (
                  <Button href={x.routeName} color='inherit' style={{ marginLeft: 70 }}>
                    <Typography variant="subtitle1" color="inherit" align='center'>
                      {x.name}
                    </Typography>
                  </Button>))}
            </>
          }
        </Toolbar>
      </AppBar>

      <Drawer anchor="left" open={isLeftMenuOpen} onClose={toggleLeftMenu(false)}>
        {sideList()}
      </Drawer>
      {loggedInUser === null && redirectUrl()}
    </Root >
  );
}