import {
  SetAccountInfosAction,
  ClearAccountInfosAction,
} from "../types/Actions";
import { AccountInfo } from "../types/AccountInfo";

export const setAccountInfos = (
  accountInfos: AccountInfo[]
): SetAccountInfosAction => ({
  type: "SET_ACCOUNT_INFOS",
  accountInfos,
});

export const clearAccountInfos = (): ClearAccountInfosAction => ({
  type: "CLEAR_ACCOUNT_INFOS",
});
