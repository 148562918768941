import React from "react";
import { StyledContainer } from "../StyledComponents";
import AppBar from "../components/AppBar";
import { Typography } from "@material-ui/core";

export default function FaqPage() {

  return (
    <div>
      <AppBar title={"常見問題"} />
      <StyledContainer style={{ paddingTop: 90, paddingBottom: 80 }}>
        <Typography variant="h5" component="h2">
          常見問題
                </Typography>
      </StyledContainer>
      <AppBar title={"常見問題"} bottom={true} />
    </div>
  )
}