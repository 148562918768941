import chroma from "chroma-js";

export const getColors = (totalCount: number): string[] => {
  const raw = chroma.scale('Spectral').mode('lch').colors(totalCount);
  return raw.map(co => {
    const chrome = chroma(co);
    if (chrome.luminance() > 0.6)
      return chrome.darken(0.5).hex()
    else
      return co;
  });
}

export const getColorsDarker = (totalCount: number): string[] => {
  const raw = chroma.scale('Spectral').mode('lch').colors(totalCount);
  return raw.map(co => {
    const chrome = chroma(co);
    if (chrome.luminance() > 0.6)
      return chrome.darken(1).hex()
    else
      return co;
  });
}

export const getColorsWithSameSL = (totalCount: number, sat: number, lumin: number): string[] => {
  const raw = chroma.scale('Spectral').mode('lch').colors(totalCount);
  return raw.map(co => chroma(co).luminance(lumin).set('hsi.s', sat).hex());
}