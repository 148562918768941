import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from "@material-ui/core";
import * as admin from 'firebase-admin';
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { updatePassword } from "../api";
import { AppState } from "../reducers";
import { LoadableAccountData } from "../reducers/accounts";
import ButtonWithLoading from "./ButtonWithLoading";
import { SnackBarProp } from "./SimpleSnackBar";

type UserRecord = admin.auth.UserRecord;

interface ResetPasswordDialogProps {
  onClose: () => void,
  isOpen: boolean,
  uid: string,
  snackBar: SnackBarProp,
  setSnackbar: (props: SnackBarProp) => void,
}

export default function ResetPasswordDialog(props: ResetPasswordDialogProps) {

  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const [password, setPassword] = useState<string>();

  const [password2, setPassword2] = useState<string>();

  const accounts = useSelector<AppState, LoadableAccountData>(state => state.accounts);

  const [account, setAccount] = useState<UserRecord>();

  useEffect(() => {
    if (accounts.kind === "loaded") {
      setAccount(accounts.data.find(x => x.uid === props.uid));
    }
  }, [props.uid])

  const submitButtonClicked = async () => {
    setIsSubmitting(true);
    if (password === password2) {
      try {
        if (account !== undefined && password !== undefined) {
          const response = await updatePassword({
            uid: account.uid,
            password: password,
          })
          setIsSubmitting(false);
          props.onClose();
        }
      } catch (e) {
        console.log(e);
        setIsSubmitting(false);
        props.setSnackbar({
          ...props.snackBar,
          open: true,
          message: e.message,
          variant: "warning",
        });
      }
    } else {
      props.setSnackbar({
        ...props.snackBar,
        open: true,
        message: "新密碼確認與新密碼不相符！",
        variant: "warning",
      });
      setIsSubmitting(false);
    }
  }

  const handlePasswordChange = () => (event: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(event.target.value);
  }

  const handlePassword2Change = () => (event: React.ChangeEvent<HTMLInputElement>) => {
    setPassword2(event.target.value);
  }


  return (
    <Dialog onClose={props.onClose} aria-labelledby="simple-dialog-title" open={props.isOpen}>
      <DialogTitle id="simple-dialog-title">重設帳戶密碼</DialogTitle>
      <DialogContent>
        <TextField
          id="password"
          label="新密碼* (必須至少由6個字元組成)"
          type="password"
          onChange={handlePasswordChange()}
          margin="normal"
          fullWidth
        />
        <TextField
          id="password2"
          label="新密碼確認*"
          type="password"
          onChange={handlePassword2Change()}
          margin="normal"
          fullWidth
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={props.onClose} color="primary">取消</Button>
        <ButtonWithLoading onClick={submitButtonClicked} color="primary" isLoading={isSubmitting}>儲存</ButtonWithLoading>
      </DialogActions>
    </Dialog>
  )
}