import { UserRole } from "../types/UserRole";
import { UserRolesActionTypes } from "../types/Actions";

const userRolesReducerDefaultState: null = null;

const userRolesReducer = (
  state: UserRole[] | null = userRolesReducerDefaultState,
  action: UserRolesActionTypes
): UserRole[] | null => {
  switch (action.type) {
    case "SET_USER_ROLES":
      return action.userRoles;
    case "CLEAR_USER_ROLES":
      return null;
    default:
      return state;
  }
};
export default userRolesReducer;
