import { Button, Grid, InputAdornment, TextField } from "@material-ui/core";
import { EnhancedEncryption, Lock } from "@material-ui/icons";
import firebase from "firebase";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { linkFakeEmail } from "../api";
import AppBar from "../components/AppBar";
import SimpleSnackBar, { SnackBarProp, variantIcon } from "../components/SimpleSnackBar";
import { AppState } from "../reducers";
import { GridCard, StyledContainer } from "../StyledComponents";
import { FirebaseAuthError } from "../types/Error";

export const SettingsPage = () => {

  const loggedInUser = useSelector<AppState, firebase.User | null>(state => state.loggedInUser);

  const closeErrorSnackBar = () => {
    setErrorSnackBar({ ...errorSnackBar, "open": false });
  }

  const [errorSnackBar, setErrorSnackBar] = useState<SnackBarProp>({
    open: false,
    variant: "error",
    onClose: closeErrorSnackBar,
    message: "",
  });

  return (
    <div>
      <AppBar title={"設定"} />
      <SimpleSnackBar open={errorSnackBar.open} onClose={closeErrorSnackBar} message={errorSnackBar.message} variant="error" />
      {(loggedInUser) ?
        <StyledContainer style={{ paddingTop: 90, paddingBottom: 80 }}>
          <Grid container spacing={4}>
            <ChangePasswordPanel email={loggedInUser?.email} triggerError={(variant, message) => setErrorSnackBar({ ...errorSnackBar, variant: variant, open: true, message: message })} />
            <LinkFakeEmailPanel email={loggedInUser?.email} triggerError={(variant, message) => setErrorSnackBar({ ...errorSnackBar, variant: variant, open: true, message: message })} />
          </Grid>
        </StyledContainer>
        :
        <SimpleSnackBar open={true} onClose={closeErrorSnackBar} message={"You don't have permission to view this page."} variant="warning" autoClose={99999} />}
      <AppBar title={"設定"} bottom={true} />
    </div>
  )
}

const ChangePasswordPanel = (props: { email: string | null | undefined, triggerError: (variant: keyof typeof variantIcon, message: string) => void }) => {

  return (
    <Grid item md={12} sm={12} xs={12}>
      <GridCard title="更改密碼" desc="可以重設密碼。須先輸入原本的密碼作確認。">
        {
          (props.email)
            ?
            <ChangePasswordForm email={props.email} triggerError={props.triggerError} />
            :
            <div style={{ margin: 20 }}>您的賬號未使用密碼驗證方式。如果您是電話登記用戶，請先在「新增密碼登入方式」設定密碼。</div>
        }
      </GridCard>
    </Grid>
  );
}

const ChangePasswordForm = (props: { email: string | null | undefined, triggerError: (variant: keyof typeof variantIcon, message: string) => void }) => {

  type ChangePasswordFormInterface = {
    oldPassword: {
      value: string,
      isError: boolean,
      reason: string | undefined,
      validateFunc: (form: ChangePasswordFormInterface, value: string) => boolean
    },
    newPassword: {
      value: string,
      isError: boolean,
      reason: string | undefined,
      validateFunc: (form: ChangePasswordFormInterface, value: string) => boolean
    },

    confirmNewPassword: {
      value: string,
      isError: boolean,
      reason: string | undefined,
      validateFunc: (form: ChangePasswordFormInterface, value: string) => boolean
    },
  }

  type ChangePasswordFormField = "oldPassword" | "newPassword" | "confirmNewPassword";

  const checkOldPassword = (form: ChangePasswordFormInterface, value: string) => {

    if (!validatePassword(value)) {
      setForm(f => { return { ...f, oldPassword: { ...f.oldPassword, isError: true, reason: "密碼需要至少為6個字元" } } });
      return false;
    }
    else {
      setForm(f => { return { ...f, oldPassword: { ...f.oldPassword, isError: false, reason: undefined } } });
      return true;
    }
  }

  const checkNewPassword = (form: ChangePasswordFormInterface, value: string) => {

    if (!validatePassword(value)) {
      setForm(f => { return { ...f, newPassword: { ...f.newPassword, isError: true, reason: "密碼需要至少為6個字元" } } });
      return false;
    }
    else if (value === form.oldPassword.value) {
      setForm(f => { return { ...f, newPassword: { ...f.newPassword, isError: true, reason: "新密碼不可以與舊密碼相同" } } });
      return false;
    }
    else {
      setForm(f => { return { ...f, newPassword: { ...f.newPassword, isError: false, reason: undefined } } });
      return true;
    }
  }

  const checkConfirmNewPassword = (form: ChangePasswordFormInterface, value: string) => {

    if (!validatePassword(value)) {
      setForm(f => { return { ...f, confirmNewPassword: { ...f.confirmNewPassword, isError: true, reason: "密碼需要至少為6個字元" } } });
      return false;
    }
    else if (value !== form.newPassword.value) {
      setForm(f => { return { ...f, confirmNewPassword: { ...f.confirmNewPassword, isError: true, reason: "確認密碼不相符" } } });
      return false;
    }
    else {
      setForm(f => { return { ...f, confirmNewPassword: { ...f.confirmNewPassword, isError: false, reason: undefined } } });
      return true;
    }
  }

  const defaultChangePasswordForm = {
    oldPassword: {
      value: "",
      isError: false,
      reason: undefined,
      validateFunc: checkOldPassword
    },
    newPassword: {
      value: "",
      isError: false,
      reason: undefined,
      validateFunc: checkNewPassword
    },
    confirmNewPassword: {
      value: "",
      isError: false,
      reason: undefined,
      validateFunc: checkConfirmNewPassword
    }
  };

  const [form, setForm] = useState<ChangePasswordFormInterface>(defaultChangePasswordForm);

  const [isSubmiting, setIsSubmiting] = useState<boolean>(false);

  const onSubmitButtonClick = () => {
    const validateResult = Object.entries(form).map(([fieldId, fieldProps]) => {
      return fieldProps.validateFunc(form, fieldProps.value);
    });

    setIsSubmiting(true);

    if (validateResult.some(result => result === false)) {
      props.triggerError("error", "表單有輸入錯誤，請檢查紅色欄位。");
      setIsSubmiting(false);
      return;
    }

    if (firebase.auth().currentUser !== undefined && firebase.auth().currentUser !== null && props.email) {
      const reauthCredential: firebase.auth.AuthCredential = firebase.auth.EmailAuthProvider.credential(
        props.email,
        form.oldPassword.value
      );

      firebase.auth().currentUser!.reauthenticateWithCredential(reauthCredential)
        .then(async (credential) => {
          try {
            await firebase.auth().currentUser!.updatePassword(form.newPassword.value);
          }
          catch (e) {
            props.triggerError("error", "認證成功，但更新密碼時出現錯誤，請稍後再試一次。");
            return;
          }

          props.triggerError("success", "密碼更新成功，請重新登入。");
          setIsSubmiting(false);
          firebase.app().auth().signOut();
        })
        .catch((err) => {
          const firebaseAuthError = err as FirebaseAuthError;

          let message = "";
          switch (firebaseAuthError.code) {
            case "auth/wrong-password": message = "舊密碼輸入錯誤，請再試一次。"; break;
            case "auth/user-mismatch": message = "你所輸入的資料與此賬號不符。"; break;
            default: message = "未知錯誤"; break;
          }

          props.triggerError("error", message);
          setIsSubmiting(false);
          return;
        })
    }
    else {
      props.triggerError("error", "頁面資料損毀，請重新登入再試。");
      return;
    }
  }

  const onFormChanged = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const fieldName = e.target.name;
    const fieldValue = e.target.value;

    setForm(f => { return { ...f, [fieldName]: { ...f[fieldName as ChangePasswordFormField], value: fieldValue } } })
    form[fieldName as ChangePasswordFormField].validateFunc(form, fieldValue);
  }

  function validatePassword(pw: string) {
    const regex = /^.{6,}$/;
    return regex.test(pw);
  }

  return (
    <Grid container spacing={2} style={{ marginTop: 10, marginBottom: 20, marginLeft: 25, marginRight: 25 }}>
      <Grid item xs={12}>
        <TextField
          error={form.oldPassword.isError}
          helperText={form.oldPassword.isError && form.oldPassword.reason}
          id="oldPassword"
          name="oldPassword"
          label={"舊密碼"}
          defaultValue=""
          type="password"
          value={form.oldPassword.value}
          onChange={(e) => onFormChanged(e)}
          margin="normal"
          variant="outlined"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Lock />
              </InputAdornment>
            ),
          }}
        />
      </Grid>

      <Grid item xs={12}>
        <TextField
          error={form.newPassword.isError}
          helperText={form.newPassword.isError && form.newPassword.reason}
          id="newPassword"
          name="newPassword"
          label={"新密碼"}
          type="password"
          defaultValue=""
          value={form.newPassword.value}
          onChange={(e) => onFormChanged(e)}
          margin="normal"
          variant="outlined"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <EnhancedEncryption />
              </InputAdornment>
            ),
          }}
        />
      </Grid>

      <Grid item xs={12}>
        <TextField
          error={form.confirmNewPassword.isError}
          helperText={form.confirmNewPassword.isError && form.confirmNewPassword.reason}
          id="confirmNewPassword"
          name="confirmNewPassword"
          label={"確認新密碼"}
          type="password"
          defaultValue=""
          value={form.confirmNewPassword.value}
          onChange={(e) => onFormChanged(e)}
          margin="normal"
          variant="outlined"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <EnhancedEncryption />
              </InputAdornment>
            ),
          }}
        />
      </Grid>

      <Grid item xs={12}>
        <Button
          type="submit"
          variant="contained"
          color="primary"
          onClick={onSubmitButtonClick}
          disabled={isSubmiting}
          disableElevation
        >
          更改密碼
        </Button>
      </Grid>

    </Grid>
  )
}

const LinkFakeEmailPanel = (props: { email: string | null | undefined, triggerError: (variant: keyof typeof variantIcon, message: string) => void }) => {
  return (
    <Grid item md={12} sm={12} xs={12}>
      <GridCard title="電話登記用戶 - 新增密碼登入方式" desc="由於我們新增了新的登入方式，在2021年3月前用電話登記的用戶可以在這裡開通電話+密碼登入方式。">
        {
          (props.email)
            ?
            <div style={{ margin: 20 }}>您的賬號已經使用密碼驗證方式（可以是電郵或者電話）。此面板適用於2021年3月更新前的電話登記用戶新增登入方式。如果您需要更改密碼，請在「更改密碼」進行更改。</div>
            :
            <div style={{ margin: 20 }}>
              <LinkFakeEmailForm email={props.email} triggerError={props.triggerError} />
            </div>
        }
      </GridCard>
    </Grid>
  )
}

const LinkFakeEmailForm = (props: { email: string | null | undefined, triggerError: (variant: keyof typeof variantIcon, message: string) => void }) => {

  type AddPasswordFormField = "newPassword" | "confirmNewPassword"

  const checkNewPassword = (form: AddPasswordFormInterface, value: string) => {

    if (!validatePassword(value)) {
      setForm(f => { return { ...f, newPassword: { ...f.newPassword, isError: true, reason: "密碼需要至少為6個字元" } } });
      return false;
    }
    else {
      setForm(f => { return { ...f, newPassword: { ...f.newPassword, isError: false, reason: undefined } } });
      return true;
    }
  }

  const checkConfirmNewPassword = (form: AddPasswordFormInterface, value: string) => {

    if (!validatePassword(value)) {
      setForm(f => { return { ...f, confirmNewPassword: { ...f.confirmNewPassword, isError: true, reason: "密碼需要至少為6個字元" } } });
      return false;
    }
    else if (value !== form.newPassword.value) {
      setForm(f => { return { ...f, confirmNewPassword: { ...f.confirmNewPassword, isError: true, reason: "確認密碼不相符" } } });
      return false;
    }
    else {
      setForm(f => { return { ...f, confirmNewPassword: { ...f.confirmNewPassword, isError: false, reason: undefined } } });
      return true;
    }
  }

  const defaultAddPasswordForm = {
    newPassword: {
      value: "",
      isError: false,
      reason: undefined,
      validateFunc: checkNewPassword
    },
    confirmNewPassword: {
      value: "",
      isError: false,
      reason: undefined,
      validateFunc: checkConfirmNewPassword
    }
  };

  const [form, setForm] = useState<AddPasswordFormInterface>(defaultAddPasswordForm);

  const [isSubmiting, setIsSubmiting] = useState<boolean>(false);

  type AddPasswordFormInterface = {
    newPassword: {
      value: string,
      isError: boolean,
      reason: string | undefined,
      validateFunc: (form: AddPasswordFormInterface, value: string) => boolean
    },

    confirmNewPassword: {
      value: string,
      isError: boolean,
      reason: string | undefined,
      validateFunc: (form: AddPasswordFormInterface, value: string) => boolean
    },
  }

  const onSubmitButtonClick = () => {
    const validateResult = Object.entries(form).map(([fieldId, fieldProps]) => {
      return fieldProps.validateFunc(form, fieldProps.value);
    });

    setIsSubmiting(true);

    if (validateResult.some(result => result === false)) {
      props.triggerError("error", "表單有輸入錯誤，請檢查紅色欄位。");
      setIsSubmiting(false);
      return;
    }

    if (firebase.auth().currentUser !== undefined && firebase.auth().currentUser !== null && firebase.auth().currentUser?.phoneNumber) {

      const recaptchaVerifier = new firebase.auth.RecaptchaVerifier("add-password-button", { 'size': 'invisible' }, firebase.app());
      recaptchaVerifier.render();

      firebase.auth().currentUser!.reauthenticateWithPhoneNumber(firebase.auth().currentUser!.phoneNumber!, recaptchaVerifier)
        .then((confirmationResult) => {
          return confirmationResult.confirm(prompt('為了確認身份，我們已經傳送驗證碼到您的手機，請在收到後在此輸入驗證碼') ?? "");
        })
        .then((userCredential) => {
          const reauthCredential =
            props.triggerError("success", "驗證成功，系統將正在處理新增登入方式");

          try {
            linkFakeEmail(form.newPassword.value).then(() => {
              props.triggerError("success", "新增登入方式成功，請重新登入");
              setIsSubmiting(false);
              firebase.app().auth().signOut();
            })
          }
          catch (e) {
            props.triggerError("error", "認證成功，但更新密碼時出現錯誤，請稍後再試一次。");
            setIsSubmiting(false);
            return;
          }
        })
        .catch((error) => {
          props.triggerError("error", "驗證失敗，請再試一次。並檢查已輸入的驗證碼正確無誤");
          setIsSubmiting(false);
          return;
        });
    }
    else {
      props.triggerError("error", "頁面資料損毀，請重新登入再試。");
      return;
    }
  }

  const onFormChanged = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const fieldName = e.target.name;
    const fieldValue = e.target.value;

    setForm(f => { return { ...f, [fieldName]: { ...f[fieldName as AddPasswordFormField], value: fieldValue } } })
    form[fieldName as AddPasswordFormField].validateFunc(form, fieldValue);
  }

  function validatePassword(pw: string) {
    const regex = /^.{6,}$/;
    return regex.test(pw);
  }

  return (
    <Grid container spacing={2} style={{ marginTop: 10, marginBottom: 20, marginLeft: 25, marginRight: 25 }}>
      <Grid item xs={12}>
        <TextField
          error={form.newPassword.isError}
          helperText={form.newPassword.isError && form.newPassword.reason}
          id="newPassword"
          name="newPassword"
          label={"新密碼"}
          type="password"
          defaultValue=""
          value={form.newPassword.value}
          onChange={(e) => onFormChanged(e)}
          margin="normal"
          variant="outlined"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <EnhancedEncryption />
              </InputAdornment>
            ),
          }}
        />
      </Grid>

      <Grid item xs={12}>
        <TextField
          error={form.confirmNewPassword.isError}
          helperText={form.confirmNewPassword.isError && form.confirmNewPassword.reason}
          id="confirmNewPassword"
          name="confirmNewPassword"
          label={"確認新密碼"}
          type="password"
          defaultValue=""
          value={form.confirmNewPassword.value}
          onChange={(e) => onFormChanged(e)}
          margin="normal"
          variant="outlined"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <EnhancedEncryption />
              </InputAdornment>
            ),
          }}
        />
      </Grid>

      <Grid item xs={12}>
        <Button
          type="submit"
          variant="contained"
          color="primary"
          onClick={onSubmitButtonClick}
          disabled={isSubmiting}
          disableElevation
          id="add-password-button"
        >
          更改密碼
        </Button>
      </Grid>

    </Grid>
  )
}