import { Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, FormGroup } from '@material-ui/core';
import moment from 'moment';
import React, { useState } from 'react';
import { useSelector } from "react-redux";
import { updateUserValidity } from '../api';
import { AppState } from '../reducers';
import { LoadableAccountData } from '../reducers/accounts';
import ButtonWithLoading from './ButtonWithLoading';

interface AutoUpdateValidityDialogProps {
  onClose: () => void,
  isOpen: boolean,
}

export const AutoUpdateValidityDialog = (props: AutoUpdateValidityDialogProps) => {

  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const [selectedUsers, setSelectedUsers] = useState<{ [id: string]: boolean }>({ "": false });

  const accounts = useSelector<AppState, LoadableAccountData>(state => state.accounts);

  const handleSelectedUsersChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (selectedUsers !== undefined) {
      setSelectedUsers({ ...selectedUsers, [event.target.name]: event.target.checked })
    }
  }

  const submitButtonClicked = async () => {
    setIsSubmitting(true);
    try {
      if (selectedUsers !== undefined) {
        const _selectedUsers = Object.keys(selectedUsers).map(key => {
          if (selectedUsers[key] === true) {
            return key;
          }
        });
        _selectedUsers.forEach(async x => {
          if (x !== undefined && x !== "") {
            await updateUserValidity({
              uid: x,
              valid_till: moment().add(7, "d").hour(23).minute(59).valueOf(),
              is_valid: true,
            });
          }
        })
      }
      setIsSubmitting(false);
      props.onClose();
    } catch (e) {
      setIsSubmitting(false);
      console.log(e);
    }
  }

  return (
    <Dialog onClose={props.onClose} aria-labelledby="simple-dialog-title" open={props.isOpen} fullWidth>
      <DialogTitle id="simple-dialog-title">{"選擇帳戶以延長有效期7天"}</DialogTitle>
      <DialogContent>
        <FormGroup>
          {selectedUsers !== undefined && accounts.kind === "loaded" && accounts.data.map(x => {
            if (x.displayName !== "") {
              return (
                <FormControlLabel
                  control={<Checkbox checked={selectedUsers[x.uid]} onChange={handleSelectedUsersChange} name={x.uid} color="primary" />}
                  label={x.displayName}
                />
              )
            }
          })}
        </FormGroup>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.onClose} color="primary">取消</Button>
        <ButtonWithLoading onClick={submitButtonClicked} color="primary" isLoading={isSubmitting}>儲存</ButtonWithLoading>
      </DialogActions>
    </Dialog>
  )
}