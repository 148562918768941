import { createStyles, Divider, Grid, makeStyles, MenuItem, TextField, Theme } from '@material-ui/core';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import { DirectionsRun, EmojiSymbols, Help, LocationSearching, SelectAll, TouchApp, Translate, Visibility } from '@material-ui/icons';
import _ from 'lodash';
import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from 'react-redux';
import styled from "styled-components";
import { computeProjectedScore } from '../pages/NewReportPage';
import { AppState } from '../reducers';
import { GridCard } from '../StyledComponents';
import { AllMissionDataType } from '../types/MissionData';
import { domains, DomainType, getChineseDomain, MissionMetadata, MissionType } from "../types/MissionType";
import DomainTaskTable from './DomainTaskTable';
import { Month, months, translationMonth } from "./PlaytimeTab";
import TrendScatterChart, { DomainDataType, DomainMissionDataType, DomainMissionRecordDataType } from "./TrendScatterChart";

const StyledVerticalTabContainer = styled.div`
  // flex-grow: 1;
  // display: flex;
`
const StyledTabPanel = styled.div`
  width: 100%;
`

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      justifyContent: "center"
    },
    scroller: {
      flexGrow: 0
    }
  }),
);

interface DomainScatterChartTabsProps {
  data: Array<AllMissionDataType>;
}

function a11yProps(index: any) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index } = props;

  return (
    <StyledTabPanel
      hidden={value !== index}
    >
      {children}
    </StyledTabPanel>
  );
}

export function _setData(domainMissions: { [key: string]: string[] }, tempData: AllMissionDataType[] | undefined, missionMetadata?: MissionMetadata | null) {
  let tempHistoryData: { mission: MissionType, record: { time: number, score: number }[] }[] = [{ mission: "", record: [] }];
  let tempMissionArr: { mission: MissionType, score: number, totalScore: number, userHighestLevel: number, missionHighestLevel: number, accTime: number, lastPlayed: number | undefined }[] = [{
    mission: "",
    score: 0,
    totalScore: 0,
    userHighestLevel: 0,
    missionHighestLevel: 0,
    accTime: 0,
    lastPlayed: undefined
  }];
  if (tempData !== undefined) {
    domains.forEach(domain => {
      const domainMission = domainMissions[domain] ?? [];
      domainMission.forEach(mission => {
        const tempMissionScores = tempData.filter(x => x.key === mission);
        let result: { time: number, score: number } = { time: 0, score: 0 };
        let tempResult: { time: number, score: number }[] = [{ time: 0, score: 0 }];
        let tempMissionScore:
          {
            mission: string, score: number, totalScore: number, userHighestLevel: number, missionHighestLevel: number, accTime: number, lastPlayed: number | undefined
          } = { mission: "", score: 0, totalScore: 0, userHighestLevel: 0, missionHighestLevel: 0, accTime: 0, lastPlayed: undefined };

        if (tempMissionScores.length !== 0) {
          tempResult = tempMissionScores.map(element => {
            if (element.total_score !== 0) {
              result = {
                score: Math.round(element.score / element.total_score * 100 * 100) / 100,
                time: element.task_start_time
              }
              if (result.score > 100) {
                result.score = 100;
              } else if (result.score < 0) {
                result.score = 0;
              }
            } else
              result = {
                score: 0,
                time: element.task_start_time
              }
            return result;
          })
          tempHistoryData.push({ mission: mission, record: tempResult });

          tempMissionScore = {
            mission: mission,
            score: Math.round(_(tempMissionScores).meanBy(mission => mission.score) * 100) / 100,
            userHighestLevel: _(tempMissionScores).map(mission => (mission.normalized_level)).max() ?? 0,
            missionHighestLevel: missionMetadata && missionMetadata[mission] ? missionMetadata[mission]?.level : 10,
            totalScore: 10,
            accTime: _(tempMissionScores).map(mission => (mission.task_end_time - mission.task_start_time) > 0 ? mission.task_end_time - mission.task_start_time : 0).sum() ?? 0,
            lastPlayed: _(tempMissionScores).map(mission => mission.task_start_time ?? 0).max(),
          };
          tempMissionArr.push(tempMissionScore);
        }
      })
    })
  }
  tempHistoryData = tempHistoryData.filter(x => x.mission !== "");
  tempMissionArr = tempMissionArr.filter(x => x.mission !== "");
  return { tempHistoryData: tempHistoryData, tempMissionArr: tempMissionArr };
}

export default function DomainScatterChartTabs(props: DomainScatterChartTabsProps) {

  const [selectedTab, setSelectedTab] = useState<number>(0);
  const [historyData, setHistoryData] = useState<{ mission: MissionType, record: { time: number, score: number }[] }[]>();
  const [missionArr, setMissionArr] = useState<{ mission: string, score: number, totalScore: number, userHighestLevel: number, missionHighestLevel: number, accTime: number, lastPlayed: number | undefined }[]>();
  const [tabData, setTabData] = useState<
    {
      domain: DomainType,
      missionHistory: {
        mission: MissionType, record: { time: number, score: number }[]
      }[] | null,
      missionScore: { mission: string, score: number, totalScore: number, userHighestLevel: number, missionHighestLevel: number, accTime: number, lastPlayed: number | undefined }[] | null
    }[]>();
  const [selectedMissions, setSelectedMissions] = useState<MissionType[]>([]);
  const [selectedDomain, setSelectedDomain] = useState<DomainType[]>([]);
  const [allTabData, setAllTabData] = useState<DomainDataType[]>();

  const [timeUnit, setTimeUnit] = useState<{ year: number, month: number }>({ year: parseInt(moment().format("YYYY")), month: parseInt(moment().format("MM")) });

  const missionMetadata = useSelector<AppState, { metadata: MissionMetadata | null; domainMission: { [key: string]: MissionType[] } }>(state => state.missionMetadata);

  const onTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setSelectedTab(newValue);
    if (newValue == 0)
      setSelectedDomain([]);
    else
      setSelectedDomain([domains[newValue - 1]]);
  };

  useEffect(() => {
    const tempData = _setData(missionMetadata.domainMission, props.data, missionMetadata.metadata);
    if (tempData.tempHistoryData.length !== 0) {
      setHistoryData(tempData.tempHistoryData);
    }
    if (tempData.tempMissionArr.length !== 0) {
      setMissionArr(tempData.tempMissionArr);
    }
    _setAllTabData();
  }, [props.data])

  useEffect(() => {
    setSelectedMissions([]);
  }, [selectedTab])

  useEffect(() => {
    setSelectedDomain([]);
    setTimeUnit({ year: parseInt(moment().format("YYYY")), month: parseInt(moment().format("MM")) });
  }, [allTabData])

  const _setAllTabData = useCallback(() => _setAllTabDataRaw(props.data), [props.data]);

  function _setAllTabDataRaw(allMissionData: AllMissionDataType[] | undefined) {
    // let tempHistoryData: { mission: DomainType, record: { time: number, score: number }[] }[] = [{ mission: "", record: [] }];
    // if (tempData !== undefined) {
    //   let tempAllDates = tempData.map(x => {
    //     return { day: moment.utc(x.task_start_time).dayOfYear(), year: moment.utc(x.task_start_time).year() }
    //   });
    //   tempAllDates.sort(function (a, b) {
    //     return a.day - b.day;
    //   });
    //   tempAllDates = tempAllDates.filter((item, index, self) =>
    //     index === self.findIndex((t) => (
    //       t.day === item.day && t.year === item.year
    //     ))
    //   )
    //   domains.map(domain => {
    //     const filtered = tempData.filter(mission => getDomainMission(domain).includes(mission.key));
    //     let tempDateHistory: { time: number, score: number }[] = [{ time: 0, score: 0 }];
    //     if (filtered.length !== 0) {
    //       tempAllDates.forEach(date => {
    //         let domainHistoryScore = 0;
    //         let domainHistoryTotalScore = 0;
    //         filtered.forEach(filteredData => {
    //           if (moment.utc(filteredData.task_start_time).dayOfYear() === date.day && moment.utc(filteredData.task_start_time).year() === date.year) {
    //             domainHistoryScore += filteredData.score;
    //             domainHistoryTotalScore += filteredData.total_score;
    //           }
    //         })
    //         if (domainHistoryTotalScore === 0) {
    //           tempDateHistory.push({ time: moment().year(date.year).dayOfYear(date.day).valueOf(), score: 0 });
    //         } else {
    //           tempDateHistory.push({ time: moment().year(date.year).dayOfYear(date.day).valueOf(), score: Math.round(domainHistoryScore / domainHistoryTotalScore * 100 * 100) / 100 });
    //         }
    //         tempDateHistory = tempDateHistory.filter(x => x.time !== 0);
    //       })
    //       tempHistoryData.push({ mission: domain, record: tempDateHistory });
    //       tempHistoryData = tempHistoryData.filter(x => x.mission !== "")
    //     }
    //   })
    // }

    // Check if input data is undefined / empty
    if (!allMissionData || allMissionData === []) return;
    let allTabDataBuffer: DomainDataType[] = [];

    // Filter missions with wrong timestamp: i.e.: 2020 Jan 1
    allMissionData = allMissionData.filter(entry => entry.task_start_time > 1577808000 && entry.task_end_time > 1577808000);
    // Order missions by date
    allMissionData = _(allMissionData).orderBy(entry => entry.task_start_time).value();

    // Find out all distinct key
    const playedMissionKey: string[] = [...new Set(allMissionData.map(m => m.key))];

    // Setup domains
    allTabDataBuffer = domains.reduce<DomainDataType[]>((domainArray, domain) => {
      console.log(domain);
      const domainMissions: string[] = missionMetadata.domainMission[domain];

      // Setup missions
      const domainMissionChartData = domainMissions.reduce<DomainMissionDataType[]>((missionArray, mission) => {
        // Setup mission records
        const missionRecords = allMissionData!.filter(missionRecordData => missionRecordData.key === mission);
        const missionRecordPresenceDay: string[] = [...new Set(missionRecords.map(m => moment(m.task_start_time).format("YYYY-MM-DD")))];

        // Insert every records of that day into the detail array
        const missionRecordChartData = missionRecords.reduce<Map<string, DomainMissionRecordDataType>>((missionRecordArray, missionRecord) => {
          const momentTime: string = moment(missionRecord.task_start_time).format("YYYY-MM-DD");

          // Insert new dates as map key
          if (!missionRecordArray.has(momentTime)) {
            missionRecordArray.set(momentTime, { "score": 0, "sd": 0, "time": moment(momentTime).valueOf(), "detail": [] });
          }

          const missionHighestLevel = missionMetadata.metadata && missionMetadata.metadata[mission] ? missionMetadata.metadata[mission]?.level : 10
          const projectedScore = computeProjectedScore(missionRecord.score, missionRecord.normalized_level, missionHighestLevel);

          missionRecordArray.get(momentTime)!.detail!.push({ "mission": mission, "score": projectedScore, "time": missionRecord.task_start_time, "level": missionRecord.normalized_level });

          return missionRecordArray;
        }, new Map());

        // Calculate the average day by day
        missionRecordChartData.forEach((dateRecord, dateKey) => {
          dateRecord.score = Math.round(_(dateRecord.detail).meanBy(d => d.score));
          dateRecord.sd = Math.sqrt(_(dateRecord.detail!.map(detail => Math.pow(detail.score - dateRecord.score, 2))).sum().valueOf() / dateRecord.detail!.length - 1);
        });

        missionArray.push({ "mission": mission, "record": missionRecordChartData, "playedDate": missionRecordPresenceDay });
        return missionArray;
      }, []);

      domainArray.push({ "domain": domain, "missions": domainMissionChartData });
      return domainArray;
    }, []);

    console.log(allTabDataBuffer);
    setAllTabData(allTabDataBuffer);
  }

  function _setTabData(history: { mission: MissionType, record: { time: number, score: number }[] }[], missionData: { mission: string, score: number, totalScore: number, userHighestLevel: number, missionHighestLevel: number, accTime: number, lastPlayed: number | undefined }[]) {
    const tempTabDatas = domains.map(domain => {
      let tempMissionData: {
        mission: string, score: number, totalScore: number, userHighestLevel: number, missionHighestLevel: number, accTime: number, lastPlayed: number | undefined
      }[] = [];
      let tempHistoryData: {
        mission: MissionType, record: { time: number, score: number }[]
      }[] = [];
      let tempTabData: {
        domain: DomainType,
        missionHistory: {
          mission: MissionType, record: { time: number, score: number }[]
        }[] | null,
        missionScore: { mission: string, score: number, totalScore: number, userHighestLevel: number, missionHighestLevel: number, accTime: number, lastPlayed: number | undefined }[] | null
      } = {
        domain: "",
        missionHistory: null,
        missionScore: null
      };
      if (history !== undefined && missionData !== undefined) {
        const domainMission = missionMetadata.domainMission[domain];
        domainMission.forEach(mission => {
          const temp = missionData.find(x => x.mission === mission);
          if (temp !== undefined) {
            tempMissionData.push(temp);
          }
          const temp2 = history.find(x => x.mission === mission);
          if (temp2 !== undefined) {
            tempHistoryData.push(temp2);
          }
        })
        if (tempHistoryData.length !== 0 && tempMissionData.length !== 0) {
          tempTabData = {
            domain: domain,
            missionHistory: tempHistoryData,
            missionScore: tempMissionData
          }
        } else {
          tempTabData = {
            domain: domain,
            missionHistory: null,
            missionScore: null
          }
        }
      }
      return tempTabData;
    })
    setTabData(tempTabDatas);
  }

  // function missionOnClick(mission: string[]) {
  //   setSelectedMissions(mission);
  // }

  // useEffect(() => {
  //   if (selectedMissions !== undefined) {
  //     selectedMissions.forEach(element => {
  //       console.log(props.data.filter(x => x.key === element));
  //     });
  //   }
  // }, [selectedMissions])

  useEffect(() => {
    if (historyData !== undefined && missionArr !== undefined) {
      _setTabData(historyData, missionArr);
    }
  }, [missionArr, historyData])

  const handleTabChange = (e: any) => {
    setSelectedTab(domains.indexOf(e) + 1);
  }

  const getDomainIconComponent = (domain: string) => {
    switch (domain) {
      case "Attention":
        return <LocationSearching style={{ color: "#cc534b" }} />
      case "Executive":
        return <DirectionsRun style={{ color: "#6e579d" }} />
      case "Memory":
        return <EmojiSymbols style={{ color: "#3b703f" }} />
      case "Motor":
        return <TouchApp style={{ color: "#4aa5d2" }} />
      case "Perception":
        return <Visibility style={{ color: "#eab245" }} />
      case "Language":
        return <Translate style={{ color: "#e2813b" }} />
      default:
        return <Help style={{ color: "#444444" }} />
    }
  }

  const getDomainColor = (domain: string) => {
    switch (domain) {
      case "Attention":
        return "#cc534b"
      case "Executive":
        return "#6e579d"
      case "Memory":
        return "#3b703f";
      case "Motor":
        return "#4aa5d2"
      case "Perception":
        return "#eab245"
      case "Language":
        return "#e2813b"
      default:
        return "#444444"
    }
  }

  const classes = useStyles();

  return (
    <>
      <Grid item sm={12} xs={12}>
        <GridCard title="各領域表現趨勢" desc="以折線圖顯示用戶在每個領域/小遊戲中分數的時間趨勢">
          <Tabs
            classes={{ root: classes.root, scroller: classes.scroller }}
            value={selectedTab}
            onChange={onTabChange}
            variant="scrollable"
            scrollButtons="auto"
            style={{ justifyContent: "center" }}
            TabIndicatorProps={{ style: { background: getDomainColor(selectedDomain.length ? selectedDomain[0] : "") } }}
          >
            < Tab label="全部" {...a11yProps(0)} style={{ flexGrow: 0 }} icon={<SelectAll />} />
            {(tabData !== undefined) ? domains.map((domain, key) => (
              (tabData.filter(x => x.domain === domain)[0].missionHistory !== null) ?
                < Tab label={getChineseDomain(domain)} {...a11yProps(key + 1)} style={{ flexGrow: 0, color: getDomainColor(domain) }} icon={getDomainIconComponent(domain)} />
                : <Tab label={getChineseDomain(domain)} {...a11yProps(key + 1)} disabled style={{ flexGrow: 0, color: getDomainColor(domain) }} icon={getDomainIconComponent(domain)} />
            )) : null}
          </Tabs>
          <Divider />

          <Grid container>
            <Grid item>
              <TextField
                id="year"
                select
                label="年份"
                value={timeUnit?.year}
                onChange={(e) => setTimeUnit({ "year": parseInt(e.target.value), "month": timeUnit!.month })}
                margin="normal"
                style={{ width: 200, justifySelf: "flex-start", display: 'flex', margin: 40 }}
              >
                <MenuItem key={"2020"} value={"2020"}>2020年</MenuItem>
                <MenuItem key={"2021"} value={"2021"}>2021年</MenuItem>
                <MenuItem key={"2022"} value={"2022"}>2022年</MenuItem>
              </TextField>
            </Grid>
            <Grid item >
              <TextField
                id="month"
                select
                label="月份"
                value={timeUnit?.month}
                onChange={(e) => setTimeUnit({ "year": timeUnit!.year, "month": parseInt(e.target.value) as Month })}
                margin="normal"
                style={{ width: 200, justifySelf: "flex-start", display: 'flex', margin: 40 }}
              >
                <MenuItem key={-1} value={-1}>全年</MenuItem>
                {months.map((option) => (
                  <MenuItem key={option} value={option}>
                    {translationMonth[option]}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
          </Grid>

          <TrendScatterChart allDomainData={allTabData} selectedMission={selectedMissions} selectedDomain={selectedDomain} selectedYear={timeUnit!.year} selectedMonth={timeUnit!.month === -1 ? undefined : timeUnit!.month} />
        </GridCard>
      </Grid>

      <Grid item sm={12} xs={12}>
        <GridCard title="任務詳細表格" desc="以表格顯示用戶每次遊玩小遊戲的詳細數據">
          <DomainTaskTable data={tabData !== undefined && selectedTab > 0 && tabData[selectedTab - 1] !== undefined ? tabData[selectedTab - 1].missionScore : null} historyData={props.data} selectedTab={selectedTab} missionOnClick={() => { }} />
        </GridCard>
      </Grid>
    </>
  )
}

//average={allTabData.filter(x => x.mission === domain)}