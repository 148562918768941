import { Button, Card, CardContent, CardMedia, Checkbox, createStyles, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, FormGroup, Grid, makeStyles, Slider, Tab, Tabs, Theme, Typography } from '@material-ui/core';
import { SelectAll } from '@material-ui/icons';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { getProfileMissions, getUserDemoMissions, getUserHighestLevel, updateProfileMissions, updateUserDemoMissions, updateUserMissionHighestLevel } from '../api';
import { AppState } from '../reducers';
import { domains, getChineseDomain, getChineseMission, getDomainColor, getDomainIconComponent, MissionMetadata, missions, MissionType, themes, ThemeType } from '../types/MissionType';
import { UserDemoMissions } from '../types/UserDemoMissions';
import ButtonWithLoading from './ButtonWithLoading';
import { ScheduleMissionPage } from './ScheduleMissionPage';

interface EditDemoMissionDialogProps {
  onClose: () => void,
  isOpen: boolean,
  uid: string,
  isProfilePage?: boolean,
  missionMetaData?: MissionMetadata | null
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      justifyContent: "center"
    },
    scroller: {
      flexGrow: 0
    },
    dialogPaper: {
      minHeight: "calc(100% - 64px)",
    },
  }),
);

export const EditDemoMissionDialog = (props: EditDemoMissionDialogProps) => {

  const classes = useStyles();
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const [userDemoMissions, setUserDemoMissions] = useState<UserDemoMissions>();

  const [userDemoDomains, setUserDemoDomains] = useState<{ [key: string]: boolean }>();

  const [tabNumber, setTabNumber] = useState<number>(0);

  const [selectedDomainTab, setSelectedDomainTab] = useState<number>(0);
  const [selectedDomain, setSelectedDomain] = useState<string>("overall");

  const [userHighestLevel, setUserHighestLevel] = useState<{ [key: string]: number }>();
  const [modifiedUserMissionHighestLevel, setModifiedUserMissionHighestLevel] = useState<{ [key: string]: number }>({});

  const missionMetadata = useSelector<AppState, { metadata: MissionMetadata | null; domainMission: { [key: string]: MissionType[] } }>(state => state.missionMetadata);

  useEffect(() => {
    _getUserDemoMission();
    _getUserHighestLevel();
  }, [])

  useEffect(() => {
    if (userDemoMissions !== undefined) {
      let tempDomain: { [key: string]: boolean } | null = null;
      domains.forEach(x => {
        if (missionMetadata.domainMission[x].every(y => userDemoMissions?.[y] && Object.values(userDemoMissions?.[y])?.every(val => val === true) === true)) {
          tempDomain = { ...tempDomain, [x]: true };
        } else {
          tempDomain = { ...tempDomain, [x]: false };
        }
      })
      if (tempDomain !== null) {
        setUserDemoDomains(tempDomain);
      }
    }
  }, [userDemoMissions])

  // useEffect(() => {
  //   if (userDemoDomains !== undefined) {
  //     domains.map(x => {
  //       let tempMission = userDemoMissions;
  //       if (userDemoDomains[x] && tempMission !== undefined) {
  //         getDomainMission(x).forEach(x => {
  //           tempMission = { ...tempMission, [x]: true };
  //         })
  //         setUserDemoMissions(tempMission);
  //       }
  //     })
  //   }
  // }, [userDemoDomains])

  const _getUserDemoMission = async () => {
    let temp: UserDemoMissions | null = null;
    if (props.isProfilePage) {
      temp = await getProfileMissions(props.uid);
    } else {
      temp = await getUserDemoMissions(props.uid);
    }
    if (temp === null) {
      let data: UserDemoMissions = {};
      missions.forEach(x => {
        data = { ...data, [x]: {} };
      })
      setUserDemoMissions(data);
    } else {
      setUserDemoMissions(temp);
    }
  }

  const _getUserHighestLevel = async () => {
    const result = await getUserHighestLevel(props.uid);

    if (!result) return;

    const mapping = Object.entries(result).reduce<{ [key: string]: number }>((buffer, [key, value]) => {
      if (value)
        buffer[key] = (value as any).level;
      return buffer;
    }, {});

    setUserHighestLevel(mapping);
  }

  const submitButtonClicked = async () => {
    setIsSubmitting(true);
    try {
      if (userDemoMissions !== undefined) {
        if (props.isProfilePage) {
          const result = await updateProfileMissions(props.uid, userDemoMissions);

          try {
            const userMissionHighestLevelResult = await updateUserMissionHighestLevel(props.uid, modifiedUserMissionHighestLevel);
            Object.entries(modifiedUserMissionHighestLevel).forEach(([key, value]) => {
              setUserHighestLevel(userHighestLevel => ({ ...userHighestLevel, [key]: value }));
            });
          }
          catch { }
        } else {
          const result = await updateUserDemoMissions(props.uid, userDemoMissions);
        }
        setIsSubmitting(false);
        props.onClose();
      }
    } catch (e) {
      setIsSubmitting(false);
      console.log(e);
    }
  }

  const handleDemoMissionChange = (event: React.ChangeEvent<HTMLInputElement>, theme: string) => {
    setUserDemoMissions({ ...userDemoMissions, [event.target.name]: { ...userDemoMissions?.[event.target.name], [theme]: event.target.checked } })
  }

  const handleDemoDomainChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUserDemoDomains({ ...userDemoDomains, [event.target.name]: event.target.checked });
    let tempMission = userDemoMissions;
    if (event.target.checked) {
      missionMetadata.domainMission[event.target.name].forEach(x => {
        if (missionMetadata?.metadata?.[event.target.name])
          Object.keys(missionMetadata!.metadata![event.target.name].theme).forEach(th => {
            tempMission = { ...tempMission, [x]: { ...tempMission![x], [th]: true } };
          });
      })
      setUserDemoMissions(tempMission);
    } else {
      if (missionMetadata?.metadata?.[event.target.name])
        missionMetadata.domainMission[event.target.name].forEach(x => {
          Object.keys(missionMetadata!.metadata![event.target.name].theme).forEach(th => {
            tempMission = { ...tempMission, [x]: { ...tempMission![x], [th]: false } };
          });
        })
      setUserDemoMissions(tempMission);
    }
  }

  const onChangeLevelSlider = (key: string, value: number) => {
    if (modifiedUserMissionHighestLevel && modifiedUserMissionHighestLevel[key] !== value)
      setModifiedUserMissionHighestLevel(modifiedUserMissionHighestLevel => ({ ...modifiedUserMissionHighestLevel, [key]: value }));
  }

  const onDomainChanged = (value: number) => {
    setSelectedDomainTab(value);
    if (value === 0)
      setSelectedDomain("overall");
    else
      setSelectedDomain(domains[value - 1]);
  }

  const filterMissionEntries = (): JSX.Element[] | undefined => {
    if (!userHighestLevel) return undefined;

    const domainMissions = selectedDomain !== "overall" ? missionMetadata.domainMission[selectedDomain] : undefined;

    return Object.entries(userHighestLevel).filter(([key, value]) => selectedDomain !== "overall" ? domainMissions?.includes(key) : true).map(([key, value]) => {
      return (<MissionLevelEditCard
        missionKey={key}
        initLevel={value}
        maxLevel={props.missionMetaData === null || props.missionMetaData === undefined ? undefined : props.missionMetaData[key] ? props.missionMetaData[key].level : undefined}
        onChangeLevelSlider={onChangeLevelSlider}
      />)
    })
  }

  const getChineseMissionName = (key: string, theme: ThemeType): string => {
    let name: string = key;


    try {
      name = missionMetadata!.metadata![key]!.theme[theme];
    }
    catch (e) {
      try {
        name = missionMetadata!.metadata![key]!.theme["Mahjong"];
      }
      catch (ex) {
        name = key;
      }
    }

    return name;
  }

  return (
    <Dialog maxWidth={"lg"} onClose={props.onClose} aria-labelledby="simple-dialog-title" open={props.isOpen} fullWidth classes={{ paper: classes.dialogPaper }}>
      <DialogTitle id="simple-dialog-title">
        {props.isProfilePage ? "更改玩家任務內容" : "更改帳戶試玩內容"}
        <Tabs
          value={tabNumber}
          onChange={(e, value) => setTabNumber(value)}
          indicatorColor="primary"
          textColor="primary"
        >
          <Tab label="治療師預設任務" />
          <Tab label="解鎖/降低任務等級" />
          <Tab label="每日任務內容" />
        </Tabs>
        {
          tabNumber === 1 &&
          <Tabs
            classes={{ root: classes.root, scroller: classes.scroller }}
            defaultValue={0}
            value={selectedDomainTab}
            onChange={(e, v) => onDomainChanged(v)}
            variant="scrollable"
            scrollButtons="auto"
            style={{ justifyContent: "center", marginTop: 10 }}
            TabIndicatorProps={{ style: { background: getDomainColor(selectedDomain ?? "") } }}
          >
            < Tab label="全部" style={{ minWidth: "120px", flexGrow: 0 }} icon={<SelectAll />} />
            {domains.map((domain, key) => (
              < Tab label={getChineseDomain(domain)} style={{ minWidth: "120px", flexGrow: 0, color: getDomainColor(domain) }} icon={getDomainIconComponent(domain)} />
            ))}
          </Tabs>
        }
      </DialogTitle>
      <DialogContent>
        {tabNumber === 0 ?
          <>
            {!props.isProfilePage ?
              <FormGroup>
                {userDemoMissions !== undefined && missions.map(y => (
                  <div style={{ display: "flex" }}>
                    {
                      themes.map((theme) => (
                        missionMetadata?.metadata?.[y]?.theme[theme] &&
                        <FormControlLabel
                          control={<Checkbox color="primary" checked={userDemoMissions[y][theme]} onChange={(e) => handleDemoMissionChange(e, theme)} name={y} />}
                          label={getChineseMissionName(y, theme)}
                        />
                      ))
                    }
                  </div>
                ))}
              </FormGroup> :
              <FormGroup>
                {userDemoDomains !== undefined && domains.map(x => (
                  <>
                    <Typography variant="h6" style={{ marginTop: 20 }}>{getChineseDomain(x)}</Typography>
                    {userDemoMissions !== undefined && missionMetadata.domainMission[x].map(y => (
                      <div style={{ display: "flex" }}>
                        {
                          themes.map((theme) => (
                            missionMetadata?.metadata?.[y].theme[theme] &&
                            <FormControlLabel
                              control={<Checkbox color="primary" checked={userDemoMissions[y] ? userDemoMissions[y][theme] : false} onChange={(e, v) => handleDemoMissionChange(e, theme)} name={y} />}
                              label={getChineseMissionName(y, theme)}
                              style={{ paddingLeft: 40 }}
                            />
                          ))
                        }
                      </div>
                    ))}
                  </>
                ))}
              </FormGroup>
            }
          </>
          : tabNumber === 1 ?
            <Grid container spacing={2} style={{ display: "flex", alignItems: "center" }}>
              {
                filterMissionEntries()
              }
            </Grid>
            : tabNumber === 2 ?
              <ScheduleMissionPage targetUserId={props.uid} />
              : <></>
        }
      </DialogContent>
      <DialogActions>
        <Button onClick={props.onClose} color="primary">取消</Button>
        {
          tabNumber !== 2 &&
          <ButtonWithLoading onClick={submitButtonClicked} color="primary" isLoading={isSubmitting}>儲存</ButtonWithLoading>
        }
      </DialogActions>
    </Dialog>
  )
}

const MissionLevelEditCard = (props: { missionKey: string, initLevel: number, maxLevel: number | undefined, onChangeLevelSlider: (key: string, value: number) => void }) => {

  const [imageIcon, setImageIcon] = useState<any>();

  useEffect(() => {
    try {
      setImageIcon(require(`../resources/minigame/${props.missionKey}.jpg`));
    }
    catch {
      setImageIcon(require(`../resources/minigame/TrainMode.jpg`));
    }
  }, [])

  return (
    <Grid item md={3} sm={6} xs={12}>
      <Card>
        <CardMedia
          component="img"
          alt={props.missionKey}
          height="80"
          image={imageIcon}
          title={props.missionKey}
        />
        <CardContent>
          <Typography variant={"h6"} id="discrete-slider" gutterBottom style={{ marginTop: 5 }} align={"center"}>
            {getChineseMission(props.missionKey)}
          </Typography>
          <Slider
            defaultValue={isNaN(props.initLevel as any) ? 1 : props.initLevel}
            aria-labelledby="discrete-slider"
            valueLabelDisplay="auto"
            onChange={(e, v) => props.onChangeLevelSlider(props.missionKey, v as number)}
            step={1}
            marks
            min={1}
            max={props.maxLevel ?? 1}
          />
        </CardContent>
      </Card>
    </Grid>
  )
}