import { Card, CircularProgress, createStyles, Grid, makeStyles, MenuItem, TextField, Theme } from '@material-ui/core';
import Backdrop from "@material-ui/core/Backdrop";
import _ from 'lodash';
import moment from 'moment';
import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { ActionStatus, GameBehavior, MahjongGameBehaviorTrendChartData } from '../types/GameBehaviorLog';
import MahjongGameBehaviorChart from './MahjongGameBehaviorChart';
import { Month, months, translationMonth } from "./PlaytimeTab";

const StyledVerticalTabContainer = styled.div`
  // flex-grow: 1;
  // display: flex;
`
const StyledTabPanel = styled.div`
  width: 100%;
`

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      justifyContent: "center"
    },
    scroller: {
      flexGrow: 0
    },
  }),
);

interface MahjongGameBehaviorChartTabsProps {
  data: GameBehavior[] | undefined;
  mode: 0 | 1;
}

function a11yProps(index: any) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index } = props;

  return (
    <StyledTabPanel
      hidden={value !== index}
    >
      {children}
    </StyledTabPanel>
  );
}

function usePrevious(value: any) {
  const ref = useRef(undefined);
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

export default function MahjongGameBehaviorChartTabs(props: MahjongGameBehaviorChartTabsProps) {

  const [chartData, setChartData] = useState<MahjongGameBehaviorTrendChartData>();

  const [loading, setLoading] = useState<boolean>(true);

  const [timeUnit, setTimeUnit] = useState<{ year: number, month: number } | undefined>({ year: moment().year(), month: moment().month() });

  const classes = useStyles();

  useEffect(() => {
    setLoading(false);
  }, [])

  useEffect(() => {
    if ((props.data !== undefined) && (props.mode !== undefined) && (timeUnit !== undefined)) {
      setChartData(extractDataByTime(generateChartData(props.data ?? [])));
    }
  }, [props.data, props.mode, timeUnit])

  const generateChartData = (data: GameBehavior[]): MahjongGameBehaviorTrendChartData => {
    // Group by time and calculate average at day interval

    const dataGroupedByTime = _(data)
      .map(entry => { return { ...entry, day: moment(entry.timestamp).startOf("day").valueOf() } })
      .groupBy(entry => entry.day)
      .valueOf();

    const dataReducer = Object.entries(dataGroupedByTime).reduce<{ [key: string]: {} }>((buffer, [dayTimestamp, logs]) => {
      const allBehaviorFromCurrentPlayer = _(logs).map(log => log.behaviors).flatten().valueOf();
      const behaviorGroupedByActionType = _(allBehaviorFromCurrentPlayer).groupBy(behavior => behavior.type).valueOf() as { [key: string]: ActionStatus[] };
      const dayAverageValue: { [key: string]: { reactionTime: number, wrongRate: number } } = Object.entries(behaviorGroupedByActionType).reduce((dayBuffer, [actionType, actions]) => {
        const currentActionReactionTimeAverage = _(actions).filter(action => action.time !== undefined).meanBy(action => action.time ?? 0);
        const currentActionWrongRateAverage = _(actions).filter(action => action.status === "success" || action.status === "fail").meanBy(action => action.status === "success" ? 0 : 1);
        return { ...dayBuffer, [actionType]: { reactionTime: Math.round(currentActionReactionTimeAverage * 100) / 100, wrongRate: Math.round(currentActionWrongRateAverage * 10000) / 100 } };
      }, {});
      return { ...buffer, [dayTimestamp]: dayAverageValue }
    }, {});

    // dataReducer here may have other use

    // Transform the object from {time: {[action: string]: {reactionTime: number, wrongRate: number}}} to {[action: string]: {[time: number]: {reactionTime: number, wrongRate: number}}}
    const transformedChartData = Object.entries(dataReducer).reduce<{ [key: string]: { [key: string]: { reactionTime: number, wrongRate: number } } }>((buffer, [dayTimestamp, actionAvgData]: [string, { [key: string]: { reactionTime: number, wrongRate: number } }]) => {
      Object.entries(actionAvgData).forEach(([actionType, avgData]: [string, { reactionTime: number, wrongRate: number }]) => {
        (buffer[actionType] || (buffer[actionType] = {}));
        buffer[actionType][dayTimestamp] = avgData;
      });

      return buffer;
    }, {});

    console.log(transformedChartData);

    return transformedChartData;
  }

  const extractDataByTime = (data: MahjongGameBehaviorTrendChartData) => {
    if (!timeUnit) return;

    const startOfMonth = moment(timeUnit!.year + "-" + timeUnit!.month).startOf("month").valueOf();
    const endOfMonth = moment(timeUnit!.year + "-" + timeUnit!.month).endOf("month").valueOf();

    const timeFilterData = Object.entries(data).reduce<MahjongGameBehaviorTrendChartData>((buffer, [actionType, actionAvgData]) => {
      buffer[actionType] = Object.entries(actionAvgData).filter(([timestamp, avgData]) => parseInt(timestamp) < endOfMonth && parseInt(timestamp) > startOfMonth).reduce<{ [key: string]: { reactionTime: number, wrongRate: number } }>((actionBuffer, [timestamp, avgData]) => {
        actionBuffer[timestamp] = avgData;
        return actionBuffer;
      }, {});

      return buffer;
    }, {});

    console.log(timeFilterData);

    return timeFilterData;
  }

  return (
    <StyledVerticalTabContainer>
      <Card style={{ position: "relative" }}>
        <Grid container>
          <Grid item>
            <TextField
              id="year"
              select
              label="年份"
              value={timeUnit?.year}
              onChange={(e) => setTimeUnit({ "year": parseInt(e.target.value), "month": timeUnit!.month })}
              margin="normal"
              style={{ width: 200, justifySelf: "flex-start", display: 'flex', margin: 40 }}
            >
              <MenuItem key={"2020"} value={"2020"}>2020年</MenuItem>
              <MenuItem key={"2021"} value={"2021"}>2021年</MenuItem>
              <MenuItem key={"2022"} value={"2022"}>2022年</MenuItem>
            </TextField>
          </Grid>
          <Grid item >
            <TextField
              id="month"
              select
              label="月份"
              value={timeUnit?.month}
              onChange={(e) => setTimeUnit({ "year": timeUnit!.year, "month": parseInt(e.target.value) as Month })}
              margin="normal"
              style={{ width: 200, justifySelf: "flex-start", display: 'flex', margin: 40 }}
            >
              {/* <MenuItem key={-1} value={-1}>全年</MenuItem> */}
              {months.map((option) => (
                <MenuItem key={option} value={option}>
                  {translationMonth[option]}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
        </Grid>

        {chartData && <MahjongGameBehaviorChart selectedYear={timeUnit?.year} selectedMonth={timeUnit?.month} data={chartData} mode={props.mode} />}
        {/* <DomainTaskTable data={tabData.filter(x => x.domain === domain)[0].missionScore} historyData={props.data} selectedTab={selectedTab} missionOnClick={missionOnClick} /> */}

        <Backdrop style={{ position: "absolute", zIndex: 999, background: "rgba(255,255,255,0.3)" }} open={loading}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </Card>
    </StyledVerticalTabContainer >
  )
}

//average={allTabData.filter(x => x.mission === domain)}