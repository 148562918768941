import _ from "lodash";

export interface UserProfile {
  user_id: string,
  name: string,
  gender?: string,
  avatar_url: string,
  year_of_birth?: number | null,
  occupation?: string,
  use_smartphone_level?: FamiliarLevelType | FamiliarLevelChineseType,
  education_level?: EducationLevelType | EducationLevelChineseType,
  mahjong_level?: FamiliarLevelType | FamiliarLevelChineseType,
  smoke?: HabitAnswerType | HabitAnswerChineseType,
  drink_alcohol?: HabitAnswerType | HabitAnswerChineseType,
  avg_walking_time?: number,
  dementia_diagnosis?: DementiaDiagnosisType | DementiaDiagnosisChineseType,
  moca_score?: MocaScore | null,
  is_dementia_mode: boolean,
  eHealthId?: string,
  center?: string,
}

export const calculateMocaScore = (scores: MocaScore): string => {

  // Check whether the score is of right type (because some are old types i.e. number only)
  if (scores && Number.isInteger(scores as any)) {
    return "需要更新格式";
  }
  else if (scores && !Number.isInteger(scores as any) && scores.visuospatialExecutive === undefined) {
    return "無效記錄";
  }

  return _(Object.values(scores).filter(s => s !== null)).sum() + "分";
}

export interface MocaScore {
  visuospatialExecutive: number | null,
  naming: number | null,
  attention: number | null,
  language: number | null,
  abstraction: number | null,
  delayedRecall: number | null,
  orientation: number | null
}

export const DementiaDiagnoses = ["healthy", "mci", "mild_dementia", "moderate_dementia", "severe_dementia", "unknown"] as const;
export type DementiaDiagnosisType = typeof DementiaDiagnoses[number];

export const DementiaDiagnosesChinese = ["健康", "MCI", "輕微", "中度", "嚴重", "未知"] as const;
export type DementiaDiagnosisChineseType = typeof DementiaDiagnosesChinese[number];

export const HabitAnswersChinese = ["沒有", "經常", "間中", "甚少"] as const;
export type HabitAnswerType = typeof HabitAnswers[number];

export const HabitAnswers = ["no", "always", "sometimes", "seldom"] as const;
export type HabitAnswerChineseType = typeof HabitAnswersChinese[number];

export const EducationLevels = ["no_education", "primary_school", "secondary_school", "university", "above_university"] as const;
export type EducationLevelType = typeof EducationLevels[number];

export const EducationLevelsChinese = ["從未接受過教育", "小學畢業", "中學畢業", "大專/大學畢業", "大學以上"] as const;
export type EducationLevelChineseType = typeof EducationLevelsChinese[number];

export const FamiliarLevels = ["no_knowledge", "newbie", "familiar"] as const;
export type FamiliarLevelType = typeof FamiliarLevels[number];

export const FamiliarLevelsChinese = ["不認識", "入門", "熟悉"] as const;
export type FamiliarLevelChineseType = typeof FamiliarLevelsChinese[number];

export const translation: any = {
  "healthy": "健康",
  "mci": "MCI",
  "mild_dementia": "輕微",
  "moderate_dementia": "中度",
  "severe_dementia": "嚴重",
  "unknown": "未知",
  "no": "沒有",
  "always": "經常",
  "sometimes": "間中",
  "seldom": "甚少",
  "no_education": "從未接受過教育",
  "primary_school": "小學畢業",
  "secondary_school": "中學畢業",
  "university": "大專/大學畢業",
  "above_university": "大學以上",
  "no_knowledge": "不認識",
  "newbie": "入門",
  "familiar": "熟悉",
}

