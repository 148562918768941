import React, { useState } from "react";
import { useSelector } from "react-redux";
import SimpleAppBar from "../components/AppBar";
import { SnackBarProp } from "../components/SimpleSnackBar";
import { AppState } from "../reducers";
import { StyledContainer } from "../StyledComponents";
import RegisterPage from "./RegisterPage";

export default function NewAccountPage() {

  const closeErrorSnackBar = () => {
    setSnackBar({ ...snackBar, "open": false });
  }

  // const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const [snackBar, setSnackBar] = useState<SnackBarProp>({
    open: false,
    onClose: closeErrorSnackBar,
    message: "",
    variant: undefined
  });

  const loggedInUser = useSelector<AppState, firebase.User | null>(state => state.loggedInUser);

  return (
    <div>
      <SimpleAppBar title={"新增帳戶"} />
      {loggedInUser &&
        // {loggedInUser && loggedInUser.roles_names.includes("admin") ?
        <StyledContainer maxWidth="lg" style={{ paddingTop: 90, paddingBottom: 80 }}>
          <RegisterPage />
        </StyledContainer>
      }
      <SimpleAppBar title={"新增帳戶"} bottom={true} />
    </div >
  );
}