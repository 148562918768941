import { DirectionsRun, EmojiSymbols, Help, LocationSearching, TouchApp, Translate, Visibility } from '@material-ui/icons';
import React from "react";

export const missions = [
  // "AskBoxPin",
  // "AskFeeling",
  // "AskSuspectNumber",
  "AskWhere",
  // "AskDistrict",
  // "ChooseSuspect",
  "BlockWater",
  "CalcMahjong",
  // "Calendar",
  "CheckCanEat",
  "CheckScore",
  "CryingBaby",
  "Chow",
  "DiceSum",
  // "DrawBox",
  // "DrawClock",
  "DrawTile",
  "Eat",
  "GuessMahjong",
  "Gong",
  "Identify",
  // "IdentificationRandom",
  // "IdentificationSingle",
  // "MapLocation",
  "MemoryDelay",
  "MatchEat",
  // "MocaCalendar",
  // "MocaFillDoorSign",
  // "NameSuspect",
  "NoChow",
  "NoGong",
  "NoPong",
  "PairTiles",
  "PayMoney",
  "Pong",
  "PianoMaster",
  "RememberDroppedSequence",
  "OtherDroppedTiles",
  "OtherDroppedTileSort",
  "RememberDroppedTiles",
  "ShanghaiMahjong",
  "ShuffleTile",
  "SortTile",
  "StackTile",
  "StopHand",
  "SpinningWheel",
  "SushiTrain",
  "SortMahJong",
  "StackTileWallShake",
  "TapCharacter",
  "TrackDiscard",
  "WeekDay",
  "WashTile"
]

export const domains = [
  "Attention",
  "Executive",
  "Memory",
  "Perception",
  // "SocialRecognition",
  "Motor",
  "Language",
  // "Orientation"
]

export const themes = [
  "Mahjong",
  "Market",
  "Outdoor",
  "OldHK",
  "Restaurant"
]

export type DomainType = typeof domains[number];
export type MissionType = typeof missions[number];
export type ThemeType = typeof themes[number];

export type MissionMetadata = {
  [key: string]: {
    domain: string,
    level: number,
    theme: {
      [key: string]: string
    }
  }
}

export function getChineseMission(mission: MissionType, theme: ThemeType = "Mahjong"): string {
  let chinese = "";
  switch (mission) {
    case "BlockWater":
      chinese = "濕滑的魚檔";
      break;
    case "CalcMahjong":
      chinese = "麻雀1+1";
      break;
    case "Calendar":
      chinese = "錯日歷";
      break;
    case "CheckScore":
      chinese = "食糊幾多番";
      break;
    case "CheckCanEat":
      chinese = "有冇食詐糊";
      break;
    case "CryingBaby":
      chinese = "哄哄寶寶睡";
      break;
    case "Chow":
      chinese = "上";
      break;
    case "DiceSum":
      chinese = "骰仔計數";
      break;
    case "DrawTile":
      chinese = "臨摹物品";
      break;
    case "Eat":
      chinese = "食";
      break;
    case "StackTileWallShake":
      chinese = "搖搖疊牌樂";
      break;
    case "Gong":
      chinese = "槓";
      break;
    case "GuessMahjong":
      chinese = "猜猜看";
      break;
    case "Identify":
      chinese = "認花式";
      break;
    case "MemoryDelay":
      chinese = "打蚊";
      break;
    case "MatchEat":
      chinese = "砌牌食糊";
      break;
    case "NoChow":
      chinese = "不可以上";
      break;
    case "NoGong":
      chinese = "不可以碰";
      break;
    case "NoPong":
      chinese = "不可以槓";
      break;
    case "PairTiles":
      chinese = "麻雀配對";
      break;
    case "PayMoney":
      chinese = "計算動動腦";
      break;
    case "PianoMaster":
      chinese = "演奏大師";
      break;
    case "Pong":
      chinese = "碰";
      break;
    case "RememberDroppedTiles":
      chinese = "跌牌記憶";
      break;
    case "OtherDroppedTiles":
      chinese = "其他跌牌記憶"
      break;
    case "RememberDroppedSequence":
      chinese = "跌牌順序";
      break;
    case "OtherDroppedTileSort":
      chinese = "其他跌牌順序";
      break;
    case "ShanghaiMahjong":
      chinese = "上海麻雀";
      break;
    case "ShuffleTile":
      chinese = "洗牌畫線";
      break;
    case "SortTile":
      chinese = "排牌";
      break;
    case "StackTile":
      chinese = "洗牌樂";
      break;
    case "StopHand":
      chinese = "眼明手快";
      break;
    case "SushiTrain":
      chinese = "迴轉麻將";
      break;
    case "SpinningWheel":
      chinese = "健康幸運輪";
      break;
    case "SortMahJong":
      chinese = "麻雀記憶力訓練";
      break;
    case "TapCharacter":
      chinese = "類別點擊";
      break;
    case "TrackDiscard":
      chinese = "記上家牌";
      break;
    case "WashTile":
      chinese = "大洗牌";
      break;
    case "WeekDay":
      chinese = "今日星期幾";
      break;
    case "SeePicKnowWord":
      chinese = "聽聲識圖";
      break;
    case "PairTilesAdvanced":
      chinese = "進階配對";
      break;
    default:
      chinese = mission;
      break;
  }
  return chinese;
}

export function getChineseDomain(domain: DomainType): string {
  let chineseDomain = "";
  switch (domain) {
    case "Attention":
      chineseDomain = "集中力";
      break;
    case "Executive":
      chineseDomain = "執行能力";
      break;
    case "Memory":
      chineseDomain = "記憶力";
      break;
    // case "Orientation":
    //   chineseDomain = "時間及環境方向";
    //   break;
    case "Motor":
      chineseDomain = "眼手協調";
      break;
    case "Perception":
      chineseDomain = "視覺空間";
      break;
    case "Language":
      chineseDomain = "語言";
      break;
    // case "Social Recognition":
    //   chineseDomain = "社交認知"
    //   break;
    default:
      chineseDomain = domain;
      break;
  }
  return chineseDomain;
}

export function getChineseTheme(theme: ThemeType): string {
  let chineseTheme = "";
  switch (theme) {
    case "Mahjong":
      return "麻雀";
    case "Market":
      return "街市";
    case "Outdoor":
      return "戶外";
    case "OldHK":
      return "老香港";
    case "Restaurant":
      return "酒樓";
    default:
      return "未知主題";
  }
}

export const levelUnicode = ["❔", "1️⃣", "2️⃣", "3️⃣", "4️⃣", "5️⃣", "6️⃣", "7️⃣", "8️⃣", "9️⃣", "🔟"];

export const getDomainIconComponent = (domain: string) => {
  switch (domain) {
    case "Attention":
      return <LocationSearching style={{ color: "#cc534b" }} />
    case "Executive":
      return <DirectionsRun style={{ color: "#6e579d" }} />
    case "Memory":
      return <EmojiSymbols style={{ color: "#3b703f" }} />
    case "Motor":
      return <TouchApp style={{ color: "#4aa5d2" }} />
    case "Perception":
      return <Visibility style={{ color: "#eab245" }} />
    case "Language":
      return <Translate style={{ color: "#e2813b" }} />
    default:
      return <Help style={{ color: "#444444" }} />
  }
}

export const getDomainColor = (domain: string) => {
  switch (domain) {
    case "Attention":
      return "#cc534b"
    case "Executive":
      return "#6e579d"
    case "Memory":
      return "#3b703f";
    case "Motor":
      return "#4aa5d2"
    case "Perception":
      return "#eab245"
    case "Language":
      return "#e2813b"
    default:
      return "#444444"
  }
}