import { IconButton, Tooltip } from "@material-ui/core";
import Button from '@material-ui/core/Button';
import AccessAlarmIcon from '@material-ui/icons/AccessAlarm';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import SportsEsportsIcon from '@material-ui/icons/SportsEsports';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import * as admin from 'firebase-admin';
import moment from 'moment';
import MUIDataTable from 'mui-datatables';
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setAccountInfos } from '../actions/accountInfos';
import { setAccounts } from '../actions/accounts';
import { setMissionMetadata } from "../actions/missionMetadata";
import { setUserRoles } from '../actions/userRoles';
import { setUserValidities } from '../actions/userValidities';
import { deleteUserProfile, getAccountInfos, getAccounts, getMissionDomain, getUserProfile, getUserRoles, getUserValidities, updateFirebaseAccountInfo } from '../api';
import SimpleAppBar from "../components/AppBar";
import { AutoUpdateValidityDialog } from '../components/AutoUpdateValidityDialog';
import DeleteDialog from "../components/DeleteDialog";
import EditAccountDialog from '../components/EditAccountDialog';
import { EditDemoMissionDialog } from '../components/EditDemoMissionDialog';
import PlaytimeDialog from '../components/PlaytimeDialog';
import ResetPasswordDialog from '../components/ResetPasswordDialog';
import SimpleSnackBar, { SnackBarProp } from "../components/SimpleSnackBar";
import { AppState } from "../reducers";
import { LoadableAccountData } from '../reducers/accounts';
import { StyledCircularProgress, StyledContainer } from "../StyledComponents";
import { AccountInfo } from '../types/AccountInfo';
import { MissionMetadata, MissionType } from "../types/MissionType";
import { isTrialAccount, RoleType, UserRole } from "../types/UserRole";
import { UserValidity } from "../types/UserValidity";

type UserRecord = admin.auth.UserRecord;

export interface AccountData extends UserRecord {
  role?: RoleType | null,
  referralCode?: string,
  is_valid?: boolean | null,
  valid_till?: number | string | null,
  creationTime: string | null,
  lastSignInTime: string | null
}

export default function AccountsPage() {

  const dispatch = useDispatch();
  const [isEditAccountDialogOpen, setIsEditAccountDialogOpen] = useState<boolean>(false);
  const [isResetPasswordDialogOpen, setIsResetPasswordDialogOpen] = useState<boolean>(false);
  const [isPlaytimeDialogOpen, setIsPlaytimeDialogOpen] = useState<boolean>(false);
  const [isEditDemoMissionDialogOpen, setIsEditDemoMissionDialogOpen] = useState<boolean>(false);
  const [isAutoUpdateValidityDialogOpen, setIsAutoUpdateValidityDialogOpen] = useState<boolean>(false);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState<boolean>(false);
  const closeErrorSnackBar = () => {
    setSnackBar({ ...snackBar, "open": false });
  }
  const [snackBar, setSnackBar] = useState<SnackBarProp>({
    open: false,
    onClose: closeErrorSnackBar,
    message: "",
    variant: undefined
  });

  const [selectedAccount, setSelectedAccount] = useState<string>();

  const loggedInUser = useSelector<AppState, firebase.User | null>(state => state.loggedInUser);

  const loggedUserRole = useSelector<AppState, RoleType | null>(state => state.userRole);

  const accounts = useSelector<AppState, LoadableAccountData>(state => state.accounts);

  const userRoles = useSelector<AppState, UserRole[] | null>(state => state.userRoles);

  const userValidities = useSelector<AppState, UserValidity[] | null>(state => state.userValidities);

  const accountInfos = useSelector<AppState, AccountInfo[] | null>(state => state.accountInfos);

  const [accountDatas, setAccountDatas] = useState<AccountData[]>();

  const missionMetadata = useSelector<AppState, { metadata: MissionMetadata | null; domainMission: { [key: string]: MissionType[] } }>(state => state.missionMetadata);

  useEffect(() => {
    async function setMissionMD() {
      if (!missionMetadata.metadata)
        dispatch(setMissionMetadata(await getMissionDomain()));
    }
    setMissionMD();
    _setAccounts();
  }, [])

  useEffect(() => {
    _setAccountDatas();
  }, [accounts, userRoles, userValidities, accountInfos])

  const _setAccounts = async () => {
    if (accounts.kind === "notLoaded") {
      dispatch(setAccounts({ kind: "loading" }));
    }
    try {
      let data = await getAccounts() as AccountData[];
      data = data.map(x => {
        return {
          ...x,
          creationTime: moment.utc(x.metadata.creationTime).local().format('YYYY-MM-DD HH:mm:ss'),
          lastSignInTime: moment.utc(x.metadata.lastSignInTime).local().format('YYYY-MM-DD HH:mm:ss')
        }
      })
      const _userValidities = await getUserValidities();
      dispatch(setUserValidities(_userValidities));
      const _userRoles = await getUserRoles();
      dispatch(setUserRoles(_userRoles));
      const _accountInfos = await getAccountInfos();
      dispatch(setAccountInfos(_accountInfos));
      dispatch(setAccounts({ kind: "loaded", data: data }));
    } catch (e) {
      dispatch(setAccounts({ kind: "error", error: e }));
    }
  }

  const _setAccountDatas = () => {
    if (accounts.kind === 'loaded' && userRoles !== null && userValidities !== null && accountInfos !== null) {
      let tempArr: AccountData[] = accounts.data.map(account => {
        let temp: AccountData = account;
        const tempRole = userRoles.find(x => x.uid === account.uid);
        const tempUserValidy = userValidities.find(x => x.uid === account.uid);
        const tempAccountInfo = accountInfos.find(x => x.uid === account.uid);
        if (tempRole !== undefined) {
          temp = { ...temp, role: tempRole.role };
        } else {
          temp = { ...temp, role: "unknown" as RoleType };
        }
        if (tempAccountInfo !== undefined) {
          if (tempAccountInfo.referralCode !== undefined) {
            temp = { ...temp, referralCode: tempAccountInfo.referralCode };
          }
          if (tempAccountInfo.phone !== undefined && account.phoneNumber === undefined) {
            temp = { ...temp, phoneNumber: tempAccountInfo.phone };
          }
        }
        if (tempUserValidy !== undefined) {
          if (tempUserValidy.valid_till !== null) {
            temp = { ...temp, is_valid: tempUserValidy.is_valid, valid_till: moment.utc(tempUserValidy.valid_till).local().format('YYYY-MM-DD') };
          } else {
            temp = { ...temp, is_valid: tempUserValidy.is_valid };
          }
        }
        return temp;
      })
      setAccountDatas(tempArr);
    }
  }

  const handleOpenEditUserDialog = (uid: string) => {
    setSelectedAccount(uid);
    setIsEditAccountDialogOpen(true);
  }

  const handleOpenResetPasswordDialog = (uid: string) => {
    setSelectedAccount(uid);
    setIsResetPasswordDialogOpen(true);
  }

  const handleOpenPlaytimeDialog = async (uid: string) => {
    setSelectedAccount(uid);
    setIsPlaytimeDialogOpen(true);
  }

  const handleEditDemoMissionDialog = (uid: string) => {
    setSelectedAccount(uid);
    setIsEditDemoMissionDialogOpen(true);
  }

  const closeEditAccountDialog = () => {
    setIsEditAccountDialogOpen(false);
    _setAccounts();
  }
  const closeResetPasswordDialog = () => {
    setIsResetPasswordDialogOpen(false);
  }

  const closePlaytimeDialog = () => {
    setIsPlaytimeDialogOpen(false);
  }

  const closeEditDemoMissionDialog = () => {
    setIsEditDemoMissionDialogOpen(false);
  }

  const closeAutoUpdateValidityDialog = () => {
    setIsAutoUpdateValidityDialogOpen(false);
  }

  const _isTrialAccount = (uid: string) => {
    if (accountDatas !== undefined && accounts.kind === "loaded") {
      const temp = accountDatas.filter(x => x.uid === uid)[0].role
      if (temp !== undefined && temp !== null) {
        return isTrialAccount(temp);
      } else {
        return false;
      }
    }
  }

  const isCenterAccount = (uid: string) => {
    if (accountDatas !== undefined && accounts.kind === "loaded") {
      const temp = accountDatas.filter(x => x.uid === uid)[0].role
      if (temp !== undefined && temp !== null) {
        if (temp === 'trial_center' || temp === 'center') {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  const handleAutoRenewValidity = async () => {
    // if (accounts !== undefined && accounts.kind === "loaded") {
    //   console.log(moment.utc().valueOf());
    //   accounts.data.forEach(async x => {
    //     console.log(x.valid_till);
    //     if (x.valid_till === undefined) {
    //       await updateUserValidity({
    //         uid: x.uid,
    //         valid_till: moment().add(7, "d").hour(23).minute(59).valueOf(),
    //         is_valid: true,
    //       });
    //     }
    //   })
    // }
    setIsAutoUpdateValidityDialogOpen(true);
  }

  const handleDeleteDialogOpen = (uid: string | undefined) => {
    if (uid !== undefined) {
      setSelectedAccount(uid);
      setIsDeleteDialogOpen(true);
    }
  }

  const handleDeleteDialogClose = () => {
    setIsDeleteDialogOpen(false);
  }

  const handleDeleteAccount = async (uid: string) => {
    try {
      const temp = await getUserProfile(uid);
      if (temp !== null) {
        await deleteUserProfile(uid);
      }
      await updateFirebaseAccountInfo({ uid: uid, disabled: true });
      setIsDeleteDialogOpen(false);
      setSnackBar({
        ...snackBar,
        open: true,
        message: "刪除成功！",
        variant: "success",
      });
      _setAccountDatas();
    } catch (e) {
      console.log(e);
    }
  }

  return (
    <div>
      <SimpleAppBar title={"帳戶"} />
      <StyledContainer maxWidth="lg" style={{ paddingTop: 90, paddingBottom: 80 }}>
        {loggedInUser && loggedUserRole === "admin" ?
          <>
            <Button href="/newaccount" color="primary" variant="contained" >
              新增帳戶
            </Button>
            <Button onClick={handleAutoRenewValidity} color="primary" variant="contained" style={{ marginLeft: 30 }} >
              延長帳戶有效期
            </Button>
            {((accounts !== undefined && accounts.kind === "loading") || accountDatas === undefined) && <StyledCircularProgress />}
            {accountDatas !== undefined && accounts.kind === "loaded" &&
              <MUIDataTable
                title={""}
                data={accountDatas}
                columns={[
                  {
                    label: ' ',
                    name: 'uid',
                    options: {
                      filter: false,
                      sort: false,
                      viewColumns: false,
                      customBodyRenderLite: (dataIndex) => (
                        <>
                          <Tooltip title="更改帳戶資料">
                            <IconButton
                              onClick={() => handleOpenEditUserDialog(accountDatas[dataIndex].uid)}
                              color="inherit"
                            >
                              <EditIcon color='primary' />
                            </IconButton>
                          </Tooltip>
                          <Tooltip title="重設密碼">
                            <IconButton
                              onClick={() => handleOpenResetPasswordDialog(accountDatas[dataIndex].uid)}
                              color="inherit"
                            >
                              <VpnKeyIcon color='primary' />
                            </IconButton>
                          </Tooltip>
                          <Tooltip title="檢視所有用家遊玩時間">
                            <IconButton
                              onClick={() => handleOpenPlaytimeDialog(accountDatas[dataIndex].uid)}
                              color="inherit"
                            >
                              <AccessAlarmIcon color='primary' />
                            </IconButton>
                          </Tooltip>
                          {_isTrialAccount(accountDatas[dataIndex].uid) === true &&
                            <Tooltip title="更改帳戶試玩內容">
                              <IconButton
                                onClick={() => handleEditDemoMissionDialog(accountDatas[dataIndex].uid)}
                                color="inherit"
                              >
                                <SportsEsportsIcon color='primary' />
                              </IconButton>
                            </Tooltip>}
                          <Tooltip title="刪除帳戶">
                            <IconButton
                              onClick={() => handleDeleteDialogOpen(accountDatas[dataIndex].uid)}
                              color="inherit"
                            >
                              <DeleteIcon color='primary' />
                            </IconButton>
                          </Tooltip>
                        </>
                      )
                    }
                  },
                  {
                    label: 'UID',
                    name: 'uid',
                    options: {
                      filter: true,
                      sort: true,
                      display: "false"
                    }
                  },
                  {
                    label: '電郵',
                    name: 'email',
                    options: {
                      filter: true,
                      sort: true,
                    }
                  },
                  {
                    label: '電話',
                    name: 'phoneNumber',
                    options: {
                      filter: true,
                      sort: true,
                      display: "false"
                    }
                  },
                  {
                    label: '顯示名稱',
                    name: 'displayName',
                    options: {
                      filter: true,
                      sort: true,
                    }
                  },
                  {
                    label: '推薦碼',
                    name: 'referralCode',
                    options: {
                      filter: true,
                      sort: true,
                      display: "false"
                    }
                  },
                  {
                    label: '確認電郵',
                    name: 'emailVerified',
                    options: {
                      filter: true,
                      sort: true,
                      display: "false",
                      customBodyRender: (value) => value ? "Yes" : "No"
                    }
                  },
                  {
                    label: '身份',
                    name: 'role',
                    options: {
                      filter: true,
                      sort: true,
                    }
                  },
                  {
                    label: '有效？',
                    name: 'is_valid',
                    options: {
                      filter: true,
                      sort: true,
                      display: "false",
                      customBodyRender: (value) => value ? "Yes" : "No"
                    }
                  },
                  {
                    label: '有效期至',
                    name: 'valid_till',
                    options: {
                      filter: true,
                      sort: true,
                    }
                  },
                  {
                    label: '最後登入',
                    name: 'lastSignInTime',
                    options: {
                      filter: false,
                      sort: true,
                      customBodyRender: (value) => value === "Invalid date" ? "從未登入" : value
                    }
                  },
                  {
                    label: '建立日期',
                    name: 'creationTime',
                    options: {
                      filter: false,
                      sort: true,
                    }
                  },

                ]}
                options={{ selectableRows: 'none', elevation: 0, print: false, download: false }} />
            }
            {
              selectedAccount !== undefined &&
              < EditAccountDialog isOpen={isEditAccountDialogOpen} onClose={closeEditAccountDialog} uid={selectedAccount} />
            }
            {
              selectedAccount !== undefined && snackBar !== undefined &&
              <ResetPasswordDialog isOpen={isResetPasswordDialogOpen} onClose={closeResetPasswordDialog} uid={selectedAccount} snackBar={snackBar} setSnackbar={setSnackBar} />
            }
            {selectedAccount !== undefined &&
              <PlaytimeDialog isOpen={isPlaytimeDialogOpen} onClose={closePlaytimeDialog} isCenter={isCenterAccount(selectedAccount)} uid={selectedAccount} />
            }
            {
              (selectedAccount !== undefined && missionMetadata.domainMission) &&
              <EditDemoMissionDialog isOpen={isEditDemoMissionDialogOpen} onClose={closeEditDemoMissionDialog} uid={selectedAccount} />
            }
            <AutoUpdateValidityDialog isOpen={isAutoUpdateValidityDialogOpen} onClose={closeAutoUpdateValidityDialog} />
            {selectedAccount !== undefined && accountDatas !== undefined &&
              <DeleteDialog open={isDeleteDialogOpen} onClose={handleDeleteDialogClose} type="帳戶" selectedName={accountDatas.filter(x => x.uid === selectedAccount)[0].displayName} selectedId={selectedAccount} handleDelete={handleDeleteAccount} />}
            <SimpleSnackBar open={snackBar.open} onClose={closeErrorSnackBar} message={snackBar.message} variant={snackBar.variant} />
          </> :
          <SimpleSnackBar open={true} onClose={closeErrorSnackBar} message={"You don't have permission to view this page."} variant="warning" autoClose={99999} />
        }
      </StyledContainer >
      <SimpleAppBar title={"帳戶"} bottom={true} />
    </div >
  );
}
