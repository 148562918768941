import { Button, Card, Divider, Grid, Typography, useMediaQuery, useTheme } from "@material-ui/core";
import React from "react";

export const PlanType = (props: { planTitle: string, desc: string, image: any, onClickShowPlans?: () => void, backgroundColor?: string, evaluate?: boolean, fullWidth?: boolean, fontColor?: string }) => {

  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.down('xs'));

  return (
    <Grid item xs={props.fullWidth ? 12 : 10} sm={props.fullWidth ? 12 : 10} md={props.fullWidth ? 12 : 10} lg={4} xl={4}>
      <Card className={props.evaluate ? "plan-type-card" : undefined} style={{ transition: "transform .25s", position: "relative", margin: "0 auto", height: "100%", borderRadius: 10, boxShadow: "0 8px 20px -12px rgb(0 0 0 / 30%)", backgroundColor: props.backgroundColor ?? "#f4f4ff" }}>
        <div style={{ margin: "20px" }}>
          <div style={{ height: "200px", width: "100%" }}>
            {props.image}
          </div>

          <Divider style={{ height: 1.5, marginTop: -1, marginBottom: 10 }} />

          <Typography align="center" style={{ fontWeight: "bold", marginBottom: 10, fontSize: 36 }} color="primary">
            {props.planTitle}
          </Typography>

          <Typography align="center" style={{ color: props.fontColor ?? "#888", fontWeight: "normal", marginBottom: 20, fontSize: 18, display: "flex", alignItems: "center", justifyContent: "center" }} color="primary">
            {props.desc}
          </Typography>

          {
            props.onClickShowPlans &&
            <Button variant="contained" disableElevation fullWidth color="primary" onClick={props.onClickShowPlans}>
              查看{props.planTitle}計劃
            </Button>
          }
        </div>
      </Card>
    </Grid>
  )
}