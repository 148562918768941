import axios from "axios";
import * as admin from "firebase-admin";
import moment from "moment";
import firebase from "./firebase";
import { store } from "./store/store";
import { AccountInfo } from "./types/AccountInfo";
import { Announcement, Comment } from "./types/Announcement";
import { CalligraphyRecordEntry } from "./types/CalligraphyData";
import { DisabledInfo } from "./types/DisabledInfo";
import { Email } from "./types/Email";
import { FirebaseAuthError } from "./types/Error";
import { GameBehaviorLog } from "./types/GameBehaviorLog";
import { GameLog } from "./types/GameLog";
import { GamePreference } from "./types/GamePreference";
import { Login } from "./types/Login";
import { AllMissionDataType } from "./types/MissionData";
import { MissionMetadata, MissionType } from "./types/MissionType";
import { Register } from "./types/Register";
import { RequestDemo } from "./types/RequestDemo";
import { UserDemoMissions } from "./types/UserDemoMissions";
import { UserMissionFrequency } from "./types/UserMissionFrequency";
import { UserMissionSummary } from "./types/UserMissionSummary";
import { UserProfile } from "./types/UserProfile";
import { RoleType, UserRole } from "./types/UserRole";
import { UserValidity } from "./types/UserValidity";

type UserRecord = admin.auth.UserRecord;

const db = firebase.app().database();
const functions = firebase.functions();

if (window.location.hostname === "localhost") {
  firebase.functions().useFunctionsEmulator("http://localhost:5001");
}

const endpoint = () => {
  if (
    process.env.REACT_APP_ENVIRONMENT !== "test" &&
    process.env.NODE_ENV === "production"
  ) {
    return "https://backend.medmindtechnology.hk/api/";
  } else {
    //TODO production
    return "http://medmind.test/api/";
  }
};

const firebaseFunctionsEndpoint = () => {
  if (window.location.hostname === "localhost") {
    return "http://localhost:5001/neurogym-game/us-central1/";
  } else {
    if (
      process.env.REACT_APP_ENVIRONMENT !== "test" &&
      process.env.NODE_ENV === "production"
    ) {
      return "https://us-central1-neurogym-prod.cloudfunctions.net/";
    } else {
      return "https://us-central1-neurogym-game.cloudfunctions.net/";
    }
  }
};

const firebaseFunctionsCallableEndpoint = () => {
  if (window.location.hostname === "localhost") {
    firebase.functions().useFunctionsEmulator("localhost:5001");
  }
  return firebase.functions();
}

const config = () => {
  const user = store.getState().loggedInUser;
  let apiToken = "";
  // if (user !== null) {
  //   apiToken = user.api_token;
  // }
  return {
    timeout: 30000,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + apiToken,
    },
  };
};

export const getUserRole = async (userId: string): Promise<UserRole> => {
  const ref = db.ref("user_roles").child(userId);
  return (await ref.once("value")).val() as UserRole;
};

export const getUserChilds = async (uid: string): Promise<string[] | null> => {
  const childRef = db.ref("user_childs").child(uid);
  const userChilds = (await childRef.once("value")).val();
  if (userChilds !== null) {
    const childIds = Object.keys(userChilds);
    return childIds;
  } else {
    return null;
  }
};

export const updateUserChild = async (
  centerUid: string,
  centerUserUid: string
): Promise<null> => {
  try {
    const ref = db.ref("user_childs").child(centerUid).child(centerUserUid);
    return await ref.set(true);
  } catch (e) {
    return e;
  }
};

export const getUserDemoMissions = async (
  uid: string
): Promise<UserDemoMissions | null> => {
  const ref = db.ref("user_demo_missions").child(uid);
  const demoMissions = (await ref.once("value")).val();
  if (demoMissions !== null) {
    return demoMissions as UserDemoMissions;
  } else {
    return null;
  }
};

export const updateUserDemoMissions = async (
  uid: string,
  demoMissions: UserDemoMissions
): Promise<null> => {
  try {
    const ref = db.ref("user_demo_missions").child(uid);
    return await ref.set(demoMissions);
  } catch (e) {
    return e;
  }
};

export const updateUserMissionHighestLevel = async (uid: string, obj: { [key: string]: number }) => {
  await Promise.all(Object.entries(obj).map(async ([key, value]) => {
    await db.ref(`user_mission_summary/${uid}/${key}/level`).set(value);
  }));
}

export const getProfileMissions = async (
  uid: string
): Promise<UserDemoMissions | null> => {
  const ref = db.ref("profile_missions").child(uid);
  const demoMissions = (await ref.once("value")).val();
  if (demoMissions !== null) {
    return demoMissions as UserDemoMissions;
  } else {
    return null;
  }
};

export const updateProfileMissions = async (
  uid: string,
  demoMissions: UserDemoMissions
): Promise<null> => {
  try {
    const ref = db.ref("profile_missions").child(uid);
    return await ref.set(demoMissions);
  } catch (e) {
    return e;
  }
};

export const getUserHighestLevel = async (uid: string) => {
  try {
    const result = await db.ref("user_mission_summary").child(uid).once("value");
    return result.val();
  } catch (e) {
    return e;
  }
}

export const getUserScore = async (uid: string) => {
  try {
    const result = await db.ref("user_score").child(uid).once("value");
    return result.val().score;
  } catch (e) {
    console.error(e);
    return 0;
  }
}

export const getUserProfiles = async (
  user: firebase.User
): Promise<UserProfile[]> => {
  const childRef = db.ref("user_childs").child(user.uid);
  const userChilds = (await childRef.once("value")).val();
  let combinedUserProfiles: UserProfile[] = [];
  if (userChilds !== null) {
    const userIds = Object.keys(userChilds);
    const userProfiles = await Promise.all(
      userIds.map((userId) => {
        return getUserProfile(userId);
      })
    );
    const _userProfiles = userProfiles.filter(
      (x): x is UserProfile => x !== null
    );
    combinedUserProfiles = [...combinedUserProfiles, ..._userProfiles];
  }
  const ownUserProfile = await getUserProfile(user.uid);

  if (ownUserProfile !== null) {
    combinedUserProfiles = [...combinedUserProfiles, ownUserProfile];
  }
  return combinedUserProfiles;
};

export const getAllCenters = async (): Promise<string[] | null> => {
  const trialCenterRef = db
    .ref("user_roles")
    .orderByChild("role")
    .equalTo("trial_center");
  const centerRef = db.ref("user_roles").orderByChild("role").equalTo("center");
  const obj1 = (await trialCenterRef.once("value")).val();
  const obj2 = (await centerRef.once("value")).val();
  const centerArr = Object.keys(obj1).concat(Object.keys(obj2));
  return centerArr;
};

export const getAllUserProfiles = async (): Promise<UserProfile[]> => {
  const ref = db.ref("user_profiles");
  const obj = (await ref.once("value")).val();
  const userProfiles = [];
  for (let [key, val] of Object.entries(obj)) {
    const userProfile = val as UserProfile;
    userProfile.user_id = key;
    userProfiles.push(userProfile);
  }
  return userProfiles;
};

export const getUserProfile = async (
  userId: string
): Promise<UserProfile | null> => {
  const profileRef = db.ref("user_profiles").child(userId);
  return (await profileRef.once("value")).val() as UserProfile;
};

export const getUserParent = async (id: string): Promise<string | null> => {
  const snapshot = await db.ref(`user_parents/${id}`).once("value");
  return snapshot.exists() ? snapshot.val() : null;
};

export const getUserParentName = async (id: string): Promise<string | null> => {
  const snapshot = await db.ref(`user_parents/${id}`).once("value");
  const parentId = snapshot.exists() ? snapshot.val() : null;

  if (parentId === null) return null;
  else {
    const nameSnapshot = await db.ref(`user_profiles/${parentId}/name`).once("value");
    return nameSnapshot.exists() ? nameSnapshot.val() : parentId;
  }
};

export const getUserParents = async (): Promise<
  { userId: string; centerId: string }[] | null
> => {
  const ref = db.ref("user_parents");
  const data = (await ref.once("value")).val();
  if (data !== null) {
    return Object.entries(data).map(([childId, parentId]) => {
      return {
        userId: childId,
        centerId: parentId as string
      }
    });
  } else {
    return null;
  }
};

export const getUserRoles = async (): Promise<UserRole[]> => {
  const ref = db.ref("user_roles");
  const temp = (await ref.once("value")).val();
  let data: UserRole[] = [];
  if (temp !== null) {
    Object.keys(temp).forEach(function (item) {
      data.push({ uid: item, role: temp[item].role });
    });
  }
  return data;
};

export const getAccountInfos = async (): Promise<AccountInfo[]> => {
  const ref = db.ref("account_infos");
  const temp = (await ref.once("value")).val();
  let data: AccountInfo[] = [];
  if (temp !== null) {
    Object.keys(temp).forEach(function (item) {
      const newData = { ...temp[item], uid: item };
      data.push(newData);
    });
  }
  return data;
};

export const getUserValidity = async (uid: string): Promise<UserValidity> => {
  const validRef = db.ref("user_validities").child(uid);
  const data = (await validRef.once("value")).val();
  return data;
};

export const getUserValidities = async (): Promise<UserValidity[]> => {
  const validRef = db.ref("user_validities");
  const data = (await validRef.once("value")).val();
  let userValidities: UserValidity[] = [];
  for (let [key, value] of Object.entries(data)) {
    const _value = value as any;
    userValidities.push({
      uid: key,
      is_valid: _value.is_valid,
      valid_till: _value.valid_till,
    });
  }
  return userValidities;
};

export const updateUserValidity = async (
  userValidity: UserValidity
): Promise<null> => {
  try {
    const ref = db.ref("user_validities").child(userValidity.uid);
    return await ref.set({
      is_valid: userValidity.is_valid,
      valid_till: userValidity.valid_till,
    });
  } catch (e) {
    return e;
  }
};

export const updateUserRole = async (userRole: UserRole): Promise<null> => {
  try {
    //update
    const ref = db.ref("user_roles").child(userRole.uid);
    return await ref.set({
      role: userRole.role,
    });
  } catch (e) {
    return e;
  }
};

export const getUserGameFrequency = async (userID: string) => {
  const ref = db.ref("user_mission_frequency").child(userID);
  const data = (await ref.once("value")).val() as UserMissionFrequency;
  let sum = 0;
  if (data !== undefined && data !== null) {
    Object.values(data).forEach((x) => {
      sum += x;
    });
  }
  return sum;
};

export const getUserMissionSummary = async (uid: string) => {
  const ref = db.ref("user_mission_summary").child(uid);
  const data = (await ref.once("value")).val() as UserMissionSummary;
  return data;
};

export const getMissionData = async (
  userID: string,
  missionType: MissionType
) => {
  // Legacy logic

  // const missionDataRef = db
  //   .ref("mission_data")
  //   .child(missionType)
  //   .orderByChild("user_id")
  //   .equalTo(userID);
  // const dataRef = await (await missionDataRef.once("value")).val();
  // const data: AllMissionDataType[] = [];
  // if (dataRef !== null) {
  //   Object.keys(dataRef).forEach(function (item) {
  //     data.push(dataRef[item]);
  //   });
  // }
  // return data;

  const userMissionDataOfTypeFunc = functions.httpsCallable('userMissionByKey');
  const result = await userMissionDataOfTypeFunc({ "user_id": userID, "key": missionType });
  return result.data as AllMissionDataType[];
};

export const getAllMissionDataOfUser = async (
  userID: string,
) => {
  console.time("GetMissionDataOfUser");
  const userMissionDataOfTypeFunc = functions.httpsCallable('userAllMissions');
  const result = await userMissionDataOfTypeFunc({ "user_id": userID });
  console.timeEnd("GetMissionDataOfUser");
  return result.data as AllMissionDataType[];
};


export const getAllMissionData = async (missionType: MissionType) => {
  const missionDataRef = db.ref("mission_data").child(missionType);
  const dataRef = await (await missionDataRef.once("value")).val();
  const data: AllMissionDataType[] = [];
  if (dataRef !== null) {
    Object.keys(dataRef).forEach(function (item) {
      data.push(dataRef[item]);
    });
  }
  return data;
};

export type MahjongBehaviorAverageData = {
  [key: string]: {
    average_time: number,
    average_wrong_rate: number
  }
}

export const getMahjongBehaviorAverage = async (): Promise<MahjongBehaviorAverageData | undefined> => {
  const response = await axios.get(
    firebaseFunctionsEndpoint() + `backend/fetchGameLogAverage`,
    config()
  );

  if ((response.status == 200 || response.status == 304) && response.data) {
    return response.data;
  }
  else
    return undefined;
}

export type CenterUserMissionScoreSnapshotData = {
  [key: string]: CenterUserMissionScoreSnapshotDataDetail
}

export type CenterUserMissionScoreSnapshotDataDetail = {
  name: string,

  a: number[]
  e: number[]
  m: number[]
  v: number[]
  p: number[]
  l: number[]

  t_a: number[]
  t_e: number[]
  t_m: number[]
  t_v: number[]
  t_p: number[]
  t_l: number[]

  overall: number[]
  t_overall: number[]
}

export type CenterUserMissionScoreSnapshotDataKeys = {
  a: number[]
  e: number[]
  m: number[]
  v: number[]
  p: number[]
  l: number[]

  t_a: number[]
  t_e: number[]
  t_m: number[]
  t_v: number[]
  t_p: number[]
  t_l: number[]

  overall: number[]
  t_overall: number[]
}

export const getCenterMissionScoreSnapshot = async (userId: string, year: string, month: string): Promise<CenterUserMissionScoreSnapshotData | undefined> => {
  const response = await axios.get(
    firebaseFunctionsEndpoint() + `backend/center-score/${userId}/${year}/${month}`,
    config()
  );

  if ((response.status == 200 || response.status == 304) && response.data) {
    return response.data;
  }
  else
    return undefined;
}

export const getMissionDomain = async (): Promise<MissionMetadata | null> => {
  const missionDataRef = db.ref("mission_domain");
  const result = await (await missionDataRef.once("value")).val();

  if (!result) return null;

  return <MissionMetadata>result;
};

export const getAccounts = async () => {
  const response = await axios.get(
    firebaseFunctionsEndpoint() + "backend/accounts",
    config()
  );
  const temp = (response.data as UserRecord[]).filter(
    (x) => x.disabled === false
  );
  return temp;
};

export const getDisplayName = async (userId: string): Promise<string> => {
  const response = await axios.get(
    firebaseFunctionsEndpoint() + "backend/account/" + userId,
    config()
  );
  return response.data.displayName;
};

export const updateUserProfile = async (
  userProfile: UserProfile,
  role: RoleType,
  login?: Login
): Promise<null | FirebaseAuthError | undefined> => {
  try {
    if (userProfile.user_id === "") {
      //create new user
      const loggedInUser = store.getState().loggedInUser;
      if (loggedInUser !== null) {
        const centerEmailDomain =
          loggedInUser.email !== null
            ? loggedInUser.email.substr(
              loggedInUser.email.indexOf("@"),
              loggedInUser.email.length - loggedInUser.email.indexOf("@")
            )
            : "@email.com";
        const randomEmail =
          Math.random().toString(36).substring(7) + centerEmailDomain;
        // const tempRole: RoleType =
        // role === "trial_center" ? "trial_center_user" : "center_user";
        const tempRole: RoleType = "center_user";
        const newRegister: Register = {
          ...{
            register_using: "email",
            user_role: tempRole,
            email: login !== undefined ? login.email : randomEmail,
            email_confirmation: login !== undefined ? login.email : randomEmail,
            password: login !== undefined ? login.password : randomEmail,
            password_confirmation:
              login !== undefined ? login.password : randomEmail,
          },
          ...userProfile,
        };
        const response = await axios.post(
          firebaseFunctionsEndpoint() + "backend/user/create",
          newRegister,
          config()
        );
        const createdUser = response.data;
        console.log(createdUser.uid);
        if (role === "admin") {
          if (userProfile.center !== undefined) {
            await updateUserChild(userProfile.center, createdUser.uid);
            const tempUserValidity = await getUserValidity(userProfile.center);
            if (tempUserValidity !== null) {
              await updateUserValidity({
                uid: createdUser.uid,
                is_valid: true,
                valid_till: tempUserValidity.valid_till,
              });
            }
          }
        } else {
          await updateUserChild(loggedInUser.uid, createdUser.uid);
        }

        return null;
      }
      return null;
    } else {
      //update
      if (userProfile.center !== undefined) {
        await updateUserChild(userProfile.center, userProfile.user_id);
        const { center, ...newUserProfile } = userProfile;
        const ref = db.ref("user_profiles").child(newUserProfile.user_id);
        await ref.set(newUserProfile);
      } else {
        const ref = db.ref("user_profiles").child(userProfile.user_id);
        await ref.set(userProfile);
      }
      return null;
    }
  } catch (e) {
    return Promise.reject(e);
  }
};

export const getGamePreference = async (
  userId: string
): Promise<GamePreference> => {
  if (typeof userId === "number") {
    userId = userId;
  }
  const ref = db.ref("user_game_preferences").child(userId);
  return (await ref.once("value")).val() as GamePreference;
};

export const postGamePreference = async (
  userId: string,
  gamePreference: GamePreference
): Promise<null> => {
  if (typeof userId === "number") {
    userId = userId;
  }
  const ref = db.ref("user_game_preferences").child(userId);
  return await ref.set(gamePreference);
};

export const getRequestDemo = async (
  id: string
): Promise<RequestDemo | null> => {
  const ref = db.ref("request_demos").child(id);
  const requestDemo: RequestDemo = (await ref.once("value")).val();
  if (requestDemo !== null) {
    return requestDemo;
  } else {
    return null;
  }
};

export const linkFakeEmail = async (newPassword: string) => {
  const currentUser = firebase.auth().currentUser;
  if (currentUser !== null) {
    const fakeEmail = currentUser.phoneNumber + "@neurogym-phone.com";
    const fakeMailCredential = firebase.auth.EmailAuthProvider.credential(fakeEmail, newPassword);
    firebase.auth().currentUser!.linkWithCredential(fakeMailCredential).then(async (cred) => {
      console.log("Successfully linked phone with email.");
      try {
        await verifyFakeEmail(fakeEmail);
        console.log("Successfully verified email.");
      }
      catch (e) {
        console.log("Fail to verify email. Reason:");
        console.warn(e);
      }
    }).catch((reason) => {
      console.log("Fail to linked phone with email. Reason: ");
      console.warn(reason);
    });
  }
}

export const register = async (register: Register): Promise<null> => {
  if (register.code !== undefined) {
    const requestDemo = await getRequestDemo(register.code);
    if (requestDemo !== null) {
      if (requestDemo.is_approved === false) {
        //Demo request is not approved
        throw new Error("Unknown error occurred. (89)");
      }
      if (requestDemo.created_user_id !== 0) {
        //Request is approved and user account is created
        throw new Error("Account is already created");
      }
    }
  }
  let currentUser;
  if (register.register_using === "email") {
    const userCredential = await firebase
      .auth()
      .createUserWithEmailAndPassword(register.email, register.password);
    if (userCredential.user !== null) {
      currentUser = userCredential.user;
    }
  } else {
    console.log("register using phone");
    currentUser = firebase.auth().currentUser;

    // Link a fake email to the phone number account
    if (currentUser !== null && register.email !== undefined) {
      const fakeEmail = register.email + "@neurogym-phone.com";
      const fakeMailCredential = firebase.auth.EmailAuthProvider.credential(fakeEmail, register.password);
      firebase.auth().currentUser!.linkWithCredential(fakeMailCredential).then(async (cred) => {
        console.log("Successfully linked phone with email.");
        try {
          await verifyFakeEmail(fakeEmail);
          console.log("Successfully verified email.");
        }
        catch (e) {
          console.log("Fail to verify email. Reason:");
          console.warn(e);
        }
      }).catch((reason) => {
        console.log("Fail to linked phone with email. Reason: ");
        console.warn(reason);
      });
    }
  }
  if (currentUser !== undefined && currentUser !== null) {
    await updateFirebaseAccountInfo({
      uid: currentUser.uid,
      displayName: register.name,
    });
    const userProfile: UserProfile = {
      user_id: currentUser.uid,
      name: register.name,
      gender: register.gender,
      year_of_birth: register.year_of_birth,
      avatar_url: "",
      is_dementia_mode: false,
    };
    if (register.occupation) {
      userProfile.occupation = register.occupation;
    }
    if (register.use_smartphone_level) {
      userProfile.use_smartphone_level = register.use_smartphone_level;
    }
    if (register.education_level) {
      userProfile.education_level = register.education_level;
    }
    if (register.mahjong_level) {
      userProfile.mahjong_level = register.mahjong_level;
    }
    db.ref("/user_profiles").child(currentUser.uid).set(userProfile);

    if (register.code === undefined) {
      if (register.register_using === "email") {
        currentUser.sendEmailVerification();
      }
    } else {
      try {
        await axios.post(
          firebaseFunctionsEndpoint() + "backend/user/email/verify",
          { email: register.email },
          config()
        );
      } catch (e) {
        console.log(e);
      }
      const ref = db.ref("request_demos").child(register.code);
      ref.update({
        created_uid: currentUser.uid,
        created_user_at: moment().valueOf(),
      });
    }
    await updateUserRole({ uid: currentUser.uid, role: register.user_role });
    let newAccountInfo: AccountInfo = { uid: currentUser.uid };
    if (register.referralCode !== undefined) {
      newAccountInfo = {
        ...newAccountInfo,
        referralCode: register.referralCode,
      };
    }
    if (register.phone !== undefined) {
      newAccountInfo = { ...newAccountInfo, phone: register.phone };
    }
    if (register.quota !== undefined) {
      newAccountInfo = { ...newAccountInfo, quota: register.quota };
    }
    await updateAccountInfo(newAccountInfo);
    if (
      register.user_role === "trial_individual" ||
      register.user_role === "trial_center"
    ) {
      const validTill = moment().add(14, "d").hour(23).minute(59).valueOf();
      await updateUserValidity({
        uid: currentUser.uid,
        valid_till: validTill,
        is_valid: true,
      });
    }
  }
  firebase.auth().signOut();
  return null;
};

export const adminRegister = async (register: Register) => {
  try {
    const response = await axios.post(
      firebaseFunctionsEndpoint() + "backend/user/create",
      register,
      config()
    );
    return response.data;
  } catch (e) {
    throw e;
  }
};

export const resendRequestDemoConfirmationEmail = async (
  requestDemoId: string | undefined
) => {
  if (requestDemoId !== undefined) {
    const requestDemo = await getRequestDemo(requestDemoId);
    if (requestDemo !== null) {
      if (requestDemo.is_approved === false) {
        await sendEmail(
          generateRequestDemoEmail(requestDemo.email, requestDemo.name)
        );
        await sendEmail(
          generateRequestDemoNotificationEmail(requestDemo.email, requestDemo.name)
        );
      } else if (
        requestDemo.is_approved === true &&
        requestDemo.created_user_id === 0
      ) {
        await sendEmail(
          generateRequestDemoApprovedEmail(
            requestDemo.email,
            requestDemo.name,
            requestDemoId
          )
        );
      }
    }
  }
};

export const submitRequestDemo = async (
  register: RequestDemo
): Promise<null> => {
  console.log(register);
  try {
    const isUserEmailExist = await checkUserEmailExist(register.email);
    const isRequestDemoEmailExist = await checkRequestDemoEmailExist(
      register.email
    );

    if (isRequestDemoEmailExist === false && isUserEmailExist === false) {
      await db.ref("request_demos").push(register);
      await sendEmail(generateRequestDemoEmail(register.email, register.name));
      await sendEmail(
        generateRequestDemoNotificationEmail(register.email, register.name)
      );
      return null;
    } else {
      throw { message: "Email is already registered." };
    }
  } catch (e) {
    throw e;
  }
};

export const verifyCenterEmail = async (id: string) => {
  await axios.post(firebaseFunctionsEndpoint() + "backend/user/verify/id", { id: id });
}

export const verifyFakeEmail = async (email: string) => {
  await axios.post(firebaseFunctionsEndpoint() + "backend/user/verify/email", { email: email });
}

export const updateRequestDemo = async (
  uid: string,
  data: RequestDemo
): Promise<null> => {
  try {
    if (data.approveStatus !== undefined) {
      const { approveStatus, id, ...newData } = data;
      const ref = db.ref("request_demos").child(uid);
      await ref.set(newData);
    } else {
      const ref = db.ref("request_demos").child(uid);
      await ref.set(data);
    }
    return null;
  } catch (e) {
    throw new Error(e);
  }
};

export const getRequestDemos = async (): Promise<RequestDemo[]> => {
  const ref = db.ref("request_demos");
  const obj = (await ref.once("value")).val();
  const requestDemos = [];
  for (let [key, val] of Object.entries(obj)) {
    const requestDemo = val as RequestDemo;
    requestDemo.id = key;
    requestDemos.push(requestDemo);
  }
  return requestDemos;
};

export const postApproveDemo = async (id: string): Promise<null> => {
  if (typeof id === "number") {
    id = id;
  }
  const ref = db.ref("request_demos").child(id);
  const requestDemo = (await ref.once("value")).val() as RequestDemo;
  try {
    await sendEmail(
      generateRequestDemoApprovedEmail(requestDemo.email, requestDemo.name, id)
    );
    ref.child("is_approved").set(true);
    return null;
  } catch (e) {
    console.log(e);
    throw e;
  }
};

export const checkUserPhoneNumberExist = async (
  email: string
): Promise<boolean> => {
  const response = await axios.post(
    firebaseFunctionsEndpoint() + "backend/user/phonenumber/exist",
    { phone_number: email },
    config()
  );
  const exist = response.data;
  console.log(exist);
  return exist.isExist;
};

const checkUserEmailExist = async (email: string): Promise<boolean> => {
  const response = await axios.post(
    firebaseFunctionsEndpoint() + "backend/user/email/exist",
    { email: email },
    config()
  );
  const emailExist = response.data;
  return emailExist.isExist;
};

const checkRequestDemoEmailExist = async (email: string): Promise<boolean> => {
  const ref = db.ref("request_demos").orderByChild("email").equalTo(email);
  const val = await (await ref.once("value")).val();
  if (val === null) {
    return false;
  } else {
    return true;
  }
};

export const sendEmail = async (email: Email) => {
  return await axios.post(
    firebaseFunctionsEndpoint() + "backend/sendemail",
    email,
    config()
  );
};

export const generateRequestDemoEmail = (to: string, name: string): Email => {
  let body = "親愛的" + name + ",<br><br>";
  body +=
    "感謝閣下申請醫念科技的「腦有記」機構試用版，我們會在5個工作天內確認你的申請。<br>";
  body +=
    "我們已經收到您的申請，為提供更適合您們的服務組合，我們稍後會根據您填寫的聯絡方式與您電話聯絡。 <br>";
  body +=
    "當審核完成後，您將會收到電郵通知。以下為此應用程式的簡介/相關資源，方便你了解本程式：<br>";
  body +=
    '腦有記單張: <a href="https://drive.google.com/file/d/1oiCZ5uRrEiH5NRdM3QfAMkmleaF9WS9w/view?usp=sharing">Google雲端</a><br>'
  body +=
    '腦有記簡單介紹: <a href="https://youtu.be/tUaNM-TxZhQ">Youtube影片</a><br>'
  body +=
    '腦有記書法遊戲訓練: <a href="https://youtu.be/p4NRxI2_qYo">Youtube影片</a><br>'
  body +=
    '腦有記麻雀遊戲訓練: <a href="https://youtu.be/6t6bVzVW2Bo">Youtube影片</a><br>'
  body +=
    '遙距訓練及線上開組: <a href="https://drive.google.com/file/d/18l6HkI15f9SdM1rdTK7IfYKFPHDRR2QN/view?usp=sharing">Google雲端</a><br><br>'

  body +=
    '如有任何問題，歡迎電郵至<a href="mailto:info@medmindtechnology.hk">info@medmindtechnology.hk</a> 、whatsapp或致電 90337986 Hayley Tam/ 90403834 Nathan Hui聯絡。<br><br>';
  body += "Nathan Hui<br>";
  body += "營運總監<br>";
  body += "醫念科技謹啟<br>";
  body += "香港科學園科技大道西 19 號 19 座 5 樓 552 室<br>";
  body +=
    '電郵：<a href="mailto:info@medmindtechnology.hk">info@medmindtechnology.hk</a><br>';
  body +=
    '<a href="https://www.medmindtechnology.hk">www.medmindtechnology.hk</a><br>';
  body +=
    'Facebook: <a href="https://www.facebook.com/LUGTSA">MedMind Technology</a><br>';
  body +=
    "此電子郵件的內容是機密的，僅供郵件中指定的收件人使用。未經發件人書面同意，請勿與任何第三方分享此消息的任何部分。如果您錯誤地收到了此消息，請回覆此電郵，以便我們改善日後服務質素。";
  return {
    to: to,
    subject: "「腦有記」試玩版申請",
    body: body,
  } as Email;
};

export const generateRequestDemoNotificationEmail = (to: string, name: string): Email => {
  let body = `用戶 「${name}」(${to}) 已透過客戶端登記申請「腦有記」試玩版。請前往portal確認申請。<br>`;
  return {
    to: "info@medmindtechnology.hk",
    subject: `${name}(${to}) 已登記申請「腦有記」試玩版`,
    body: body,
  } as Email;
};

const generateRequestDemoApprovedEmail = (
  to: string,
  name: string,
  id: string
): Email => {
  let body = "親愛的" + name + ",<br><br>";
  let portalUrl = "";
  if (
    process.env.REACT_APP_ENVIRONMENT !== "test" &&
    process.env.NODE_ENV === "production"
  ) {
    portalUrl = "https://neurogym-prod.web.app";
  } else {
    portalUrl = "http://portal.test.medmindtechnology.hk";
  }
  body +=
    "閣下已成功登記「腦有記」機構試用版，系統已更新，請到以下連結下載最新版本。<br>";
  body += 'iOS: <a href="https://apple.co/2SiuLCJ">Apple Store</a><br>';
  body += 'Google Play: <a href="http://bit.ly/2SBASAW">Google Play</a><br>';
  body += "或搜尋：Neurogym 腦有記<br><br>";

  body +=
    '設定密碼完成註冊，請按以下連結: <a href="' +
    portalUrl +
    // "/register?code=" + 
    "/requestDemo?code=" +
    id +
    '">完成註冊</a><br><br>';

  body +=
    '登入/遊戲的影片教學，請按以下連結：<a href="https://drive.google.com/file/d/15oz0ttzEdLoejLfN8dnk3sDYZneyFl48/view?usp=sharing">教學</a><br><br>';
  body +=
    "為讓你更易掌握整個訓練程式的設定與運用，我們的團隊將會與你聯絡，希望與你進行一次視像會議。<br><br>";
  body +=
    "完整版有更多的訓練內容，主題和報告詳情，我們樂意與你們會面作更全面的產品服務介紹。歡迎查詢/預約，請電郵至info@medmindtechnology.hk 或致電 9033 7986 Hayley Tam／9040 3834 Nathan Hui聯絡。<br><br>";

  body += "Hayley Tam<br>";
  body += "業務拓展總監謹啟<br>";
  body += "醫念科技<br>";
  body += "香港科學園科技大道西 19 號 19 座 5 樓 552 室<br>";
  body += "電話：＋852 9033 7986<br>";
  body +=
    '電郵：<a href="mailto:info@medmindtechnology.hk">info@medmindtechnology.hk</a><br>';
  body +=
    '<a href="www.medmindtechnology.hk">www.medmindtechnology.hk</a><br><br>';
  body +=
    "此電子郵件的內容是機密的，僅供郵件中指定的收件人使用。未經發件人書面同意，請勿與任何第三方分享此消息的任何部分。如果您錯誤地收到了此消息，請回覆此電郵，以便我們改善日後服務質素。";

  return {
    to: to,
    subject: "「腦有記」試玩版確認通知",
    body: body,
  } as Email;
};

export const resetPassword = async (email: string) => {
  firebase
    .auth()
    .sendPasswordResetEmail(email)
    .then(function () {
      console.log("email sent");
    })
    .catch(function (error) {
      // An error happened.
      console.log(error);
    });
};

export const updatePassword = async (data: {
  uid: string;
  password: string;
}) => {
  try {
    const response = await axios.put(
      firebaseFunctionsEndpoint() + "backend/accounts/update",
      data,
      config()
    );
    console.log(response);
  } catch (e) {
    console.log(e);
    if (e.response) {
      throw e.response.data;
    } else {
      throw e;
    }
  }
};

export const updateFirebaseAccountInfo = async (data: {
  uid: string;
  email?: string;
  displayName?: string;
  disabled?: boolean;
}) => {
  try {
    const response = await axios.put(
      firebaseFunctionsEndpoint() + "backend/accounts/update",
      data,
      config()
    );
    console.log(response);
  } catch (e) {
    console.log(e);
    if (e.response) {
      throw e.response.data;
    } else {
      throw e;
    }
  }
};

export const deleteUserProfile = async (uid: string) => {
  const ref = db.ref("user_profiles").child(uid);
  const obj = (await ref.once("value")).val();
  db.ref("deleted_user_profiles/" + uid).set(obj);
  return await ref.remove();
};

export const updateAccountInfo = async (data: AccountInfo): Promise<null> => {
  try {
    //update
    const ref = db.ref("account_infos").child(data.uid);
    const { uid, ...newData } = data;
    return await ref.set(newData);
  } catch (e) {
    return e;
  }
};

export const getAccountInfo = async (uid: string): Promise<AccountInfo> => {
  const ref = db.ref("account_infos").child(uid);
  return (await ref.once("value")).val() as AccountInfo;
};

export const postAnnouncement = async (data: Announcement) => {
  try {
    console.log(data);
    await db.ref("announcements").push(data);
    return null;
  } catch (e) {
    return e;
  }
};

export const updateAnnouncement = async (data: Announcement) => {
  try {
    if (data.code !== undefined) {
      const ref = db.ref("announcements").child(data.code);
      const { code, ...newData } = data;
      return await ref.set(newData);
    }
  } catch (e) {
    return e;
  }
};

export const deleteAnnouncement = async (id: string) => {
  const ref = db.ref("announcements").child(id);
  return await ref.remove();
};

export const getAnnouncements = async (
  uid: string
): Promise<Announcement[] | undefined> => {
  const ref = db.ref("announcements");
  const tempRef = ref.orderByChild("receivers/" + uid).equalTo(true);
  const dataRef = await (await tempRef.once("value")).val();
  const data: Announcement[] = [];
  if (dataRef != null) {
    let temp: Announcement = {
      message: "",
      createdBy: "",
      createdAt: 0,
      title: "",
      receivers: { "": false },
    };
    Object.keys(dataRef).forEach(function (item) {
      temp = {
        code: item,
        message: dataRef[item].message,
        createdBy: dataRef[item].createdBy,
        createdAt: dataRef[item].createdAt,
        title: dataRef[item].title,
        receivers: dataRef[item].receivers,
      };
      data.push(temp);
    });
  }
  const result = await Promise.all(
    data.map(async (x) => {
      let tempComment: Comment[] = [];
      if (x.code !== undefined) {
        tempComment = await getComments(x.code);
      }
      if (tempComment !== undefined && tempComment.length !== 0) {
        return { ...x, comments: tempComment };
      } else {
        return x;
      }
    })
  );
  return result;
};

export const postComment = async (data: Comment) => {
  try {
    await db.ref("announcement_comments").push(data);
    return null;
  } catch (e) {
    return e;
  }
};

export const updateComment = async (data: Announcement[]) => {
  try {
    // const ref = db.ref("announcement_comments").child();
    // return await ref.set(data);
  } catch (e) {
    return e;
  }
};

export const getComments = async (id: string): Promise<Comment[]> => {
  const ref = db
    .ref("announcement_comments")
    .orderByChild("announcementId")
    .equalTo(id);
  const dataRef = await (await ref.once("value")).val();
  const data: Comment[] = [];
  if (dataRef != null) {
    Object.keys(dataRef).forEach(function (item) {
      data.push(dataRef[item]);
    });
  }
  return data;
};

export const getRequestDemoByEmail = async (
  email: string
): Promise<RequestDemo | null> => {
  const ref = db.ref("request_demos").orderByChild("email").equalTo(email);
  const data = await (await ref.once("value")).val();
  if (data !== null) {
    const result = Object.values(data)[0];
    return result as RequestDemo;
  } else {
    return null;
  }
};

export const getRequestDemoByPhone = async (
  phone: string
): Promise<RequestDemo | null> => {
  const ref = db
    .ref("request_demos")
    .orderByChild("contact_number")
    .equalTo(phone);
  const data = await (await ref.once("value")).val();
  if (data !== null) {
    const result = Object.values(data)[0];
    return result as RequestDemo;
  } else {
    return null;
  }
};

export const getGameLogById = async (
  matchId: string
): Promise<GameLog | undefined> => {
  const snapshot = await db.ref(`game_log/${matchId}`).once("value");
  if (snapshot.exists()) {
    return { ...snapshot.val(), id: matchId };
  }
  else {
    return undefined;
  }
};

export const getUserGameLog = async (
  uid: string
): Promise<GameLog[] | undefined> => {
  const response = await axios.get(
    firebaseFunctionsEndpoint() + `backend/gamelog/${uid}`,
    config()
  );

  if ((response.status == 200 || response.status == 304) && response.data) {
    return response.data;
  }
  else
    return undefined;
};

export const getUserGameLogRankMode = async (uid: string): Promise<GameLog[] | undefined> => {
  const ref = db.ref("game_log_rank_mode");
  const dataRef = await (await ref.once("value")).val();
  const data: GameLog[] = [];
  if (dataRef !== null) {
    Object.keys(dataRef).forEach(function (item) {
      data.push({ ...dataRef[item], id: item });
    });
  }
  return data;
};

export const getUserCalligraphyData = async (uid: string): Promise<CalligraphyRecordEntry[] | undefined> => {
  const ref = db.ref(`calligraphy_data/${uid}`);
  const dataRef = (await ref.once("value")).val();
  const data: CalligraphyRecordEntry[] = [];
  if (dataRef !== null) {
    Object.values(dataRef).forEach(function (item) {
      data.push(<CalligraphyRecordEntry>item);
    });
  }

  console.log(data);
  return data;
};

export const getUserGameBehaviorLog = async (uid: string): Promise<GameBehaviorLog[] | undefined> => {
  const response = await axios.get(
    firebaseFunctionsEndpoint() + `backend/game_behavior_log/${uid}`,
    config()
  );

  if ((response.status == 200 || response.status == 304) && response.data) {
    return response.data;
  }
  else
    return undefined;
};

export const getSpecificGameBehaviorLog = async (matchId: string): Promise<GameBehaviorLog | null> => {
  const ref = await db.ref(`game_behavior_log/${matchId}`).once("value");

  if (ref.exists()) {
    const dataRef = ref.val();
    console.log(dataRef);
    return dataRef;
  }
  else {
    return null;
  }
};

export const getUserGameBehaviorLogRankMode = async (): Promise<GameBehaviorLog[] | undefined> => {
  // const ref = db.ref("game_behavior_log").orderByChild("user_id").equalTo(uid);
  const ref = db.ref("game_behavior_log_rank_mode");
  const dataRef = await (await ref.once("value")).val();
  // console.log(dataRef);
  const data: GameBehaviorLog[] = [];
  if (dataRef !== null) {
    Object.keys(dataRef).forEach((item) => {
      data.push({ ...dataRef[item], id: item });
    });
  }
  return data;
};

export const getImageFromFirebaseStorage = async (path: string, fileName: string): Promise<string | null> => {
  var storage = firebase.storage();
  var pathReference = storage.ref(`${path}/${fileName}${path === "FaiChunDrawings" ? ".png" : ".jpg"}`);

  return pathReference.getDownloadURL().then(function (url) {
    return url;
  }).catch(function (error) {
    return null;
  });
}

export const getDisableInfo = async (uid: string) => {
  const contentResult = await db.ref(`disable_content/${uid}`).once("value");
  const deviceResult = await db.ref(`disable_device/${uid}`).once("value");
  return {
    contentDisabled : contentResult.exists() ? Object.keys(contentResult.val()) : [],
    deviceDisabled : deviceResult.exists() ? Object.keys(deviceResult.val()) : []
  }
}

export const updateDisableInfo = async (uid: string, disabledInfo : DisabledInfo) => {
  const contentJson = disabledInfo.contentDisabled.reduce<{ [key: string]: boolean }>((buffer, topic) => {
    buffer[topic] = true;
    return buffer;
  }, {});
  const deviceJson = disabledInfo.deviceDisabled.reduce<{ [key: string]: boolean }>((buffer, topic) => {
    buffer[topic] = true;
    return buffer;
  }, {});
  console.log("Updated Info: "+contentJson);
  console.log("Updated Info: "+deviceJson);
  await db.ref(`disable_content/${uid}`).set(contentJson);
  await db.ref(`disable_device/${uid}`).set(deviceJson);
}

export const getUserNameById = async (uid: string): Promise<string | undefined> => {
  const snapshot = await db.ref(`user_profiles/${uid}/name`).once("value");
  return snapshot.exists() ? snapshot.val() : undefined;
}

export const deleteMissionRecord = async (uid: string | undefined, missionKey: string, missionPushKey: string): Promise<boolean> => {
  if (uid === undefined || missionKey === undefined || missionPushKey === undefined) return false;

  try {
    // Delete the record in mission_data
    await db.ref(`mission_data/${missionKey}/${missionPushKey}`).remove();

    await db.ref(`user_mission_history/${uid}/${missionKey}/${missionPushKey}`).remove();
  }
  catch (error) {

  }

  return true;
}

export const getMissionSchedule = async (uid: string): Promise<any> => {

  try {
    const data = await db.ref(`user_mission_schedule/${uid}`).once("value");
    return data.val();
  }
  catch (e) {
    return undefined;
  }
}

export const saveMissionSchedule = async (uid: string, data: any): Promise<boolean> => {

  try {
    await db.ref(`user_mission_schedule/${uid}`).set(data);
    return true;
  }
  catch (e) {
    return false;
  }
}