import DateFnsUtils from "@date-io/date-fns";
import { Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormControlLabel, FormGroup, FormLabel, MenuItem, Radio, RadioGroup, TextField } from "@material-ui/core";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import _ from "lodash";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getDisableInfo, getUserChilds, getUserParentName, updateAccountInfo, updateDisableInfo, updateFirebaseAccountInfo, updateUserProfile, updateUserRole, updateUserValidity } from "../api";
import { AppState } from "../reducers";
import { LoadableAccountData } from "../reducers/accounts";
import { LoadableProfileData } from "../reducers/userProfiles";
import { AccountInfo } from "../types/AccountInfo";
import { DisabledInfo } from "../types/DisabledInfo";
import { Role, RoleType, UserRole } from "../types/UserRole";
import { UserValidity } from "../types/UserValidity";
import ButtonWithLoading from "./ButtonWithLoading";
import SimpleSnackBar, { SnackBarProp } from "./SimpleSnackBar";

interface EditAccountDialogProps {
  onClose: () => void,
  isOpen: boolean,
  uid: string
}

export default function EditAccountDialog(props: EditAccountDialogProps) {

  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const accounts = useSelector<AppState, LoadableAccountData>(state => state.accounts);
  const userProfiles = useSelector<AppState, LoadableProfileData>(state => state.userProfiles);
  const userRoles = useSelector<AppState, UserRole[] | null>(state => state.userRoles);
  const userValidities = useSelector<AppState, UserValidity[] | null>(state => state.userValidities);
  const accountInfos = useSelector<AppState, AccountInfo[] | null>(state => state.accountInfos);
  const [userValidity, setUserValidity] = useState<UserValidity>();
  const [userRole, setUserRole] = useState<UserRole>();
  const [email, setEmail] = useState<string | undefined>();
  const [name, setName] = useState<string | undefined>();
  const [accountInfo, setAccountInfo] = useState<AccountInfo | undefined>();
  const [userParentName, setUserParentName] = useState<string | null | undefined>();

  const [disableInfo, setDisableInfo] = useState<DisabledInfo>({contentDisabled : [], deviceDisabled: []});

  const closeErrorSnackBar = () => {
    setSnackBar({ ...snackBar, "open": false });
  }

  const [snackBar, setSnackBar] = useState<SnackBarProp>({
    open: false,
    onClose: closeErrorSnackBar,
    message: "",
    variant: undefined
  });

  useEffect(() => {
    if (accounts.kind === "loaded") {
      setEmail(accounts.data.filter(x => x.uid === props.uid)[0].email);
      setName(accounts.data.filter(x => x.uid === props.uid)[0].displayName);

      if (userRole?.role === "center" || userRole?.role === "trial_center" || userRole?.role === "center_user" || userRole?.role === "trial_center_user")
        _setUserParentName();

      if (userRoles !== null) {
        const tempRole = userRoles.filter(x => x.uid === props.uid)[0]
        if (tempRole !== undefined) {
          setUserRole(tempRole);
        } else {
          setUserRole({ uid: props.uid, role: "undefined" as RoleType })
        }
      }
      if (userValidities !== null) {
        const tempUserValidy = userValidities.find(x => x.uid === props.uid);
        if (tempUserValidy !== undefined) {
          setUserValidity(tempUserValidy);
        } else {
          setUserValidity({ uid: props.uid, is_valid: null, valid_till: null });
        }
      }
      if (accountInfos !== null) {
        const tempAccountInfo = accountInfos.find(x => x.uid === props.uid);
        if (tempAccountInfo !== undefined) {
          setAccountInfo(tempAccountInfo);
        } else {
          setAccountInfo({ uid: props.uid, referralCode: "", phone: "", quota: undefined });
        }
      }
      fetchDisabledInfo();
    }
  }, [props])

  const _setUserParentName = async () => {
    setUserParentName(await getUserParentName(props.uid));
  }

  const submitButtonClicked = async () => {
    setIsSubmitting(true);
    try {
      if (userRole !== undefined) {
        await updateUserRole(userRole);
        if (userRole.role === "center" && userRoles !== null) {
          const oldRole = userRoles.filter(x => x.uid === props.uid)[0]
          if (oldRole !== undefined && oldRole.role === "trial_center") {
            const childIds = await getUserChilds(props.uid);
            if (childIds !== null) {
              childIds.forEach(async child => {
                await updateUserRole({ uid: child, role: "center_user" });
              })
            }
          }
        } else if (userRole.role === "trial_center" && userRoles !== null) {
          const oldRole = userRoles.filter(x => x.uid === props.uid)[0]
          if (oldRole !== undefined && oldRole.role === "center") {
            const childIds = await getUserChilds(props.uid);
            if (childIds !== null) {
              childIds.forEach(async child => {
                // await updateUserRole({ uid: child, role: "trial_center_user" });
                await updateUserRole({ uid: child, role: "center_user" });
              })
            }
          }
        }
      }
      if (accountInfo !== undefined) {
        let newAccountInfo: AccountInfo = { uid: accountInfo.uid };
        if (accountInfo.referralCode !== "") {
          newAccountInfo = { ...accountInfo, referralCode: accountInfo.referralCode };
        }
        if (accountInfo.phone !== "") {
          newAccountInfo = { ...accountInfo, phone: accountInfo.phone };
        }
        if (accountInfo.quota !== undefined) {
          newAccountInfo = { ...accountInfo, quota: accountInfo.quota };
        }
        await updateAccountInfo(newAccountInfo);
      }
      if (userValidity !== undefined) {
        await updateUserValidity(userValidity);
        let tempAcInfo: {
          uid: string;
          email?: string;
          displayName?: string;
          disabled?: boolean;
        } = { uid: userValidity.uid };
        if (email !== undefined) {
          tempAcInfo = { ...tempAcInfo, email: email }
        }
        if (name !== undefined) {
          tempAcInfo = { ...tempAcInfo, displayName: name }
        }
        await updateFirebaseAccountInfo(tempAcInfo);
        if (userRole !== undefined && (userRole.role === 'trial_individual' || userRole.role === 'trial_center_user' || userRole.role === 'center_user')) {
          if (userProfiles.kind === 'loaded') {
            const tempProfile = userProfiles.data.find(x => x.user_id === userValidity.uid);
            if (tempProfile !== undefined && name !== undefined) {
              const result = await updateUserProfile({ ...tempProfile, name: name }, 'admin');
            }
          }
        }
      }
      await updateDisableInfo(props.uid, disableInfo);

      setIsSubmitting(false);
      props.onClose();
    } catch (e) {
      setSnackBar({ ...snackBar, open: true, message: e.message });
      setIsSubmitting(false);
      console.log(e);
    }
  }

  const handleIsValidChange = () => (event: React.ChangeEvent<HTMLInputElement>) => {
    if (userValidity !== undefined) {
      setUserValidity({ ...userValidity, is_valid: event.target.value === "true" });
    }
  }

  const handleValidTillChange = (temp: Date | null) => {
    if (userValidity !== undefined && temp !== null) {
      let date = temp;
      const tempTimestamp = Number(moment(date).hour(23).minute(59).format("x"));
      if (tempTimestamp > moment.utc().valueOf()) {
        setUserValidity({ ...userValidity, is_valid: true, valid_till: tempTimestamp });
      } else {
        setUserValidity({ ...userValidity, is_valid: false, valid_till: tempTimestamp });
      }
    }
  };

  const handleRoleChange = () => (event: React.ChangeEvent<HTMLInputElement>) => {
    if (userRole !== undefined) {
      setUserRole({ uid: props.uid, role: event.target.value as RoleType });
    }
  }

  const handleAccountInfoChange = (name: keyof AccountInfo) => (event: React.ChangeEvent<HTMLInputElement>) => {
    if (accountInfo !== undefined) {
      if (name === "quota") {
        setAccountInfo({ ...accountInfo, [name]: (parseInt(event.target.value)) });
      } else {
        setAccountInfo({ ...accountInfo, [name]: event.target.value });
      }
    }
  }

  const handleNameChange = () => (event: React.ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value);
  }

  const handleEmailChange = () => (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
  }

  const handleDisabledInfo = (name: string, checked: boolean, infoName: string) => {
    let info = {...disableInfo} as DisabledInfo;
    if (checked)
    //@ts-ignore
      info[infoName].push(name);
    else
    //@ts-ignore
      info[infoName] = info[infoName].filter(el => el !== name);
    setDisableInfo(info);
  }

  const fetchDisabledInfo = async () => {
    setDisableInfo(await getDisableInfo(props.uid));
  }

  return (
    <Dialog onClose={props.onClose} aria-labelledby="simple-dialog-title" open={props.isOpen}>
      <DialogTitle id="simple-dialog-title">更改帳戶</DialogTitle>
      <DialogContent>
        <>
          {
            (userRole?.role === "center" || userRole?.role === "trial_center" || userRole?.role === "center_user" || userRole?.role === "trial_center_user")
            && <div style={{ fontSize: 20, marginBottom: 10 }}>所屬團體：{userParentName === undefined ? "獲取機構名稱中" : userParentName === null ? "獲取機構名稱失敗" : userParentName}</div>
          }
          <TextField
            id="name"
            label="顯示名稱*"
            type="text"
            value={name}
            onChange={handleNameChange()}
            margin="normal"
            fullWidth
          />
          {email !== undefined && <TextField
            id="email"
            label="電郵地址*"
            type="text"
            value={email}
            onChange={handleEmailChange()}
            margin="normal"
            fullWidth
          />}
          {userValidity !== undefined &&
            <>
              <div style={{ paddingTop: 10 }}>
                <FormControl component="fieldset" >
                  <FormLabel component="legend">有效？</FormLabel>
                  <RadioGroup name="is_valid" value={userValidity.is_valid} onChange={handleIsValidChange()}>
                    <FormControlLabel value={true} control={<Radio color='primary' />} label="Yes" />
                    <FormControlLabel value={false} control={<Radio color='primary' />} label="No" />
                  </RadioGroup>
                </FormControl>
              </div>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  disableToolbar
                  variant="inline"
                  format="MM/dd/yyyy"
                  margin="normal"
                  id="date-picker-inline"
                  label="有效期至"
                  value={userValidity.valid_till}
                  onChange={handleValidTillChange}
                  fullWidth
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }} />
              </MuiPickersUtilsProvider>
            </>}
          {userRole !== undefined &&
            <TextField
              id="user_role"
              select
              label="帳戶身份"
              value={userRole.role}
              onChange={handleRoleChange()}
              fullWidth
              margin="normal"
            >
              {Role.map(role => (
                <MenuItem key={role} value={role}>
                  {role}
                </MenuItem>
              ))}
            </TextField>}
          {accountInfo !== undefined && userRole !== undefined && (userRole.role === "trial_center" || userRole.role === "center") &&
            < TextField
              id="quota"
              label="Quota"
              type="number"
              value={accountInfo.quota}
              onChange={handleAccountInfoChange('quota')}
              margin="normal"
              fullWidth
            />}
          {accountInfo !== undefined && userRole !== undefined && (userRole.role === "trial_center" || userRole.role === "center" || userRole.role === "trial_individual" || userRole.role === "individual") &&
            <FormControl component="fieldset">
              <FormGroup row>
                <FormLabel component="legend" style={{ fontSize: 14, width: "100%", marginTop: 20 }}>已禁用主題</FormLabel>
                <FormControlLabel control={<Checkbox name="Mahjong" color="primary" checked={disableInfo.contentDisabled.includes("Mahjong")} onChange={(e, v) => handleDisabledInfo("Mahjong", v, "contentDisabled")} />} label="麻雀" />
                <FormControlLabel control={<Checkbox name="Calligraphy" color="primary" checked={disableInfo.contentDisabled.includes("Calligraphy")} onChange={(e, v) => handleDisabledInfo("Calligraphy", v, "contentDisabled")} />} label="書法" />
                <FormControlLabel control={<Checkbox name="Minigame" color="primary" checked={disableInfo.contentDisabled.includes("Minigame")} onChange={(e, v) => handleDisabledInfo("Minigame", v, "contentDisabled")} />} label="小遊戲" />
                <FormControlLabel control={<Checkbox name="DailyMission" color="primary" checked={disableInfo.contentDisabled.includes("DailyMission")} onChange={(e, v) => handleDisabledInfo("DailyMission", v, "contentDisabled")} />} label="每日任務" />
              </FormGroup>
            </FormControl>
          }
          {accountInfo !== undefined && userRole !== undefined && (userRole.role === "trial_center" || userRole.role === "center" || userRole.role === "trial_individual" || userRole.role === "individual") &&
          <FormControl component="fieldset">
            <FormGroup row>
              <FormLabel component="legend" style={{ fontSize: 14, width: "100%", marginTop: 20 }}>已禁用機體</FormLabel>
              <FormControlLabel control={<Checkbox name="Desktop" color="primary" checked={disableInfo.deviceDisabled.includes("Desktop")} onChange={(e, v) => handleDisabledInfo("Desktop", v, "deviceDisabled")} />} label="桌面版" />
              <FormControlLabel control={<Checkbox name="Mobile" color="primary" checked={disableInfo.deviceDisabled.includes("Mobile")} onChange={(e, v) => handleDisabledInfo("Mobile", v, "deviceDisabled")} />} label="流動版" />
            </FormGroup>
          </FormControl>
          }
          {accountInfo !== undefined &&
            <TextField
              id="referralCode"
              label="推薦碼"
              type="text"
              value={accountInfo.referralCode}
              onChange={handleAccountInfoChange('referralCode')}
              margin="normal"
              fullWidth
            />}
          {accountInfo !== undefined &&
            <TextField
              id="phone"
              label="聯絡電話"
              type="text"
              value={accountInfo.phone}
              onChange={handleAccountInfoChange('phone')}
              margin="normal"
              fullWidth
            />}
        </>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.onClose} color="primary">取消</Button>
        <ButtonWithLoading onClick={submitButtonClicked} color="primary" isLoading={isSubmitting}>儲存</ButtonWithLoading>
      </DialogActions>
      <SimpleSnackBar open={snackBar.open} onClose={closeErrorSnackBar} message={snackBar.message} variant={snackBar.variant} />
    </Dialog >
  )
}