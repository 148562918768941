import { Container, Grid, useMediaQuery, useTheme } from "@material-ui/core";
import _ from 'lodash';
import React, { useEffect, useState } from "react";
import { Cell, Pie, PieChart, PolarAngleAxis, PolarGrid, PolarRadiusAxis, Radar, RadarChart, ResponsiveContainer, Tooltip } from "recharts";
import styled from "styled-components";
import { getColors } from "../resources/colors";
import { GridCard } from "../StyledComponents";
import { AllMissionDataType } from "../types/MissionData";
import { domains, DomainType, getChineseMission } from '../types/MissionType';
import { UserProfile } from "../types/UserProfile";
import DomainScatterChartTabs from "./DomainScatterChartTabs";

const COLORS = [['#ff3333', '#f9f9f9'], ['#9966cc', '#f9f9f9'], ['#66cc33', '#f9f9f9'], ['#ffcc33', '#f9f9f9'], ['#33ccff', '#f9f9f9'], ['#ff6699', '#f9f9f9']];

const getRandScore = (max = 99, min = 80): number => {
  return _.round((Math.random() * (max - min)) + min, 2);
};

export enum ScoreLevel {
  first = "1",
  second = "2",
  third = "3",
  forth = "4",
}

export enum ScoreLevelColor {
  first = "green",
  second = "#F9DDA4",
  third = "orange",
  forth = "red"
}
const StyledGridHeader3 = styled.div`
    padding-bottom: 10px;
    font-size: 16px;
    text-align: center;
    margin-left: -20px
`

const StyledGridRow2 = styled(Grid)`
    padding-top: 5px;
    padding-bottom: 40px;
    padding-left: 40px;
    padding-right: 40px;
`

const getScoreLevelColor = (scoreLevel: ScoreLevel): string => {
  if (scoreLevel === ScoreLevel.first) {
    return ScoreLevelColor.first;
  } else if (scoreLevel === ScoreLevel.second) {
    return ScoreLevelColor.second;
  } else if (scoreLevel === ScoreLevel.third) {
    return ScoreLevelColor.third;
  } else {
    return ScoreLevelColor.forth;
  }
}
const getScoreLevel = (score: number): ScoreLevel => {
  if (score > 85) {
    return ScoreLevel.first;
  } else if (score > 75) {
    return ScoreLevel.second;
  } else if (score > 70) {
    return ScoreLevel.third;
  } else {
    return ScoreLevel.forth;
  }
}

interface MissionTabProps {
  domainScore: { "domain": string, "chineseDomain": string, "score": number }[];
  missionData: AllMissionDataType[];
  selectedUser: UserProfile;
  userScore: number
}

const RenderPieChart = React.memo((props: { domainScore: { "domain": string, "chineseDomain": string, "score": number }[] }) => {
  console.log("rerender!")
  let pieChartData: { [score: string]: number }[] = [];
  let obj: { "domain": string, "chineseDomain": string, "score": number } | undefined = { "domain": "", "chineseDomain": "", "score": 0 };
  let point: number = 0;
  return (
    <Grid container spacing={0} style={{ display: "flex", alignItems: "center", marginTop: 20, marginBottom: 20 }} >
      {domains.map((domain, index) => {
        if (props.domainScore !== undefined) {
          obj = props.domainScore.find(x => x.domain === domain);
          pieChartData = toPieChartData(domain, props.domainScore);
          point = pieChartData[0].score;
        }
        return (
          <Grid item lg={2} md={4} sm={6} xs={6}>
            <ResponsiveContainer width="90%" height={140}>
              <PieChart>
                <Pie data={pieChartData} dataKey="score" innerRadius={40} outerRadius={50} animationBegin={400} startAngle={90} endAngle={450}>
                  {pieChartData.map((entry, _index) => <Cell fill={COLORS[index][_index]} />)}
                </Pie>
                <text x="50%" y={70} textAnchor="middle" dominantBaseline="middle">
                  {point.toFixed(0) + "%"}
                </text>
              </PieChart>
            </ResponsiveContainer>
            <StyledGridHeader3>{(obj !== undefined) ? obj.chineseDomain : NaN}</StyledGridHeader3>
          </Grid>
        );
      })}</Grid>)
});

function toPieChartData(domain: DomainType, scores: { "domain": string, "chineseDomain": string, "score": number }[]): { [score: string]: number }[] {
  let data: { [score: string]: number }[] = [];
  const obj = scores.find(x => x.domain === domain);
  if (obj !== undefined) {
    if (obj.score > 100)
      obj.score = 100;
    data = [{
      "score": obj.score
    },
    {
      "score": 100 - obj.score
    }]
  }
  return data;
}

export default function MissionTab(props: MissionTabProps) {

  const [missionDistributionPieChartData, setMissionDistributionPieChartData] = useState<{ name: string, count: number }[]>();

  const theme = useTheme();
  const smBreakPoint = useMediaQuery(theme.breakpoints.up('sm'));

  let combinedHistory1 = [
    { score: getRandScore(95, 90), time: -5 },
    { score: getRandScore(90, 88), time: -4 },
    { score: getRandScore(89, 87), time: -3 },
    { score: getRandScore(88, 82), time: -2 },
    { score: getRandScore(83, 79), time: -1 },
    { score: getRandScore(90, 65), time: 0 }
  ];

  useEffect(() => {
    if (props.missionData) {
      const pieChartReducer = props.missionData.reduce<{ [key: string]: { name: string, count: number } }>((buffer, entry) => {
        const chineseKey = getChineseMission(entry.key);// Recharts bugged and labelFormatter property not working to translate mission key to chinese, so I use chinese name as key to improve UX.
        (!buffer[chineseKey] && (buffer[chineseKey] = { name: chineseKey, count: 0 }));
        buffer[chineseKey].count++;
        return buffer;
      }, {});

      setMissionDistributionPieChartData(Object.values(pieChartReducer));
    }

  }, [props.missionData]);

  const getMostPlayedMission = (): string => {
    const maxEntry = _(missionDistributionPieChartData).maxBy(entry => entry.count);
    return `${maxEntry?.name} (${maxEntry?.count}次)`;
  }

  const getBestPerformanceDoamin = (): string => {
    const maxDomain = _(props.domainScore).maxBy(entry => entry.score);
    return `${maxDomain!.chineseDomain} (${Math.round(maxDomain!.score)}分)`;
  }

  const mapMissionDistributionPieChartData = (missionDistributionPieChartData: { name: string, count: number }[]) => {
    const colors = getColors(missionDistributionPieChartData.length);
    return missionDistributionPieChartData.map((entry, index) => <Cell key={`cell-${index}`} fill={colors[index]} />)
  }

  const getLevelByScore = (score: number): { level: number, next: number } => {
    if (!score) return { level: 0, next: 10 };

    const currentLevel = Math.floor(Math.pow((score - 10), 0.8) / 15);
    const nextLevelExp = Math.round(10 + Math.pow(((currentLevel + 1) * 15), 1.25))

    return { level: currentLevel, next: nextLevelExp - score };
  }

  return (
    <Container style={{ minHeight: '100px', paddingBottom: 20, marginTop: 40 }}>
      <Grid container spacing={4}>
        <Grid item lg={4} md={12} sm={12} xs={12}>
          <GridCard title="用戶等級" desc="用戶目前在遊戲中的等級以及相關訊息">
            <div style={{ minHeight: 314, marginTop: 50 }}>
              <text style={{ fontSize: 36 }}>
                {"Lv. "}
              </text>
              <text style={{ fontSize: 120 }}>
                {getLevelByScore(props.userScore).level}
              </text>
              <br />
              <br />
              <text style={{ fontSize: 20 }}>
                總經驗值: {props.userScore}
              </text>
              <br />
              <text style={{ fontSize: 20 }}>
                離下一級: {getLevelByScore(props.userScore).next}
              </text>
            </div>
          </GridCard>
        </Grid>
        <Grid item lg={4} md={6} sm={12} xs={12}>
          <GridCard title="用戶任務偏好" desc="以圓餅圖顯示用戶玩小遊戲的分佈">
            <div style={{ fontSize: 17, marginTop: 20 }}>
              用戶遊玩最多的任務：<br />
              <div style={{ fontSize: 22 }}>
                {missionDistributionPieChartData ?
                  getMostPlayedMission() :
                  "沒有資料"
                }
              </div>
            </div>
            {
              missionDistributionPieChartData ?
                <ResponsiveContainer width="100%" height={300}>
                  <PieChart>
                    <Tooltip formatter={(v) => `${v}次 (${Math.round((v as number) / props.missionData.length * 100)}%)`} />
                    <Pie dataKey="count" data={missionDistributionPieChartData} outerRadius={100} animationBegin={400} innerRadius={60}>
                      {mapMissionDistributionPieChartData(missionDistributionPieChartData)}
                    </Pie>
                    <text x="50%" y={155} textAnchor="middle" dominantBaseline="middle" fontSize={24}>
                      {props.missionData.length + "次"}
                    </text>
                  </PieChart>
                </ResponsiveContainer>
                : '沒有資料'}
          </GridCard>
        </Grid>
        <Grid item lg={4} md={6} sm={12} xs={12}>
          <GridCard title="各領域表現雷達圖" desc="以雷達圖顯示用戶在每一個領域中的表現">
            <div style={{ fontSize: 17, marginTop: 20 }}>
              用戶表現最好的領域：<br />
              <div style={{ fontSize: 22 }}>
                {props.domainScore ?
                  getBestPerformanceDoamin() :
                  "沒有資料"
                }
              </div>
            </div>
            <ResponsiveContainer width="100%" height={300}>
              <RadarChart outerRadius={100} height={300} data={props.domainScore.map(dScore => { return { ...dScore, score: Math.round(dScore.score) } })}>
                <PolarGrid />
                <PolarAngleAxis dataKey="chineseDomain" />
                <PolarRadiusAxis angle={30} domain={[0, 100]} />
                <Radar name={props.selectedUser.name} dataKey="score" stroke="#6cb6d9" fill="#79ccf2" fillOpacity={0.6} />
                <Tooltip />
              </RadarChart>
            </ResponsiveContainer>
          </GridCard>
        </Grid>
        <Grid item sm={12} xs={12}>
          <GridCard title="各領域表現總結" desc="以圓餅圖顯示用戶在每一個領域中的表現">
            <RenderPieChart domainScore={props.domainScore} />
          </GridCard>
        </Grid>
        {props.missionData && <DomainScatterChartTabs data={props.missionData} />}
      </Grid >
    </Container>
  )
}