import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import moment from 'moment';
import React, { useEffect } from 'react';
import { Legend, Scatter, ScatterChart, XAxis, YAxis } from 'recharts';
import { StyledCorrectIcon, StyledWrongIcon } from '../StyledComponents';
import { AllMissionDataType, DiceSumMission, RememberDroppedSequenceMission, RememberDroppedTilesMission, ShuffleTileMission, TrackDiscardMission, WeekDayMission } from '../types/MissionData';
import { MissionType } from '../types/MissionType';

const week = [null, '一', '二', '三', '四', '五', '六', '日'];

interface Props {
  data: AllMissionDataType;
  open: boolean;
  onClose: () => void;
}

export default function AnswerDialog(props: Props) {

  useEffect(() => {
    console.log(props.data);
  }, [props])

  return (
    <Dialog onClose={props.onClose} aria-labelledby="simple-dialog-title" open={props.open}>
      <DialogTitle id="simple-dialog-title">答案詳情</DialogTitle>
      <DialogContent>
        {AnswerDialogDictionary[props.data.key](props.data) ?? NotImplementedDialog(props.data)}
      </DialogContent>
      <DialogActions>
        <Button onClick={props.onClose} color="primary" autoFocus>
          返回
        </Button>
      </DialogActions>
    </Dialog>
  )
}

const ShuffleTileAnswerDialog = (data: AllMissionDataType) => {
  return (
    <ScatterChart
      width={500}
      height={400}
      margin={{
        top: 20, right: 20, bottom: 20, left: 20,
      }}
    >
      <XAxis type="number" dataKey="x" domain={['dataMin - 100', 'dataMax +100']} hide />
      <YAxis type="number" dataKey="y" domain={['dataMin - 100', 'dataMax +100']} hide />
      <Legend />
      <Scatter name="答案" data={(data as ShuffleTileMission).correct_answer} fill="#656565" line={{ strokeWidth: 10 }} />
      <Scatter name="用家答案" data={(data as ShuffleTileMission).answer} fill="#ffbec9" line={{ strokeWidth: 10 }} />
    </ScatterChart>
  )
};

const RememberDroppedSequenceAnswerDialog = (data: AllMissionDataType) => {
  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>原本次序</TableCell>
          <TableCell>
            {(data as RememberDroppedSequenceMission).answers.map(
              x => {
                return (x.tile_original_position) + " "
              }
            )}
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        <TableRow>
          <TableCell>用家答案</TableCell>
          <TableCell>
            {(data as RememberDroppedSequenceMission).answers.map(
              x => {
                return (x.player_selected_position) + " "
              }
            )}
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  )
};

const RememberDroppedTilesAnswerDialog = (data: AllMissionDataType) => {
  const temp = (data as RememberDroppedTilesMission).answers;
  if (temp !== undefined) {
    return (
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>答案</TableCell>
            <TableCell align="right">用家答案</TableCell>
            <TableCell align="right"></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>

          {temp.map(x => {
            return (
              <TableRow>
                <TableCell align="center">{x.correct_answer}</TableCell>
                <TableCell align="center">{x.selected_answer}</TableCell>
                <TableCell align="center">{x.correct_answer === x.selected_answer ? <StyledCorrectIcon /> : <StyledWrongIcon />}</TableCell>
              </TableRow>
            )
          })}
        </TableBody>
      </Table>
    )
  } else {
    return (
      <div>
        No mission data!
      </div>
    )
  }
}

const TrackDiscardAnswerDialog = (data: AllMissionDataType) => {
  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>答案</TableCell>
          <TableCell align="right">用家答案</TableCell>
          <TableCell align="right"></TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        <TableRow>
          {data.key === "DiceSum" ?
            <>
              <TableCell align="center">{(data as DiceSumMission).correct_answer}</TableCell>
              <TableCell align="center">{(data as DiceSumMission).answer}</TableCell>
              <TableCell align="center">{(data as DiceSumMission).correct_answer === (data as DiceSumMission).answer ? <StyledCorrectIcon /> : <StyledWrongIcon />}</TableCell>
            </> :
            data.key === "WeekDay" ?
              <>
                <TableCell align="center">{"星期" + week[moment.utc(data.task_start_time).isoWeek()]}</TableCell>
                <TableCell align="center">{"星期" + week[(data as WeekDayMission).answer]}</TableCell>
                <TableCell align="center">{(data as WeekDayMission).answer === moment.utc(data.task_start_time).isoWeek() ? <StyledCorrectIcon /> : <StyledWrongIcon />}</TableCell>
              </> :
              data.key === "TrackDiscard" ?
                <>
                  <TableCell align="center">
                    {(data as TrackDiscardMission).choices[(data as TrackDiscardMission).correct_answer.indexOf(true)] + ", " +
                      (data as TrackDiscardMission).choices[(data as TrackDiscardMission).correct_answer.lastIndexOf(true)]}
                  </TableCell>
                  <TableCell align="center">
                    {(data as TrackDiscardMission).selected[0] + ", " + (data as TrackDiscardMission).selected[1]}
                  </TableCell>
                  <TableCell align="center">
                    {((data as TrackDiscardMission).choices[(data as TrackDiscardMission).correct_answer.indexOf(true)] + ", " +
                      (data as TrackDiscardMission).choices[(data as TrackDiscardMission).correct_answer.lastIndexOf(true)]) === (data as TrackDiscardMission).selected[0] + ", " + (data as TrackDiscardMission).selected[1]
                      ? <StyledCorrectIcon /> : <StyledWrongIcon />}
                  </TableCell>
                </> :
                <div>
                  No mission data!
            </div>
          }
        </TableRow>

      </TableBody>
    </Table>
  )
}

const AskWhereAnswerDialog = (data: AllMissionDataType) => {
  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell align="right">用家答案</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        <TableRow>
          <TableCell align="center">{(data as DiceSumMission).answer}</TableCell>
        </TableRow>
      </TableBody>
    </Table>
  )
};

const NotImplementedDialog = (data: AllMissionDataType) => {
  return (
    <div>
      No mission data!
    </div>
  );
}

export const AnswerDialogDictionary: { [key in MissionType]: (data: AllMissionDataType) => JSX.Element } = {
  "ShuffleTile": ShuffleTileAnswerDialog,
  "RememberDroppedSequence": RememberDroppedSequenceAnswerDialog,
  "RememberDroppedTiles": RememberDroppedTilesAnswerDialog,
  "TrackDiscard": TrackDiscardAnswerDialog,
  "AskWhere": AskWhereAnswerDialog,
  "null": NotImplementedDialog
};