import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, Typography } from "@material-ui/core"
import React from "react"

export const TermsAndConditionsDialog = (props: { open: boolean, toggleDialog: (open: boolean) => void }) => {
  return (
    <Dialog
      open={props.open}
      fullWidth
      disableBackdropClick={false}
      onClose={() => props.toggleDialog(false)}
      maxWidth={"xl"}
    >
      <DialogTitle>{"條款及細則"}</DialogTitle>
      <DialogContent dividers>
        <DialogContentText>
          <Typography>
            您好！歡迎使用腦有記NeuroGym應用程式，《腦有記NeuroGym》是由醫念科技有限公司Medmind Technology Limited（下稱本公司）所開發與發行。為了尊重及保護您的個人隱私權， 本應用程式制定了隱私權保護政策，摘述本公司蒐集資料與運用方式。請仔細閱讀此隱私權政策。
          </Typography>
          <br />
          <Typography>
            本隱私權政策最後更新日期為2021年1月5日。
          </Typography>
          <br />
          <Divider />
          <br />
          <Typography>
            一、資料收集<br />
            根據本應用程式所提供的不同服務，可能向用家蒐集下列個人基本資料：
          </Typography>
          <br />
          <Typography>
            (1) 帳戶註冊：<br />
            當您註冊腦有記NeuroGym帳戶時，我們會請您提供個人資訊，包括姓名(暱稱也可)、電郵地址、電話號碼、性別等等。若發現有偽造或非本人使用，腦有記NeuroGym有權依照用家合約終止用家資格以及停用各項服務。
            用家可自行, 或交由管理人 (例如您所屬的機構或照顧者) 建立帳戶。如果您的帳戶是由管理人分派, 那可能適用於不同的條款或附加條款。為保障帳戶的安全性，管理人有權暫停您的帳戶或保全您的資料以作安全。
          </Typography>
          <br />
          <Typography>
            (2) 自願性線上活動及網上問卷調查：<br />
            當您參加本應用程式線上活動或網上問卷調查時，可能會請您提供姓名、電話、電郵地址及基本認知健康等個人資訊，該資料並不做其它用途。
          </Typography>
          <br />
          <Typography>
            (3) 統計與分析：<br />
            用家在使用本應用程式時，伺服器會自行產生相關記錄(LOG)，包括連線設備IP位址、使用時間、使用的瀏覽器、瀏覽及點選紀錄等資料，本公司將統計以上用家資料進行內部研究及分析，並以提升程式的服務品質。
            本公司的電腦系統將自動分析用家的訓練結果。當用家使用我們的系統進行傳送、接收及儲存內容功能時，系統便會自動進行分析，以便能提供與用家相關的一系列數據功能。
          </Typography>
          <br />
          <Divider />
          <br />
          <Typography>
            二、 用家資料的運用政策<br />
            本公司不會出售、交換、或出租任何您的個人資料給其他團體或個人。只有在以下狀況，本公司會在「隱私權保護條款」原則之下，運用您的個人資料。
          </Typography>
          <br />
          <Typography>
            (1)  傳送商業資訊：<br />
            腦有記NeuroGym將在您註冊前取得您的同意後，傳送商業性資料或電子郵件給您。 腦有記NeuroGym除了在該資料或電子郵件上註明是由腦有記NeuroGym發送，也會在該資料或電子郵件上提供您能隨時停止接收這些資料或電子郵件的方法。
          </Typography>
          <br />
          <Typography>
            (2) 提供其他服務或優惠：<br />
            為了提供您其他服務或優惠權益，需要與提供該服務或優惠之第三者共用您的資料時，本公司會在活動時提供充分說明，並且在資料收集之前通知您，您可以自由選擇是否接受該項特定服務或優惠。當用家使用我們的產品時，即表示您授予醫念科技有限公司（及我們的合作夥伴） 全球通用的授權，可使用、代管、儲存、重製、修改、製作衍生作品（例如用於科研或變更您的內容，使其更加配合我們的產品或服務）、傳播、發佈、公開操作、公開展示與散佈這類內容。您於本項授權授予的權利僅限用於營運、宣傳與改善產品或服務，以及開發新的產品或服務。
          </Typography>
          <br />
          <Typography>
            (3) 法律程序：<br />
            當司法單位因應公眾安全，要求本公司公開特定個人資料時，本公司將視司法單位合法正式的程序，以及對本公司所有用家安全考量下做可能必要之配合。
            凡法律准許時，醫念科技對所失利益、收入或資料、財務損失或間接、特殊、衍生性、懲戒性或處罰性損害賠償均不負責。在法律准許範圍內，用家對本條款所提出任何請求之總責任（包括對任何默示擔保之責任），均不超過您就使用產品或服務而向智活研發支付的金額（或者醫念科技亦可選擇再向您提供該產品或服務）。
            不論在任何情況下，醫念科技對無法合理預見之任何損失或損害均不負責。
          </Typography>
          <br />
          <Divider />
          <br />
          <Typography>
            三、 個人資料自我保護措施<br />
            為保障用家的帳戶，請您妥善保管好您的密碼及任何個人資料。用家亦盡量應避免在不同的應該程式中使用相同密碼，以免他人盜用其帳戶。如用家與他共享不同的電子平台，切記適時登山帳戶，以防他人讀取你的個人資料。如用家發現帳戶有不正常使用情況出現或遭人盜用，請即更改密碼或與聯絡我們為你重置帳戶。
          </Typography>
          <br />
          <Typography>
            *更新腦有記NeuroGym帳戶的個人資料：<br />
            當您在腦有記NeuroGym中主動註冊後，您可以利用您的腦有記NeuroGym帳號和密碼修改您所輸入的任何腦有記NeuroGym個人資料（如姓名、暱稱、住址、電話、電子信箱等）。如果您忘記密碼，也可查詢並修改帳戶密碼。
          </Typography>
          <br />
          <Divider />
          <br />
          <Typography>
            四、隱私權保護政策諮詢：<br />
            下載並使用本應用程式時，即代表您同意本隱私權政策。若您不同意本隱私權政策全部或一部之內容，請立即暫停使用或刪除本應用程式。本公司有權於任何時間在本應用程式公布經修改、變更之隱私權政策內容，變更不會回溯適用，並將於公布變更起即時生效。故請定期注意本頁面，以取得本隱私權政策的最新資訊。
          </Typography>
          <br />
          <Divider />
          <br />
          <Typography>
            如果您對於本應用程式的隱私權保護政策或對於本公司收集、運用、更新用家個人資料有意見或問題，請瀏覽我們的聯絡網頁<a href="https://www.medmindtechnology.hk">https://www.medmindtechnology.hk</a>或請發送電郵至<a href="mailto:info@medmindtechnology.hk" target="_blank">info@medmindtechnology.hk</a>。
          </Typography>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" disableElevation onClick={() => props.toggleDialog(false)} color="primary">
          關閉
        </Button>
      </DialogActions>
    </Dialog>
  )
}