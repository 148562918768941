import { AccountInfo } from "../types/AccountInfo";
import { AccountInfosActionTypes } from "../types/Actions";

const accountInfosReducerDefaultState: null = null;

const accountInfosReducer = (
  state: AccountInfo[] | null = accountInfosReducerDefaultState,
  action: AccountInfosActionTypes
): AccountInfo[] | null => {
  switch (action.type) {
    case "SET_ACCOUNT_INFOS":
      return action.accountInfos;
    case "CLEAR_ACCOUNT_INFOS":
      return null;
    default:
      return state;
  }
};
export default accountInfosReducer;
