import { Backdrop, Button, ButtonGroup, Container, Grid, makeStyles, Tab, Tabs } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { StyledGridHeader } from "../StyledComponents";
import { AllMissionDataType } from "../types/MissionData";
import { groupBy } from "lodash";
import DomainScatterChartTabs from "../components/DomainScatterChartTabs";
import moment from "moment";
import { useSelector } from "react-redux";
import { AppState } from "../reducers";
import CenterDomainScatterChartTabs from "../components/CenterDomainScatterChartTabs";
import { CenterUserMissionScoreSnapshotData, getCenterMissionScoreSnapshot } from "../api";
import { ChartMode } from "../components/CenterTrendScatterChart";

const useStyles = makeStyles({
  chartContainer: {
    background: '#FAFAFA',
    border: 0,
    borderRadius: 5,
    boxShadow: '0 0 50px 0px rgba(0, 0, 0, .15)',
    padding: '50px',
    margin: '50px 0',
    position: "relative"
  }
});

export default function CenterMissionTab() {
  const [showChart, setShowChart] = useState<boolean>(false);
  const [rawData, setRawData] = useState<{ [key: string]: CenterUserMissionScoreSnapshotData }>({});
  const loggedInUser = useSelector<AppState, firebase.User | null>(state => state.loggedInUser);

  const [scoreChartDisplayMode, setScoreChartDisplayMode] = useState<"score">("score");
  const [timeChartDisplayMode, setTimeChartDisplayMode] = useState<"time" | "time_acc">("time");

  const [dataForScoreChart, setDataForScoreChart] = useState<CenterUserMissionScoreSnapshotData>();
  const [dataForTimeChart, setDataForTimeChart] = useState<CenterUserMissionScoreSnapshotData>();

  const [tabNumberCenterMode, setTabNumberCenterMode] = useState<number>(0);

  const onClickShowChartButton = async () => {
    setShowChart(true);
  }

  const loadDataOfSpecificMonth = async (year: string, month: string): Promise<CenterUserMissionScoreSnapshotData | undefined> => {
    if (!loggedInUser) return undefined;
    const result = await getCenterMissionScoreSnapshot(loggedInUser.uid, year, month);

    if (!result) return undefined;

    const rawDataKey: string = `${year}-${month}`;

    if (!rawData) setRawData({});

    setRawData({
      ...rawData,
      [rawDataKey]: result!
    });
    return result;
  }

  const handleTimeUnitChange = async (mode: ChartMode, year: string, month: string) => {
    // Check cache, if cache exist then set the cache
    const rawDataKey: string = `${year}-${month}`;
    if (rawData[rawDataKey]) {
      if (mode === "time" || mode === "time_acc")
        setDataForTimeChart(rawData[rawDataKey]);
      else if (mode === "score")
        setDataForScoreChart(rawData[rawDataKey]);
    }
    else {
      if (mode === "time" || mode === "time_acc")
        setDataForTimeChart(await loadDataOfSpecificMonth(year, month));
      else if (mode === "score")
        setDataForScoreChart(await loadDataOfSpecificMonth(year, month));
    }
  }

  useEffect(() => {
    console.log(timeChartDisplayMode);
  }, [timeChartDisplayMode])

  return (
    <>
      <Grid sm={9} xs={12} style={{ paddingTop: 30 }}>
        <Tabs
          value={tabNumberCenterMode}
          onChange={(e, v) => setTabNumberCenterMode(v)}
          indicatorColor="primary"
          textColor="primary"
          centered
        >
          <Tab label="成員遊戲成績" />
          <Tab label="成員遊戲時間" />
        </Tabs>
        {tabNumberCenterMode === 0 ?
          <CenterScoreTab showChart={showChart} data={dataForScoreChart} onClickShowChartButton={onClickShowChartButton} handleTimeUnitChange={handleTimeUnitChange} />
          :
          tabNumberCenterMode === 1 ?
            <CenterTimeTab showChart={showChart} data={dataForTimeChart} onClickShowChartButton={onClickShowChartButton} handleTimeUnitChange={handleTimeUnitChange} />
            : null
        }
      </Grid>

    </>
  );
}

const CenterScoreTab = (props: { showChart: boolean, data: CenterUserMissionScoreSnapshotData | undefined, onClickShowChartButton: () => void, handleTimeUnitChange: (mode: ChartMode, year: string, month: string) => void }) => {
  const styles = useStyles();
  const [displayMode, setDisplayMode] = useState<"score">("score");

  return (
    <Container>
      <Container className={`${styles.chartContainer}`}>
        <StyledGridHeader style={{ textAlign: 'left', paddingLeft: 30, paddingTop: 0, marginBottom: 50 }}>
          機構 用家平均分排行榜
          </StyledGridHeader>
        {
          props.showChart && <CenterDomainScatterChartTabs mode={displayMode} data={props.data} onChangeTimeUnit={props.handleTimeUnitChange} />
        }
        <Backdrop style={{ position: "absolute", zIndex: 999, background: "rgba(255,255,255,0.8)" }} open={!props.showChart}>
          <Button variant="contained" color="primary" onClick={props.onClickShowChartButton}>按此載入機構排行榜（機構成員較多時載入數據可能需時）</Button>
        </Backdrop>
      </Container>
    </Container >
  )
}

const CenterTimeTab = (props: { showChart: boolean, data: CenterUserMissionScoreSnapshotData | undefined, onClickShowChartButton: () => void, handleTimeUnitChange: (mode: ChartMode, year: string, month: string) => void }) => {
  const styles = useStyles();
  const [displayMode, setDisplayMode] = useState<"time" | "time_acc">("time");

  return (
    <Container>
      <Container className={`${styles.chartContainer}`}>
        <StyledGridHeader style={{ textAlign: 'left', paddingLeft: 30, paddingTop: 0, marginBottom: 50, position: "relative" }}>
          機構 用家每日遊玩時間排行榜
            <ButtonGroup
            style={{ position: "absolute", right: 0, top: 0 }}
            color="primary"
            size="large"
            aria-label="large outlined secondary button group"
          >
            <Button
              onClick={() => setDisplayMode("time")}
              style={{
                backgroundColor: displayMode === "time" ? "#ffffff" : "#093a4f",
                color: displayMode === "time" ? "#093a4f" : "#ffffff",
              }}
            >個別</Button>
            <Button
              onClick={() => setDisplayMode("time_acc")}
              style={{
                backgroundColor: displayMode === "time_acc" ? "#ffffff" : "#093a4f",
                color: displayMode === "time_acc" ? "#093a4f" : "#ffffff",
              }}
            >累積</Button>
          </ButtonGroup>
        </StyledGridHeader>
        {
          props.showChart && <CenterDomainScatterChartTabs mode={displayMode} data={props.data} onChangeTimeUnit={props.handleTimeUnitChange} />
        }
        <Backdrop style={{ position: "absolute", zIndex: 999, background: "rgba(255,255,255,0.8)" }} open={!props.showChart}>
          <Button variant="contained" color="primary" onClick={props.onClickShowChartButton}>按此載入機構排行榜（機構成員較多時載入數據可能需時）</Button>
        </Backdrop>
      </Container>
    </Container >
  )
}