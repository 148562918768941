import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, makeStyles, Slider, Tooltip, Typography } from "@material-ui/core";
import { Info } from "@material-ui/icons";
import React, { useState } from "react";
import { calculateMocaScore, MocaScore } from "../types/UserProfile";
import Q1 from "./../image/Q1.jpg";
import Q2 from "./../image/Q2.jpg";
import Q3 from "./../image/Q3.jpg";
import Q4 from "./../image/Q4.jpg";
import Q5 from "./../image/Q5.jpg";
import Q6 from "./../image/Q6.jpg";
import Q7 from "./../image/Q7.jpg";

export const EditMocaScorePanel = (props: { initialValue?: MocaScore, open: boolean, onClose: () => void, onConfirm: (scores: MocaScore | null) => void }) => {

  const [initValue, setInitValue] = useState<MocaScore | undefined>(props.initialValue);

  const [scores, setScores] = useState<MocaScore>(
    {
      visuospatialExecutive: null,
      naming: null,
      attention: null,
      language: null,
      abstraction: null,
      delayedRecall: null,
      orientation: null
    }
  );

  const marks = [
    {
      value: -1,
      label: '未設定',
    },
    {
      value: 0,
      label: '0分',
    },
    {
      value: 1,
      label: '1分',
    },
    {
      value: 2,
      label: '2分',
    },
    {
      value: 3,
      label: '3分',
    },
    {
      value: 4,
      label: '4分',
    },
    {
      value: 5,
      label: '5分',
    },
    {
      value: 6,
      label: '6分',
    },
    {
      value: 7,
      label: '7分',
    },
  ];

  return (
    <Dialog
      open={props.open}
      fullWidth
      onClose={props.onClose}
      maxWidth={"lg"}
    >
      <DialogTitle>編輯 MOCA 分數</DialogTitle>
      <DialogContent dividers>
        <Grid container spacing={8} style={{ width: "100%", margin: "0 auto", marginTop: 20 }}>

          <GridSliderWrapper backgroundImage={Q1} title="視覺空間/執行性">
            <Slider
              defaultValue={initValue?.visuospatialExecutive ?? -1}
              valueLabelDisplay="on"
              valueLabelFormat={(value) => value === -1 ? "-" : value}
              onChange={(e, v) => setScores(prev => { return { ...prev, "visuospatialExecutive": v as number === -1 ? null : v as number } })}
              step={1}
              marks={marks.slice(0, 7)}
              min={-1}
              max={5}
            />
          </GridSliderWrapper>

          <GridSliderWrapper backgroundImage={Q2} title="命名">
            <Slider
              defaultValue={initValue?.naming ?? -1}
              valueLabelDisplay="on"
              valueLabelFormat={(value) => value === -1 ? "-" : value}
              onChange={(e, v) => setScores(prev => { return { ...prev, "naming": v as number === -1 ? null : v as number } })}
              step={1}
              marks={marks.slice(0, 5)}
              min={-1}
              max={3}
            />
          </GridSliderWrapper>

          <GridSliderWrapper backgroundImage={Q4} title="專注">
            <Slider
              defaultValue={initValue?.attention ?? -1}
              valueLabelDisplay="on"
              valueLabelFormat={(value) => value === -1 ? "-" : value}
              onChange={(e, v) => setScores(prev => { return { ...prev, "attention": v as number === -1 ? null : v as number } })}
              step={1}
              marks={marks.slice(0, 8)}
              min={-1}
              max={6}
            />
          </GridSliderWrapper>

          <GridSliderWrapper backgroundImage={Q5} title="語言">
            <Slider
              defaultValue={initValue?.language ?? -1}
              valueLabelDisplay="on"
              valueLabelFormat={(value) => value === -1 ? "-" : value}
              onChange={(e, v) => setScores(prev => { return { ...prev, "language": v as number === -1 ? null : v as number } })}
              step={1}
              marks={marks.slice(0, 5)}
              min={-1}
              max={3}
            />
          </GridSliderWrapper>

          <GridSliderWrapper backgroundImage={Q6} title="抽象">
            <Slider
              defaultValue={initValue?.abstraction ?? -1}
              valueLabelDisplay="on"
              valueLabelFormat={(value) => value === -1 ? "-" : value}
              onChange={(e, v) => setScores(prev => { return { ...prev, "abstraction": v as number === -1 ? null : v as number } })}
              step={1}
              marks={marks.slice(0, 4)}
              min={-1}
              max={2}
            />
          </GridSliderWrapper>

          <GridSliderWrapper backgroundImage={Q3} title="延遲記憶">
            <Slider
              defaultValue={initValue?.delayedRecall ?? -1}
              valueLabelDisplay="on"
              valueLabelFormat={(value) => value === -1 ? "-" : value}
              onChange={(e, v) => setScores(prev => { return { ...prev, "delayedRecall": v as number === -1 ? null : v as number } })}
              step={1}
              marks={marks.slice(0, 7)}
              min={-1}
              max={5}
            />
          </GridSliderWrapper>

          <GridSliderWrapper backgroundImage={Q7} title="定向">
            <Slider
              defaultValue={initValue?.orientation ?? -1}
              valueLabelDisplay="on"
              valueLabelFormat={(value) => value === -1 ? "-" : value}
              onChange={(e, v) => setScores(prev => { return { ...prev, "orientation": v as number === -1 ? null : v as number } })}
              step={1}
              marks={marks.slice(0, 8)}
              min={-1}
              max={6}
            />
          </GridSliderWrapper>

        </Grid>
      </DialogContent>
      <DialogActions>
        <div style={{ marginRight: "auto", marginLeft: 10 }}>
          <span style={{ fontSize: 24 }}>
            總分：
          </span>
          <span style={{ fontSize: 24 }}>
            {calculateMocaScore(scores)}
          </span>
        </div>
        <Button disabled={Object.values(scores).some(v => v !== null)} variant="contained" disableElevation onClick={() => { props.onConfirm(null); props.onClose(); }} color="primary">
          尚未進行MOCA測試
        </Button>
        <Button disabled={!scores || Object.values(scores).some(v => v === null)} variant="contained" disableElevation onClick={() => { props.onConfirm(scores); props.onClose(); }} color="primary">
          儲存並返回
        </Button>
        <Button variant="contained" disableElevation onClick={props.onClose} color="primary">
          返回
        </Button>
      </DialogActions>
    </Dialog>
  )
}

const useStyle = makeStyles((theme) => ({
  noMaxWidth: {
    maxWidth: "none"
  }
}));

const GridSliderWrapper = (props: { children: React.ReactNode, backgroundImage: string, title: string }) => {

  const classes = useStyle();

  return (
    <Grid
      item xs={12} sm={12} md={12} lg={6} xl={4}
      style={{ marginBottom: 10 }}
    >
      {props.children}
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <Typography gutterBottom variant="h6">
          {props.title}
        </Typography>
        <Tooltip title={<img src={props.backgroundImage} style={{ width: 600, maxWidth: "95vw" }} />} classes={{ tooltip: classes.noMaxWidth }}>
          <Info color="primary" />
        </Tooltip>
      </div>
    </Grid>
  )
}