import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import ImageIcon from '@material-ui/icons/Image';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import React from 'react';
import { getImageFromFirebaseStorage } from "../api";
import { colorScheme, translationCalligraphyType } from "./CalligraphyTab";
import SimpleSnackBar, { SnackBarProp } from "./SimpleSnackBar";

const useRowStyles = makeStyles({
  root: {
    '& > *': {
      borderBottom: 'unset',
    },
  },
  closeButton: {
    position: 'absolute',
    right: 0,
    top: 0,
    color: 'grey'
  }
});

export interface CalligraphyDataDetailEntry {
  type: string,
  totalTimeTaken: number,
  averageTimeTaken: number,
  averageAccuracy: number | null,
  history: CalligraphyDataDetailHistory[]
}

export interface CalligraphyDataDetailHistory {
  date: string
  averageSpeed: number | null,
  averageThickness: number | null,
  timeTaken: number | null,
  airTime: number | null,
  accuracy: number | null,
  ref: string | undefined | null
}

interface CalligraphyDataTableProps {
  data: CalligraphyDataDetailEntry[]
}

export const CalligraphyTypeStoragePath: { [key: string]: string } = {
  CalligraphyMainGame: "FaiChunDrawings",
  ChineseRestaurantMainGame: "FaiChunDrawings",
  CalligraphyMidAutumnEvent: "Calligraphy-MidAutumn",
  CalligraphyChineseRadical: "Calligraphy-ChineseRadical",
  CalligraphyIdiomMode: "Calligraphy-Idiom",
  WriteOperaNameMainGame: "FaiChunDrawings"
}

export const getHSLColor = (value: number) => {
  //value from 0 to 1
  var hue = ((value) * 120).toString(10);
  return ["hsl(", hue, ",100%,35%)"].join("");
}

const Row = (props: { data: CalligraphyDataDetailEntry, iconColor: string }) => {
  const { data, iconColor } = props;
  const [open, setOpen] = React.useState(false);
  const [openCalligraphyWorkDialog, setOpenCalligraphyWorkDialog] = React.useState<{ open: boolean, ref: string | null }>({ "open": false, "ref": null });
  const [calligraphyWorkImage, setCalligraphyWorkImage] = React.useState<string | null>(null);

  const closeErrorSnackBar = () => {
    setErrorSnackBar({ ...errorSnackBar, "open": false });
  }

  const [errorSnackBar, setErrorSnackBar] = React.useState<SnackBarProp>({
    open: false,
    onClose: closeErrorSnackBar,
    message: "",
  });

  const classes = useRowStyles();

  const handleClickOpenCalligraphyWork = (calligraphyType: string, ref: string | null | undefined) => {
    // Load calligraphy from firebase
    if (ref !== undefined || null || "") {
      getImageFromFirebaseStorage(CalligraphyTypeStoragePath[calligraphyType] ?? "FaiChunDrawings", ref!).then((url: string | null) => {
        if (url) {
          setCalligraphyWorkImage(url);
          setOpenCalligraphyWorkDialog({ "open": true, "ref": ref === undefined ? null : ref });
        }
        else {
          setErrorSnackBar({ open: true, onClose: closeErrorSnackBar, message: "載入書法作品時發生錯誤，請稍後再試" });
        }
      }
      ).catch((err) => {
        setErrorSnackBar({ open: true, onClose: closeErrorSnackBar, message: "載入書法作品時發生錯誤，請稍後再試" });
      });
    }
  }

  const handleClickCloseCalligraphyWork = () => {
    setOpenCalligraphyWorkDialog({ "open": false, "ref": null });
  }

  return (
    <React.Fragment>
      <TableRow className={classes.root}>
        <TableCell>
          <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)} style={{ color: iconColor }}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell align="left">
          {translationCalligraphyType[data.type] !== null ? translationCalligraphyType[data.type] : data.type}
        </TableCell>
        <TableCell align="right">{Math.round(data.totalTimeTaken * 100) / 100 + "秒"}</TableCell>
        <TableCell align="right">{Math.round(data.averageTimeTaken * 100) / 100 + "秒"}</TableCell>
        <TableCell align="right" style={{ fontWeight: "bold", color: data.averageAccuracy === null ? "black" : getHSLColor(data.averageAccuracy) }}>
          {data.averageAccuracy === null ? "—" : Math.round((data.averageAccuracy * 100) * 100) / 100 + "%"}
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <Typography variant="h6" gutterBottom component="div">
                詳細遊玩記錄
              </Typography>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell>作品</TableCell>
                    <TableCell>日期</TableCell>
                    <TableCell align="right">平均書寫速度</TableCell>
                    <TableCell align="right">平均筆畫粗度</TableCell>
                    <TableCell align="right">使用時間</TableCell>
                    <TableCell align="right">空閒時間</TableCell>
                    <TableCell align="right">準確率</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data.history.map((historyRow) => (
                    <TableRow>
                      <TableCell component="th" scope="row">
                        {
                          historyRow.ref === undefined || null ?
                            (<IconButton aria-label="查看作品" style={{ backgroundColor: "#FAFAFA", marginLeft: "-9px" }} disabled>
                              <ImageIcon />
                            </IconButton>)
                            :
                            (<IconButton aria-label="查看作品" style={{ backgroundColor: "#FAFAFA", marginLeft: "-9px" }} onClick={() => handleClickOpenCalligraphyWork(data.type, historyRow.ref)}>
                              <ImageIcon style={{ color: iconColor }} />
                            </IconButton>)
                        }
                      </TableCell>
                      <TableCell>{historyRow.date}</TableCell>
                      <TableCell align="right">{historyRow.averageSpeed ? (historyRow.averageSpeed + "像素/幀") : (!historyRow.averageSpeed && !historyRow.averageThickness) ? "沒有書寫" : "資料損毀"}</TableCell>
                      <TableCell align="right">{historyRow.averageThickness ? (historyRow.averageThickness + "像素") : (!historyRow.averageSpeed && !historyRow.averageThickness) ? "沒有書寫" : "資料損毀"}</TableCell>
                      <TableCell align="right">{historyRow.timeTaken ? (historyRow.timeTaken + "秒") : "資料損毀"}</TableCell>
                      <TableCell align="right">{historyRow.airTime ? (historyRow.airTime + "秒") : "資料損毀"}</TableCell>
                      <TableCell align="right" style={{ fontWeight: "bold", color: historyRow.accuracy === null ? "black" : getHSLColor(historyRow.accuracy) }}>
                        {historyRow.accuracy === null ? "—" : Math.round(historyRow.accuracy * 100) + "%"}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>

      <SimpleSnackBar open={errorSnackBar.open} onClose={closeErrorSnackBar} message={errorSnackBar.message} />

      <Dialog onClose={handleClickCloseCalligraphyWork} aria-labelledby="customized-dialog-title" open={openCalligraphyWorkDialog.open} maxWidth="lg" >
        <DialogTitle id="customized-dialog-title">
          {openCalligraphyWorkDialog ? (
            <IconButton aria-label="close" className={classes.closeButton} onClick={handleClickCloseCalligraphyWork}>
              <CloseIcon />
            </IconButton>
          ) : null}
          查看作品：{openCalligraphyWorkDialog.ref}
        </DialogTitle>
        <DialogContent dividers>
          {
            calligraphyWorkImage === null ? null : <img src={calligraphyWorkImage} style={{ width: "100%" }} alt="作品" />
          }
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
}

export default function CalligraphyTable(props: CalligraphyDataTableProps) {
  return (
    <TableContainer component={Paper}>
      <Table aria-label="collapsible table">
        <TableHead>
          <TableRow>
            <TableCell />
            <TableCell>書法模式</TableCell>
            <TableCell align="right">總遊玩時間</TableCell>
            <TableCell align="right">平均每局書法時間</TableCell>
            <TableCell align="right">平均準確率</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.data.map((row: CalligraphyDataDetailEntry, index: number) => (
            <Row key={row.type} data={row} iconColor={colorScheme[index]} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}