import React, { useEffect } from "react";
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';

export interface AlertDialogProps {
  open: boolean,
  onClose: () => void,
  message: string,
}

export default function AlertDialog(props: AlertDialogProps) {

  useEffect(() => {
    setOpen(props.open);
  }, [props.open])

  const [open, setOpen] = React.useState(false);

  const handleClose = () => {
    setOpen(false);
    props.onClose();
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{props.message}</DialogTitle>
      <DialogActions>
        <Button onClick={handleClose} color="primary" autoFocus>
          OK
          </Button>
      </DialogActions>
    </Dialog>
  )
}