import { Collapse, Divider, Grid, Tooltip } from "@material-ui/core";
import { EmojiEvents, ExpandLess, ExpandMore } from "@material-ui/icons";
import chroma from "chroma-js";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { PolarAngleAxis, PolarGrid, PolarRadiusAxis, Radar, RadarChart, ResponsiveContainer } from "recharts";
import { getSpecificGameBehaviorLog, getUserNameById } from "../api";
import { analyzeSingleMatchMahjongSkill, simulateGameByLog } from "../helper/mahjong";
import { AppState } from "../reducers";
import { LoadableProfileData } from "../reducers/userProfiles";
import { GridCard } from "../StyledComponents";
import { actions, GameBehavior, GameBehaviorLog } from "../types/GameBehaviorLog";
import { GameLog } from "../types/GameLog";
import { getHSLColor } from "./CalligraphyDataTable";

export const MahjongGameRecord = (props: { match: GameLog, opened: boolean, forceFetch?: boolean }) => {
  const [matchDetail, setMatchDetail] = useState<GameBehaviorLog | null>();
  const [tableData, setTableData] = useState<any>();
  const [playerEffiency, setPlayerEffiency] = useState<{ attackEffiency: number, defenseEffiency: number }[]>();

  // Load data upon opening the detail
  useEffect(() => {
    if (props.opened === true)
      fetchData();
  }, [props.opened]);

  useEffect(() => {
    if (matchDetail) {
      computeAllActionDataOfAllPlayer();
    }
  }, [matchDetail]);

  const fetchData = async () => {
    if (props.opened && matchDetail === undefined) {
      const data = await getSpecificGameBehaviorLog(props.match.id);
      console.log(data);
      setPlayerEffiency(simulateGameByLog(props.match));
      setMatchDetail(data);
    }
  };

  const computeActionData = (behavior: GameBehavior, actionType: string) => {
    const allActionsOfType = behavior.behaviors?.filter(action => action.type === actionType);

    if (!allActionsOfType) {
      return { time: null, wrongRate: null };
    }

    const withTime = allActionsOfType.filter(action => action.time !== undefined && action.status === "success");
    const successAndFail = allActionsOfType.filter(action => action.status === "success" || action.status === "fail");

    if (!(withTime.length === 0 || successAndFail.length === 0)) {
      const avgTime = _(withTime).meanBy(action => action.time);
      const wrongRate = allActionsOfType.filter(action => action.status === "fail").length / successAndFail.length;

      return { time: Math.round(avgTime * 100) / 100, withTimeCount: withTime.length, wrongRate: Math.round(wrongRate * 10000) / 100, successAndFailCount: successAndFail.length };
    }
    else {
      return { time: null, withTimeCount: withTime.length, wrongRate: null, successAndFailCount: successAndFail.length };
    }
  }

  const computeAllActionDataOfAllPlayer = () => {
    if (!matchDetail) return;
    const result = matchDetail.players.map(player => {
      // Overall
      return {
        "draw": computeActionData(player, "draw"),
        "discard": computeActionData(player, "discard"),
        "chow": computeActionData(player, "chow"),
        "pong": computeActionData(player, "pong"),
        "gong": computeActionData(player, "gong"),
        "dark_gong": computeActionData(player, "dark_gong"),
        "eat": computeActionData(player, "eat"),
        "selfdraw": computeActionData(player, "selfdraw"),
      }
    });

    Object.keys(actions).forEach(action => {
      const actionStats = result.map<{ time: number | null, wrongRate: number | null }>((r: any) => r[action]);

      if (!actionStats.every(aS => aS.time === null)) {
        const champion = _(actionStats).minBy(aS => aS.time === null ? 999 : aS.time);

        if (champion !== undefined) {
          const indexOfChampion = actionStats.indexOf(champion);
          ((result[indexOfChampion] as any)[action]).isChampion = true;
        }
      }
    });

    setTableData(result);
  }

  return props.match && (
    <Grid container justify="space-between" spacing={2} style={{ marginTop: 10, marginBottom: 10 }}>
      {
        props.match.players[0] &&
        <AllPlayerBehaviorCard
          userId={props.match.players[0].user_id}
          data={tableData !== undefined && tableData[0]}
          effiency={playerEffiency?.[0]}
          suffix={props.match.winner === 0 ? (props.match.loser === -1 ? "自摸" : "食糊") : (props.match.loser === 0 ? "出銃" : "")}
          dealer={props.match.dealer === 0}
          gameLog={props.match}
        />
      }

      {
        props.match.players[1] &&
        <AllPlayerBehaviorCard
          userId={props.match.players[1].user_id}
          data={tableData !== undefined && tableData[1]}
          effiency={playerEffiency?.[1]}
          suffix={props.match.winner === 1 ? (props.match.loser === -1 ? "自摸" : "食糊") : (props.match.loser === 1 ? "出銃" : "")}
          dealer={props.match.dealer === 1}
          gameLog={props.match}
        />
      }
      {
        props.match.players[2] &&
        <AllPlayerBehaviorCard
          userId={props.match.players[2].user_id}
          data={tableData !== undefined && tableData[2]}
          effiency={playerEffiency?.[2]}
          suffix={props.match.winner === 2 ? (props.match.loser === -1 ? "自摸" : "食糊") : (props.match.loser === 2 ? "出銃" : "")}
          dealer={props.match.dealer === 2}
          gameLog={props.match}
        />
      }
      {
        props.match.players[3] &&
        <AllPlayerBehaviorCard
          userId={props.match.players[3].user_id}
          data={tableData !== undefined && tableData[3]}
          effiency={playerEffiency?.[3]}
          suffix={props.match.winner === 3 ? (props.match.loser === -1 ? "自摸" : "食糊") : (props.match.loser === 3 ? "出銃" : "")}
          dealer={props.match.dealer === 3}
          gameLog={props.match}
        />
      }
    </Grid>
  )
}

const AllPlayerBehaviorCard = (props: { userId: string | undefined, suffix?: string, dealer: boolean, data: any, effiency: { attackEffiency: number, defenseEffiency: number } | undefined, gameLog: GameLog }) => {

  const [openBehaviorDetail, setOpenBehaviorDetail] = useState<boolean>(false);
  const [avgTimeAndWrongRate, setAvgTimeAndWrongRate] = useState<{ time: number | null, wrongRate: number | null }>();
  const [radarData, setRadarData] = useState<any>();
  const userProfiles = useSelector<AppState, LoadableProfileData>(state => state.userProfiles);

  const [name, setName] = useState<string>();

  useEffect(() => {
    if (props.data) {
      calcAvgTimeAndWrongRate();
      calcRadarData();
    }
  }, [props.data]);

  useEffect(() => {
    if (props.userId)
      getUserName(props.userId)
  }, [props.userId]);

  const getUserName = async (userId: string) => {
    if (userProfiles && (userProfiles as unknown as any).data)
      setName((userProfiles as unknown as any).data.find((p: any) => p.user_id === userId)?.name ?? (userId.includes("npc") && userId.length < 10 ? "電腦" : userId));
    else {
      const name: string | undefined = await getUserNameById(userId);
      if (userId.includes("npc") && userId.length < 10)
        setName("電腦");
      else
        setName(name ?? userId);
    }
  }

  const calcRadarData = () => {
    const result = analyzeSingleMatchMahjongSkill(props.gameLog, props.userId, props.effiency?.attackEffiency ?? 0, props.effiency?.defenseEffiency ?? 0);
    setRadarData(result);
  }

  const calcAvgTimeAndWrongRate = () => {

    console.log(props.data);

    const sum = Object.entries(props.data).reduce<{ time: number | null, timeCount: number, wrongRate: number | null, wrongRateCount: number }>((buffer, [action, stats]: [string, any]) => {
      if (stats.withTimeCount !== 0 && stats.time !== null) {
        if (buffer.time === null) buffer.time = 0;
        buffer.time += stats.time * stats.withTimeCount;
        buffer.timeCount += stats.withTimeCount;
      }

      if (stats.successAndFailCount !== 0 && stats.wrongRate !== null) {
        if (buffer.wrongRate === null) buffer.wrongRate = 0;
        buffer.wrongRate += stats.wrongRate * stats.successAndFailCount;
        buffer.wrongRateCount += stats.successAndFailCount;
      }

      return buffer;
    }, { time: null, timeCount: 0, wrongRate: null, wrongRateCount: 0 });

    console.log(sum);

    if (sum.timeCount === 0 || sum.wrongRateCount === 0) {
      setAvgTimeAndWrongRate({ time: null, wrongRate: null });
      return;
    }

    setAvgTimeAndWrongRate({
      time: (sum.time! / sum.timeCount),
      wrongRate: (sum.wrongRate! / sum.wrongRateCount)
    });
  }

  const ActionTypeSection = (props: { actionType: string, data: { time: number | null, wrongRate: number | null, isChampion?: boolean } }) => (
    <Grid item xs={12} style={{ maxWidth: "97%", paddingBottom: 0 }}>
      <div style={{ minHeight: 50, height: "100%", borderRadius: 5, backgroundColor: props.data.isChampion ? "#ecd787" : "#f0f0f0", overflow: "hidden", display: "flex" }}>
        <div style={{ width: 20, backgroundColor: props.data.isChampion ? "#e4c653" : "#e0e0e0", fontSize: 16, display: "flex", justifyContent: "center", alignItems: "center", padding: "8px 5px", writingMode: "vertical-rl", textOrientation: "upright" }}>
          {props.actionType}
        </div>
        <div style={{ position: "relative", margin: 10, display: "flex", justifyContent: "space-between", alignItems: "center", width: "100%" }}>

          <div style={{ width: "45%", textAlign: "center" }}>
            {props.data.time !== null ? (<><span style={{ fontSize: 22 }}>{props.data.time}</span><span style={{ fontSize: 14 }}>秒</span></>) : "—"}
          </div>

          <div style={{ width: "45%", textAlign: "center" }}>
            {props.data.wrongRate !== null ? (<><span style={{ fontSize: 22 }}>{props.data.wrongRate}</span><span style={{ fontSize: 14 }}>%</span></>) : "—"}
          </div>

          {
            props.data.isChampion &&
            <div style={{ position: "absolute", left: "50%", top: "50%", transform: "translate(-50%, -43%)" }}>
              <EmojiEvents style={{ color: "#e4c653", height: 48, width: 48 }} />
            </div>
          }

        </div>
      </div>
    </Grid>
  );

  const renderUserName = () => {
    const userName = name !== null ? name : props.userId ?? "未知用戶";
    const dealer = <span style={{ color: "#a8a8a8" }}>{props.dealer ? " 莊" : ""}</span>
    const suffix = <span style={{ color: props.suffix === "自摸" || "食糊" ? "#239139" : props.suffix === "出銃" ? "#e02828" : "initial" }}>{" " + props.suffix}</span>
    return <>{userName}{dealer}{suffix}</>
  }

  const customTick = (payload: { payload: any, x: number, y: number, textAnchor: string, stroke: string, radius: number }) => {
    return (
      <g
        className="recharts-layer recharts-polar-angle-axis-tick"
      >
        <text
          fontSize={28}
          radius={payload.radius}
          stroke={payload.stroke}
          x={payload.x}
          y={payload.y + 10}
          className="recharts-text recharts-polar-angle-axis-tick-value"
          textAnchor={payload.textAnchor}
        >
          <tspan x={payload.x} dy="0em">
            {payload.payload.value}
          </tspan>
        </text>
      </g>
    );
  }

  return (
    <Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
      <GridCard title={renderUserName()} centerTitle>

        <div style={{ maxHeight: 275, height: 275 }}>
          <ResponsiveContainer width="100%" height="100%" >
            <RadarChart cx="50%" cy="50%" outerRadius="70%" data={radarData}>
              <PolarGrid />
              <PolarAngleAxis dataKey="skill" tick={(payload: any) => customTick(payload)} />
              <PolarRadiusAxis domain={[0, 100]} />
              <Radar name={name ?? props.userId ?? "未知用戶"} dataKey="data" stroke="#093a4f" fill="#093a4f" fillOpacity={0.6} label={<>a</>} />
            </RadarChart>
          </ResponsiveContainer>
        </div>

        <Divider />

        <Grid container justify="space-evenly" spacing={1} style={{ height: 216, margin: "0 auto", width: "initial" }}>
          <Grid item xs={12}>
            <div style={{ borderRadius: 5, backgroundColor: (props.effiency && !isNaN(props.effiency.attackEffiency)) ? chroma(getHSLColor(props.effiency.attackEffiency)).desaturate(1.5).luminance(0.7).hex() : "#f0f0f0", overflow: "hidden", height: 100 }}>
              <Tooltip title="代表玩家打出某一隻牌時候（相對打出其他牌），更能令他在之後入章率和有效牌數提升的能力。">
                <div style={{ width: "100%", height: 40, fontSize: 28, backgroundColor: (props.effiency && !isNaN(props.effiency.attackEffiency)) ? chroma(getHSLColor(props.effiency.attackEffiency)).desaturate(1.5).luminance(0.6).hex() : "#ddd", display: "flex", justifyContent: "center", alignItems: "center" }}>
                  進攻效率
              </div>
              </Tooltip>
              <div style={{ fontWeight: "bold", height: 56, display: "flex", justifyContent: "center", alignItems: "center", fontSize: 38 }}>
                {props.effiency && !isNaN(props.effiency.attackEffiency) ? Math.round(props.effiency.attackEffiency * 100) : "-"}
              </div>
            </div>
          </Grid>
          <Grid item xs={12}>
            <div style={{ borderRadius: 5, backgroundColor: (props.effiency && !isNaN(props.effiency.defenseEffiency)) ? chroma(getHSLColor(props.effiency.defenseEffiency)).desaturate(1.5).luminance(0.7).hex() : "#f0f0f0", overflow: "hidden", height: 100 }}>
              <Tooltip title="代表玩家打出某一隻牌時候（相對打出其他牌），更低機會出銃的能力。">
                <div style={{ width: "100%", height: 40, fontSize: 28, backgroundColor: (props.effiency && !isNaN(props.effiency.defenseEffiency)) ? chroma(getHSLColor(props.effiency.defenseEffiency)).desaturate(1.5).luminance(0.6).hex() : "#ddd", display: "flex", justifyContent: "center", alignItems: "center" }}>
                  防守效率
                </div>
              </Tooltip>
              <div style={{ fontWeight: "bold", height: 56, display: "flex", justifyContent: "center", alignItems: "center", fontSize: 38 }}>
                {props.effiency && !isNaN(props.effiency.defenseEffiency) ? Math.round(props.effiency.defenseEffiency * 100) : "-"}
              </div>
            </div>
          </Grid>
        </Grid>

        <Grid container justify="space-evenly" spacing={1} style={{ height: 216, margin: "0 auto", width: "initial" }}>
          <Grid item xs={12}>
            <div style={{ borderRadius: 5, backgroundColor: "#f0f0f0", overflow: "hidden", height: 100 }}>
              <div style={{ width: "100%", height: 40, fontSize: 28, backgroundColor: "#ddd", display: "flex", justifyContent: "center", alignItems: "center" }}>
                反應時間
              </div>
              <div style={{ fontWeight: "bold", height: 56, lineHeight: "62px", display: "flex", justifyContent: "center", alignItems: "baseline", fontSize: 38 }}>
                {
                  avgTimeAndWrongRate && avgTimeAndWrongRate.time !== null && !isNaN(avgTimeAndWrongRate.time as any) ?
                    <>
                      <>
                        {Math.round(avgTimeAndWrongRate.time * 100) / 100}
                      </>
                      <span style={{ fontSize: 20 }}>
                        {" 秒"}
                      </span>
                    </>
                    :
                    "-"
                }
              </div>
            </div>
          </Grid>
          <Grid item xs={12}>
            <div style={{ borderRadius: 5, backgroundColor: "#f0f0f0", overflow: "hidden", height: 100 }}>
              <div style={{ width: "100%", height: 40, fontSize: 28, backgroundColor: "#ddd", display: "flex", justifyContent: "center", alignItems: "center" }}>
                錯誤率
              </div>
              <div style={{ fontWeight: "bold", height: 56, lineHeight: "62px", display: "flex", justifyContent: "center", alignItems: "baseline", fontSize: 38 }}>
                {
                  avgTimeAndWrongRate && avgTimeAndWrongRate.wrongRate !== null && !isNaN(avgTimeAndWrongRate.wrongRate as any) ?
                    <>
                      <>
                        {Math.round(avgTimeAndWrongRate.wrongRate * 100) / 100}
                      </>
                      <span style={{ fontSize: 20 }}>
                        {" %"}
                      </span>
                    </>
                    :
                    "-"
                }
              </div>
            </div>
          </Grid>
        </Grid>

        {props.data &&
          <>
            <Divider />

            <Tooltip title={openBehaviorDetail ? "隱藏詳細資料" : "展開詳細資料"} arrow placement="top">
              <div onClick={() => setOpenBehaviorDetail(open => !open)} style={{ display: "flex", justifyContent: "center" }}>
                {openBehaviorDetail ? <ExpandLess color="primary" /> : <ExpandMore color="primary" />}
              </div>
            </Tooltip>

            <Collapse in={openBehaviorDetail} unmountOnExit>
              <Divider />
              <Grid container spacing={1} style={{ minHeight: 200, margin: "5px 0" }}>

                <Grid item xs={12} style={{ display: "flex", justifyContent: "space-between", marginBottom: "-4.5px" }}>

                  <div style={{ marginLeft: 30, marginRight: 9, width: "100%", display: "flex", justifyContent: "space-between" }}>
                    <div style={{ minHeight: 30, width: "49%", borderRadius: "5px 5px 0 0", backgroundColor: "#e0e0e0", overflow: "hidden", display: "flex", justifyContent: "center", alignItems: "center" }}>
                      <span>
                        反應時間
                  </span>
                    </div>

                    <div style={{ minHeight: 30, width: "49%", borderRadius: "5px 5px 0 0", backgroundColor: "#e0e0e0", overflow: "hidden", display: "flex", justifyContent: "center", alignItems: "center" }}>
                      <span>
                        錯誤率
                  </span>
                    </div>
                  </div>
                </Grid>

                <ActionTypeSection actionType="摸牌" data={props.data["draw"]} />
                <ActionTypeSection actionType="打牌" data={props.data["discard"]} />
                <ActionTypeSection actionType="上" data={props.data["chow"]} />
                <ActionTypeSection actionType="碰" data={props.data["pong"]} />
                <ActionTypeSection actionType="槓" data={props.data["gong"]} />
                <ActionTypeSection actionType="暗槓" data={props.data["dark_gong"]} />
                <ActionTypeSection actionType="食糊" data={props.data["eat"]} />
                <ActionTypeSection actionType="自摸" data={props.data["selfdraw"]} />
              </Grid>
            </Collapse>
          </>
        }
      </GridCard>
    </Grid >
  )
}