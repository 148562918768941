import { amber, green } from '@material-ui/core/colors';
import IconButton from '@material-ui/core/IconButton';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import { makeStyles, Theme } from '@material-ui/core/styles';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CloseIcon from '@material-ui/icons/Close';
import ErrorIcon from '@material-ui/icons/Error';
import InfoIcon from '@material-ui/icons/Info';
import WarningIcon from '@material-ui/icons/Warning';
import clsx from 'clsx';
import React, { SyntheticEvent, useEffect, useState } from 'react';

export interface SnackBarProp {
  open: boolean,
  onClose: () => void,
  message: string,
  variant?: keyof typeof variantIcon,
  autoClose?: number
}

export default function SimpleSnackbar(props: SnackBarProp) {

  const [open, setOpen] = useState(false);

  useEffect(() => {
    setOpen(props.open);
  }, [props.open])

  const handleClose = (event?: SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
    props.onClose();
  };

  return (
    <Snackbar
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      open={open}
      autoHideDuration={props.autoClose !== undefined ? props.autoClose : 6000}
      onClose={handleClose}
    >
      <MySnackbarContentWrapper
        onClose={handleClose}
        variant={props.variant}
        message={props.message}
      />
    </Snackbar>
  )
}

const useStyles1 = makeStyles((theme: Theme) => ({
  success: {
    backgroundColor: green[500],
  },
  error: {
    backgroundColor: theme.palette.error.dark,
  },
  info: {
    backgroundColor: theme.palette.primary.main,
  },
  warning: {
    backgroundColor: amber[400],
    color: "black",
  },
  icon: {
    fontSize: 20,
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing(1),
  },
  message: {
    display: 'flex',
    alignItems: 'center',
  },
}));

export const variantIcon = {
  success: CheckCircleIcon,
  warning: WarningIcon,
  error: ErrorIcon,
  info: InfoIcon,
};

interface MySnackbarContentWrapperProps {
  className?: string;
  message?: string;
  onClose?: () => void;
  variant?: keyof typeof variantIcon;
}

function MySnackbarContentWrapper(props: MySnackbarContentWrapperProps) {
  const classes = useStyles1();
  const { className, message, onClose, variant, ...other } = props;
  const Icon = variant !== undefined ? variantIcon[variant] : undefined;

  return (
    <SnackbarContent
      className={variant !== undefined ? clsx(classes[variant], className) : ""}
      aria-describedby="client-snackbar"
      message={
        <span id="client-snackbar" className={classes.message}>
          {Icon !== undefined && <Icon className={clsx(classes.icon, classes.iconVariant)} />}
          {message}
        </span>
      }
      action={[
        <IconButton key="close" aria-label="close" color="inherit" onClick={onClose}>
          <CloseIcon className={classes.icon} />
        </IconButton>,
      ]}
      {...other}
    />
  );
}