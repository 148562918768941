import { Collapse, IconButton, Tooltip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { Delete } from '@material-ui/icons';
import AssignmentIcon from '@material-ui/icons/Assignment';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import _ from 'lodash';
import moment from 'moment';
import 'moment/locale/zh-tw';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { deleteMissionRecord } from '../api';
import { AppState } from '../reducers';
import { getColors } from '../resources/colors';
import { StyledTable } from '../StyledComponents';
import { AllMissionDataType } from '../types/MissionData';
import { getChineseMission, MissionType } from '../types/MissionType';
import { RoleType } from '../types/UserRole';
import AnswerDialog, { AnswerDialogDictionary } from './AnswerDialog';
import SimpleSnackBar, { SnackBarProp } from './SimpleSnackBar';

function hexToRgb(hex: string) {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result ? {
    r: parseInt(result[1], 16),
    g: parseInt(result[2], 16),
    b: parseInt(result[3], 16)
  } : null;
}

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

const StyledGridHeader1 = styled.div`
    padding-top: 20px;
    padding-bottom: 10px;
    padding-left:10px;
    font-size: 20px;
    text-align: left;
`
interface DomainTaskProps {
  // data: Array<{ task_name: string, result: boolean | string, domain: Domain }>,
  data: Array<{ mission: string, score: number, totalScore: number, userHighestLevel: number, missionHighestLevel: number, accTime: number, lastPlayed: number | undefined }> | null,
  historyData: Array<AllMissionDataType>
  selectedTab: number,
  missionOnClick: (mission: Array<MissionType>) => void
}

const Row = (props: {
  data: { mission: string, score: number, totalScore: number, userHighestLevel: number, missionHighestLevel: number, accTime: number, lastPlayed: number | undefined },
  historyData: AllMissionDataType[],
  selectedMissions: Array<string>,
  setSelectedMissions: (missions: Array<string>) => void,
  missionOnClick: (mission: Array<MissionType>) => void,
  setAnswerDialogOpen: (x: boolean) => void,
  setAnswerHistory: (data: AllMissionDataType) => void,
  onSuccessDelete: (missionKey: string, missionPushKey: string) => void,
  color: string,
}) => {
  const [open, setOpen] = useState<boolean>(false);
  const [levelData, setLevelData] = useState<{ level: number, time: number, score: number }[]>();
  const [levelHistory, setLevelHistory] = useState<{ level: number, data: AllMissionDataType[] }[]>();
  const [color, setColor] = useState<string>();
  const loggedUserRole = useSelector<AppState, RoleType | null>(state => state.userRole);
  const loggedInUser = useSelector<AppState, firebase.User | null>(state => state.loggedInUser);

  useEffect(() => {
    const levelArr = props.historyData.map(item => item.normalized_level).filter((value, index, self) => self.indexOf(value) === index);
    const temp1: { level: number, time: number, score: number }[] = [];
    const temp2: { level: number, data: AllMissionDataType[] }[] = [];
    levelArr.forEach(lv => {
      const tempData = props.historyData.filter(x => x.normalized_level === lv);
      temp2.push({
        level: lv,
        data: tempData,
      })
      setLevelHistory(temp2);
      temp1.push({
        level: lv,
        time: Math.round(_(tempData).meanBy(d => (d.task_end_time - d.task_start_time) / 1000) * 100) / 100,
        score: Math.round(_(tempData).meanBy(d => d.score) * 100) / 100
      })
    })
    setLevelData(temp1);
    const tempColor = hexToRgb(props.color);
    if (tempColor !== null) {
      const tempString = "rgba(" + tempColor.r + "," + tempColor.g + "," + tempColor.b + ",0.3)";
      setColor(tempString);
    }
  }, [props.data])

  const handleMissionOnClick = () => {
    setOpen(!open);
    if (open === false) {
      props.setSelectedMissions([...props.selectedMissions, props.data.mission]);
    } else {
      props.setSelectedMissions(props.selectedMissions.filter(x => x !== props.data.mission));
    }
  }

  const handleOpenAnswerDialog = (data: AllMissionDataType) => {
    props.setAnswerDialogOpen(true);
    props.setAnswerHistory(data);
  }

  const getChineseNumber: { [key: number]: string } = {
    1: '一',
    2: '二',
    3: '三',
    4: '四',
    5: '五',
    6: '六',
  }

  return (
    <React.Fragment>
      {color !== undefined &&
        <>
          <TableRow onClick={() => handleMissionOnClick()} hover>
            <TableCell>
              <IconButton size="small" onClick={() => handleMissionOnClick()}>
                {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
              </IconButton>
            </TableCell>
            <TableCell component="th" scope="row">
              {getChineseMission(props.data.mission)}
            </TableCell>
            <TableCell >{(props.data.score >= 0 ? (props.data.score) : "-")}/{props.data.totalScore}</TableCell>
            <TableCell >{props.data.userHighestLevel}/{props.data.missionHighestLevel}</TableCell>
            <TableCell >{moment.duration(props.data.accTime).humanize()}</TableCell>
            <TableCell >{moment(props.data.lastPlayed).fromNow()}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
              <Collapse in={open} timeout="auto" unmountOnExit>
                <div style={{ width: "100%", overflow: "scroll" }}>
                  <Table size="small">
                    <TableHead>
                      <TableRow style={{ backgroundColor: '#c1c1c1' }}>
                        <TableCell style={{ width: 100 }}> 等級</TableCell>
                        <TableCell align="center" style={{ minWidth: 60 }}>此等級平均所需時間</TableCell>
                        <TableCell align="center" style={{ minWidth: 30 }}>此等級平均分數</TableCell>
                        <TableCell align="center" style={{ minWidth: 15 }}>詳細答案</TableCell>
                      </TableRow>
                    </TableHead>
                    {levelData !== undefined && levelData.map((data, key) => (
                      <>
                        <TableRow key={key} style={{ backgroundColor: '#ececec' }}>
                          <TableCell>等級{getChineseNumber[data.level]}</TableCell>
                          <TableCell align="center">{data.time}秒</TableCell>
                          <TableCell align="center">{(data.score >= 0 ? (data.score) : "-")}/10分</TableCell>
                          <TableCell align="center">操作</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell style={{ width: 150 }}> 日期</TableCell>
                          <TableCell align="center" style={{ minWidth: 60 }}>所需時間</TableCell>
                          <TableCell align="center" style={{ minWidth: 60 }}>分數</TableCell>
                          <TableCell align="center" style={{ minWidth: 15 }}></TableCell>
                        </TableRow>
                        {levelHistory !== undefined && levelHistory.filter(x => x.level === data.level)[0].data.map((history, keys) => (
                          <MissionHistoryRecord user={loggedInUser} history={history} keys={keys} handleOpenAnswerDialog={handleOpenAnswerDialog} onSuccessDelete={props.onSuccessDelete} />
                        ))}
                      </>
                    ))}
                    {/* <TableHead>
                    <TableRow>
                      <TableCell style={{ width: 100 }}> 日期</TableCell>
                      <TableCell align="center">時間</TableCell>
                      <TableCell align="center" style={{ minWidth: 60 }}>所需時間</TableCell>
                      <TableCell align="center" style={{ minWidth: 30 }}>等級</TableCell>
                      <TableCell align="center" style={{ minWidth: 30 }}>分數</TableCell>
                      <TableCell align="center" style={{ minWidth: 60 }}>檢視答案</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {props.historyData.map((historyRow, key) => (
                      <TableRow key={key}>
                        <TableCell >{moment.utc(historyRow.task_start_time).local().format('YYYY年 MMM Do')}</TableCell>
                        <TableCell align="center">{moment.utc(historyRow.task_start_time).local().format('HH:mm:ss')}</TableCell>
                        <TableCell align="center">{((historyRow.task_end_time - historyRow.task_start_time) / 1000).toFixed(2)}s</TableCell>
                        <TableCell align="center">{historyRow.level}</TableCell>
                        {historyRow.score <= historyRow.total_score ?
                          <TableCell align="center">{historyRow.score} / {historyRow.total_score}</TableCell>
                          : <TableCell align="center">{null}</TableCell>}
                        <TableCell align="center">
                          <IconButton
                            onClick={() => handleOpenAnswerDialog(historyRow)}
                            color="inherit"
                          >
                            <VisibilityIcon color='primary' />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody> */}
                  </Table>
                </div>
                {/* </Box> */}
              </Collapse>
            </TableCell>
          </TableRow>
        </>}
    </React.Fragment >
  );
}

const MissionHistoryRecord = (props: { user: firebase.User | null, history: AllMissionDataType, keys: number, handleOpenAnswerDialog: (hist: AllMissionDataType) => void, onSuccessDelete: (missionKey: string, missionPushKey: string) => void }) => {

  const [deleted, setDeleted] = useState<boolean>(false);
  const loggedUserRole = useSelector<AppState, RoleType | null>(state => state.userRole);

  const closeErrorSnackBar = () => {
    setSnackBar({ ...snackBar, "open": false });
  };

  const [snackBar, setSnackBar] = useState<SnackBarProp>({
    open: false,
    onClose: closeErrorSnackBar,
    message: "",
    variant: undefined
  });

  const tryDeleteMissionRecord = async (uid: string | undefined, missionKey: string, missionPushKey: string) => {
    const success = await deleteMissionRecord(uid, missionKey, missionPushKey);
    if (success) {
      props.onSuccessDelete(missionKey, missionPushKey);
      setDeleted(true);
      setSnackBar({ ...snackBar, open: true, variant: "success", message: "成功刪除記錄" });
    }
    else {
      setSnackBar({ ...snackBar, open: true, variant: "error", message: "刪除記錄失敗" });
    }
  }

  return (
    <>
      <SimpleSnackBar open={snackBar.open} onClose={closeErrorSnackBar} message={snackBar.message} variant={snackBar.variant} autoClose={snackBar.autoClose} />
      <TableRow key={props.keys} style={{ display: deleted ? "none" : undefined }}>
        <Tooltip title={"遊戲編號：" + props.history.push_key} arrow placement="top" interactive>
          <TableCell>{moment.utc(props.history.task_start_time).local().format('YYYY年 MMM Do')}</TableCell>
        </Tooltip>
        <TableCell align="center">{((props.history.task_end_time - props.history.task_start_time) / 1000).toFixed(2)}s</TableCell>
        <TableCell align="center">{(props.history.score >= 0 ? (props.history.score) : "-") + "/" + props.history.total_score}</TableCell>
        <TableCell align="center" style={{ display: "flex", justifyContent: "center" }}>
          <Tooltip arrow placement="top" title={AnswerDialogDictionary[props.history.key] ? "點此開啟詳細資訊面板" : "暫未提供"}>
            <div>
              <IconButton
                color="primary"
                disabled={AnswerDialogDictionary[props.history.key] ? false : true}
                onClick={() => props.handleOpenAnswerDialog(props.history)}
              >
                <AssignmentIcon />
              </IconButton>
            </div>
          </Tooltip>
          {
            loggedUserRole === "admin" &&
            <Tooltip arrow placement="top" title={"點此刪除此記錄"}>
              <div>
                <IconButton
                  color="primary"
                  onClick={() => tryDeleteMissionRecord(props.user?.uid, props.history.key, props.history.push_key)}
                >
                  <Delete />
                </IconButton>
              </div>
            </Tooltip>
          }
        </TableCell>
      </TableRow>
    </>
  )
}

export default function DomainTaskTable(props: DomainTaskProps) {

  const [selectedMissions, setSelectedMissions] = useState<Array<MissionType>>([]);
  const [answerDialogOpen, setAnswerDialogOpen] = useState<boolean>(false);
  const [answerHistory, setAnswerHistory] = useState<AllMissionDataType>();
  const [missionHistory, setMissionHistory] = useState<AllMissionDataType[]>();
  const classes = useStyles();

  const handleCloseAnswerDialog = () => {
    setAnswerDialogOpen(false);
  }

  useEffect(() => {
    setMissionHistory(props.historyData);
  }, [props.historyData]);

  const deleteMissionEntry = (missionKey: string, missionPushKey: string) => {
    if (!missionHistory) return;

    setMissionHistory(prev => prev?.filter(hist => hist.push_key !== missionPushKey));
  }
  const mapTableRow = () => {
    const colors = getColors(props.data!.length);

    return props.data!.map((row, index) => (
      (row !== undefined) &&
      <Row
        key={row.mission}
        data={row}
        historyData={missionHistory?.filter(x => x.key === row.mission) ?? []}
        missionOnClick={props.missionOnClick}
        selectedMissions={selectedMissions}
        setSelectedMissions={setSelectedMissions}
        setAnswerDialogOpen={setAnswerDialogOpen}
        setAnswerHistory={setAnswerHistory}
        color={colors[index]}
        onSuccessDelete={deleteMissionEntry}
      />
    ));
  }

  useEffect(() => {
    props.missionOnClick(selectedMissions);
  }, [selectedMissions])

  return (
    <>
      {(props.data !== null) ?
        <StyledTable className={classes.table}>
          <TableRow style={{ backgroundColor: '#f2f2f2' }}>
            <TableCell>
              展開詳細
            </TableCell>
            <TableCell>
              任務名稱
            </TableCell>
            <TableCell>
              平均分
            </TableCell>
            <TableCell>
              最高等級
            </TableCell>
            <TableCell>
              累計遊玩時間
            </TableCell>
            <TableCell>
              最後遊玩時間
            </TableCell>
          </TableRow>
          {mapTableRow()}
          {answerDialogOpen && answerHistory !== undefined &&
            <AnswerDialog data={answerHistory} open={answerDialogOpen} onClose={handleCloseAnswerDialog} />}
        </StyledTable >
        :
        props.selectedTab > 0 ?
          <div style={{ marginTop: 20, marginBottom: 20 }}>此用家尚未遊玩過此領域的小遊戲或資料損毀</div>
          :
          <div style={{ marginTop: 20, marginBottom: 20 }}>請先在上方「各領域表現趨勢」圖表中，選擇其中一個領域。（例如：集中力）</div>
      }
    </>
  )
}