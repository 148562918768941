import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core'
import React from 'react'

interface DeleteDialogProps {
  type: "用家" | "帳戶" | "公告",
  selectedName: string | undefined,
  selectedId: string
  open: boolean,
  onClose: () => void,
  handleDelete: (id: string) => void,
}

export default function DeleteDialog(props: DeleteDialogProps) {
  return (
    <Dialog
      open={props.open}
      onClose={props.onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth='md'
    >
      <DialogTitle id="alert-dialog-title">確認刪除{props.type}{props.selectedName}？</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          請注意，您無法撤消此操作！
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.onClose} color="primary">
          不是
        </Button>
        <Button onClick={() => props.handleDelete(props.selectedId)} color="primary" autoFocus>
          是
        </Button>
      </DialogActions>
    </Dialog>
  )
}