import { Backdrop, Button, CircularProgress, Divider, Grid, InputAdornment, Link, MenuItem, Tab, Tabs, TextField, Tooltip, useMediaQuery, useTheme } from '@material-ui/core';
import Container from '@material-ui/core/Container';
import { Lock, MailOutline, PhoneIphone } from '@material-ui/icons';
import "firebase/auth";
import "firebase/database";
import React, { useEffect, useState } from "react";
import { getCountries, getCountryCallingCode } from 'react-phone-number-input/input';
import en from 'react-phone-number-input/locale/en.json';
import { useDispatch, useSelector } from "react-redux";
import { Redirect } from 'react-router-dom';
import { loggedIn } from '../actions/loggedIn';
import { setUserRole } from '../actions/userRole';
import { checkUserPhoneNumberExist, getUserRole } from '../api';
import SimpleSnackBar, { SnackBarProp } from "../components/SimpleSnackBar";
import firebase from "../firebase";
import { useQueryParams } from '../helper/hooks';
import top from "../image/top1.png";
import { AppState } from "../reducers";
import { GridCard } from '../StyledComponents';
import { Login } from "../types/Login";
import { LoginUsing } from "../types/Register";

const enCountryName: { [key: string]: string } = en;

export default function LoginPage(props: any) {

  const theme = useTheme();
  const queryParams = useQueryParams();
  const mdBreakPoint = useMediaQuery(theme.breakpoints.up('md'));
  const smBreakPoint = useMediaQuery(theme.breakpoints.up('sm'));
  const xsBreakPoint = useMediaQuery(theme.breakpoints.up('xs'));

  const dispatch = useDispatch();

  const loggedInUser = useSelector<AppState, firebase.User | null>(state => state.loggedInUser);

  const [loginInfo, setLoginInfo] = useState<Login>({
    email: "",
    password: "",
  });

  const [isLoggedIn, setIsLoggedIn] = useState<boolean>(false);

  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const [loginMethod, setLoginMethod] = useState<LoginUsing>("email");

  const [smsCodeSent, setSmsCodeSent] = useState<boolean>(false);

  const [emailError, setEmailError] = useState<{ isError: boolean, reason: string | null }>({ isError: false, reason: null })

  const closeErrorSnackBar = () => {
    setErrorSnackBar({ ...errorSnackBar, "open": false });
  }

  const [errorSnackBar, setErrorSnackBar] = useState<SnackBarProp>({
    open: false,
    onClose: closeErrorSnackBar,
    message: "",
  });

  const [country, setCountry] = useState<string>("HK");

  const onLoginFieldChange = (name: keyof Login) => (event: React.ChangeEvent<HTMLInputElement>) => {
    setLoginInfo({ ...loginInfo, [name]: event.target.value });
  }

  const [confirmationResult, setConfirmationResult] = useState<firebase.auth.ConfirmationResult>();


  function validateEmail(email: string) {
    const regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return regex.test(email.toLowerCase());
  }

  function validateSpace(email: string) {
    const regex = /\s/;
    return !regex.test(email);
  }

  const onLoginButtonClick = async (event: React.MouseEvent) => {
    setIsSubmitting(true);
    event.preventDefault();

    if (loginInfo.email === null || loginInfo.email === "") {
      if (loginMethod === "email")
        setErrorSnackBar({ ...errorSnackBar, message: "請輸入電郵地址", "open": true });
      else
        setErrorSnackBar({ ...errorSnackBar, message: "請輸入電話號碼", "open": true });

      setIsSubmitting(false);
      return;
    }

    if (validateSpace(loginInfo.email)) {
      if (loginMethod === "email") {
        if (!validateEmail(loginInfo.email)) {
          setErrorSnackBar({ ...errorSnackBar, message: "您輸入的電郵格式有誤，請檢查後再試", "open": true });
          setEmailError({ isError: true, reason: "電郵格式有誤" });
          setIsSubmitting(false);
          return;
        } else {
          setEmailError({ isError: false, reason: null });
        }
      }
      else {
        setEmailError({ isError: false, reason: null });
      }
    }
    else {
      setErrorSnackBar({ ...errorSnackBar, message: "您輸入的電郵/電話號碼有空白鍵，請檢查後再試", "open": true });
      setEmailError({ isError: true, reason: "有空白符號" });
      setIsSubmitting(false);
      return;
    }

    if ((loginInfo.password === null || loginInfo.password === "") && loginMethod != "phone_sms" && !smsCodeSent) {
      setErrorSnackBar({ ...errorSnackBar, message: "請輸入密碼", "open": true });
      setIsSubmitting(false);
      return;
    }

    if (smsCodeSent) {
      if (loginInfo.password !== null) {
        try {
          if (confirmationResult !== undefined) {
            const credential = await confirmationResult.confirm(loginInfo.password);
            if (checkIfPhoneLoginUserExist() === true) {
              handleLoginSuccess();
            } else {
              setErrorSnackBar({ ...errorSnackBar, message: "User does not exist.", "open": true });
            }
          }
        } catch (error) {
          //TODO: show error;
          console.log(error);
        }
        setIsSubmitting(false);
      }
    } else {
      if (loginMethod === "email") {
        if (loginInfo.email !== "" && loginInfo.password !== "") {
          try {
            const credential = await firebase.auth().signInWithEmailAndPassword(loginInfo.email, loginInfo.password);
            console.log(credential.user);
            //some code to get user role
            handleLoginSuccess();
          } catch (error) {
            let errorMessage = "";
            if (error.code === "auth/user-not-found" || error.code === "auth/wrong-password") {
              errorMessage = "電郵地址或者密碼錯誤，請檢查後再試一次。";
            } else {
              errorMessage = error.message;
            }
            console.log(error);
            setErrorSnackBar({ ...errorSnackBar, message: errorMessage, "open": true });
          }
          setIsSubmitting(false);
        }
      }
      else if (loginMethod === "phone_password") {
        if (loginInfo.email !== "" && loginInfo.password !== "") {
          try {
            const padEmail = "+" + getCountryCallingCode(country) + loginInfo.email + "@neurogym-phone.com";
            const credential = await firebase.auth().signInWithEmailAndPassword(padEmail, loginInfo.password);
            console.log(credential.user);
            //some code to get user role
            handleLoginSuccess();
          } catch (error) {
            let errorMessage = "";
            if (error.code === "auth/user-not-found" || error.code === "auth/wrong-password") {
              errorMessage = "電話號碼或者密碼錯誤，如果你忘記密碼，您可以選擇嘗試使用SMS登入。";
            } else {
              errorMessage = error.message;
            }
            console.log(error);
            setErrorSnackBar({ ...errorSnackBar, message: errorMessage, "open": true });
          }
          setIsSubmitting(false);
        }
      }
      else if (loginMethod === "phone_sms") {
        if (loginInfo.email !== "") {
          const phoneNumber = "+" + getCountryCallingCode(country) + loginInfo.email;
          const applicationVerifier = new firebase.auth.RecaptchaVerifier('recaptcha-container', {
            'size': 'invisible'
          });
          const exist = await checkUserPhoneNumberExist(phoneNumber);
          if (exist === true) {
            try {
              const confirmationResult = await firebase.app().auth().signInWithPhoneNumber(phoneNumber, applicationVerifier);
              setConfirmationResult(confirmationResult);
              setSmsCodeSent(true);
            } catch (error) {
              let errorMessage = "";
              if (error.code === "auth/invalid-phone-number") {
                errorMessage = "The phone number format is not correct.";
              } else {
                errorMessage = error.message;
              }
              setErrorSnackBar({ ...errorSnackBar, message: errorMessage, "open": true });
              console.log(error);
            }
          } else {
            setErrorSnackBar({ ...errorSnackBar, message: "User does not exist.", "open": true });
          }
          setIsSubmitting(false);
        }
      }
    }
    setIsSubmitting(false);
  }

  useEffect(() => {
    if (props.location?.state?.token) {
      tokenLogin(props.location.state.token);
    }
  }, []);

  useEffect(() => {
    if (loggedInUser !== null) {
      setIsLoggedIn(true);
    }
  })

  const tokenLogin = async (token: string) => {
    setIsSubmitting(true);
    firebase.auth().signInWithCustomToken(token)
      .then((credential) => {
        handleLoginSuccess();
        setIsSubmitting(false);
      })
      .catch((err) => {
        setErrorSnackBar({ ...errorSnackBar, message: "TOKEN登入失敗，請嘗試手動登入", "open": true });
        setIsSubmitting(false);
      });
  }

  const handleLoginSuccess = async () => {
    const user = firebase.auth().currentUser;
    if (user !== null) {
      dispatch(loggedIn(user));
      const role = await getUserRole(user.uid);
      dispatch(setUserRole(role.role));
      setIsLoggedIn(true);
    }
  }

  const checkIfPhoneLoginUserExist = () => {
    const user = firebase.auth().currentUser;
    if (user !== null) {
      const metadata = user.metadata;
      console.log(metadata.creationTime);
      console.log(metadata.lastSignInTime);
      if (metadata.creationTime === metadata.lastSignInTime) {
        return false;
      } else {
        return true;
      }
    }
  }

  const handleLoginMethodChange = (value: LoginUsing) => {
    setLoginInfo({
      email: "",
      password: "",
    });
    setSmsCodeSent(false);
    setLoginMethod(value);
  }

  function a11yProps(index: any) {
    return {
      id: `full-width-tab-${index}`,
      'aria-controls': `full-width-tabpanel-${index}`,
    };
  }

  const redirectBackToUrl = () => {
    const queryUId = props.location?.state?.query_uid // For report
    return props.location?.state?.from ? (props.location.state.from + (queryUId ? `?uid=${queryUId}` : "")) : "/home";
  }

  return (
    <>
      <Container disableGutters maxWidth='xl' style={{ height: "100vh" }}>
        {isLoggedIn && <Redirect to={redirectBackToUrl()} />}
        <div style={{ position: "fixed", zIndex: -1, width: "100%", height: "100%", backgroundColor: "#d8eff6", backgroundImage: `url(${top})`, backgroundPosition: "left top", backgroundRepeat: "no-repeat", backgroundAttachment: "cover", backgroundSize: "100%" }}></div>
        <Grid container justify="center" alignContent="center" alignItems="center" style={{ width: "100%", height: "100%" }}>
          <SimpleSnackBar open={errorSnackBar.open} onClose={closeErrorSnackBar} message={errorSnackBar.message} variant="error" />

          <Grid item style={{ width: 330 }}>
            <GridCard title="登入網上系統" desc={"先在以下三種登入方式選擇其中之一，然後輸入相關資料以進行登入。"} centerTitle blur>

              <Backdrop style={{ position: "absolute", zIndex: 999, background: "rgba(255,255,255,0.3)" }} open={isSubmitting}>
                <CircularProgress color="inherit" />
              </Backdrop>

              <Tabs
                variant="fullWidth"
                value={loginMethod}
                onChange={(e, v) => handleLoginMethodChange(v)}
                indicatorColor="primary"
                textColor="primary"
                centered
              >
                <Tab label={<Tooltip arrow placement="top" title="若您註冊時使用電郵地址登記，請選擇此登入方法，輸入註冊時所使用的電郵地址和密碼進行認證。"><span>電郵地址</span></Tooltip>} style={{ minWidth: 110 }} value="email" {...a11yProps(0)} />
                <Tab label={<Tooltip arrow placement="top" title="若您註冊時使用電話號碼登記，可選擇此登入方法，輸入註冊時所使用的電話號碼和密碼進行認證。（如果您無法使用「電話+密碼」方式登入，請嘗試使用「電話+SMS」驗證方式）"><span>電話+密碼</span></Tooltip>} style={{ minWidth: 110 }} value="phone_password" {...a11yProps(1)} />
                <Tab label={<Tooltip arrow placement="top" title="若您註冊時使用電話號碼登記，可選擇此登入方法，輸入註冊時所使用的電話號碼，系統會透過電話短訊將驗證碼寄給你進行認證。"><span>電話+短訊</span></Tooltip>} style={{ minWidth: 110 }} value="phone_sms" {...a11yProps(2)} />
              </Tabs>

              <Divider />

              <Grid container justify="center" alignContent="center" alignItems="center" style={{ margin: "10px auto", width: "80%" }} spacing={2}>

                {(loginMethod === "phone_sms" || loginMethod === "phone_password") &&
                  <Grid item xs={12}>
                    <TextField
                      id="countryCode"
                      select
                      label="國家/地區"
                      value={country}
                      onChange={event => {
                        console.log(getCountryCallingCode(event.target.value))
                        setCountry(event.target.value)
                      }}
                      margin="normal"
                      variant="outlined"
                      fullWidth
                    >
                      {getCountries().map((country: string) => (
                        <MenuItem key={country} value={(country)}>
                          {enCountryName[country]} +{getCountryCallingCode(country)}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                }

                <Grid item xs={12}>
                  <TextField
                    id="email"
                    label={loginMethod === 'email' ? "電郵地址 *" : "電話號碼 *"}
                    value={loginInfo.email}
                    onChange={onLoginFieldChange('email')}
                    margin="normal"
                    variant="outlined"
                    fullWidth
                    error={emailError.isError}
                    helperText={emailError.reason}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          {loginMethod === 'email' ? <MailOutline /> : <PhoneIphone />}
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>

                {(loginMethod === "email" || loginMethod === "phone_password" || smsCodeSent) &&
                  <Grid item xs={12}>
                    <TextField
                      id="password"
                      label={!smsCodeSent ? "密碼" : "請輸入驗證碼"}
                      type="password"
                      value={loginInfo.password}
                      onChange={onLoginFieldChange('password')}
                      margin="normal"
                      variant="outlined"
                      fullWidth
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <Lock />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                }

                <Grid item xs={12}>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    onClick={onLoginButtonClick}
                    disabled={isSubmitting}
                    disableElevation
                    fullWidth
                  >
                    登入
                    </Button>
                </Grid>

                <Grid item xs={6}>
                  <Link href="/plans" underline="none">
                    <Button variant="outlined" color="primary" fullWidth>
                      註冊
                    </Button>
                  </Link>
                </Grid>

                <Grid item xs={6}>
                  <Link href="/forgetpassword" underline="none">
                    <Button variant="outlined" style={{ color: "#e02828", borderColor: "#e02828" }} fullWidth>
                      忘記密碼
                    </Button>
                  </Link>
                </Grid>

                {/* <Grid item xs={12}>
                  <Link underline="none" onClick={onLoginByToken}>
                    <Button variant="outlined" style={{ color: "#e02828", borderColor: "#e02828" }} fullWidth>
                      TOKEN
                    </Button>
                  </Link>
                </Grid> */}

              </Grid>
            </GridCard>
          </Grid>
        </Grid>

        <div id="recaptcha-container"></div>

        {/* <Typography style={{ height: '100vh' }} >        
          {isLoggedIn && <Redirect to="/home" />}
          <Container>
            <div>
              {isSubmitting && <StyledCircularProgress />}
              <Typography gutterBottom variant="h5" component="h2">
                登入 {(process.env.NODE_ENV !== 'production' || process.env.REACT_APP_ENVIRONMENT === "test") && <span style={{ color: "red" }}>(DEVELOPMENT SERVER)</span>}
              </Typography>
              <StyledLoginForm autoComplete="off">
                <Grid container style={{ height: '65%', justifyContent: 'center', display: 'flex' }}>
                  <Grid item md={6} sm={12} justify="center">
                    <RadioGroup name="registerUsing" value={loginMethod} onChange={onLoginMethodToggleChange} style={{ marginLeft: 15 }}>
                      <FormControlLabel value="email" control={<Radio color='primary' />} label="電郵地址" />
                      <FormControlLabel value="phone_password" control={<Radio color='primary' />} label="電話號碼+密碼" />
                      <FormControlLabel value="phone_sms" control={<Radio color='primary' />} label="電話號碼+SMS驗證" />
                    </RadioGroup>
                  </Grid>
                  <Grid item md={6} sm={12}>
                    <Grid container spacing={1} alignItems="flex-end">
                      {(loginMethod === "phone_sms" || loginMethod === "phone_password") &&
                        <Grid item xs={12}>
                          <TextField
                            id="countryCode"
                            select
                            label="國家/地區"
                            value={country}
                            onChange={event => {
                              console.log(getCountryCallingCode(event.target.value))
                              setCountry(event.target.value)
                            }}
                            margin="normal"
                            variant="outlined"
                            fullWidth
                          >
                            {getCountries().map((country: string) => (
                              <MenuItem key={country} value={(country)}>
                                {enCountryName[country]} +{getCountryCallingCode(country)}
                              </MenuItem>
                            ))}
                          </TextField>
                        </Grid>
                      }
                      <Grid item xs={12}>
                        <TextField
                          id="email"
                          label={loginMethod === 'email' ? "電郵地址 *" : "電話號碼 *"}
                          value={loginInfo.email}
                          onChange={onLoginFieldChange('email')}
                          margin="normal"
                          variant="outlined"
                          fullWidth
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                {loginMethod === 'email' ? <MailOutlineIcon /> : <PhoneIphoneIcon />}
                              </InputAdornment>
                            ),
                          }}
                        />
                      </Grid>

                      {(loginMethod === "email" || loginMethod === "phone_password" || smsCodeSent) &&
                        <Grid item xs={12}>
                          <TextField
                            id="password"
                            label={!smsCodeSent ? "密碼" : "請輸入驗證碼"}
                            type="password"
                            value={loginInfo.password}
                            onChange={onLoginFieldChange('password')}
                            margin="normal"
                            variant="outlined"
                            fullWidth
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  <LockIcon />
                                </InputAdornment>
                              ),
                            }}
                          />
                        </Grid>
                      }
                    </Grid>
                  </Grid>
                  <Grid item md={6} sm={12}>
                    <a href='/forgetpassword' style={{ alignSelf: 'flex-end' }}>忘記密碼</a>
                    <Button
                      type="submit"
                      variant="contained"
                      style={{ marginLeft: 20, backgroundColor: 'transparent', backgroundImage: 'url(' + loginButton + ')', backgroundSize: '100% 100%' }}
                      onClick={onLoginButtonClick}
                      disabled={isSubmitting}
                      disableElevation
                    >登入</Button>
                  </Grid>
                </Grid>
              </StyledLoginForm>
            </div>
          </Container>
          <div id="recaptcha-container"></div>
        </Typography> */}
      </Container >
    </>
  )
}