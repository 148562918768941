import { AccountsActionTypes } from "../types/Actions";
import { admin } from "firebase-admin/lib/auth";

type UserRecord = admin.auth.UserRecord;

interface Account extends UserRecord {
  creationTime: string | null;
  lastSignInTime: string | null;
}

interface LoadedData {
  kind: "loaded";
  data: Account[];
}

interface LoadingData {
  kind: "loading";
}

interface LoadingDataError {
  kind: "error";
  error?: string;
}

interface NotLoaded {
  kind: "notLoaded";
}

export type LoadableAccountData =
  | LoadedData
  | LoadingData
  | LoadingDataError
  | NotLoaded;

const getAccountsReducerDefaultState: LoadableAccountData = {
  kind: "loading",
};

const accountsReducer = (
  state: LoadableAccountData = getAccountsReducerDefaultState,
  action: AccountsActionTypes
): LoadableAccountData => {
  switch (action.type) {
    case "SET_ACCOUNTS":
      return action.accounts;
    case "CLEAR_ACCOUNTS":
      return { kind: "notLoaded" };
    default:
      return state;
  }
};
export default accountsReducer;
