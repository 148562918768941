import { Typography } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import CardActions from "@material-ui/core/CardActions";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Grid from "@material-ui/core/Grid";
import InputAdornment from "@material-ui/core/InputAdornment";
import MenuItem from "@material-ui/core/MenuItem";
// import { clearLoggedInUser } from "../types/User";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import TextField from "@material-ui/core/TextField";
import { AxiosError } from "axios";
import moment from "moment";
import * as qs from "query-string";
import React, { useEffect, useState } from "react";
import {
  getCountries,
  getCountryCallingCode
} from "react-phone-number-input/input";
import en from "react-phone-number-input/locale/en.json";
import { useDispatch, useSelector } from "react-redux";
import { Redirect } from "react-router-dom";
import styled from "styled-components";
import { loggedOut } from "../actions/loggedIn";
import { clearUserProfiles } from "../actions/userProfiles";
import { clearUserRole } from "../actions/userRole";
import {
  adminRegister,
  checkUserPhoneNumberExist,
  getRequestDemo,
  register
} from "../api";
import AlertDialog from "../components/AlertDialog";
import ImportCSVDialog from "../components/ImportCSVDialog";
import SimpleSnackBar, { SnackBarProp } from "../components/SimpleSnackBar";
import { TermsAndConditionsDialog } from "../components/TermsAndConitionsDialog";
import firebase from "../firebase";
import { AppState } from "../reducers";
import {
  StyledCircularProgress,
  StyledContainer,
  StyledLoginCard,
  StyledLoginForm
} from "../StyledComponents";
import { Register, RegisterUsing } from "../types/Register";
import { Role, RoleType } from "../types/UserRole";
import { VerifyPhoneNumber } from "../types/VerifyPhoneNumber";

const enCountryName: { [key: string]: string } = en;

const StyledRegisterCard = styled(StyledLoginCard as any)`
  top: 0;
  margin-top: 10px;
`;

const genders = [
  {
    value: "M",
    label: "男",
  },
  {
    value: "F",
    label: "女",
  },
];

const use_smartphone_levels = [
  {
    value: "no_knowledge",
    label: "未用過",
  },
  {
    value: "newbie",
    label: "入門",
  },
  {
    value: "familiar",
    label: "熟悉",
  },
];

const mahjong_levels = [
  {
    value: "no_knowledge",
    label: "不認識",
  },
  {
    value: "newbie",
    label: "入門",
  },
  {
    value: "familiar",
    label: "熟悉",
  },
];

const education_levels = [
  { value: "no_education", label: "從未接受過教育" },
  { value: "primary_school", label: "小學畢業" },
  { value: "secondary_school", label: "中學畢業" },
  { value: "university", label: "大專/大學畢業" },
  { value: "above_university", label: "大學以上" },
];

interface DateofBirth {
  year: number | null;
  month: number | null;
  day: number | null;
}

export default function RegisterPage() {
  const dispatch = useDispatch();

  const userRole = useSelector<AppState, RoleType | null>(
    (state) => state.userRole
  );

  const newRegisterInfo: Register =
  {
    register_using: "email",
    email: "",
    email_confirmation: "",
    password: "",
    password_confirmation: "",
    user_id: "-1",
    name: "",
    gender: "",
    avatar_url: "",
    year_of_birth: null,
    occupation: "",
    user_role: "trial_individual",
    is_dementia_mode: false,
    smsCode: ""
  };

  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const [registerInfo, setRegisterInfo] = useState<Register>(newRegisterInfo);

  const [verifyPhoneNumberInfo, setVerifyPhoneNumberInfo] = useState<
    VerifyPhoneNumber
  >({
    phone_number: undefined,
    verification_code: undefined,
  });

  const [confirmationResult, setConfirmationResult] = useState<
    firebase.auth.ConfirmationResult
  >();

  const [smsCodeSent, setSmsCodeSent] = useState<boolean>(false);

  useEffect(() => {
    if (userRole === "admin") {
      setErrorSnackBar({
        ...errorSnackBar,
        message: "You are registering with admin account!",
        open: true,
      });
    }
    const qsObject = qs.parse(window.location.search);
    let code: string;
    if (qsObject.code !== undefined && qsObject.code !== null) {
      if (typeof qsObject.code === "string") {
        dispatch(loggedOut());
        dispatch(clearUserProfiles());
        dispatch(clearUserRole());
        code = qsObject.code;
        console.log(code);
        setIsSubmitting(true);
        getRequestDemo(code)
          .then((data) => {
            if (data !== null) {
              setIsRegistrationAllowed(true);
              setRegisterInfo({
                ...registerInfo,
                email: data.email,
                email_confirmation: data.email,
                name: data.name,
                user_role: "trial_center",
                code: code,
                displayName: data.company_name,
                referralCode: data.referralCode,
                phone: data.contact_number,
              });
              if (data.created_user_at !== 0) {
                setRegistrationNotAllowedMessage("已經註冊成功");
                setIsRegistrationAllowed(false);
                setOpenRegisterNotAllowedDialog(true);
              }
            } else {
              setRegistrationNotAllowedMessage("註冊碼無效");
              setIsRegistrationAllowed(false);
              setOpenRegisterNotAllowedDialog(true);
            }
          })
          .catch((err) => {
            setRegistrationNotAllowedMessage("註冊碼無效");
            setIsRegistrationAllowed(false);
            setOpenRegisterNotAllowedDialog(true);
          })
          .finally(() => setIsSubmitting(false));
      }
    } else {
      if (userRole !== "admin") {
        // if (process.env.NODE_ENV === "production" && process.env.REACT_APP_ENVIRONMENT !== "test") {
        //   setRegistrationNotAllowedMessage("公開註冊尚未開放")
        //   setOpenRegisterNotAllowedDialog(true);
        // } else {
        firebase.app().auth().signOut();
        setIsRegistrationAllowed(true);

        // }
      } else {
        setIsRegistrationAllowed(true);
      }
    }
  }, []);

  type InputError = {
    isError: boolean,
    reason: string | null
  }

  const [emailError, setEmailError] = useState<InputError>({ isError: false, reason: null });
  const [confirmEmailError, setConfirmEmailError] = useState<InputError>({ isError: false, reason: null });
  const [passwordError, setPasswordError] = useState<InputError>({ isError: false, reason: null });
  const [confirmPasswordError, setConfirmPasswordError] = useState<InputError>({ isError: false, reason: null });
  const [nameError, setNameError] = useState<InputError>({ isError: false, reason: null });
  // const [genderError, setGenderError] = useState<boolean>(false);
  // const [yearError, setYearError] = useState<boolean>(false);
  const [country, setCountry] = useState<string>("HK");
  const [phoneError, setPhoneError] = useState<InputError>({ isError: false, reason: null });
  const [centerNameError, setCenterNameError] = useState<InputError>({ isError: false, reason: null });
  const [smsCodeError, setSmsCodeError] = useState<InputError>({ isError: false, reason: null });

  const [
    openRegisterSuccessfullyDialog,
    setOpenRegisterSuccessfullyDialog,
  ] = useState<boolean>(false);

  const [
    openRegisterNotAllowedDialog,
    setOpenRegisterNotAllowedDialog,
  ] = useState<boolean>(false);

  const [isRegistrationAllowed, setIsRegistrationAllowed] = useState<boolean>(
    false
  );

  const [redirect, setRedirect] = useState<boolean>(false);

  const [
    registrationNotAllowedMessage,
    setRegistrationNotAllowedMessage,
  ] = useState<string>("");

  const closeErrorSnackBar = () => {
    setErrorSnackBar({ ...errorSnackBar, open: false });
  };

  const [errorSnackBar, setErrorSnackBar] = useState<SnackBarProp>({
    open: false,
    onClose: closeErrorSnackBar,
    message: "",
  });

  const [importDialogOpen, setImportDialogOpen] = useState<boolean>(false);

  const [openTACDialog, setOpenTACDialog] = useState<boolean>(false);

  const toggleTACDialog = (open: boolean) => {
    setOpenTACDialog(open);
  }

  const onLoginButtonClick = async (event: React.MouseEvent) => {
    event.preventDefault();
    console.log(registerInfo);
    let hasError = false;
    if (registerInfo.email === "") {
      setEmailError({ isError: true, reason: `${registerInfo.register_using === "email" ? "電郵地址" : "電話號碼"}不能為空白。` });
      hasError = true;
    }
    else if (registerInfo.register_using === "email" && !validateEmail(registerInfo.email)) {
      setEmailError({ isError: true, reason: "請輸入正確格式的電郵地址。" });
      hasError = true;
    }
    else if (registerInfo.register_using === "phone_number" && !validatePhone(registerInfo.email)) {
      setEmailError({ isError: true, reason: "請輸入正確格式的電話號碼。" });
      hasError = true;
    }
    if (registerInfo.password === "") {
      setPasswordError({ isError: true, reason: "密碼不能為空白。" });
      hasError = true;
    }
    if (!validatePassword(registerInfo.password)) {
      setPasswordError({ isError: true, reason: "密碼需要由至少六個字元組成" });
      hasError = true;
    }
    if (smsCodeSent === true && registerInfo.smsCode === "") {
      setSmsCodeError({ isError: true, reason: "驗證碼不能為空白。" });
      hasError = true;
    }
    if (registerInfo.email_confirmation === "") {
      setConfirmEmailError({ isError: true, reason: `確認${registerInfo.register_using === "email" ? "電郵地址" : "電話號碼"}不能為空白。` });
      hasError = true;
    }
    if (
      registerInfo.register_using === "email" &&
      registerInfo.password_confirmation === ""
    ) {
      setConfirmPasswordError({ isError: true, reason: "確認密碼不能為空白。" });
      hasError = true;
    }
    if (registerInfo.email !== registerInfo.email_confirmation) {
      setConfirmEmailError({ isError: true, reason: `與上項輸入之${registerInfo.register_using === "email" ? "電郵地址" : "電話號碼"}不相符。` });
      hasError = true;
    }
    if (
      registerInfo.register_using === "email" &&
      registerInfo.password !== registerInfo.password_confirmation
    ) {
      setConfirmPasswordError({ isError: true, reason: "與上項輸入之密碼不相符。" });
      hasError = true;
    }
    if (registerInfo.code === undefined && registerInfo.name === "") {
      setNameError({ isError: true, reason: "名字不能為空白。" });
      hasError = true;
    }
    // if (registerInfo.code === undefined && registerInfo.register_using === "email" && registerInfo.phone === undefined) {
    //   setPhoneError(true);
    //   hasError = true;
    // }
    if (registerInfo.code === undefined && (registerInfo.user_role === 'center' || registerInfo.user_role === 'trial_center') && registerInfo.displayName === undefined) {
      setCenterNameError({ isError: true, reason: "機構名字不能為空白。" });
      hasError = true;
    }
    // if (
    //   registerInfo.code === undefined &&
    //   registerInfo.year_of_birth === null
    // ) {
    //   setYearError(true);
    //   hasError = true;
    // }
    if (hasError === false) {
      let _registerInfo = Object.assign({}, registerInfo);
      if (_registerInfo.register_using === "phone_number") {
        _registerInfo.email =
          "+" + getCountryCallingCode(country) + _registerInfo.email;
        _registerInfo.email_confirmation =
          "+" +
          getCountryCallingCode(country) +
          _registerInfo.email_confirmation;
      }
      setIsSubmitting(true);
      if (smsCodeSent) {
        if (_registerInfo.smsCode) {
          try {
            if (confirmationResult !== undefined) {
              const credential = await confirmationResult.confirm(
                _registerInfo.smsCode
              );
              if (checkIfPhoneLoginUserExist() === false) {
                try {
                  await _register(_registerInfo);
                } catch (e) {
                  console.log(e);
                  if (e.message) {
                    setErrorSnackBar({
                      ...errorSnackBar,
                      message: e.message,
                      open: true,
                    });
                  } else {
                    setErrorSnackBar({
                      ...errorSnackBar,
                      message: "Unknown error occurred..",
                      open: true,
                    });
                  }
                }
              } else {
                setErrorSnackBar({
                  ...errorSnackBar,
                  message: "User does not exist.",
                  open: true,
                });
              }
            }
          } catch (error) {
            //TODO: show error;
            console.log(error);
            let errorMessage = "";
            if (error.code === "auth/invalid-verification-code") {
              errorMessage = "驗證碼錯誤。";
            } else {
              errorMessage = error.message;
            }
            setErrorSnackBar({
              ...errorSnackBar,
              message: errorMessage,
              open: true,
            });
            console.log(error);
          }
          setIsSubmitting(false);
        }
      } else {
        if (_registerInfo.register_using === "phone_number") {
          const applicationVerifier = new firebase.auth.RecaptchaVerifier(
            "recaptcha-container",
            { size: "invisible" }
          );
          const phoneNumber = _registerInfo.email;
          const isExist = await checkUserPhoneNumberExist(phoneNumber);
          if (isExist === false) {
            try {
              const confirmationResult = await firebase
                .app()
                .auth()
                .signInWithPhoneNumber(phoneNumber, applicationVerifier);
              setConfirmationResult(confirmationResult);
              setSmsCodeSent(true);
              setErrorSnackBar({
                ...errorSnackBar,
                message: "驗證碼已經送出，您將會在幾分鐘內收到。然後請在欄位輸入收到的驗證碼。",
                open: true,
              });
            } catch (error) {
              let errorMessage = "";
              if (error.code === "auth/invalid-phone-number") {
                errorMessage = "The phone number format is not correct.";
              } else {
                errorMessage = error.message;
              }
              setErrorSnackBar({
                ...errorSnackBar,
                message: errorMessage,
                open: true,
              });
              console.log(error);
            }
          } else {
            setErrorSnackBar({
              ...errorSnackBar,
              message: "電話號碼已經註冊",
              open: true,
            });
          }
          setIsSubmitting(false);
        } else {
          setIsSubmitting(true);
          if (userRole === "admin") {
            _registerInfo = { ..._registerInfo, emailVerified: true };
          }
          if (_registerInfo.user_role !== 'center' && _registerInfo.user_role !== 'trial_center') {
            _registerInfo = { ...registerInfo, displayName: _registerInfo.name };
          }
          try {
            await _register(_registerInfo);
          } catch (e) {
            let error = e as AxiosError;
            if (
              error.response &&
              error.response.data &&
              error.response.data.message
            ) {
              setErrorSnackBar({
                ...errorSnackBar,
                message: e.response.data.message,
                open: true,
              });
            } else {
              setErrorSnackBar({
                ...errorSnackBar,
                message: e.message,
                open: true,
              });
            }
          }
          setIsSubmitting(false);
        }
      }
    } else {
      setErrorSnackBar({ ...errorSnackBar, "open": true, message: "請輸入帶*的資料。" });
    }
  };

  const _register = async (registerInfo: Register) => {
    try {
      if (userRole === "admin") {
        const valid = moment().add(14, "d").hour(23).minute(59).valueOf();
        await adminRegister({
          ...registerInfo,
          is_valid: true,
          valid_till: valid,
        });
        setOpenRegisterSuccessfullyDialog(true);
      } else {
        await register(registerInfo);
        setOpenRegisterSuccessfullyDialog(true);
      }
    } catch (e) {
      throw e;
    }
  };
  const checkIfPhoneLoginUserExist = () => {
    const user = firebase.auth().currentUser;
    if (user !== null) {
      const metadata = user.metadata;
      console.log(metadata.creationTime);
      console.log(metadata.lastSignInTime);
      if (metadata.creationTime === metadata.lastSignInTime) {
        return false;
      } else {
        return true;
      }
    }
  };

  function validatePassword(pw: string) {
    const regex = /^.{6,}$/;
    return regex.test(pw);
  }

  const onRegisterFieldChange = (name: keyof Register, value: any) => {
    if (name === "email") {
      if (registerInfo.register_using === "email") {
        if (!validateEmail(value))
          setEmailError({ isError: true, reason: "請輸入正確格式的電郵地址。" });
        else
          setEmailError({ isError: false, reason: null });
      }
      else {
        if (!validatePhone(value))
          setEmailError({ isError: true, reason: "請輸入正確格式的電話號碼。" });
        else
          setEmailError({ isError: false, reason: null });
      }
    }

    if (name === "email_confirmation") {
      if (registerInfo.register_using === "email") {
        if (!validateEmail(value))
          setConfirmEmailError({ isError: true, reason: "請輸入正確格式的電郵地址。" });
        else if (value !== registerInfo.email)
          setConfirmEmailError({ isError: true, reason: "與上項輸入之電郵地址不相符。" });
        else
          setConfirmEmailError({ isError: false, reason: null });
      }
      else {
        if (!validatePhone(value))
          setConfirmEmailError({ isError: true, reason: "請輸入正確格式的電話號碼。" });
        else if (value !== registerInfo.email)
          setConfirmEmailError({ isError: true, reason: "與上項輸入之電話號碼不相符。" });
        else
          setConfirmEmailError({ isError: false, reason: null });
      }
    }

    if (name === "password") {
      if (validatePassword(value)) {
        setPasswordError({ isError: false, reason: null });
      }
      else {
        setPasswordError({ isError: true, reason: "密碼需要由至少六個字元組成" });
      }
    }

    if (name === "smsCode") {
      setSmsCodeError({ isError: false, reason: null });
    }

    if (name === "password_confirmation") {
      if (value !== registerInfo.password)
        setConfirmPasswordError({ isError: true, reason: "與上項輸入之密碼不相符。" });
      else
        setConfirmPasswordError({ isError: false, reason: null });
    }
    setNameError({ isError: false, reason: null });
    // setGenderError(false);
    // setYearError(false);
    setErrorSnackBar({ ...errorSnackBar, open: false });
    setRegisterInfo({ ...registerInfo, [name]: value });
  };

  const yearsSelect = (): Array<number> => {
    let years = [];
    for (
      let i = new Date().getFullYear() - 80;
      i < new Date().getFullYear() - 1;
      i++
    ) {
      years.push(i);
    }
    return years;
  };

  function validateEmail(email: string) {
    const regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return regex.test(email.toLowerCase());
  }

  function validatePhone(phone: string) {
    const regex = /^[0-9]*$/;
    return regex.test(phone);
  }

  const closeRegisterSuccessfullyDialog = () => {
    setOpenRegisterSuccessfullyDialog(false);
    setRegisterInfo(newRegisterInfo);
    setIsRegistrationAllowed(false);
    setRedirect(true);
  };
  const closeRegisterNotAllowedDialog = () => {
    setOpenRegisterNotAllowedDialog(false);
  };
  const handleregisterUsingChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    var registerUsing = (event.target as HTMLInputElement)
      .value as RegisterUsing;
    setRegisterInfo({
      ...registerInfo,
      register_using: registerUsing,
      email: "",
      email_confirmation: "",
      password: "",
      password_confirmation: "",
    });
  };

  const handleImportClick = () => {
    setImportDialogOpen(true);
  }

  const handleImportClose = () => {
    setImportDialogOpen(false);
  }

  return (
    <StyledContainer maxWidth="md">
      <SimpleSnackBar
        open={errorSnackBar.open}
        onClose={closeErrorSnackBar}
        message={errorSnackBar.message}
      />
      <AlertDialog
        open={openRegisterSuccessfullyDialog}
        onClose={closeRegisterSuccessfullyDialog}
        message={userRole === "center" || userRole === "trial_center" ? "註冊成功！如有任何問題，歡迎電郵至info@medmindtechnology.hk 、whatsapp或致電 90337986 Hayley Tam/ 90403834 Nathan Hui聯絡。" : (registerInfo.register_using === "phone_number" ? "註冊成功！您可以使用電話號碼及預設密碼登入" : "註冊成功！請前往您輸入之電郵地址認證註冊")}
      />
      {redirect === true && <Redirect to="/login" />}
      <AlertDialog
        open={openRegisterNotAllowedDialog}
        onClose={closeRegisterNotAllowedDialog}
        message={registrationNotAllowedMessage}
      />
      <ImportCSVDialog open={importDialogOpen} onClose={handleImportClose} />
      <StyledRegisterCard>
        {isSubmitting && <StyledCircularProgress />}
        {registerInfo.code === undefined ? (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <div style={{ fontSize: 24, flex: userRole === 'admin' ? 1 : undefined }}>快速登記</div>
            {userRole === 'admin' &&
              <Button
                onClick={handleImportClick}
                color="primary"
                variant="outlined"
              >
                批量匯入帳戶資料
              </Button>
            }
            {userRole !== "admin" && (
              <Button
                href="/centerregister"
                variant="outlined"
                color="primary"
                style={{ fontSize: 24, marginLeft: 10 }}
              >
                登記中心帳戶
              </Button>
            )}
          </div>
        ) : (
          <div style={{ fontSize: 24 }}>完成中心帳戶註冊</div>
        )}
        <StyledLoginForm autoComplete="off">
          {registerInfo.code === undefined && (
            <RadioGroup
              aria-label="registerUsing"
              name="registerUsing"
              value={registerInfo.register_using}
              onChange={handleregisterUsingChange}
            >
              <FormControlLabel
                value="email"
                control={<Radio color='primary' />}
                label="電郵地址"
              />
              <FormControlLabel
                value="phone_number"
                control={<Radio color='primary' />}
                label=" 電話號碼"
                disabled={userRole === "admin" ? true : false}
              />
            </RadioGroup>
          )}

          <Grid container spacing={1} alignItems="flex-end">
            {registerInfo.register_using === "phone_number" && (
              <Grid item xs={4}>
                <TextField
                  id="countryCode"
                  select
                  label="國家/地區"
                  value={country}
                  onChange={(event) => setCountry(event.target.value)}
                  margin="normal"
                  fullWidth
                >
                  {getCountries().map((country: string) => (
                    <MenuItem key={country} value={country}>
                      {enCountryName[country]} +{getCountryCallingCode(country)}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
            )}
            <Grid
              item
              xs={registerInfo.register_using === "phone_number" ? 8 : 12}
            >
              <TextField
                error={emailError.isError}
                id="email"
                label={
                  registerInfo.register_using === "email"
                    ? "電郵地址 *"
                    : "電話號碼 *"
                }
                value={registerInfo.email}
                onChange={e => onRegisterFieldChange("email", e.target.value)}
                margin="normal"
                InputProps={{
                  readOnly: registerInfo.code !== undefined ? true : false,
                }}
                fullWidth
                helperText={emailError.isError && emailError.reason}
              />
            </Grid>
          </Grid>
          <TextField
            error={confirmEmailError.isError}
            id="email_confirmation"
            label={
              "確認" +
              (registerInfo.register_using === "email"
                ? "電郵地址"
                : "電話號碼") +
              " *"
            }
            value={registerInfo.email_confirmation}
            onChange={e => onRegisterFieldChange("email_confirmation", e.target.value)}
            margin="normal"
            InputProps={
              registerInfo.register_using === "phone_number"
                ? {
                  startAdornment: (
                    <InputAdornment position="start">
                      {"+" + getCountryCallingCode(country)}
                    </InputAdornment>
                  ),
                }
                : {
                  readOnly: registerInfo.code !== undefined ? true : false,
                }
            }
            helperText={confirmEmailError.isError && confirmEmailError.reason}
          />

          <TextField
            error={passwordError.isError}
            id="password"
            label={"密碼* (必須至少由6個字元組成)"}
            type="password"
            value={registerInfo.password}
            onChange={e => onRegisterFieldChange("password", e.target.value)}
            margin="normal"
            helperText={passwordError.isError && passwordError.reason}
          />

          <TextField
            error={confirmPasswordError.isError}
            id="password_confirmation"
            label="確認密碼*"
            type="password"
            value={registerInfo.password_confirmation}
            onChange={e => onRegisterFieldChange("password_confirmation", e.target.value)}
            margin="normal"
            helperText={confirmPasswordError.isError && confirmPasswordError.reason}
          />

          {(registerInfo.register_using === "phone_number") && smsCodeSent && (
            <TextField
              error={smsCodeError.isError}
              label="驗證碼*（由六位數字組成）"
              id="smsCode"
              value={registerInfo.smsCode}
              onChange={e => onRegisterFieldChange("smsCode", e.target.value)}
              margin="normal"
              helperText={smsCodeError.isError && smsCodeError.reason}
            />

          )}

          {registerInfo.code === undefined && (
            <TextField
              error={nameError.isError}
              id="name"
              label="姓名 *"
              value={registerInfo.name}
              onChange={e => onRegisterFieldChange("name", e.target.value)}
              margin="normal"
              helperText={nameError.isError && nameError.reason}
            />
          )}

          {/* {registerInfo.register_using === "email" &&
            <TextField
              error={phoneError}
              id="phone"
              label={"聯絡電話 *"}
              value={registerInfo.phone}
              onChange={onRegisterFieldChange("phone")}
              margin="normal"
              fullWidth
            />} */}

          {registerInfo.code === undefined && (
            <TextField
              id="referralCode"
              label="推薦碼（選填：未持有推薦碼請忽略此項）"
              value={registerInfo.referralCode}
              onChange={e => onRegisterFieldChange("referralCode", e.target.value)}
              margin="normal"
            />
          )}

          {registerInfo.code === undefined && userRole === "admin" && (
            <TextField
              // error={genderError}
              id="gender"
              select
              label="性別 *"
              value={registerInfo.gender}
              onChange={e => onRegisterFieldChange("gender", e.target.value)}
              margin="normal"
            >
              {genders.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          )}

          {registerInfo.code === undefined && userRole === "admin" && (
            <TextField
              // error={yearError}
              id="year"
              select
              label="出生年份*"
              value={registerInfo.year_of_birth}
              onChange={e => onRegisterFieldChange("year_of_birth", e.target.value)}
              margin="normal"
            >
              {yearsSelect().map((year) => (
                <MenuItem key={year} value={year}>
                  {year}
                </MenuItem>
              ))}
            </TextField>
          )}

          {registerInfo.code === undefined && userRole === "admin" && (
            <TextField
              id="occupation"
              label="職業"
              value={registerInfo.occupation}
              onChange={e => onRegisterFieldChange("occupation", e.target.value)}
              margin="normal"
              fullWidth
            />
          )}

          {registerInfo.code === undefined && userRole === "admin" && (
            <TextField
              id="use_smartphone_level"
              select
              label="使用智能電話/平板電腦的熟悉度"
              value={registerInfo.use_smartphone_level}
              onChange={e => onRegisterFieldChange("use_smartphone_level", e.target.value)}
              margin="normal"
            >
              {use_smartphone_levels.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          )}

          {registerInfo.code === undefined && userRole === "admin" && (
            <TextField
              id="education_level"
              select
              label="教育程度"
              value={registerInfo.education_level}
              onChange={e => onRegisterFieldChange("education_level", e.target.value)}
              margin="normal"
            >
              {education_levels.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          )}

          {registerInfo.code === undefined && userRole === "admin" && (
            <TextField
              id="mahjong_level"
              select
              label="打麻雀熟悉度"
              value={registerInfo.mahjong_level}
              onChange={e => onRegisterFieldChange("mahjong_level", e.target.value)}
              margin="normal"
            >
              {mahjong_levels.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          )}

          {registerInfo.code === undefined && userRole === "admin" && (
            <TextField
              id="user_role"
              select
              label="身份"
              value={registerInfo.user_role}
              onChange={e => onRegisterFieldChange("user_role", e.target.value)}
              margin="normal"
            >
              {Role.map((role) => (
                <MenuItem key={role} value={role}>
                  {role}
                </MenuItem>
              ))}
            </TextField>
          )}

          {registerInfo.code === undefined && userRole === "admin" &&
            (registerInfo.user_role === 'center' || registerInfo.user_role === 'trial_center') && (
              <>
                <TextField
                  id="displayName"
                  error={centerNameError.isError}
                  label="中心名稱*"
                  value={registerInfo.displayName}
                  onChange={e => onRegisterFieldChange("displayName", e.target.value)}
                  margin="normal"
                  fullWidth
                  helperText={centerNameError.isError && centerNameError.reason}
                />
                <TextField
                  id="quota"
                  label="Quota"
                  type="number"
                  value={registerInfo.quota}
                  onChange={e => onRegisterFieldChange("quota", e.target.value)}
                  margin="normal"
                />
              </>
            )}
          <br /><br />
          <Typography style={{ fontWeight: "bold", fontSize: "18px" }}>
            *只要您使用服務，即表示您同意本<a href="#" onClick={() => toggleTACDialog(true)}><i>「條款及細則」</i></a>，故請詳閱條款內容。
          </Typography>
          <CardActions>
            <Button
              type="submit"
              style={{ marginLeft: "auto" }}
              variant="contained"
              color="primary"
              onClick={onLoginButtonClick}
              disabled={isSubmitting || !isRegistrationAllowed}
            >
              提交
            </Button>
          </CardActions>
        </StyledLoginForm>
      </StyledRegisterCard>
      <div id="recaptcha-container"></div>
      <TermsAndConditionsDialog open={openTACDialog} toggleDialog={toggleTACDialog} />
    </StyledContainer>
  );
}
