import { Card, CircularProgress, createStyles, Divider, Grid, makeStyles, MenuItem, TextField, Theme } from '@material-ui/core';
import Backdrop from "@material-ui/core/Backdrop";
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import { SelectAll } from '@material-ui/icons';
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { CenterUserMissionScoreSnapshotData, CenterUserMissionScoreSnapshotDataKeys } from '../api';
import { domains, DomainType, getChineseDomain, getDomainColor, getDomainIconComponent } from "../types/MissionType";
import CenterTrendScatterChart, { CenterDomainDataType, CenterDomainMissionDataType, CenterDomainMissionRecordDataType, ChartMode } from './CenterTrendScatterChart';
import { Month, months, translationMonth } from "./PlaytimeTab";

const StyledVerticalTabContainer = styled.div`
  // flex-grow: 1;
  // display: flex;
`
const StyledTabPanel = styled.div`
  width: 100%;
`

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      justifyContent: "center"
    },
    scroller: {
      flexGrow: 0
    }
  }),
);

interface CenterDomainScatterChartTabsProps {
  data: CenterUserMissionScoreSnapshotData | undefined;
  mode: "time" | "score" | "time_acc";
  onChangeTimeUnit: (mode: ChartMode, year: string, month: string) => void
}

function a11yProps(index: any) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index } = props;

  return (
    <StyledTabPanel
      hidden={value !== index}
    >
      {children}
    </StyledTabPanel>
  );
}

function usePrevious(value: any) {
  const ref = useRef(undefined);
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

export default function CenterDomainScatterChartTabs(props: CenterDomainScatterChartTabsProps) {

  const [rawCenterMissionScoreData, setRawCenterMissionScoreData] = useState<CenterUserMissionScoreSnapshotData>();

  const [selectedTab, setSelectedTab] = useState<number>(0);
  const [selectedDomain, setSelectedDomain] = useState<DomainType | "overall" | undefined>("overall");
  const [allTabData, setAllTabData] = useState<CenterDomainDataType>();

  const [averageData, setAverageData] = useState<CenterDomainMissionDataType>();

  const [loading, setLoading] = useState<boolean>(true);
  const [mode, setMode] = useState<"time" | "score" | "time_acc" | undefined>(undefined);

  const [timeUnit, setTimeUnit] = useState<{ year: number, month: number } | undefined>({ year: moment().year(), month: moment().month() });

  const prevSelectedDomain = usePrevious(selectedDomain);
  const prevMode = usePrevious(mode);
  const prevTimeUnit = usePrevious(timeUnit);

  const onTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setSelectedTab(newValue);
    if (newValue === 0)
      setSelectedDomain("overall");
    else
      setSelectedDomain(domains[newValue - 1]);
  };

  useEffect(() => {
    setRawCenterMissionScoreData(props.data);
  }, [props.data])

  useEffect(() => {
    if (rawCenterMissionScoreData !== undefined && mode !== undefined && timeUnit !== undefined && selectedDomain !== undefined)
      setAllTabData(setTabData(selectedDomain));
  }, [rawCenterMissionScoreData, mode, selectedDomain]);

  useEffect(() => {
    setMode(props.mode);
  }, [props.mode]);

  useEffect(() => {
    console.log(timeUnit);
    console.log(prevTimeUnit);
    if (timeUnit == prevTimeUnit || timeUnit === undefined) return;

    setLoading(true);
    props.onChangeTimeUnit(props.mode, timeUnit.year.toString(), ("0" + timeUnit.month.toString()).slice(-2));
  }, [timeUnit])

  useEffect(() => {
    console.log(allTabData);
    if (allTabData !== undefined) {
      setLoading(false);
      computeAverageArray();
    }
  }, [allTabData])

  useEffect(() => {
    console.warn("load: " + loading);
  }, [loading])

  const computeAverageArray = () => {
    if (!allTabData) return;

    const buffer: CenterDomainMissionRecordDataType[] = [];

    const start = moment(`${timeUnit!.year}-${("0" + timeUnit!.month).slice(-2)}`);

    for (const end = start.clone().add(1, 'month'); start.isBefore(end); start.add(1, 'day')) {

      let dayValueAcc = 0;
      let dayValueCount = 0;

      for (const user of allTabData.users) {
        const target = user.record.find(r => r.time === start.valueOf());
        if (target) {
          dayValueAcc += target.score;
          dayValueCount++;
        }

      }

      if (dayValueCount > 0) {
        buffer.push({ "time": start.valueOf(), "score": dayValueAcc / dayValueCount++ });
      }
    }

    setAverageData({ "userId": "average", "name": "平均分", "record": buffer });
  }


  const setTabData = (domain: DomainType | undefined): CenterDomainDataType | undefined => {
    if (!rawCenterMissionScoreData) return undefined;
    console.log(props.mode + " called\n" + `selectedDomain: ${prevSelectedDomain} | ${selectedDomain} | ${prevSelectedDomain === selectedDomain}
    \nprevMode: ${prevMode} | ${mode} | ${prevMode === mode}
    \nprevTimeUnit: ${prevTimeUnit} | ${timeUnit} | ${prevTimeUnit === timeUnit}
    \nrawCenterMissionScoreData: ${rawCenterMissionScoreData}`);

    const isTimeMode = props.mode.includes("time");

    let domainNameKey: keyof (CenterUserMissionScoreSnapshotDataKeys) = "overall";
    switch (domain) {
      case "Attention": domainNameKey = isTimeMode ? "t_a" : "a"; break;
      case "Memory": domainNameKey = isTimeMode ? "t_m" : "m"; break;
      case "Motor": domainNameKey = isTimeMode ? "t_p" : "p"; break;
      case "Executive": domainNameKey = isTimeMode ? "t_e" : "e"; break;
      case "Perception": domainNameKey = isTimeMode ? "t_v" : "v"; break;
      case "Language": domainNameKey = isTimeMode ? "t_l" : "l"; break;
      case "overall": domainNameKey = isTimeMode ? "t_overall" : "overall"; break;
    }

    const allUserData: CenterDomainMissionDataType[] = Object.entries(rawCenterMissionScoreData).reduce<CenterDomainMissionDataType[]>((userBuffer, [userId, userData]) => {
      if (domainNameKey === "t_overall")
        userData.t_overall = new Array(userData.overall.length).fill(0);

      let cumulativeReducer = 0; // Used for calculating cumulative charts

      const recordBuffer: CenterDomainMissionRecordDataType[] = userData[domainNameKey].reduce<CenterDomainMissionRecordDataType[]>((recordArray, record, index) => {
        if (domainNameKey === "t_overall") {
          record = userData.t_a[index] + userData.t_m[index] + userData.t_p[index] + userData.t_e[index] + userData.t_l[index] + userData.t_v[index];
        }

        if (record != null) {
          // Exception: t_overall will be calculated here to reduce bandwidth used when transmitting data from server side
          recordArray.push({ "time": moment(`${timeUnit!.year}-${("0" + timeUnit!.month).slice(-2)}-${("0" + (index + 1)).slice(-2)}`).valueOf(), "score": record + cumulativeReducer })
        }

        if (props.mode.includes("acc"))
          cumulativeReducer += record;

        return recordArray;
      }, []);

      userBuffer.push({ "userId": userId, "name": userData.name, "record": recordBuffer });
      return userBuffer;
    }, []);

    const result = { "domain": domain ?? "overall", "users": allUserData }
    return result;
  }

  const classes = useStyles();

  return (
    <StyledVerticalTabContainer>
      <Card style={{ position: "relative" }}>
        <Tabs
          classes={{ root: classes.root, scroller: classes.scroller }}
          defaultValue={0}
          value={selectedTab}
          onChange={onTabChange}
          variant="scrollable"
          scrollButtons="auto"
          style={{ justifyContent: "center" }}
          TabIndicatorProps={{ style: { background: getDomainColor(selectedDomain ?? "") } }}
        >
          < Tab label="全部" {...a11yProps("overall")} style={{ width: "100px", flexGrow: 0 }} icon={<SelectAll />} />
          {domains.map((domain, key) => (
            < Tab label={getChineseDomain(domain)} {...a11yProps(key + 1)} style={{ width: "100px", flexGrow: 0, color: getDomainColor(domain) }} icon={getDomainIconComponent(domain)} />
          ))}
        </Tabs>
        <Divider />

        <Grid container>
          <Grid item>
            <TextField
              id="year"
              select
              label="年份"
              value={timeUnit?.year}
              onChange={(e) => setTimeUnit({ "year": parseInt(e.target.value), "month": timeUnit!.month })}
              margin="normal"
              style={{ width: 200, justifySelf: "flex-start", display: 'flex', margin: 40 }}
            >
              <MenuItem key={"2020"} value={"2020"}>2020年</MenuItem>
              <MenuItem key={"2021"} value={"2021"}>2021年</MenuItem>
              <MenuItem key={"2022"} value={"2022"}>2022年</MenuItem>
            </TextField>
          </Grid>
          <Grid item >
            <TextField
              id="month"
              select
              label="月份"
              value={timeUnit?.month}
              onChange={(e) => setTimeUnit({ "year": timeUnit!.year, "month": parseInt(e.target.value) as Month })}
              margin="normal"
              style={{ width: 200, justifySelf: "flex-start", display: 'flex', margin: 40 }}
            >
              {/* <MenuItem key={-1} value={-1}>全年</MenuItem> */}
              {months.map((option) => (
                <MenuItem key={option} value={option}>
                  {translationMonth[option]}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
        </Grid>

        {allTabData && <CenterTrendScatterChart mode={mode} averageData={averageData} domainData={allTabData} selectedDomain={selectedDomain} selectedYear={timeUnit!.year} selectedMonth={timeUnit!.month === -1 ? undefined : timeUnit!.month} />}
        {/* <DomainTaskTable data={tabData.filter(x => x.domain === domain)[0].missionScore} historyData={props.data} selectedTab={selectedTab} missionOnClick={missionOnClick} /> */}

        <Backdrop style={{ position: "absolute", zIndex: 999, background: "rgba(255,255,255,0.3)" }} open={loading}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </Card>
    </StyledVerticalTabContainer >
  )
}

//average={allTabData.filter(x => x.mission === domain)}