import { createStyles, makeStyles, Theme } from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { setUserProfiles } from "../actions/userProfiles";
import { getAllUserProfiles, getUserProfile, getUserProfiles } from "../api";
import firebase from '../firebase';
import { AppState } from "../reducers";
import { LoadableProfileData } from "../reducers/userProfiles";
import { UserProfile } from "../types/UserProfile";
import { RoleType } from "../types/UserRole";

const StyledFormControl = styled(FormControl)`
  width: 100%;
  align-items: center;
`;

interface Prop {
  onUserSelectChange(selectedUserProfile: UserProfile | string[]): void,
  selectedUserId?: string | string[],
  multiple?: boolean,
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    chips: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    chip: {
      margin: 2,
    },
    dropDownBox: {
      width: "100%",
      marginTop: "40px",
      marginBottom: "10px",
    }
  }),
);


export default function SelectUserDropdown(props: Prop) {

  const classes = useStyles();

  const [selectedUser, setSelectedUser] = useState<UserProfile>();

  const [selectedUsers, setSelectedUsers] = useState<string[]>([]);

  const userProfiles = useSelector<AppState, LoadableProfileData>(state => state.userProfiles);

  const userRole = useSelector<AppState, RoleType | null>(state => state.userRole);

  const loggedInUser = useSelector<AppState, firebase.User | null>(state => state.loggedInUser);

  const dispatch = useDispatch();

  const [passedSelectedUserId, setPassedSelectedUserId] = useState<string>();

  useEffect(() => {
    if (props.selectedUserId !== undefined) {
      if (props.multiple && Array.isArray(props.selectedUserId)) {
        setSelectedUsers(props.selectedUserId);
      } else {
        getUserProfile(props.selectedUserId as string).then(data => {
          if (data !== null) {
            setSelectedUser(data);
            props.onUserSelectChange(data);
          }
        })
      }
    }
  }, [props.selectedUserId]);

  const renderSelectUserOption = () => {
    let userOptions = [] as Array<any>;
    if (userProfiles.kind === "loaded" && userProfiles.data.length > 0) {
      userProfiles.data.map(profile => {
        userOptions.push(
          <MenuItem key={profile.user_id} value={profile.user_id}>{profile.name}</MenuItem>
        )
      });
    } else {
      userOptions.push(<MenuItem key={-1} value={""} disabled>{"No users"}</MenuItem>)
    }
    return userOptions;
  };

  const renderSelectUserOptionComboBox = () => {
    let userOptions: { "value": string, "name": string }[] = [];
    if (userProfiles.kind === "loaded" && userProfiles.data.length > 0) {
      userOptions = userProfiles.data.map(profile => {
        return {
          "value": profile.user_id ? profile.user_id : "unknown",
          "name": profile.name ? profile.name : "unknown",
        }
      });
    }

    return _(userOptions).sortBy(user => user.name).value();
  };

  useEffect(() => {
    const user = loggedInUser;
    if (user !== null) {
      if (userRole === "center" || userRole === "trial_center") {
        getUserProfiles(user).then(data => {
          dispatch(setUserProfiles({ kind: "loaded", data: data }));
          if (passedSelectedUserId !== undefined) {
            let tempSeletedUser = data.find(user => user.user_id === passedSelectedUserId);
            if (tempSeletedUser !== undefined) {
              setSelectedUser(tempSeletedUser);
              props.onUserSelectChange(tempSeletedUser);
            }
          }
        }, () => {
        });
      } else if (userRole === 'admin') {
        getAllUserProfiles().then(data => {
          dispatch(setUserProfiles({ kind: "loaded", data: data }));
          if (passedSelectedUserId !== undefined) {
            let tempSeletedUser = data.find(user => user.user_id === passedSelectedUserId);
            if (tempSeletedUser !== undefined) {
              setSelectedUser(tempSeletedUser);
              props.onUserSelectChange(tempSeletedUser);
            }
          }
        }, () => {
        });
      } else {
        getUserProfile(user.uid).then(data => {
          if (data !== null) {
            dispatch(setUserProfiles({ kind: "loaded", data: [data] }));
          } else {
            dispatch(setUserProfiles({ kind: "loaded", data: [] }));
          }
        }, () => {

        });
      }
    }
  }, [passedSelectedUserId]);

  //TODO show profile for individual user

  const onUserSelectChange = (event: React.ChangeEvent<{ name?: string; value: unknown }>) => {
    let selectedUserId = event.target.value;
    if (userProfiles !== undefined && userProfiles.kind === "loaded" && userProfiles.data.length > 0) {
      let selectedUserProfile = userProfiles.data.find(userProfile => userProfile.user_id === selectedUserId);
      if (selectedUserProfile !== undefined) {
        setSelectedUser(selectedUserProfile);
        props.onUserSelectChange(selectedUserProfile);
      }
    }
  };

  const onUserSelectChangeComboBox = (event: { name: string; value: string } | null) => {
    if (!event) return;
    let selectedUserId = event.value;
    if (userProfiles !== undefined && userProfiles.kind === "loaded" && userProfiles.data.length > 0) {
      let selectedUserProfile = userProfiles.data.find(userProfile => userProfile.user_id === selectedUserId);
      if (selectedUserProfile !== undefined) {
        setSelectedUser(selectedUserProfile);
        props.onUserSelectChange(selectedUserProfile);
      }
    }
  };

  const handleUsersChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setSelectedUsers(event.target.value as string[]);
  };

  useEffect(() => {
    console.log(selectedUsers);
    if (selectedUsers.length > 0) {
      props.onUserSelectChange(selectedUsers);
    }
  }, [selectedUsers])

  const getUsername = (user_id: string) => {
    if (userProfiles.kind === 'loaded') {
      const temp = userProfiles.data.find(userProfile => userProfile.user_id === user_id);
      if (temp !== undefined) {
        return temp.name;
      } else {
        return ("");
      }
    } else {
      return ("");
    }
  }

  return (
    <>
      {props.selectedUserId === undefined ?
        <StyledFormControl>
          {(props.multiple && userProfiles.kind === "loaded") ?
            <Autocomplete
              className={classes.dropDownBox}
              id="combo-box"
              options={renderSelectUserOptionComboBox()}
              groupBy={(option) => option.name.charAt(0)}
              getOptionLabel={(option) => option.name}
              renderInput={(params) => <TextField {...params} label="選擇玩家" variant="outlined" style={{ backgroundColor: "#e6f8fa" }} />}
              value={selectedUser ? { "value": selectedUser.user_id, "name": selectedUser.name } : null}
              onChange={(event, value) => onUserSelectChangeComboBox(value)}
              fullWidth={true}
            />
            :
            <Autocomplete
              className={classes.dropDownBox}
              id="combo-box"
              options={renderSelectUserOptionComboBox()}
              groupBy={(option) => option.name.charAt(0)}
              getOptionLabel={(option) => option.name}
              renderInput={(params) => <TextField {...params} label="選擇玩家" variant="outlined" style={{ backgroundColor: "#e6f8fa" }} />}
              value={selectedUser ? { "value": selectedUser.user_id, "name": selectedUser.name } : null}
              onChange={(event, value) => onUserSelectChangeComboBox(value)}
              fullWidth={true}
            />}
        </StyledFormControl>
        :
        <StyledFormControl disabled>
          <Autocomplete
            className={classes.dropDownBox}
            id="combo-box"
            options={renderSelectUserOptionComboBox()}
            groupBy={(option) => option.name.charAt(0)}
            getOptionLabel={(option) => option.name}
            renderInput={(params) => <TextField {...params} label="選擇玩家" variant="outlined" style={{ backgroundColor: "#e6f8fa" }} />}
            value={selectedUser ? { "value": selectedUser.user_id, "name": selectedUser.name } : null}
            onChange={(event, value) => onUserSelectChangeComboBox(value)}
            fullWidth={true}
          />
        </StyledFormControl>
      }
    </>
  );
};
