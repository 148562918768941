import { Container, Divider, Typography } from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import _ from 'lodash';
import moment from 'moment';
import React, { useEffect, useState } from "react";
import { CartesianGrid, Legend, ResponsiveContainer, Scatter, ScatterChart, Tooltip, XAxis, YAxis } from 'recharts';
import { getColorsWithSameSL } from "../resources/colors";
import { DomainType, getChineseDomain, getChineseMission, levelUnicode, MissionType } from "../types/MissionType";


const domainColors = ['#ff3333', '#9966cc', '#66cc33', '#ffcc33', '#33ccff', '#ff6699'];

export interface DomainDataType {
  domain: DomainType,
  missions: DomainMissionDataType[]
}

export interface DomainMissionDataType {
  mission: string,
  playedDate: string[]
  record: Map<string, DomainMissionRecordDataType>
}

export interface DomainMissionRecordDataType {
  score: number,
  sd: number
  time: number,
  detail: DomainMissionRecordDetailDataType[] | undefined
}

export interface DomainMissionRecordDetailDataType {
  mission: string | undefined,
  level: number,
  score: number,
  time: number,
}

interface TrendScatterChartProps {
  allDomainData: DomainDataType[] | undefined,
  selectedDomain: DomainType[] | undefined,
  selectedMission: MissionType[] | undefined,
  selectedYear: number | undefined,
  selectedMonth: number | undefined,
  clickLineAction?: (e: any) => void,
}

export default function TrendScatterChart(props: TrendScatterChartProps) {

  const targetTimeConstraint: moment.Moment = moment((props.selectedYear ? props.selectedYear.toString() : moment().format("YYYY")) + (props.selectedMonth ? `-${props.selectedMonth}` : "")).startOf(props.selectedMonth ? "month" : "year");
  console.log((props.selectedMonth ? "month" : "year") + " " + targetTimeConstraint.format("YYYY-MM"));

  const [chartData, setChartData] = useState<DomainMissionDataType[] | undefined>();
  const [customTicks, setCustomTicks] = useState<number[]>([]);

  const [hideLines, sethideLines] = useState<string[]>([]);

  const extractDataFromSelectedCriteria = (): DomainMissionDataType[] | undefined => {
    if (!props.allDomainData) return undefined;

    // Default value: Show all domain data
    if (props.selectedDomain!.length === 0 && props.selectedMission!.length === 0) {

      // Merge all mission data into one array inside a domain
      const result = props.allDomainData.reduce<DomainMissionDataType[]>((missionArray, domain) => {

        missionArray.push({
          "mission": domain.domain,
          "record": domain.missions.reduce<Map<string, DomainMissionRecordDataType>>((recordArray, mission) => {

            const missionRecordFilterBuffer = new Map([...mission.record].filter(([key, value]) => {
              return moment(key).isSame(targetTimeConstraint, props.selectedMonth ? "month" : "year")
            }));

            missionRecordFilterBuffer.forEach((value, key) => {
              // If there are already something inside the date, add itself into the array instead of creating new one
              if (recordArray.has(key))
                recordArray.get(key)!.detail = [...recordArray.get(key)!.detail!, ...value.detail!];
              else
                recordArray.set(key, { "score": 0, "sd": 0, "time": moment(key).valueOf(), "detail": value.detail! });
            });

            recordArray = new Map([...recordArray.entries()].sort((a, b) => moment(a[1].time).valueOf() - moment(b[1].time).valueOf()));

            recordArray.forEach((dateRecord, dateKey) => {
              dateRecord.score = Math.round(_(dateRecord.detail).meanBy(d => d.score));
              dateRecord.sd = Math.sqrt(_(dateRecord.detail!.map(detail => Math.pow(detail.score - dateRecord.score, 2))).sum() / dateRecord.detail!.length - 1);
            });

            return recordArray;
          }, new Map()),
          "playedDate": [...new Set(domain.missions.map(m => m.playedDate).flat(1))]
        });

        return missionArray;
      }, []);

      return result;
    }
    // Then, check if user selected a domain but not selecting a specific mission
    else if (props.selectedDomain!.length > 0 && props.selectedMission!.length === 0) {
      const domainMissions = props.allDomainData.find(domainData => domainData.domain === props.selectedDomain![0])?.missions;

      const buffer = domainMissions?.reduce<DomainMissionDataType[]>((missionRecordBuffer, mission) => {

        const missionRecordFilterBuffer = new Map([...mission.record].filter(([key, value]) => {
          return moment(key).isSame(targetTimeConstraint, props.selectedMonth ? "month" : "year");
        }));

        missionRecordBuffer.push({ "mission": mission.mission, "playedDate": mission.playedDate, "record": missionRecordFilterBuffer });
        return missionRecordBuffer;
      }, []);

      return buffer;
    }
    // Finally, check if user selected a specific mission
    else if (props.selectedMission) {
      // Findout the mission belongs to which domain
      const targetMission = props.allDomainData.find(domainData => domainData.missions.some(mission => mission.mission === props.selectedMission![0]))?.missions.find(mission => mission.mission === props.selectedMission![0]);
      return targetMission ? [targetMission] : [];
    }
  }

  useEffect(() => {
    setChartData(extractDataFromSelectedCriteria());
  }, [props.selectedDomain, props.selectedYear, props.selectedMonth, props.allDomainData]);

  useEffect(() => {
    setCustomTicks(generateTick());
  }, [chartData]);

  useEffect(() => {
    console.log(hideLines);
  }, [hideLines])

  useEffect(() => {

  }, [customTicks]);

  const handleLineOnClick = (e: any) => {
    if (props.clickLineAction !== undefined) {
      //props.clickLineAction(traceBack(e.payload));
    }
  }

  function CustomTooltip(props: any) {
    if (props.active) {
      let mission = "";

      // Check if all domain data is selected
      if (props.payload[0].payload.domain !== undefined) {
        mission = getChineseDomain(props.payload[0].payload.domain);
      }
      else {
        mission = getChineseMission(props.payload[0].payload.detail[0].mission);
      }

      return (
        <div>
          <Paper variant="outlined" style={{ marginTop: '-200px', width: '250px', textAlign: 'left' }} >
            <Container style={{ marginTop: "15px", marginBottom: "15px" }}>
              <Typography variant="h6" align="center" style={{ fontWeight: "bold", marginBottom: "10px" }}>{mission}</Typography>
              <div style={{ marginTop: "10px", fontSize: "14px" }}>
                <Divider />
                <table style={{ width: "100%", marginTop: "10px", marginBottom: "10px" }}><tbody>
                  <tr style={{ width: "100%" }}><td align="right" style={{ width: "50%", color: "#999999" }}>統計時間段&nbsp;</td><td style={{ width: "50%" }}>&nbsp;{moment.utc(props.payload[0].payload.time).local().format('MMM Do')}</td></tr>
                  <tr style={{ width: "100%" }}><td align="right" style={{ width: "50%", color: "#999999" }}>本日平均分&nbsp;</td><td style={{ width: "50%" }}>&nbsp;{props.payload[0].payload.score}分</td></tr>
                  <tr style={{ width: "100%" }}><td align="right" style={{ width: "50%", color: "#999999" }}>分數範圍&nbsp;</td><td style={{ width: "50%" }}>&nbsp;{_(props.payload[0].payload.detail).minBy(d => d.score).score} - {_(props.payload[0].payload.detail).maxBy(d => d.score).score}分</td></tr>
                  <tr style={{ width: "100%" }}><td align="right" style={{ width: "50%", color: "#999999" }}>標準偏差&nbsp;</td><td style={{ width: "50%" }}>&nbsp;{isNaN(props.payload[0].payload.sd) ? "-" : `${Math.round(props.payload[0].payload.sd * 100) / 100}分`}</td></tr>
                </tbody></table>
                <Divider />
                {(props.selectedDomain?.length === 0 && props.selectedMission?.length === 0) ?
                  (<table style={{ width: "100%", marginTop: "10px" }}><tbody>
                    <tr style={{ width: "100%", marginBottom: "5px" }}>
                      <td style={{ color: "#999999", width: "33%", textAlign: "center" }}>等級</td>
                      <td style={{ color: "#999999", width: "33%", textAlign: "center" }}>分數</td>
                      <td style={{ color: "#999999", width: "34%", textAlign: "center" }}>記錄時間</td>
                      <tr style={{ width: "100%" }}><td align="right" style={{ width: "50%", color: "#999999" }}>分數範圍&nbsp;</td><td style={{ width: "50%" }}>&nbsp;{_(props.payload[0].payload.detail).minBy(d => d.score).score} - {_(props.payload[0].payload.detail).maxBy(d => d.score).score}分</td></tr>
                      <tr style={{ width: "100%" }}><td align="right" style={{ width: "50%", color: "#999999" }}>標準偏差&nbsp;</td><td style={{ width: "50%" }}>&nbsp;{isNaN(props.payload[0].payload.sd) ? "-" : `${Math.round(props.payload[0].payload.sd * 100) / 100}分`}</td></tr>
                    </tr>
                    {props.payload[0].payload.detail.map((entry: { time: number, score: number, level: number }) =>
                    (<tr style={{ width: "100%" }}>
                      <td style={{ width: "33%", textAlign: "center" }}>{`Lv. ${levelUnicode.length - 1 >= entry.level ? levelUnicode[entry.level] : entry.level}`}</td>
                      <td style={{ width: "33%", textAlign: "center" }}>{`${entry.score}分`}</td>
                      <td style={{ width: "34%", textAlign: "center" }}>{`${moment(entry.time).format('HH:mm:ss')}`}</td>
                    </tr>
                    ))}
                  </tbody></table>)
                  :
                  (<table style={{ width: "100%", marginTop: "10px" }}>
                    <tr style={{ width: "100%", marginBottom: "5px" }}>
                      <td style={{ color: "#999999", width: "40%", textAlign: "center" }}>遊戲名稱</td>
                      <td style={{ color: "#999999", width: "30%", textAlign: "center" }}>等級</td>
                      <td style={{ color: "#999999", width: "30%", textAlign: "center" }}>分數</td>
                    </tr>
                    {props.payload[0].payload.detail.map((entry: { mission: string, time: number, score: number, level: number }) =>
                    (<tr style={{ width: "100%" }}>
                      <td style={{ width: "40%", textAlign: "center" }}>{getChineseMission(entry.mission)}</td>
                      <td style={{ width: "30%", textAlign: "center" }}>{`Lv. ${levelUnicode.length - 1 >= entry.level ? levelUnicode[entry.level] : entry.level}`}</td>
                      <td style={{ width: "30%", textAlign: "center" }}>{`${entry.score}分`}</td>
                    </tr>
                    ))}
                  </table>)
                }
              </div>
            </Container>
          </Paper>
        </div>)
    } else {
      return (
        null
      )
    }
  }

  const generateTick = () => {
    const start: moment.Moment = moment((props.selectedYear ? props.selectedYear.toString() : moment().format("YYYY")) + (props.selectedMonth ? `-${props.selectedMonth}` : ""));
    const resultArray = [];

    if (!props.selectedMonth) {
      for (const end = start.clone().add(1, 'year'); start.isBefore(end); start.add(1, 'month')) {
        resultArray.push(start.valueOf());
      }
    }
    else {
      for (const end = start.clone().add(1, 'month'); start.isBefore(end); start.add(1, 'day')) {
        resultArray.push(start.valueOf());
      }
    }

    return resultArray;
  }

  const handleMouseEnter = (evt: any) => {
    sethideLines(hideLines => [...hideLines, evt.value]);
  }

  const handleMouseLeave = (evt: any) => {
    sethideLines(hideLines => hideLines.filter(str => str !== evt.value));
  }

  const mapTrendChartData = () => {
    const colors = getColorsWithSameSL(chartData!.length, 0.5, 0.4);

    return chartData!.map((entry, index) => {
      return (
        <Scatter
          name={(props.selectedDomain?.length === 0 && props.selectedMission?.length === 0) ? getChineseDomain(entry.mission) : getChineseMission(entry.mission)}
          data={[...entry.record.values()].map(value => { return { ...value, ...{ "domain": (props.selectedDomain?.length === 0 && props.selectedMission?.length === 0) ? entry.mission : undefined } } })} fill={colors[index]}
          line
          opacity={hideLines.length > 0 && !hideLines.includes(getChineseDomain(entry.mission)) && !hideLines.includes(getChineseMission(entry.mission)) ? 0.1 : 1}
        />
      )
    });
  }

  return (
    <ResponsiveContainer width="100%" height={288}>
      <ScatterChart
        width={1}
        height={288}
        margin={{
          top: 15, right: 30, left: 10, bottom: 65,
        }}
      >
        <CartesianGrid />
        <XAxis type="number" interval={0} dataKey={'time'} name="Time"
          domain={
            props.selectedMonth ?
              [
                moment((props.selectedYear ? props.selectedYear.toString() : moment().format("YYYY")) + "-" + props.selectedMonth).startOf("month").valueOf(),
                moment((props.selectedYear ? props.selectedYear.toString() : moment().format("YYYY")) + "-" + props.selectedMonth).endOf("month").valueOf()
              ] :
              [
                moment(props.selectedYear ? props.selectedYear.toString() : moment().format("YYYY")).startOf("year").valueOf(),
                moment(props.selectedYear ? props.selectedYear.toString() : moment().format("YYYY")).endOf("year").valueOf()
              ]}
          tickFormatter={(unixTime) => props.selectedMonth ? moment(unixTime).format('MMM Do') : moment(unixTime).format('YYYY年 MMM')}
          ticks={customTicks}
          angle={-35}
          textAnchor="end"
        />
        <YAxis type="number" dataKey={'score'} name="分數" domain={[0, 100]} />
        <Tooltip cursor={{ strokeDasharray: '3 3' }} content={<CustomTooltip />} />
        <Legend wrapperStyle={{ bottom: "30px" }} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} />
        {
          chartData &&
          mapTrendChartData()
        }
      </ScatterChart>
    </ResponsiveContainer>
  )

}