import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, List, ListItem, ListItemIcon, ListItemText, MenuItem, Select, Typography } from "@material-ui/core";
import { Assignment, EmojiEvents } from "@material-ui/icons";
import React, { useEffect, useState } from "react";
import { getDisplayName } from "../api";
import { usernames } from "../pages/DiscoverPage";
import { GridCard } from "../StyledComponents";
import { getChineseMission } from "../types/MissionType";
import { MissionLevelDistributionChart } from "./MissionLevelDistributionChart";
import { MissionScoreDistributionChart } from "./MissionScoreDistributionChart";

export const ScoreDistributionPanel = (props: { missionKey: string, allFreq: number, data: any }) => {
  const [level, setLevel] = useState<number>(1);

  const [imageIcon, setImageIcon] = useState<any>();

  const [detailDialogOpen, setDetailDialogOpen] = useState<boolean>(false);

  const [missionTotalPlayedTime, setMissionTotalPlayedTime] = useState<number | string>("-");
  const [missionPlayedTimePercentage, setMissionPlayedTimePercentage] = useState<number | string>("-");
  const [missionAverageLevel, setMissionAverageLevel] = useState<number | string>("-");
  const [missionUniquePlayer, setMissionUniquePlayer] = useState<number | string>("-");
  const [currentLevelTotalPlayedTime, setCurrentLevelTotalPlayedTime] = useState<number | string>("-");
  const [currentLevelPlayedPercentage, setCurrentLevelPlayedPercentage] = useState<number | string>("-");
  const [currentLevelAverageScore, setCurrentLevelAverageScore] = useState<number | string>("-");
  const [currentLevelSD, setCurrentLevelSD] = useState<number | string>("-");

  const [levelDistribution, setLevelDistribution] = useState<{ [key: number]: number }>();

  useEffect(() => {
    try {
      setImageIcon(require(`../resources/minigame/${props.missionKey}.jpg`));
    }
    catch {
      setImageIcon(require(`../resources/minigame/TrainMode.jpg`));
    }

    setMissionTotalPlayedTime(getMissionTotalPlayedTime());
    setLevelDistribution(calcLevelDistribution());
    setMissionUniquePlayer(getMissionUniquePlayer());
  }, []);

  useEffect(() => {
    if (missionTotalPlayedTime !== "-") {
      setMissionAverageLevel(getMissionAverageLevel());
      setMissionPlayedTimePercentage(getMissionPlayedTimePercentage());
    }
  }, [missionTotalPlayedTime])

  useEffect(() => {
    setCurrentLevelTotalPlayedTime(getCurrentLevelTotalPlayedTime());
  }, [level])

  useEffect(() => {
    if (currentLevelTotalPlayedTime !== "-") {
      setCurrentLevelAverageScore(getCurrentLevelAverageScore());
      setCurrentLevelPlayedPercentage(getCurrentLevelPlayedPercentage());
    }
  }, [currentLevelTotalPlayedTime]);

  useEffect(() => {
    if (currentLevelAverageScore !== "-")
      setCurrentLevelSD(getCurrentLevelSD());
  }, [currentLevelAverageScore]);

  const levelSelectMenu = () => (
    <div style={{ float: "right", marginTop: "-16px", marginRight: "-16px" }}>
      <Select
        labelId="demo-simple-select-label"
        id="levelSelect"
        value={level}
        onChange={(e) => setLevel(e.target.value as number)}
      >
        {
          [...Array(Object.keys(props.data.levels).length + 1).keys()].splice(1).map((num) => (
            <MenuItem key={num} value={num}>{`Lv.${num}`}</MenuItem>
          ))
        }

      </Select>
      <IconButton onClick={() => setDetailDialogOpen(true)} color="primary">
        <Assignment />
      </IconButton>
    </div>
  )

  const calcLevelDistribution = () => {
    return Object.entries(props.data.levels).reduce<{ [key: number]: number }>((buffer, [level, levelData]: [string, any]) => {
      buffer[parseInt(level)] = levelData.metadata.freq;
      return buffer;
    }, {});
  };

  const getMissionTotalPlayedTime = () => {
    return props.data.metadata.freq;
  }

  const getMissionPlayedTimePercentage = () => {
    if (currentLevelTotalPlayedTime === 0) return 0;

    return Math.round((props.data.metadata.freq as number) / props.allFreq * 10000) / 100;
  }

  const getMissionUniquePlayer = () => {
    return props.data.metadata.uniq;
  }

  const getMissionAverageLevel = () => {
    if (currentLevelTotalPlayedTime === 0) return "-";

    return Math.round(props.data.metadata.avg_lv * 100) / 100;
  }

  const getCurrentLevelTotalPlayedTime = () => {
    return props.data.levels[level].metadata.freq;
  }

  const getCurrentLevelPlayedPercentage = () => {
    return Math.round((currentLevelTotalPlayedTime as number) / (missionTotalPlayedTime as number) * 10000) / 100;
  }

  const getCurrentLevelAverageScore = () => {
    if (currentLevelTotalPlayedTime === 0) return "-";

    return Math.round(props.data.levels[level].metadata.avg * 100) / 100;
  }

  const getCurrentLevelSD = () => {
    if (currentLevelTotalPlayedTime === 0) return "-";

    return Math.round(props.data.levels[level].metadata.sd * 100) / 100;
  }

  const DiscoverMissonDetailDialog = () => {
    return (
      <Dialog
        open={detailDialogOpen}
        fullWidth
        disableBackdropClick={false}
        onClose={() => setDetailDialogOpen(false)}
        maxWidth={"lg"}
      >
        <DialogTitle>{getChineseMission(props.missionKey)}</DialogTitle>
        <DialogContent dividers>
          <Grid container justify="center" spacing={4}>
            <Leaderboard title="個人遊玩次數排行榜" desc="顯示遊玩最多次這個遊戲的用戶" unit="次" data={props.data.leaderboard.indiFreq} />
            <Leaderboard title="個人平均分數排行榜" desc="顯示這個遊戲最高平均分數的用戶" unit="分" data={props.data.leaderboard.indiScore} />
            <Leaderboard center title="機構遊玩次數排行榜" desc="顯示遊玩最多次這個遊戲的機構" unit="次" data={props.data.leaderboard.centerFreq} />
            <Leaderboard center title="機構平均分數排行榜" desc="顯示這個遊戲的最高平均分數的機構" unit="分" data={props.data.leaderboard.centerScore} />
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" disableElevation onClick={() => setDetailDialogOpen(false)} color="primary">
            關閉
        </Button>
        </DialogActions>
      </Dialog>
    )
  }

  const Leaderboard = (props: { title: string, desc: string, unit: string, center?: boolean, data: { [key: string]: number } }) => {

    const DisplayName = (props: { id: string }) => {

      const [displayName, setDisplayName] = useState<string>();

      useEffect(() => {
        set();
      }, []);

      const set = async () => {
        setDisplayName(await getDisplayName(props.id));
      }

      return (
        <>
          {displayName ?? <span style={{ color: "red" }}>未輸入機構名字</span>}
        </>
      )
    }

    return (
      <Grid item xl={6} md={12}>
        <GridCard title={props.title} desc={props.desc}>
          <div style={{ marginLeft: 10 }}>
            <List>
              {Object.entries(props.data).map(([id, value], index) => (
                <ListItem>
                  <ListItemIcon>
                    {
                      index === 0 ? <EmojiEvents style={{ color: "#c9b037" }} /> :
                        index === 1 ? <EmojiEvents style={{ color: "#B4B4B4" }} /> :
                          index === 2 ? <EmojiEvents style={{ color: "#ad8a56" }} /> :
                            undefined
                    }
                  </ListItemIcon>
                  <ListItemText
                    primary={
                      <>
                        <Typography>{props.center === true ? <DisplayName id={id} /> : (usernames[id as string] ?? id)}</Typography>
                        <Typography style={{ position: "absolute", right: 25, top: 25 }}>
                          {Math.round(value * 10) / 10}
                          <span style={{ fontSize: 12 }}>{props.unit}</span>
                        </Typography>
                      </>
                    }
                    secondary={id}
                  />
                </ListItem>
              ))}
            </List>
          </div>
        </GridCard>
      </Grid>
    );
  }

  return (
    <Grid item md={4} sm={6} xs={12}>
      <DiscoverMissonDetailDialog />
      <GridCard title={`${getChineseMission(props.missionKey)}`} headerAddon={levelSelectMenu()} backgroundImage={imageIcon}>
        <fieldset style={{ borderRadius: 5, borderColor: "#ddd", margin: "20px", borderStyle: "solid", borderWidth: "1.5px" }}>
          <legend style={{ margin: "0 auto", color: "#888" }}>此小遊戲</legend>
          <Grid container alignItems="center" style={{ margin: 5 }}>
            <Grid item md={3}>
              <div style={{ fontSize: "14px", textAlign: "center", color: "#888" }}>
                遊玩次數
              </div>
              <div style={{ fontSize: "20px", textAlign: "center", marginTop: 2 }}>
                {missionTotalPlayedTime}
                <span style={{ fontSize: "12px" }}>次</span>
              </div>
            </Grid>
            <Grid item md={3}>
              <div style={{ fontSize: "14px", textAlign: "center", color: "#888" }}>
                遊戲次數佔比
              </div>
              <div style={{ fontSize: "20px", textAlign: "center", marginTop: 2 }}>
                {missionPlayedTimePercentage}
                <span style={{ fontSize: "12px" }}>%</span>
              </div>
            </Grid>
            <Grid item md={3}>
              <div style={{ fontSize: "14px", textAlign: "center", color: "#888" }}>
                平均等級
              </div>
              <div style={{ fontSize: "20px", textAlign: "center", marginTop: 2 }}>
                <span style={{ fontSize: "12px" }}>Lv.</span>
                {missionAverageLevel}
              </div>
            </Grid>
            <Grid item md={3}>
              <div style={{ fontSize: "14px", textAlign: "center", color: "#888" }}>
                獨立玩家
              </div>
              <div style={{ fontSize: "20px", textAlign: "center", marginTop: 2 }}>
                {missionUniquePlayer}
                <span style={{ fontSize: "12px" }}>人</span>
              </div>
            </Grid>
          </Grid>
        </fieldset>

        <fieldset style={{ borderRadius: 5, borderColor: "#ddd", margin: "20px", borderStyle: "solid", borderWidth: "1.5px" }}>
          <legend style={{ margin: "0 auto", color: "#888" }}>此遊戲遊玩次數分佈</legend>
          {levelDistribution && <MissionLevelDistributionChart data={Object.entries(levelDistribution)} />}
        </fieldset>

        <fieldset style={{ borderRadius: 5, borderColor: "#ddd", margin: "20px", borderStyle: "solid", borderWidth: "1.5px" }}>
          <legend style={{ margin: "0 auto", color: "#888" }}>此等級</legend>
          <Grid container alignItems="center" style={{ margin: 5 }}>
            <Grid item md={3}>
              <div style={{ fontSize: "14px", textAlign: "center", color: "#888" }}>
                遊玩次數
              </div>
              <div style={{ fontSize: "20px", textAlign: "center", marginTop: 2 }}>
                {currentLevelTotalPlayedTime}
                <span style={{ fontSize: "12px" }}>次</span>
              </div>
            </Grid>
            <Grid item md={3}>
              <div style={{ fontSize: "14px", textAlign: "center", color: "#888" }}>
                等級次數佔比
              </div>
              <div style={{ fontSize: "20px", textAlign: "center", marginTop: 2 }}>
                {currentLevelPlayedPercentage}
                <span style={{ fontSize: "12px" }}>%</span>
              </div>
            </Grid>
            <Grid item md={3}>
              <div style={{ fontSize: "14px", textAlign: "center", color: "#888" }}>
                平均分
              </div>
              <div style={{ fontSize: "20px", textAlign: "center", marginTop: 2 }}>
                {currentLevelAverageScore}
                <span style={{ fontSize: "12px" }}>分</span>
              </div>
            </Grid>
            <Grid item md={3}>
              <div style={{ fontSize: "14px", textAlign: "center", color: "#888" }}>
                標準差
              </div>
              <div style={{ fontSize: "20px", textAlign: "center", marginTop: 2 }}>
                {currentLevelSD}
                <span style={{ fontSize: "12px" }}>分</span>
              </div>
            </Grid>
          </Grid>
        </fieldset>

        <fieldset style={{ borderRadius: 5, borderColor: "#ddd", margin: "20px", borderStyle: "solid", borderWidth: "1.5px" }}>
          <legend style={{ margin: "0 auto", color: "#888" }}>此等級分數分佈</legend>
          <MissionScoreDistributionChart data={Object.entries(props.data.levels[level].scores)} />
        </fieldset>

      </GridCard>
    </Grid>
  )
}