import { Grid, Tab, Tabs } from "@material-ui/core";
import Container from "@material-ui/core/Container/Container";
import { TableChartOutlined, TimelineOutlined, TrendingUpOutlined } from "@material-ui/icons";
import React, { useEffect, useState } from "react";
import { getUserGameBehaviorLog, MahjongBehaviorAverageData } from "../api";
import { GridCard } from "../StyledComponents";
import { actions, ActionType, GameBehavior, MahjongGameBehaviorTrendChartData } from "../types/GameBehaviorLog";
import { GameLog } from "../types/GameLog";
import { MissionData } from "../types/MissionData";
import MahjongGameBehaviorChartTabs from "./MahjongGameBehaviorChartTabs";
import MahjongHistoryChart from "./MahjongHistoryChart";
import MahjongHistoryTable from "./MahjongHistoryTable";
import MahjongSummaryTable from "./MahjongSummaryTable";

interface MahjongTabProps {
  uid: string;
  mahjongData: GameLog[] | undefined;
  missionData: MissionData[];
  mahjongBehaviorAverage: MahjongBehaviorAverageData | undefined;
  mahjongBehaviorTrendData: MahjongGameBehaviorTrendChartData[][] | undefined;
}

const getTableData = (action: ActionType, userBehaviors: GameBehavior[] | GameBehavior, mahjongBehaviorAverage: MahjongBehaviorAverageData | undefined) => {
  let avgTime: number | null = 0;
  let accuracy: number | null = 0;
  let lengthCount = 0;
  let timefulLengthCount = 0;

  if (Array.isArray(userBehaviors) && userBehaviors.length > 0) {
    userBehaviors.forEach(userBehavior => {
      if (userBehavior !== undefined && userBehavior.behaviors !== undefined) {
        const behavior = userBehavior.behaviors.filter(x => x.type === action);
        avgTime! += behavior.reduce((a, b) => b.time === undefined ? a + 0 : a + b.time, 0);
        accuracy! += behavior.filter(x => x.status === "fail").length;
        timefulLengthCount += behavior.filter(x => x.time).length;
        lengthCount += behavior.length;
      }
    })
  } else if (!Array.isArray(userBehaviors)) {
    const behavior = userBehaviors.behaviors.filter(x => x.type === action);
    avgTime += behavior.reduce((a, b) => b.time === undefined ? a + 0 : a + b.time, 0);
    accuracy += behavior.filter(x => x.status === "fail").length;
    timefulLengthCount += behavior.filter(x => x.time).length;
    lengthCount += behavior.length;
  }

  avgTime = timefulLengthCount === 0 ? null : (Math.round(avgTime / timefulLengthCount * 100) / 100);
  accuracy = lengthCount === 0 ? null : Math.round(accuracy / lengthCount * 100 * 100) / 100;
  const all_player_avgTime = mahjongBehaviorAverage && mahjongBehaviorAverage[action] ? Math.round(mahjongBehaviorAverage[action].average_time * 100) / 100 : undefined;
  const all_player_accuracy = mahjongBehaviorAverage && mahjongBehaviorAverage[action] ? Math.round(mahjongBehaviorAverage[action].average_wrong_rate * 10000) / 100 : undefined;

  return { avgTime: avgTime, accuracy: accuracy, all_player_avgTime: all_player_avgTime, all_player_accuracy: all_player_accuracy };
}

export const getTableRow = (userBehaviors: GameBehavior[] | GameBehavior, mahjongBehaviorAverage: MahjongBehaviorAverageData | undefined) => {
  let _tableData = [];
  for (let i = 0; i < 8; i++) {
    const tempRow = getTableData(((Object.keys(actions))[i] as ActionType), userBehaviors, mahjongBehaviorAverage);
    _tableData.push({ ...tempRow, type: (Object.values(actions))[i] });
  }
  return _tableData;
}

export default function MahjongTab(props: MahjongTabProps) {

  const [userBehaviors, setUserBehaviors] = useState<GameBehavior[]>();
  const [tableData, setTableData] = useState<Array<{ type: string, accuracy: number | null, avgTime: number | null, all_player_accuracy: number | undefined, all_player_avgTime: number | undefined }>>();
  const [tabNumber, setTabNumber] = useState<number>(0);

  const [isLoadingUserBehavior, setIsLoadingUserBehavior] = useState<boolean>(true);

  useEffect(() => {
    _setUserBehavior();
  }, [])

  useEffect(() => {
    if (userBehaviors !== undefined) {
      setTableData(getTableRow(userBehaviors, props.mahjongBehaviorAverage));
    }
  }, [userBehaviors])

  useEffect(() => {
    console.log(tableData);
  }, [tableData]);

  function notEmpty<TValue>(value: TValue | null | undefined): value is TValue {
    return value !== null && value !== undefined;
  }

  const _setUserBehavior = async () => {
    const data = await getUserGameBehaviorLog(props.uid);

    setIsLoadingUserBehavior(false);

    if (data !== undefined) {
      const selectedUserBehavior = data.reduce<GameBehavior[]>((buffer, match) => {
        if (!match) return buffer;
        const behaviorInCurrentLog = match.players.find(player => player.user_id === props.uid);
        behaviorInCurrentLog && buffer.push({ ...behaviorInCurrentLog, timestamp: match.timestamp });
        return buffer;
      }, []);
      setUserBehaviors(selectedUserBehavior);
    }
  }

  const handleTabChange = (event: React.ChangeEvent<any>, newValue: number) => {
    setTabNumber(newValue);
  }

  return (
    <>
      <Container style={{ minHeight: '100px', paddingBottom: 20, marginTop: 40 }}>
        <Grid container spacing={4}>
          <Grid item md={12} sm={12}>
            <GridCard title="麻雀遊戲玩家行動統計" desc="顯示用戶在有參與的麻雀遊戲中行動的反應時間和違反規則幾率">
              <Tabs
                value={tabNumber}
                onChange={handleTabChange}
                indicatorColor="primary"
                textColor="primary"
              >
                <Tab label="數據表格" icon={<TableChartOutlined />} />
                <Tab label="反應時間趨勢圖表" icon={<TrendingUpOutlined />} />
                <Tab label="錯誤率趨勢圖表" icon={<TimelineOutlined />} />
              </Tabs>
              {tabNumber === 0 && <MahjongSummaryTable tableData={tableData} isLoadingUserBehavior={isLoadingUserBehavior} />}
              {tabNumber === 1 && (tableData !== undefined ? <MahjongGameBehaviorChartTabs data={userBehaviors} mode={0} /> : '未有資料')}
              {tabNumber === 2 && (tableData !== undefined ? <MahjongGameBehaviorChartTabs data={userBehaviors} mode={1} /> : '未有資料')}
            </GridCard>
          </Grid>
          <MahjongHistoryChart mahjongData={props.mahjongData} uid={props.uid} />
          {userBehaviors !== undefined && <MahjongHistoryTable mahjongData={props.mahjongData} behaviorData={userBehaviors} mahjongBehaviorAverage={props.mahjongBehaviorAverage} />}
        </Grid>
      </Container>
      {/* <MahjongChallengeModeTable missionData={props.missionData.filter(x => x.in_game === true)} /> */}
    </>
  )
}