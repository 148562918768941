import { Button, Card, CardContent, CardHeader, Grid, IconButton, Tooltip, Typography } from '@material-ui/core';
import Avatar from '@material-ui/core/Avatar';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import HistoryIcon from '@material-ui/icons/History';
import RecordVoiceOverIcon from '@material-ui/icons/RecordVoiceOver';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { deleteAnnouncement, getAnnouncements, getDisplayName } from '../api';
import AppBar from "../components/AppBar";
import DeleteDialog from '../components/DeleteDialog';
import NewAnnouncementTable from '../components/NewAnnouncementTable';
import SelectUserDropdown from '../components/SelectUserDropdown';
import SimpleSnackBar, { SnackBarProp } from '../components/SimpleSnackBar';
import { StyledCircularProgress, StyledContainer } from '../StyledComponents';
import { Announcement } from '../types/Announcement';
import { UserProfile } from '../types/UserProfile';

interface LoadedData {
  kind: "loaded";
  announcements: Announcement[];
}

interface LoadingData {
  kind: "loading";
}

interface LoadingDataError {
  kind: "error";
  error?: string;
}

type LoadableData = LoadedData | LoadingData | LoadingDataError;

export default function AnnouncementPage() {

  const closeErrorSnackBar = () => {
    setSnackBar({ ...snackBar, "open": false });
  }

  const [data, setData] = useState<LoadableData>();

  const [selectedUser, setSelectedUser] = useState<UserProfile>();

  const [selectedAnnouncement, setSelectedAnnouncement] = useState<Announcement | undefined>();

  const [deleteDialogOpen, setDeleteDialogOpen] = useState<boolean>(false);

  const [newAnnouncementOpen, setNewAnnouncementOpen] = useState<boolean>(false);

  const [snackBar, setSnackBar] = useState<SnackBarProp>({
    open: false,
    onClose: closeErrorSnackBar,
    message: "",
    variant: undefined,
  });

  useEffect(() => {
    if (selectedUser !== undefined) {
      _setData(selectedUser.user_id);
    }
  }, [selectedUser])

  useEffect(() => {
    console.log(data);
    setDisplayName();
  }, [data])

  const _setData = async (uid: string) => {
    try {
      const tempAnnouncements = await getAnnouncements(uid);
      if (tempAnnouncements !== undefined) {
        setData({ kind: "loaded", announcements: tempAnnouncements });
      }
    } catch (e) {
      setData({ kind: "error", error: e });
    }
  }

  const setDisplayName = async () => {
    if (data !== undefined && data.kind === "loaded") {
      let tempData: Announcement[] = await Promise.all(data.announcements.map(async x => {
        const tempDisplayName = await getDisplayName(x.createdBy);
        return { ...x, createdBy: tempDisplayName };
      }));
      setData({ kind: "loaded", announcements: tempData });
    }
  }

  const onUserSelectChange = (selectedUserProfile: UserProfile) => {
    if (selectedUserProfile !== undefined) {
      setSelectedUser(selectedUserProfile);
    }
  };

  const handleDeleteDialogOpen = (data: Announcement | undefined) => {
    if (data !== undefined) {
      setSelectedAnnouncement(data);
      setDeleteDialogOpen(true);
    }
  }

  const handleDeleteDialogClose = () => {
    setDeleteDialogOpen(false);
  }

  const handleDeleteAnnouncement = async (id: string) => {
    try {
      const result = await deleteAnnouncement(id);
      setSnackBar({
        ...snackBar,
        message: "刪除公告成功！",
        open: true,
        variant: "success",
      });
      setDeleteDialogOpen(false);
      if (selectedUser !== undefined) {
        _setData(selectedUser.user_id);
      }
    } catch (e) {
      setSnackBar({
        ...snackBar,
        message: e,
        open: true,
        variant: "warning",
      });
    }
  }

  const handleNewAnnoucement = () => {
    setSelectedAnnouncement(undefined);
    setNewAnnouncementOpen(!newAnnouncementOpen);
    if (selectedUser !== undefined) {
      _setData(selectedUser.user_id);
    }
  }


  const handleAnnouncementEdit = (data: Announcement | undefined) => {
    if (data !== undefined) {
      setSelectedAnnouncement(data);
      setNewAnnouncementOpen(true);
    }
  }

  return (
    <div>
      <AppBar title={"公告"} />
      <StyledContainer style={{ paddingTop: 90, paddingBottom: 80 }}>
        <Grid container style={{ marginBottom: 25 }}>
          <Grid item sm={10} xs={9}>
            <Typography variant="h5" component="h2" >
              {newAnnouncementOpen ? (selectedAnnouncement === undefined ? "發出公告" : "更改公告") : "檢視玩家公告"}
            </Typography>
          </Grid>
          <Grid item sm={2} xs={3} >
            <Button onClick={handleNewAnnoucement} color="primary" variant="contained" endIcon={newAnnouncementOpen ? <HistoryIcon /> : <RecordVoiceOverIcon />}>
              {newAnnouncementOpen ? "檢視玩家公告" : (selectedAnnouncement === undefined ? "發出公告" : "檢視玩家公告")}
            </Button>
          </Grid>
        </Grid>
        {newAnnouncementOpen ?
          <NewAnnouncementTable snackBar={snackBar} setSnackbar={setSnackBar} announcement={selectedAnnouncement} />
          :
          <>
            <Grid container>
              <Grid item sm={6} md={4} xl={4} xs={12}>
                {selectedUser === undefined ? <SelectUserDropdown onUserSelectChange={onUserSelectChange} /> :
                  <SelectUserDropdown onUserSelectChange={onUserSelectChange} selectedUserId={selectedUser.user_id} />}
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              {data !== undefined && data.kind === "loading" && <StyledCircularProgress />}
              {(data !== undefined && data.kind === "loaded") && (data.announcements.length === 0 ? "現沒有公告" :
                data.announcements.map(x => (
                  <Grid item sm={6} xs={12}>
                    <Card style={{ width: '100%', marginTop: 35 }}>
                      <CardHeader
                        avatar={
                          <Avatar>
                            Med
                          </Avatar>
                        }
                        action={
                          <>
                            <Tooltip title="更改公告">
                              <IconButton onClick={() => handleAnnouncementEdit(x)}>
                                <EditIcon />
                              </IconButton>
                            </Tooltip>
                            <Tooltip title="刪除公告">
                              <IconButton onClick={() => handleDeleteDialogOpen(x)}>
                                <DeleteIcon />
                              </IconButton>
                            </Tooltip>
                          </>
                        }
                        title={x.createdBy}
                        subheader={moment.utc(x.createdAt).local().format('YYYY年 MMM Do HH:mm:ss')}
                      />
                      <CardContent>
                        <Typography variant="h5">
                          {x.title}
                        </Typography>
                        <Typography variant="subtitle1">
                          {x.message}
                        </Typography>
                      </CardContent>
                    </Card>
                  </Grid>
                )))}
            </Grid>
          </>}
        {selectedAnnouncement !== undefined && selectedAnnouncement.code !== undefined &&
          < DeleteDialog open={deleteDialogOpen} onClose={handleDeleteDialogClose} type="公告" selectedName={""} selectedId={selectedAnnouncement.code} handleDelete={handleDeleteAnnouncement} />
        }
        <SimpleSnackBar open={snackBar.open} onClose={closeErrorSnackBar} message={snackBar.message} variant={snackBar.variant} />
      </StyledContainer>
      <AppBar title={"公告"} bottom={true} />
    </div>
  )
}

