import { ButtonProps } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import React, { useEffect, useState } from 'react';

interface ButtonWithLoadingProps extends ButtonProps {
  onClick: () => void,
  isLoading: boolean,
}

const ButtonWithLoading = (props: ButtonWithLoadingProps) => {

  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    setIsLoading(props.isLoading);
  }, [props]);

  return (
    <div style={{ position: 'relative' }}>
      <Button onClick={props.onClick} color="primary" disabled={isLoading} style={props.style} variant="contained" >
        {props.children}
      </Button>
      {isLoading && <CircularProgress size={24} style={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
      }} />}
    </div>
  )
}

export default ButtonWithLoading;