import { User } from "../types/User";
import { LoggedInUserActionTypes } from "../types/Actions";

const loggedInUserReducerDefaultState: firebase.User | null = null;

const loggedInUserReducer = (state: firebase.User | null = loggedInUserReducerDefaultState, action: LoggedInUserActionTypes): firebase.User | null => {
  switch (action.type) {
    case 'LOGGED_IN':
      return action.user;
    case 'LOGGED_OUT':
      return null;
    default:
      return state;
  }
}
export default loggedInUserReducer;