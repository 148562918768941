import { Button, CardActions, Container, Grid, TextField, Typography, useMediaQuery, useTheme } from "@material-ui/core";
import React, { useState } from "react";
import { resetPassword } from "../api";
import top from "../image/top1.png";
import { StyledCenteredLoginCard } from "../StyledComponents";
import SimpleSnackBar, { SnackBarProp } from "../components/SimpleSnackBar";

export default function ForgetPasswordPage() {

  const theme = useTheme();
  const mdBreakPoint = useMediaQuery(theme.breakpoints.up('md'));
  const smBreakPoint = useMediaQuery(theme.breakpoints.up('sm'));
  const xsBreakPoint = useMediaQuery(theme.breakpoints.up('xs'));

  const [email, setEmail] = useState<string>();

  const closeErrorSnackBar = () => {
    setErrorSnackBar({ ...errorSnackBar, "open": false });
  }

  const [errorSnackBar, setErrorSnackBar] = useState<SnackBarProp>({
    open: false,
    onClose: closeErrorSnackBar,
    message: "",
  });

  const onButtonClick = async () => {
    if (email !== undefined) {
      try {
        await resetPassword(email);
        setErrorSnackBar({ ...errorSnackBar, open: true, message: '已發出重設密碼的電郵。' })
      } catch (e) {
        setErrorSnackBar({ ...errorSnackBar, open: true, message: e.response.message })
      }
    }
  }

  return (
    <>
      <Container disableGutters maxWidth='xl'>
        <Typography style={{ backgroundColor: '#d8eff6', height: '100vh' }} >
          <img src={top} width='100%' height={mdBreakPoint ? '35%' : smBreakPoint ? '28%' : xsBreakPoint ? '18%' : '13%'} />
          <SimpleSnackBar open={errorSnackBar.open} onClose={closeErrorSnackBar} message={errorSnackBar.message} variant="error" />
          <Grid container style={{ backgroundColor: '#d8eff6', height: '65%', justifyContent: 'center', display: 'flex' }}>
            <StyledCenteredLoginCard elevation={0} >
              <Typography style={{ backgroundColor: '#d8eff6', height: '100vh' }} >
                <Typography gutterBottom variant="h5" component="h2">
                  忘記密碼 {(process.env.NODE_ENV !== 'production' || process.env.REACT_APP_ENVIRONMENT === "test") && <span style={{ color: "red" }}>(DEVELOPMENT SERVER)</span>}
                </Typography>
                <Grid item >
                  <TextField
                    id="email"
                    label="電郵地址"
                    onChange={(event) => setEmail(event.target.value)}
                    margin="normal"
                    fullWidth
                  />
                </Grid>
                <CardActions>
                  <Button type="submit" style={{ marginLeft: 'auto' }} variant="contained" color="primary" onClick={onButtonClick} >確定</Button>
                </CardActions>
              </Typography>
            </StyledCenteredLoginCard>
          </Grid>
        </Typography>
      </Container>
    </>
  )
}