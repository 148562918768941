import { Button, ButtonGroup, Container, Grid, MenuItem, Tab, Tabs, TextField } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Tooltip from '@material-ui/core/Tooltip';
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Bar, BarChart, CartesianGrid, Legend, ResponsiveContainer, Tooltip as RechartsTooltip, XAxis, YAxis } from 'recharts';
import { countPlaytimeWithTypes } from "../pages/NewReportPage";
import { GridCard } from "../StyledComponents";
import { CalligraphyRecordEntry, CalligraphyStrokeData } from "../types/CalligraphyData";
import CalligraphyTable, { CalligraphyDataDetailEntry } from "./CalligraphyDataTable";
import { CalligraphyTrendChart, CalligraphyTrendChartProps } from "./CalligraphyTrendChart";

const timeFunc = (input: number) => Math.round(input / 60 * 100) / 100;

const units = ["day", "week", "month", "all"] as const;
type Unit = typeof units[number];
const translationUnit = {
  day: "上星期",
  week: "月份",
  month: "年份",
  all: "全部",
}

enum CalligraphyTrendChartType {
  Playtime = "Playtime" as any,
  Accuracy = "Accuracy" as any,
  Thickness = "Thickness" as any,
  Speed = "Speed" as any,
  AirTime = "AirTime" as any,
}

const CalligraphyTrendChartTypeTranslation: { [key: string]: string } = {
  "Playtime": "遊玩時間",
  "Accuracy": "準確率",
  "Thickness": "筆畫粗度",
  "Speed": "書寫速度",
  "AirTime": "空閒時間"
}

const CalligraphyTrendChartTypeDesc: { [key: string]: string } = {
  "Playtime": "顯示用家玩一局書法遊戲（大約書寫一張書法紙）的時間",
  "Accuracy": "顯示用家書寫的軌跡符合預設文字的程度",
  "Thickness": "顯示用家書寫時手指按下熒幕的平均力度",
  "Speed": "顯示用家書寫時手指在熒幕移動的平均速度（一幀約為0.03-0.06秒不等，不同裝置會產生誤差）",
  "AirTime": "顯示用家在進入遊戲畫面後；按下完成前，手指沒有接觸熒幕的時間"
}

const CalligraphyTrendChartDataClassifier: { [key in keyof typeof CalligraphyTrendChartType]: string } = {
  Playtime: "分鐘",
  Accuracy: "%",
  Thickness: "像素",
  Speed: "像素/幀",
  AirTime: "分鐘"
}

export const translationCalligraphyType: { [key: string]: string } = {
  CalligraphyMainGame: "經典書法",
  ChineseRestaurantMainGame: "酒樓模式",
  CalligraphyMidAutumnEvent: "中秋燈謎",
  CalligraphyChineseRadical: "學習部首",
  CalligraphyIdiomMode: "成語模式",
  WriteOperaNameMainGame: "粵劇模式"
}

const quantityArray = [
  5,
  10,
  20,
  50,
  100
]

const months = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12] as const;
type Month = typeof months[number];
const translationMonth = {
  1: "一月",
  2: "二月",
  3: "三月",
  4: "四月",
  5: "五月",
  6: "六月",
  7: "七月",
  8: "八月",
  9: "九月",
  10: "十月",
  11: "十一月",
  12: "十二月",
}

export const colorScheme = [
  "#ff6188",
  "#fc9867",
  "#ffd866",
  "#a9dc76",
  "#78dce8",
  "#ab9df2",
]

export const paleColorScheme = [
  "#FFB0C4",
  "#FECCB3",
  "#FFECB3",
  "#D4EEBB",
  "#BCEEF4",
  "#D5CEF9",
]

interface CalligraphyTabProps {
  calligraphyData: CalligraphyRecordEntry[]
}

type CalligraphyChartData = {
  date: string
} & {
  [key: string]: number
}

type CalligraphyDistributionChartData = {
  [key: string]: number
}

const useStyles = makeStyles({
  chartContainer: {
    background: '#FAFAFA',
    border: 0,
    borderRadius: 5,
    boxShadow: '0 0 15px 0px rgba(0, 0, 0, .2)',
    padding: '50px',
    margin: '50px 0',
  },
  playtimeChart: {
    minHeight: '558px'
  },
  accuracyChart: {
    minHeight: '424px'
  }
});

export const SetUnitDropdownMenu = (props: { unit: Unit | "undefined", quantity: number | "undefined", displayMode: "quantity" | "time", onChangeTimeUnit: (unit: Unit | "undefined") => void, onChangeMonth: (month: Month | "undefined") => void, onChangeQuantity: (quan: number | "undefined") => void }): JSX.Element => {
  return (
    <>{
      props.displayMode === "time" ?
        (
          <Grid item md={4} sm={6} xs={12}>
            <TextField
              id="unit"
              select
              label="顯示時間歷史"
              defaultValue={"undefined"}
              value={props.unit}
              onChange={(e) => props.onChangeTimeUnit(e.target.value as Unit | "undefined")}
              margin="normal"
              style={{ width: 200, justifySelf: "flex-start", display: 'flex', margin: 40 }}
            >
              <MenuItem key={"undefined"} value={"undefined"}>
                {"- 請選擇時間段 -"}
              </MenuItem>
              {units.map((option) => (
                <MenuItem key={option} value={option}>
                  {translationUnit[option]}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
        )
        :
        <Grid item md={4} sm={6} xs={12}>
          <TextField
            id="unit"
            select
            label="顯示最近遊玩歷史"
            defaultValue={"undefined"}
            value={props.quantity}
            onChange={(v) => props.onChangeQuantity(v.target.value as unknown as number | "undefined")}
            margin="normal"
            style={{ width: 200, justifySelf: "flex-start", display: 'flex', margin: 40 }}
          >
            <MenuItem key={"undefined"} value={"undefined"}>
              {"- 請選擇顯示次數 -"}
            </MenuItem>
            {quantityArray.map((option) => (
              <MenuItem key={option} value={option}>
                {`最近${option}次`}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
    }
      {
        (props.displayMode === "time" && props.unit === "week") &&
        <Grid item md={4} sm={6} xs={12}>
          <TextField
            id="month"
            select
            label="月份"
            defaultValue={"undefined"}
            onChange={(e) => props.onChangeMonth(e.target.value as unknown as Month | "undefined")}
            margin="normal"
            style={{ width: 200, justifySelf: "flex-start", display: 'flex', margin: 40 }}
          >
            <MenuItem key={"undefined"} value={undefined}>
              {"- 請選擇月份 -"}
            </MenuItem>
            {months.map((option) => (
              <MenuItem key={option} value={option}>
                {translationMonth[option]}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
      }
    </>
  )
}

export default function CalligraphyTab(props: CalligraphyTabProps) {
  const [calligraphyType, setCalligraphyType] = useState<string[]>([]);

  const [accuracy, setAccuracy] = useState<CalligraphyDistributionChartData[]>();

  const [dataTable, setDataTable] = useState<CalligraphyDataDetailEntry[]>();

  const [unit, setUnit] = useState<Unit | "undefined">("undefined");

  const [month, setMonth] = useState<Month | "undefined">("undefined");

  const [quantity, setQuantity] = useState<number | "undefined">("undefined");

  const [trendChartDataType, setTrendChartDataType] = useState<CalligraphyTrendChartType>(CalligraphyTrendChartType.Playtime);

  const [trendChartData, setTrendChartData] = useState<{ data: CalligraphyChartData[] | undefined, dataKey: string | undefined }>({ "data": undefined, "dataKey": undefined });

  const [trendChartDataProps, setTrendChartDataProps] = useState<CalligraphyTrendChartProps>();

  const [trendChartDisplayMode, setTrendChartDisplayMode] = useState<"quantity" | "time">("time");

  useEffect(() => {
    if (trendChartDisplayMode === "time" && unit !== undefined && (unit === "month" && month !== undefined) || unit !== "month") {
      if (unit === "all") {
        setTrendChartData({ "data": byAll(), "dataKey": "date" });
      } else if (unit === 'week') {
        setMonth(moment.utc().month() as Month);
      } else if (unit === "day") {
        setTrendChartData({ "data": byDay(), "dataKey": "date" });
      } else if (unit === "month") {
        setTrendChartData({ "data": byMonth(), "dataKey": "date" });
      }
    }
    else {
      setTrendChartData({ "data": undefined, "dataKey": undefined });
    }
  }, [unit])

  useEffect(() => {
    if (quantity !== "undefined") {
      setTrendChartData({ "data": byQuantity(quantity), "dataKey": "quantity" });
    }
  }, [quantity])

  useEffect(() => {
    byWeek();
  }, [month])

  useEffect(() => {
    setAccuracy(computeAccuracyDistribution());

    const dataTableEntryBuffer: CalligraphyDataDetailEntry[] = calligraphyType.map<CalligraphyDataDetailEntry>(entry => {
      return {
        "type": entry,
        "totalTimeTaken": 0,
        "averageTimeTaken": 0,
        "averageAccuracy": null,
        "history": []
      }
    });

    props.calligraphyData.forEach((history) => {
      const targetType = dataTableEntryBuffer.find(e => e.type === history.type);

      if (targetType !== undefined) {
        targetType.history.push({
          "date": moment.utc(history.timestamp).format("YYYY年 MMM Do"),
          "averageSpeed": Math.round(history.data.avgSpeed * 100) / 100,
          "averageThickness": history.data.tempRecordData ? (Math.round(history.data.tempRecordData.reduce((current: number, next: CalligraphyStrokeData[]) => {
            return current += next.reduce((current2: number, { thickness }: any) => current2 += thickness, 0)
          }, 0
          ) / history.data.tempRecordData.reduce((current3, next3) => current3 += next3.length, 0) * 100) / 100) : null,
          "timeTaken": Math.round(history.data.timeTaken * 100) / 100,
          "airTime": Math.round(history.data.totalAirTime * 100) / 100,
          "accuracy": history.data.accuracy !== undefined ? history.data.accuracy[history.data.accuracy.length - 1] : null,
          "ref": history.ref
        });
      }
    });

    // Calculate avg
    dataTableEntryBuffer.forEach(type => {
      type.totalTimeTaken = type.history.reduce((current: number, { timeTaken }: any): number => current + timeTaken, 0);
      type.averageTimeTaken = type.totalTimeTaken / type.history.length;
      type.averageAccuracy = type.history.every(history => history.accuracy === null) ?
        null :
        type.history.reduce((current: number, { accuracy }: any): number => current += accuracy === null ? 0 : accuracy, 0) / type.history.filter(history => history.accuracy !== null).length;
    });

    setDataTable(dataTableEntryBuffer);

  }, [calligraphyType])

  useEffect(() => {
    // Init trend chart props
    setTrendChartDataProps({
      "data": trendChartData.data === undefined ? [] : trendChartData.data,
      "dataKey": trendChartData.dataKey === undefined ? "date" : trendChartData.dataKey,
      "xLabel": trendChartData.dataKey === undefined ? "" : trendChartData.dataKey,
      "yLabel": CalligraphyTrendChartDataClassifier[trendChartDataType],
      "tooltipClassifier": CalligraphyTrendChartDataClassifier[trendChartDataType],
      "calligraphyType": calligraphyType,
    });

    console.log(trendChartData);
  }, [trendChartData])

  useEffect(() => {
    if (trendChartDisplayMode === "quantity" && quantity !== "undefined") {
      setTrendChartData({ "data": byQuantity(quantity), "dataKey": "quantity" });
    }
    else if (trendChartDisplayMode === "time" && unit !== undefined && (unit === "month" && month !== undefined) || unit !== "month") {
      if (unit === "all") {
        setTrendChartData({ "data": byAll(), "dataKey": "date" });
      } else if (unit === 'week') {
        setMonth(moment.utc().month() as Month);
      } else if (unit === "day") {
        setTrendChartData({ "data": byDay(), "dataKey": "date" });
      } else if (unit === "month") {
        setTrendChartData({ "data": byMonth(), "dataKey": "date" });
      }
    }
    else {
      setTrendChartData({ "data": undefined, "dataKey": undefined });
    }
  }, [trendChartDataType])

  useEffect(() => {
    const allTypes = props.calligraphyData.map(d => d.type);
    const distinctTypes: string[] = allTypes.filter((item, pos) => allTypes.indexOf(item) == pos);
    setCalligraphyType(distinctTypes);
  }, [])

  const styles = useStyles();

  const onUnitChange = (value: Unit | "undefined") => {
    console.log(value);
    setUnit(value);
  }
  const onMonthChange = (value: Month | "undefined") => {
    setMonth(value);
  }

  const handleTrendChartDataTypeChange = (event: React.ChangeEvent<any>, newValue: CalligraphyTrendChartType) => {
    setTrendChartDataType(newValue);
  }

  const computeAccuracyDistribution = (): CalligraphyDistributionChartData[] => {
    const buffer: any[] = Array.from(Array(101).keys()).map(num => {
      return {
        "accuracy": num.toString(),
        ...calligraphyType!.reduce((a: { [key: string]: number }, b: string) => (a[b] = 0, a), {})
      }
    });

    for (const entry of props.calligraphyData) {
      if (entry.data.accuracy === undefined || entry.data.accuracy.length < 1) continue;
      const roundedAccuracy = Math.round(entry.data.accuracy[entry.data.accuracy.length - 1] * 100);
      if (roundedAccuracy < 0 || roundedAccuracy > 100) continue;

      if (buffer.find(e => e["accuracy"] !== undefined && e["accuracy"] == roundedAccuracy)) {
        buffer[roundedAccuracy][entry.type]++;
      }
    }

    buffer.forEach(entry => entry.accuracy = entry.accuracy + "%");
    return buffer as CalligraphyDistributionChartData[];
  }

  const byDay = () => {
    let tempDateArr = [];
    for (let i: number = 1; i < 8; i++) {
      const temp = moment().subtract(i, 'd');
      tempDateArr.push(moment.utc(temp).local().format('YYYY/MM/DD'));
    }
    tempDateArr = (tempDateArr as string[]).sort(function (a, b) {
      if (a < b) {
        return -1;
      }
      if (a > b) {
        return 1;
      }
      return 0;
    });
    const tempPlaytime = tempDateArr.map(x => {
      const temp = byAll().find(y => y.date === x);
      if (temp !== undefined) {
        return temp;
      }
      else {
        return { date: x, ...calligraphyType!.reduce((a: { [key: string]: number }, b: string) => (a[b] = 0, a), {}) } as CalligraphyChartData;
      }
    })
    return tempPlaytime;
  }

  const byWeek = () => {
    if (month !== undefined) {
      const temp = byAll().filter(x => parseInt(x.date.substring(5, 7)) === month);
      console.warn(temp);
      setTrendChartData({ "data": temp, "dataKey": "date" });
    }
  }

  const byMonth = () => {
    const buffer = formatData(trendChartDataType);

    const useTimeFunc = trendChartDataType === CalligraphyTrendChartType.Playtime || trendChartDataType === CalligraphyTrendChartType.AirTime ? timeFunc : (input: number) => input;
    const useAvg = trendChartDataType === CalligraphyTrendChartType.Playtime || trendChartDataType === CalligraphyTrendChartType.AirTime ? undefined : true;

    const calligraphyDataBuffer = countPlaytimeWithTypes(buffer, useTimeFunc, useAvg);

    let tempDate = calligraphyDataBuffer.map(g => { return g.date });
    tempDate = (tempDate as string[]).sort(function (a, b) {
      if (a < b) {
        return -1;
      }
      if (a > b) {
        return 1;
      }
      return 0;
    });
    tempDate = tempDate.filter((item, index) => tempDate.indexOf(item) === index);
    const tempPlaytime: CalligraphyChartData[] | undefined = tempDate.map(x => {
      const targetPlaytime = calligraphyDataBuffer.find(y => y.date === x);

      if (!targetPlaytime) return { date: x, ...calligraphyType!.reduce((a: { [key: string]: number }, b: string) => (a[b] = 0, a), {}) } as CalligraphyChartData;

      return { date: targetPlaytime.date, ...targetPlaytime.type } as CalligraphyChartData;
    });

    return tempPlaytime == undefined ? [] : tempPlaytime;
  }

  const byAll = (): CalligraphyChartData[] => {
    const buffer = formatData(trendChartDataType);
    console.log(buffer);

    const useTimeFunc = trendChartDataType === CalligraphyTrendChartType.Playtime || trendChartDataType === CalligraphyTrendChartType.AirTime ? timeFunc : (input: number) => input;
    const useAvg = trendChartDataType === CalligraphyTrendChartType.Playtime || trendChartDataType === CalligraphyTrendChartType.AirTime ? undefined : true;

    const calligraphyDataBuffer = countPlaytimeWithTypes(buffer, useTimeFunc, useAvg);

    let tempDate = calligraphyDataBuffer.map(g => { return g.date });
    tempDate = (tempDate as string[]).sort(function (a, b) {
      if (a < b) {
        return -1;
      }
      if (a > b) {
        return 1;
      }
      return 0;
    });
    tempDate = tempDate.filter((item, index) => tempDate.indexOf(item) === index);

    const tempPlaytime: CalligraphyChartData[] | undefined = tempDate.map(x => {
      const targetPlaytime = calligraphyDataBuffer.find(y => y.date === x);

      if (!targetPlaytime) return { date: x, ...calligraphyType!.reduce((a: { [key: string]: number }, b: string) => (a[b] = 0, a), {}) } as CalligraphyChartData;

      const a = { date: targetPlaytime.date, ...targetPlaytime.type } as CalligraphyChartData;
      console.log(a);
      return a;
    });

    console.log(tempPlaytime);

    return tempPlaytime == undefined ? [] : tempPlaytime;
  }

  const byQuantity = (last: number): CalligraphyChartData[] => {
    if (quantity === "undefined") return [];
    let bufferData: any[] = [];

    bufferData = formatData(trendChartDataType);

    // Group data by type
    const GroupedBufferData = calligraphyType.reduce((a: { [key: string]: { [key: string]: string | number }[] }, b: string) => (a[b] = [], a), {});

    for (const [key, value] of Object.entries(GroupedBufferData)) {
      const filteredData = bufferData.filter(data => { return data.type === key });
      GroupedBufferData[key] = filteredData.slice(Math.max(filteredData.length - quantity!, 0));
      console.log(key + " " + GroupedBufferData[key].length);
    }

    // Flatten the grouped data
    const quantityDataArray: { [key: string]: string | number | null }[] = Array.from({ length: quantity === undefined ? 10 : quantity }, (_, i) => i + 1).reverse().map(quant => {
      return {
        "date": "",
        "quantity": quant,
        ...calligraphyType.reduce((a: { [key: string]: number | null }, b: string) => (a[b] = null, a), {})
      }
    });

    for (const [key, value] of Object.entries(GroupedBufferData)) {
      Object.values(value).reverse().forEach((entry, index) => {
        const targetQuant = quantityDataArray.find(e => e.quantity === index + 1);
        if (targetQuant !== undefined) {
          targetQuant[key] = entry.payload;
        }
      });
    }

    console.log(quantityDataArray);
    return quantityDataArray as CalligraphyChartData[];
  }

  const formatData = (trendChartDataType: CalligraphyTrendChartType): [] => {
    switch (trendChartDataType) {
      case CalligraphyTrendChartType.Playtime:
        return formatCalligraphyTrendPlaytimeData();
      case CalligraphyTrendChartType.Speed:
        return formatCalligraphyTrendSpeedData();
      case CalligraphyTrendChartType.AirTime:
        return formatCalligraphyTrendAirTimeData();
      case CalligraphyTrendChartType.Accuracy:
        return formatCalligraphyTrendAccuracyData();
      case CalligraphyTrendChartType.Thickness:
        return formatCalligraphyTrendThicknessData();
      default:
        return [];
    }
  }

  const formatCalligraphyTrendPlaytimeData = () => {
    let tempData = props.calligraphyData.map(row => {
      const temp = moment.utc(row.timestamp).local().format('YYYY/MM/DD');
      return { "date": temp, "payload": row.data.timeTaken, type: row.type };
    })

    return sortByDate(tempData);
  }

  const formatCalligraphyTrendSpeedData = () => {
    let tempData = props.calligraphyData.map(row => {
      const temp = moment.utc(row.timestamp).local().format('YYYY/MM/DD');
      return { "date": temp, "payload": row.data.avgSpeed, type: row.type };
    })

    return sortByDate(tempData);
  }

  const formatCalligraphyTrendAirTimeData = () => {
    let tempData = props.calligraphyData.map(row => {
      const temp = moment.utc(row.timestamp).local().format('YYYY/MM/DD');
      return { "date": temp, "payload": row.data.totalAirTime, type: row.type };
    })

    return sortByDate(tempData);
  }

  const formatCalligraphyTrendAccuracyData = () => {
    let tempData = props.calligraphyData.filter(d => d.data.accuracy !== undefined && d.data.accuracy.length > 0).map(row => {
      const temp = moment.utc(row.timestamp).local().format('YYYY/MM/DD');
      return { "date": temp, "payload": Math.round(row.data.accuracy![row.data.accuracy!.length - 1] * 100), type: row.type };
    })

    return sortByDate(tempData);
  }

  const formatCalligraphyTrendThicknessData = () => {
    let tempData = props.calligraphyData.filter(d => d.data.tempRecordData !== undefined && d.data.tempRecordData.length > 0).map(row => {
      const tempDate = moment.utc(row.timestamp).local().format('YYYY/MM/DD');
      const tempThickness = row.data.tempRecordData.reduce((current: number, next: CalligraphyStrokeData[]) => {
        return current += next.reduce((current2: number, { thickness }: any) => current2 += thickness, 0)
      }, 0
      ) / row.data.tempRecordData.reduce((current3, next3) => current3 += next3.length, 0);
      return { "date": tempDate, "payload": tempThickness, type: row.type };
    })

    return sortByDate(tempData);
  }

  const sortByDate = (tempData: any) => {
    tempData = tempData.sort(function (a: any, b: any) {
      if (a.date < b.date) {
        return -1;
      }
      if (a.date > b.date) {
        return 1;
      }
      return 0;
    });

    return tempData;
  }

  const getToggleButtonGroup = () => {
    return (
      <ButtonGroup
        style={{ float: "right" }}
        color="primary"
        size="large"
        aria-label="large outlined secondary button group"
      >
        <Button
          onClick={() => setTrendChartDisplayMode("time")}
          style={{
            backgroundColor: trendChartDisplayMode === "time" ? "#ffffff" : "#093a4f",
            color: trendChartDisplayMode === "time" ? "#093a4f" : "#ffffff",
            height: "35px"
          }}
        >按時間</Button>
        <Button
          onClick={() => setTrendChartDisplayMode("quantity")}
          style={{
            backgroundColor: trendChartDisplayMode === "quantity" ? "#ffffff" : "#093a4f",
            color: trendChartDisplayMode === "quantity" ? "#093a4f" : "#ffffff",
            height: "35px"
          }}
        >按次數</Button>
      </ButtonGroup>
    )
  }

  return (
    <Container style={{ minHeight: '100px', paddingBottom: 20, marginTop: 40 }}>
      <Grid container spacing={4}>
        <Grid item md={12} sm={12}>
          <GridCard title="書法評價分佈" desc="以分佈圖顯示用戶在書法模式得到不同評價的次數">
            {(accuracy !== undefined) &&
              accuracy.length !== 0 ?
              <ResponsiveContainer width="100%" height={288}>
                <BarChart
                  width={1}
                  height={288}
                  margin={{
                    top: 15, right: 30, left: 10, bottom: 15,
                  }}
                  data={accuracy}
                >
                  <CartesianGrid />
                  <XAxis dataKey={'accuracy'} name="準確率" label={{ value: "書寫準確率", position: "insideBottomRight", dy: 20 }} interval={9} />
                  <YAxis label={{ value: "達成次數", position: "insideLeft", angle: -90, dy: 20 }} allowDecimals={false} />
                  <RechartsTooltip cursor={{ strokeDasharray: '3 3' }} formatter={value => { return value + '次' }} />
                  <Legend />
                  {
                    calligraphyType!.map((cType, index) => {
                      return (<Bar name={(cType in translationCalligraphyType) ? translationCalligraphyType[cType] : cType} dataKey={cType} stroke={colorScheme[index]} fill={colorScheme[index]} stackId="1" />)
                    })
                  }
                </BarChart>
              </ResponsiveContainer>
              : "沒有遊玩紀錄"}
          </GridCard>
        </Grid>
        <Grid item md={12} sm={12}>
          <GridCard title="各模式數據趨勢" desc="以折線圖顯示用戶在書法模式在各方面數據的時間趨勢" headerAddon={getToggleButtonGroup()}>
            <Grid container>
              <Grid sm={12} xs={12}>
                <Tabs
                  defaultValue={CalligraphyTrendChartType.Playtime}
                  value={trendChartDataType}
                  onChange={handleTrendChartDataTypeChange}
                  indicatorColor="primary"
                  textColor="primary"
                >
                  {Object.keys(CalligraphyTrendChartType).map(chartType => {
                    return (
                      <Tab
                        style={{ minWidth: 100 }}
                        value={chartType}
                        label={
                          <Tooltip
                            color="primary"
                            title={CalligraphyTrendChartTypeDesc[chartType]}
                            placement="top"
                            arrow
                          >
                            <span>
                              {CalligraphyTrendChartTypeTranslation[chartType] !== undefined ? CalligraphyTrendChartTypeTranslation[chartType] : chartType}
                            </span>
                          </Tooltip>
                        } />
                    )
                  })}
                </Tabs>
              </Grid>
              <SetUnitDropdownMenu displayMode={trendChartDisplayMode} unit={unit} quantity={quantity} onChangeTimeUnit={onUnitChange} onChangeMonth={onMonthChange} onChangeQuantity={setQuantity} />
            </Grid>
            {
              (trendChartDisplayMode === "time" && unit === "undefined") ||
                (trendChartDisplayMode === "time" && unit !== "undefined" && unit === "week" && month === "undefined") ||
                (trendChartDisplayMode === "quantity" && quantity === "undefined")
                ?
                <div style={{ marginTop: 20, marginBottom: 20 }}>請先在上方選擇數據顯示範圍</div>
                :
                trendChartData === undefined || trendChartData.data?.length === 0 || !trendChartDataProps
                  ?
                  <div style={{ marginTop: 20, marginBottom: 20 }}>"沒有遊玩記錄"</div>
                  :
                  <CalligraphyTrendChart {...trendChartDataProps} />
            }
          </GridCard>
        </Grid>
        <Grid item md={12} sm={12}>
          <GridCard title="書法詳細數據" desc="以表格顯示用戶在書法模式所記錄的數據">
            <CalligraphyTable data={dataTable === undefined ? [] : dataTable} />
          </GridCard>
        </Grid>
      </Grid>
    </Container >
  )
}