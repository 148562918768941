import { MissionMetadataActionTypes } from "../types/Actions";
import { DomainType, MissionMetadata, MissionType } from "../types/MissionType";

const missionMetadataDefaultState = { metadata: null, domainMission: {} };

const missionMetadataReducer = (
  state: {
    metadata: (MissionMetadata | null),
    domainMission: { [key: string]: MissionType[] }
  } = missionMetadataDefaultState,
  action: MissionMetadataActionTypes
): { metadata: MissionMetadata | null, domainMission: { [key: string]: MissionType[] } | null } => {
  switch (action.type) {
    case "SET_MISSION_METADATA":
      return { metadata: action.metadata, domainMission: action.domainMission }
    case "CLEAR_MISSION_METADATA":
      return missionMetadataDefaultState;
    default:
      return state;
  }
};
export default missionMetadataReducer;
