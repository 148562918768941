import { createMuiTheme, MuiThemeProvider } from '@material-ui/core';
import React, { useEffect } from 'react';
import { Route, Switch } from 'react-router-dom';
import './App.css';
import AccountsPage from './pages/AccountsPage';
import AnnouncementPage from './pages/AnnouncementPage';
import ApproveDemoPage from './pages/ApproveDemoPage';
import ContactUsPage from './pages/ContactUsPage';
import { DiscoverPage } from './pages/DiscoverPage';
import FaqPage from './pages/FaqPage';
import ForgetPasswordPage from './pages/ForgetPasswordPage';
import HomePage from './pages/HomePage';
import Login from './pages/LoginPage';
import { MahjongGameRecordPage } from './pages/MahjongGameRecordPage';
import NewAccountPage from './pages/NewAccountPage';
import NewReportPage from './pages/NewReportPage';
import PlansPage from './pages/PlansPage';
import RegisterPage from './pages/RegisterPage';
import RequestDemoPage from './pages/RequestDemoPage';
import { SettingsPage } from './pages/SettingsPage';
import Tutorial from './pages/Tutorial';
import UserProfilesPage from './pages/UserProfilesPage';


const theme = createMuiTheme({
  typography: {
    "fontFamily": `"cwTeXYen", 'Quicksand'`,
    "fontSize": 16,
    "fontWeightLight": 500,
    "fontWeightRegular": 500,
    "fontWeightMedium": 500
  },
  palette: {
    primary: {
      main: '#093a4f',
    },
    secondary: {
      main: '#d2e6d5',
    },
  },
  overrides: {
    MuiTooltip: {
      tooltip: {
        backgroundColor: "#0b4761",
        fontSize: "16px"
      },
      arrow: {
        color: "#0b4761",
      }
    }
  }
});

const App: React.FC = () => {

  let search = window.location.search;
  let params = new URLSearchParams(search);
  let apiToken = params.get('api_token');
  let uid = params.get('uid');
  let isDemo = params.get('demo');

  useEffect(() => {
    console.log(process.env.NODE_ENV);
    console.log(process.env.REACT_APP_ENVIRONMENT);
  }, [])
  // if (apiToken !== null && uid !== null) {
  //   let tempUser = {
  //     id: parseInt(uid),
  //     email: "",
  //     api_token: apiToken,
  //     roles_names: ["temp_user"],
  //     permissions_names: ["view_full_report", "view_simple_report"],
  //     pin: ""
  //   } as User;
  //   dispatch(loggedIn(tempUser));
  // } else if (isDemo === 'true') {
  //   let tempUser = {
  //     id: 3,
  //     email: "",
  //     api_token: apiToken,
  //     roles_names: ["demo"],
  //     permissions_names: ["view_full_report", "view_simple_report"],
  //     pin: ""
  //   } as User;
  //   dispatch(loggedIn(tempUser));
  // }
  return (
    <MuiThemeProvider theme={theme}>
      <div className="App">
        <Switch>
          <Route path="/home" component={HomePage} />
          <Route path="/users" component={UserProfilesPage} />
          {/* <Route path="/reports/game" component={GameReport} />
        <Route path="/reports/mission" component={MissionReport} />
        <Route path="/reports/full" component={FullReportPage} /> */}
          <Route path="/contactus" component={ContactUsPage} />
          <Route path="/faq" component={FaqPage} />
          <Route path="/tutorials" component={Tutorial} />
          <Route path="/logout" component={UserProfilesPage} />
          <Route path="/login" component={Login} />
          <Route path="/forgetpassword" component={ForgetPasswordPage} />
          {/* <Route path="/register" component={RegisterPage} />
        <Route path="/requestdemo" component={RequestDemoPage} /> */}
          <Route path="/plans" component={PlansPage} />
          <Route path="/discover" component={DiscoverPage} />
          <Route path="/register" component={PlansPage} />
          <Route path="/centerregister" component={RequestDemoPage} />
          <Route path="/requestdemo" component={RegisterPage} />
          <Route path="/approvedemo" component={ApproveDemoPage} />
          <Route path="/report" component={NewReportPage} />
          <Route path="/accounts" component={AccountsPage} />
          <Route path="/newaccount" component={NewAccountPage} />
          <Route path="/announcement" component={AnnouncementPage} />
          <Route path="/settings/:section" component={SettingsPage} />
          <Route path="/mahjong/:matchId" component={MahjongGameRecordPage} />
          <Route path="/" component={HomePage} />
        </Switch>
      </div>
    </MuiThemeProvider>
  );
}

export default App;
