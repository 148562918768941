import { Typography } from "@material-ui/core";
import Button from '@material-ui/core/Button';
import CardActions from '@material-ui/core/CardActions';
import Grid from '@material-ui/core/Grid';
import InputAdornment from '@material-ui/core/InputAdornment';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import moment from "moment";
import React, { useState } from 'react';
import { useHistory } from "react-router-dom";
import { submitRequestDemo, updateRequestDemo } from '../api';
import { StyledCircularProgress, StyledLoginCard, StyledLoginForm } from "../StyledComponents";
import { RequestDemo, UsersAgeDistribution, UsersMentalHealthDistribution } from '../types/RequestDemo';
import AlertDialog from "./AlertDialog";
import SimpleSnackBar, { SnackBarProp } from "./SimpleSnackBar";

interface Prop {
  data?: RequestDemo;
  onClose?: () => void;
}

const noOfUserOptions = [
  {
    value: "below_100",
    label: "100以下"
  },
  {
    value: "100-300",
    label: "100-300"
  },
  {
    value: "300-500",
    label: "300-500"
  },
  {
    value: "more_than_500",
    label: "500以上"
  }
];

const CompanyTypeOptions = [
  {
    value: "sponsered",
    label: "資助-護理安老院"
  },
  {
    value: "daily",
    label: "長者日間護理中心"
  },
  {
    value: "district_daily",
    label: "長者地區中心暨日間護理單位"
  },
  {
    value: "disrict",
    label: "長者地區中心",
  },
  {
    value: "neighbor",
    label: "長者鄰舍中心",

  }, {
    value: "elderly_home",
    label: "護老院"
  }, {
    value: "private",
    label: "私人安老服務"
  },
  {
    value: "integrated",
    label: "綜合家居照顧服務"
  },
  {
    value: "professional",
    label: "安老院舍外展專業服務"
  }, {
    value: "rehab_center",
    label: "復康中心"
  }, {
    value: "rehab_home",
    label: "復康院舍"
  },
  {
    value: "other",
    label: "其他"
  }
];


export default function MissionDataGrid(props: Prop) {

  const history = useHistory();

  const newRequestDemo: RequestDemo = props.data !== undefined ?
    props.data :
    {
      email: "",
      email_confirmation: "",
      name: "",
      company_name: "",
      contact_number: "",
      company_address: "",
      company_type: "",
      company_type_other: "",
      no_of_user: "",
      is_approved: false,
      created_user_id: 0,
      created_user_at: 0,
      created_at: 0
    };

  type InputError = {
    isError: boolean,
    reason: string | null
  }

  const [registerInfo, setRegisterInfo] = useState<RequestDemo>(newRequestDemo);
  const [emailError, setEmailError] = useState<InputError>({ isError: false, reason: null });
  const [confirmEmailError, setConfirmEmailError] = useState<InputError>({ isError: false, reason: null });
  const [nameError, setNameError] = useState<InputError>({ isError: false, reason: null });
  const [companyNameError, setCompanyNameError] = useState<InputError>({ isError: false, reason: null });
  const [contactNumberError, setContactNumberError] = useState<InputError>({ isError: false, reason: null });
  const [noOfUserError, setNoOfUserError] = useState<InputError>({ isError: false, reason: null });
  const [companyTypeError, setCompanyTypeError] = useState<InputError>({ isError: false, reason: null });
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [openRegisterSuccessfullyDialog, setOpenRegisterSuccessfullyDialog] = useState(false);


  const onRegisterFieldChange = (name: keyof RequestDemo | keyof UsersAgeDistribution | keyof UsersMentalHealthDistribution, value: any) => {
    setRegisterInfo({ ...registerInfo, [name]: value });

    if (name === "email") {
      if (!validateEmail(value))
        setEmailError({ isError: true, reason: "請輸入正確格式的電郵地址。" });
      else
        setEmailError({ isError: false, reason: null });
    }

    if (name === "email_confirmation") {
      if (props.data === undefined) {
        if (!validateEmail(value))
          setConfirmEmailError({ isError: true, reason: "請輸入正確格式的電郵地址。" });
        else if (value !== registerInfo.email)
          setConfirmEmailError({ isError: true, reason: "與上項輸入之電郵地址不相符。" });
        else
          setConfirmEmailError({ isError: false, reason: null });
      }
    }

    if (name === "name") {
      setNameError({ isError: false, reason: null });
    }

    if (name === "company_name") {
      setCompanyNameError({ isError: false, reason: null });
    }

    if (name === "company_type" || name === "company_type_other") {
      setCompanyTypeError({ isError: false, reason: null });
    }

    if (name === "contact_number") {
      if (!validatePhone(value)) {
        setContactNumberError({ isError: true, reason: "請輸入只含有數字的號碼。" });
      }
      else {
        setContactNumberError({ isError: false, reason: null });
      }
    }

    if (name === "no_of_user") {
      setNoOfUserError({ isError: false, reason: null });
    }

    setErrorSnackBar({ ...errorSnackBar, "open": false });
  }

  function validateEmail(email: string) {
    const regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return regex.test(email.toLowerCase());
  }

  function validatePhone(phone: string) {
    const regex = /^[0-9]*$/;
    return regex.test(phone);
  }

  const onLoginButtonClick = (event: React.MouseEvent) => {
    event.preventDefault();
    console.log(registerInfo);
    let hasError = false;
    if (registerInfo.email === "") {
      setEmailError({ isError: true, reason: "電郵地址不能為空白。" });
      hasError = true;
    }
    else if (!validateEmail(registerInfo.email)) {
      setEmailError({ isError: true, reason: "請輸入正確格式的電郵地址。" });
      hasError = true;
    }
    if (registerInfo.email_confirmation === "" && props.data === undefined) {
      setConfirmEmailError({ isError: true, reason: "確認電郵地址不能為空白。" });
      hasError = true;
    }
    if (registerInfo.email !== registerInfo.email_confirmation && props.data === undefined) {
      setConfirmEmailError({ isError: true, reason: "與上項輸入之電郵地址不相符。" });
      hasError = true;
    }
    if (registerInfo.name === "") {
      setNameError({ isError: true, reason: "名字不能為空白。" });
      hasError = true;
    }
    if (registerInfo.company_name === "") {
      setCompanyNameError({ isError: true, reason: "機構名字不能為空白。" });
      hasError = true;
    }
    if (registerInfo.contact_number === "") {
      setContactNumberError({ isError: true, reason: "聯絡電話不能為空白。" });
      hasError = true;
    } else if (!validatePhone(registerInfo.contact_number)) {
      setContactNumberError({ isError: true, reason: "請輸入只含有數字的號碼。" });
      hasError = true;
    }
    if (registerInfo.no_of_user === "") {
      setNoOfUserError({ isError: true, reason: "請選擇機構用戶數量。" });
      hasError = true;
    }
    if (registerInfo.company_type === "") {
      setCompanyTypeError({ isError: true, reason: "請選擇機構種類。" });
      hasError = true;
    }
    if (registerInfo.company_type === "other" && registerInfo.company_type_other === "") {
      setCompanyTypeError({ isError: true, reason: "機構種類不能為空白。" });
      hasError = true;
    }
    if (hasError === false) {
      setIsSubmitting(true);
      if (props.data === undefined) {
        registerInfo.created_at = moment().valueOf();
        submitRequestDemo(registerInfo).then((data) => {
          console.log(data);
          setOpenRegisterSuccessfullyDialog(true);
          setRegisterInfo(newRequestDemo);
        }, (err) => {
          console.log(err);
          let errorMessage: string;
          err.message !== undefined ? errorMessage = err.message : errorMessage = "Unknown error occurred.";
          setErrorSnackBar({ ...errorSnackBar, message: errorMessage, open: true });
        }).finally(() => {
          setIsSubmitting(false)
        });
      } else {
        if (props.data.id !== undefined) {
          updateRequestDemo(props.data.id, registerInfo).then((data) => {
            console.log(data);
            setOpenRegisterSuccessfullyDialog(true);
          }, (err) => {
            console.log(err);
            let errorMessage: string;
            err.message !== undefined ? errorMessage = err.message : errorMessage = "Unknown error occurred.";
            setErrorSnackBar({ ...errorSnackBar, message: errorMessage, open: true });
          }).finally(() => {
            setIsSubmitting(false)
          });
        }
      }
    } else {
      setErrorSnackBar({ ...errorSnackBar, variant: "error", message: "表單含有無效資料，請檢查錯誤訊息", open: true });
    }
  }

  const onUsersAgeDistributionChange = (name: keyof UsersAgeDistribution) => (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.value.length === 0) {
      const users_age_distribution = { ...registerInfo.users_age_distribution, [name]: "" };
      setRegisterInfo({ ...registerInfo, users_age_distribution: users_age_distribution });
    } else {
      if (typeof parseInt(event.target.value) === "number") {
        const users_age_distribution = { ...registerInfo.users_age_distribution, [name]: parseInt(event.target.value) };
        setRegisterInfo({ ...registerInfo, users_age_distribution: users_age_distribution });
      }
    }
  }

  const onUsersMentalHealthDistribution = (name: keyof UsersMentalHealthDistribution) => (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.value.length === 0) {
      let users_mental_health_distribution = { ...registerInfo.users_mental_health_distribution, [name]: "" };
      setRegisterInfo({ ...registerInfo, users_mental_health_distribution: users_mental_health_distribution });
    } else {
      if (typeof parseInt(event.target.value) === "number") {
        let users_mental_health_distribution = { ...registerInfo.users_mental_health_distribution, [name]: parseInt(event.target.value) };
        setRegisterInfo({ ...registerInfo, users_mental_health_distribution: users_mental_health_distribution });
      }
    }
  }

  const closeErrorSnackBar = () => {
    setErrorSnackBar({ ...errorSnackBar, "open": false });
  }

  const [errorSnackBar, setErrorSnackBar] = useState<SnackBarProp>({
    open: false,
    onClose: closeErrorSnackBar,
    message: "",
  });

  const closeRegisterSuccessfullyDialog = () => {
    setOpenRegisterSuccessfullyDialog(false);
    if (props.onClose !== undefined) {
      props.onClose();
    }

    history.push("/login");
  }

  return (
    <>
      <SimpleSnackBar open={errorSnackBar.open} onClose={closeErrorSnackBar} message={errorSnackBar.message} />
      <AlertDialog open={openRegisterSuccessfullyDialog} onClose={closeRegisterSuccessfullyDialog} message={props.data !== undefined ? "更新成功!" : "提交成功！我們會於申請批核後透過電郵通知你"} />
      <StyledLoginCard>
        {isSubmitting && <StyledCircularProgress />}
        <Typography gutterBottom variant="h5" component="h2">{props.data === undefined ? "登記機構專用帳戶" : "更改申請訴求"}</Typography>
        {props.data === undefined &&
          <Typography variant="subtitle1" color="primary">
            感謝閣下申請NeuroGym的機構專用版，瞭解這個一站式認知訓練嘅遊戲平台！<br />請填下以下表格，以便我們盡快處理您的申請，機構登記收集的數據只供作內部統計用途，並不會涉及費用或顯示個別機構的資料。
          </Typography>
        }
        <StyledLoginForm autoComplete="off">
          <TextField
            error={companyNameError.isError}
            id="company_name"
            label="機構名稱 *"
            onChange={(e) => onRegisterFieldChange('company_name', e.target.value)}
            margin="normal"
            helperText={companyNameError.isError && companyNameError.reason}
            defaultValue=""
          />

          <TextField
            error={emailError.isError}
            id="email"
            label="電郵地址 *"
            onChange={(e) => onRegisterFieldChange('email', e.target.value)}
            margin="normal"
            helperText={emailError.isError && emailError.reason}
            defaultValue=""
          />
          {props.data === undefined &&
            <TextField
              error={confirmEmailError.isError}
              id="email_confirmation"
              label="確認電郵地址 *"
              onChange={(e) => onRegisterFieldChange('email_confirmation', e.target.value)}
              margin="normal"
              helperText={confirmEmailError.isError && confirmEmailError.reason}
              defaultValue=""
            />}

          <TextField
            error={nameError.isError}
            id="first_name"
            label="聯絡人姓名 *"
            onChange={(e) => onRegisterFieldChange('name', e.target.value)}
            margin="normal"
            helperText={nameError.isError && nameError.reason}
            defaultValue=""
          />

          <TextField
            id="referralCode"
            label="推薦碼（選填：未持有推薦碼請忽略此項）"
            onChange={(e) => onRegisterFieldChange("referralCode", e.target.value)}
            margin="normal"
            defaultValue=""
          />

          <TextField
            error={contactNumberError.isError}
            id="contact_number"
            label="聯絡電話 *"
            onChange={(e) => onRegisterFieldChange('contact_number', e.target.value)}
            margin="normal"
            helperText={contactNumberError.isError && contactNumberError.reason}
            defaultValue=""
          />

          <TextField
            id="user_position"
            label="職位"
            onChange={(e) => onRegisterFieldChange('user_position', e.target.value)}
            margin="normal"
            defaultValue=""
          />

          <TextField
            error={companyTypeError.isError}
            id="company_type"
            label="機構類別 *"
            onChange={(e) => onRegisterFieldChange('company_type', e.target.value)}
            margin="normal"
            select
            hidden={true}
            helperText={companyTypeError.isError && companyTypeError.reason}
            defaultValue=""
          >
            {CompanyTypeOptions.map(option => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>

          <div style={{ width: "100%" }}
            hidden={registerInfo.company_type !== "other"}
          >
            <TextField
              error={companyTypeError.isError}
              label="請註明機構類別"
              fullWidth
              onChange={(e) => onRegisterFieldChange('company_type_other', e.target.value)}
              helperText={companyTypeError.isError && companyTypeError.reason}
            >
            </TextField>
          </div>

          <TextField
            error={noOfUserError.isError}
            id="no_of_user"
            label="機構人數 *"
            value={registerInfo.no_of_user}
            onChange={(e) => onRegisterFieldChange('no_of_user', e.target.value)}
            margin="normal"
            select
            helperText={noOfUserError.isError && noOfUserError.reason}
            defaultValue="below_100"
          >
            {noOfUserOptions.map(option => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>

          <TextField
            id="company_address"
            label="機構地址"
            onChange={(e) => onRegisterFieldChange('company_address', e.target.value)}
            margin="normal"
            defaultValue=""
          />

          <Grid container spacing={1} style={{ marginTop: 16 }}>
            <Grid item xs={12}>
              <InputLabel>
                會員年齡
            </InputLabel>
            </Grid>
            <Grid item xs={12} sm={3}>
              <TextField
                id="users_age_distribution_55"
                label="55-65"
                value={registerInfo.users_age_distribution !== undefined && registerInfo.users_age_distribution.group_55_65}
                onChange={onUsersAgeDistributionChange('group_55_65')}
                type="number"
                InputProps={{ endAdornment: <InputAdornment position="end">%</InputAdornment> }}
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <TextField
                id="users_age_distribution_65"
                label="65-75"
                value={registerInfo.users_age_distribution !== undefined && registerInfo.users_age_distribution.group_65_75}
                onChange={onUsersAgeDistributionChange('group_65_75')}
                type="number"
                InputProps={{ endAdornment: <InputAdornment position="end">%</InputAdornment> }}
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <TextField
                id="users_age_distribution_75"
                label="75-85"
                value={registerInfo.users_age_distribution !== undefined && registerInfo.users_age_distribution.group_75_85}
                onChange={onUsersAgeDistributionChange('group_75_85')}
                type="number"
                InputProps={{ endAdornment: <InputAdornment position="end">%</InputAdornment> }}
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <TextField
                id="users_age_distribution_85"
                label="85以上"
                value={registerInfo.users_age_distribution !== undefined && registerInfo.users_age_distribution.group_85_above}
                onChange={onUsersAgeDistributionChange('group_85_above')}
                type="number"
                InputProps={{ endAdornment: <InputAdornment position="end">%</InputAdornment> }}
              />
            </Grid>
          </Grid>

          {props.data === undefined ? <Grid container spacing={1} style={{ marginTop: 16 }}>
            <Grid item xs={12}>
              <InputLabel>
                會員精神健康
            </InputLabel>
            </Grid>
            <Grid item xs={12} sm={2}>
              <TextField
                id="users_mental_health_distribution_healthy"
                label="健康"
                value={registerInfo.users_mental_health_distribution !== undefined && registerInfo.users_mental_health_distribution.healthy}
                onChange={onUsersMentalHealthDistribution('healthy')}
                type="number"
                InputProps={{ endAdornment: <InputAdornment position="end">%</InputAdornment> }}
              />
            </Grid>
            <Grid item xs={12} sm={2}>
              <TextField
                id="users_mental_health_distribution_suspect"
                label="懷疑認知障礙症"
                value={registerInfo.users_mental_health_distribution !== undefined && registerInfo.users_mental_health_distribution.suspect}
                onChange={onUsersMentalHealthDistribution('suspect')}
                type="number"
                InputProps={{ endAdornment: <InputAdornment position="end">%</InputAdornment> }}
              />
            </Grid>
            <Grid item xs={12} sm={2}>
              <TextField
                id="users_mental_health_distribution_early"
                label="初期認知障礙症"
                value={registerInfo.users_mental_health_distribution !== undefined && registerInfo.users_mental_health_distribution.early}
                onChange={onUsersMentalHealthDistribution('early')}
                type="number"
                InputProps={{ endAdornment: <InputAdornment position="end">%</InputAdornment> }}
              />
            </Grid>
            <Grid item xs={12} sm={2}>
              <TextField
                id="users_mental_health_distribution_middle"
                label="中期認知障礙症"
                value={registerInfo.users_mental_health_distribution !== undefined && registerInfo.users_mental_health_distribution.middle}
                onChange={onUsersMentalHealthDistribution('middle')}
                type="number"
                InputProps={{ endAdornment: <InputAdornment position="end">%</InputAdornment> }}
              />
            </Grid>
            <Grid item xs={12} sm={2}>
              <TextField
                id="users_mental_health_distribution_late"
                label="晚期認知障礙症"
                value={registerInfo.users_mental_health_distribution !== undefined && registerInfo.users_mental_health_distribution.late}
                onChange={onUsersMentalHealthDistribution('late')}
                type="number"
                InputProps={{ endAdornment: <InputAdornment position="end">%</InputAdornment> }}
              />
            </Grid>
          </Grid> :
            <>
              <Grid container spacing={1} style={{ marginTop: 16 }}>
                <Grid item xs={12}>
                  <InputLabel>
                    會員精神健康
                </InputLabel>
                </Grid>
                <Grid item xs={12} sm={2} style={{ paddingRight: 25 }}>
                  <TextField
                    id="users_mental_health_distribution_healthy"
                    label="健康"
                    value={registerInfo.users_mental_health_distribution !== undefined && registerInfo.users_mental_health_distribution.healthy}
                    onChange={onUsersMentalHealthDistribution('healthy')}
                    type="number"
                    InputProps={{ endAdornment: <InputAdornment position="end">%</InputAdornment> }}
                  />
                </Grid>
                <Grid item xs={12} sm={5}>
                  <TextField
                    id="users_mental_health_distribution_suspect"
                    label="懷疑認知障礙症"
                    value={registerInfo.users_mental_health_distribution !== undefined && registerInfo.users_mental_health_distribution.suspect}
                    onChange={onUsersMentalHealthDistribution('suspect')}
                    type="number"
                    InputProps={{ endAdornment: <InputAdornment position="end">%</InputAdornment> }}
                  />
                </Grid>
                <Grid item xs={12} sm={5}>
                  <TextField
                    id="users_mental_health_distribution_early"
                    label="初期認知障礙症"
                    value={registerInfo.users_mental_health_distribution !== undefined && registerInfo.users_mental_health_distribution.early}
                    onChange={onUsersMentalHealthDistribution('early')}
                    type="number"
                    InputProps={{ endAdornment: <InputAdornment position="end">%</InputAdornment> }}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={1}>
                <Grid item xs={12} sm={5}>
                  <TextField
                    id="users_mental_health_distribution_middle"
                    label="中期認知障礙症"
                    value={registerInfo.users_mental_health_distribution !== undefined && registerInfo.users_mental_health_distribution.middle}
                    onChange={onUsersMentalHealthDistribution('middle')}
                    type="number"
                    InputProps={{ endAdornment: <InputAdornment position="end">%</InputAdornment> }}
                  />
                </Grid>
                <Grid item xs={12} sm={5}>
                  <TextField
                    id="users_mental_health_distribution_late"
                    label="晚期認知障礙症"
                    value={registerInfo.users_mental_health_distribution !== undefined && registerInfo.users_mental_health_distribution.late}
                    onChange={onUsersMentalHealthDistribution('late')}
                    type="number"
                    InputProps={{ endAdornment: <InputAdornment position="end">%</InputAdornment> }}
                  />
                </Grid>
              </Grid></>}
          <CardActions>
            <Button type="submit" style={{ marginLeft: 'auto' }} variant="contained" color="primary" onClick={onLoginButtonClick} disabled={isSubmitting}>提交</Button>
          </CardActions>
        </StyledLoginForm>
      </StyledLoginCard>
    </>
  )
}
