import React from "react";
import { StyledContainer } from "../StyledComponents";
import AppBar from "../components/AppBar";
import { Typography, Grid } from "@material-ui/core";
import logo from "../image/logo_PNG.png"

export default function ContactUsPage() {

  return (
    <div>
      <AppBar title={"聯絡我們"} />
      <StyledContainer style={{ paddingTop: 90, paddingBottom: 80 }}>
        <Typography variant="h5" component="h2" align="center" style={{ marginTop: 30 }}>
          聯絡我們
        </Typography>
        <Grid style={{ justifyContent: 'center', display: 'flex', marginTop: 30 }}>
          <Typography variant="subtitle1" component="h2" align="left" style={{ lineHeight: '250%', color: 'grey' }}>
            聯絡我們<br />
            MedMind Technology<br />
            Phone/whatsapp: 9033 7986 Hayley Tam/ 9040 3834 Nathan Hui<br />
            Email: <a href="mailto: info@medmindtechnology.hk">info@medmindtechnology.hk</a><br />
            Address: Unit 552 , 5/F, Building 19W, No. 19 Science Park West Avenue, Hong Kong Science Park<br />
          </Typography>
        </Grid>
        <Grid style={{ justifyContent: 'flex-end', display: 'flex', marginTop: 30, alignContent: 'flex-end' }}>
          <img src={logo} style={{ display: 'block', marginTop: 50, marginBottom: 50 }}></img>
        </Grid>
      </StyledContainer>
      <AppBar title={"聯絡我們"} bottom={true} />
    </div>
  )
}