import { Button, Chip, Container, Divider, makeStyles, Typography } from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import { Face, Visibility, VisibilityOff } from "@material-ui/icons";
import moment from 'moment';
import React, { useEffect, useState } from "react";
import { AxisDomain, CartesianGrid, LegendPayload, ResponsiveContainer, Scatter, ScatterChart, Tooltip, XAxis, YAxis } from 'recharts';
import { DomainType } from "../types/MissionType";
import { getColors } from "./../resources/colors";

const domainColors = ['#ff3333', '#9966cc', '#66cc33', '#ffcc33', '#33ccff', '#ff6699'];
export const playerColors = ['#FF6B6Bcc', '#ffa726cc', '#66bb6acc', '#29b6f6cc', '#F6511Dcc', '#7256a9cc', '#82ca9dcc', '#777b88cc', '#a18276cc', '#345995cc', '#15616dcc', '#FFCAB1cc', '#88292Fcc', '#ec407acc'];

type CustomLegendPayload = LegendPayload & { isActive: boolean };

export interface CenterDomainDataType {
  domain: DomainType | "overall",
  users: CenterDomainMissionDataType[]
}

export interface CenterDomainMissionDataType {
  userId: string,
  name: string,
  record: CenterDomainMissionRecordDataType[]
}

export interface CenterDomainMissionRecordDataType {
  score: number,
  time: number,
}

interface CenterTrendScatterChartProps {
  domainData: CenterDomainDataType | undefined,
  selectedDomain: DomainType | undefined,
  selectedYear: number | undefined,
  selectedMonth: number | undefined,
  mode: ChartMode | undefined,
  averageData: CenterDomainMissionDataType | undefined
}

export type ChartMode = "time" | "score" | "time_acc"

export default function CenterTrendScatterChart(props: CenterTrendScatterChartProps) {
  const [chartData, setChartData] = useState<CenterDomainDataType | undefined>();
  const [averageData, setAverageData] = useState<CenterDomainMissionDataType | undefined>();
  const [customTicks, setCustomTicks] = useState<number[]>([]);
  const [customDomain, setCustomDomain] = useState<[AxisDomain, AxisDomain]>();
  const [activedUsers, setActivedUsers] = useState<string[]>();

  useEffect(() => {
    setChartData(props.domainData);
  }, [props.selectedDomain, props.domainData]);

  useEffect(() => {
    setCustomDomain(computeCustomDomain());
    setCustomTicks(generateTick());

    if (chartData && !activedUsers)
      setActivedUsers(chartData?.users.map(user => user.userId));
  }, [chartData]);

  useEffect(() => {
  }, [activedUsers])

  useEffect(() => {

  }, [props.averageData])

  const computeCustomDomain = (): [AxisDomain, AxisDomain] => {
    return props.selectedMonth ?
      [
        moment((props.selectedYear ? props.selectedYear.toString() : moment().format("YYYY")) + "-" + props.selectedMonth).startOf("month").valueOf() as AxisDomain,
        moment((props.selectedYear ? props.selectedYear.toString() : moment().format("YYYY")) + "-" + props.selectedMonth).endOf("month").valueOf() as AxisDomain
      ] :
      [
        moment(props.selectedYear ? props.selectedYear.toString() : moment().format("YYYY")).startOf("year").valueOf() as AxisDomain,
        moment(props.selectedYear ? props.selectedYear.toString() : moment().format("YYYY")).endOf("year").valueOf() as AxisDomain
      ]
  }

  function CenterCustomTooltip(props: any) {
    if (props.active && ((activedUsers ?? []).includes(props.payload[0].payload.userId) || props.payload[0].payload.name === "平均")) {

      const measureUnitKey: string = props.payload[0].payload.mode === "time" ? "本日遊玩時間" : props.payload[0].payload.mode === "score" ? "本日平均分" : props.payload[0].payload.mode === "time_acc" ? "累計遊玩時間" : "載入中"
      const measureUnit: string = props.payload[0].payload.mode === "score" ? "分" : "載入中"

      return (
        <div>
          <Paper variant="outlined" style={{ width: '250px', textAlign: 'left' }} >
            <Container style={{ marginTop: "15px", marginBottom: "15px" }}>
              <Typography variant="h6" align="center" style={{ fontWeight: "bold", marginBottom: "10px" }}>{props.payload[0].payload.name ?? "未知玩家"}</Typography>
              <div style={{ marginTop: "10px", fontSize: "14px" }}>
                <Divider />
                <table style={{ width: "100%", marginTop: "10px", marginBottom: "10px" }}><tbody>
                  <tr style={{ width: "100%" }}><td align="right" style={{ width: "50%", color: "#999999" }}>統計時間段&nbsp;</td><td style={{ width: "50%" }}>&nbsp;{moment.utc(props.payload[0].payload.time).local().format('MMM Do')}</td></tr>
                  <tr style={{ width: "100%" }}><td align="right" style={{ width: "50%", color: "#999999" }}>{measureUnitKey}&nbsp;</td><td style={{ width: "50%" }}>&nbsp;{props.payload[0].payload.mode === "score" ? (props.payload[0].payload.score + measureUnit) : timeFormatter(props.payload[0].payload.score)}</td></tr>
                </tbody></table>
              </div>
            </Container>
          </Paper>
        </div>)
    } else {
      return (
        null
      )
    }
  }

  const timeFormatter = (second: number): string => {
    if (second < 0)
      return "資料損毀 未能顯示"
    if (second < 60)
      return second + "秒";
    else if (second < 3600)
      return Math.round(second / 60 * 100) / 100 + "分鐘";
    else if (second < 86400)
      return Math.round(second / 60 / 60 * 100) / 100 + "小時";
    else
      return Math.round(second / 60 / 60 * 100) / 100 + "小時 (合計" + Math.round(second / 60 / 60 / 24 * 100) / 100 + "天)";
  }

  const generateTick = () => {
    const start: moment.Moment = moment((props.selectedYear ? props.selectedYear.toString() : moment().format("YYYY")) + (props.selectedMonth ? `-${props.selectedMonth}` : ""));
    const resultArray = [];

    if (!props.selectedMonth) {
      for (const end = start.clone().add(1, 'year'); start.isBefore(end); start.add(1, 'month')) {
        resultArray.push(start.valueOf());
      }
    }
    else {
      for (const end = start.clone().add(1, 'month'); start.isBefore(end); start.add(1, 'day')) {
        resultArray.push(start.valueOf());
      }
    }

    return resultArray;
  }

  const onChipToggleActive = (id: string, active: boolean) => {
    if (!activedUsers) return;

    if (active) {
      setActivedUsers(activedUsers => [...activedUsers!, id]);
    }
    else {
      setActivedUsers(activedUsers!.filter(existingId => existingId !== id));
    }
  }

  const onClickSelectAll = () => {
    if (activedUsers)
      setActivedUsers(chartData?.users.map(user => user.userId));
  }

  const onClickUnselectAll = () => {
    if (activedUsers)
      setActivedUsers([]);
  }

  const setCenterTrendScatterChartData = (chartData: CenterDomainDataType) => {
    const colors = getColors(chartData.users.length);

    return chartData.users.map((entry, index) => {
      return (
        <Scatter
          name={entry.name}
          data={[...entry.record.values()].map(value => { return { ...value, ...{ "userId": entry.userId, "name": entry.name, "mode": props.mode } } })}
          fill={colors[index]}
          line
          strokeOpacity={(activedUsers ?? []).includes(entry.userId) ? 1 : 0}
          fillOpacity={(activedUsers ?? []).includes(entry.userId) ? 1 : 0}
        />
      )
    })
  }

  const setCenterTrendScatterChartLegendData = (chartData: CenterDomainDataType) => {
    const colors = getColors(chartData.users.length);

    return chartData.users.map<CustomLegendPayload>((user, index) => ({
      id: user.userId,
      value: user.name,
      color: colors[index],
      type: "circle",
      isActive: (activedUsers ?? []).includes(user.userId)
    }))
  }

  return (
    <>
      <ResponsiveContainer width="100%" height={260}>
        <ScatterChart
          key={"ScatterChart"}
          margin={{
            top: 15, right: 30, left: 10, bottom: 65,
          }}
        >
          <CartesianGrid />
          <XAxis
            type="number" interval={0} dataKey={'time'} name="Time"
            domain={customDomain}
            tickFormatter={(unixTime) => props.selectedMonth ? moment(unixTime).format('MMM Do') : moment(unixTime).format('YYYY年 MMM')}
            ticks={customTicks}
            angle={-35}
            textAnchor="end"
          />
          <YAxis
            type="number"
            dataKey={'score'}
            name="分數"
            domain={[0, 100]}
            tickFormatter={(y) => props.mode?.includes("time") ? y / 60 : y}
            label={{ value: props.mode?.includes("time") ? "分鐘" : "分數", angle: -90, position: 'insideLeft' }}
          />
          <Tooltip cursor={{ strokeDasharray: '3 3' }} content={<CenterCustomTooltip />} />
          {props.averageData &&
            <Scatter
              name={"平均"}
              data={props.averageData.record.map(value => { return { ...value, ...{ "name": "平均", "mode": props.mode } } })}
              fill="#222222cc"
              line
              strokeOpacity={0.25}
              fillOpacity={0.25}
            />
          }
          {
            chartData &&
            setCenterTrendScatterChartData(chartData)
          }
        </ScatterChart>
      </ResponsiveContainer>
      <CustomLegend
        onChipToggleActive={onChipToggleActive}
        payload={
          chartData &&
          setCenterTrendScatterChartLegendData(chartData)
        }
      />
      <div style={{ marginBottom: 10 }}>
        <Button
          style={{ margin: 10 }}
          variant="contained"
          color="primary"
          size="large"
          startIcon={<Visibility />}
          onClick={onClickSelectAll}
        >
          全部顯示
        </Button>
        <Button
          style={{ margin: 10 }}
          variant="contained"
          color="primary"
          size="large"
          startIcon={<VisibilityOff />}
          onClick={onClickUnselectAll}
        >
          全部隱藏
        </Button>
      </div>
    </>
  )
}

const CustomLegend = (props: { payload: CustomLegendPayload[] | undefined, onChipToggleActive: (id: string, active: boolean) => void }) => {

  const useChipStyles = makeStyles((theme: any) => ({
    root: {
      marginBottom: 40,
      display: 'flex',
      justifyContent: 'center',
      flexWrap: 'wrap',
      '& > *': {
        margin: theme.spacing(0.5),
      },
    },
  }));

  const classes = useChipStyles();

  if (props.payload !== undefined) {
    return (
      <Container>
        <div className={classes.root}>
          {
            (props.payload).map((user) => {
              const element: JSX.Element = <LegendChip id={user.id} value={user.value} color={(user.color ? user.color : "#222222")} isActive={user.isActive} onToggleActive={props.onChipToggleActive} />
              return element;
            })
          }
        </div>
      </Container>
    )
  }
  else return null;
}

const LegendChip = (props: { id: string, value: string, color: string, isActive: boolean, onToggleActive: (id: string, active: boolean) => void }) => {

  const handleToggle = () => {
    props.onToggleActive(props.id, !props.isActive);
  }

  return (
    <Chip
      style={{ borderColor: props.color, color: props.color }}
      label={props.value}
      variant="outlined"
      icon={<Face style={{ color: props.color }} />}
      onClick={handleToggle}
      onDelete={handleToggle}
      deleteIcon={props.isActive ? <Visibility style={{ color: props.color }} /> : <VisibilityOff style={{ color: props.color }} />}
    />
  )
}