import { StyledContainer, StyledLoginCard } from "../StyledComponents";
import { Typography, Grid, TextField, CardActions, Button } from "@material-ui/core";
import SelectUserDropdown from "./SelectUserDropdown";
import SimpleSnackBar, { SnackBarProp } from "./SimpleSnackBar";
import React, { useEffect, useState } from 'react';
import { Announcement } from "../types/Announcement";
import { useSelector } from "react-redux";
import { AppState } from "../reducers";
import { LoadableProfileData } from "../reducers/userProfiles";
import { postAnnouncement, updateAnnouncement } from "../api";
import moment from "moment";

interface NewAnnouncementTableProps {
  snackBar: SnackBarProp,
  setSnackbar: (props: SnackBarProp) => void,
  announcement?: Announcement,
}

export default function NewAnnouncementTable(props: NewAnnouncementTableProps) {

  const [newAnnouncement, setNewAnnouncement] = useState<Announcement>();

  const [oldAnnouncement, setOldAnnouncement] = useState<Announcement>();

  const [newReceivers, setNewReceivers] = useState<Array<string>>([]);

  const [titleError, setTitleError] = useState<boolean>();

  const [messageError, setMessageError] = useState<boolean>();

  const loggedInUser = useSelector<AppState, firebase.User | null>(state => state.loggedInUser);

  const userProfiles = useSelector<AppState, LoadableProfileData>(state => state.userProfiles);

  useEffect(() => {
    if (props.announcement !== undefined) {
      setOldAnnouncement(props.announcement);
    }
  }, [props])

  useEffect(() => {
    if (newAnnouncement !== undefined) {
      let temp = newAnnouncement;
      temp.receivers = resetReceivers()
      newReceivers.forEach(x => {
        temp.receivers[x] = true;
      })
      setNewAnnouncement(temp);
    }
  }, [newReceivers])

  useEffect(() => {
    if (loggedInUser !== null) {
      const tempReceivers = resetReceivers();
      if (tempReceivers !== undefined) {
        setNewAnnouncement({
          title: "",
          receivers: tempReceivers,
          message: "",
          createdAt: 0,
          createdBy: loggedInUser.uid,
        })
      }
    }
  }, [userProfiles])

  const resetReceivers = () => {
    let tempReceivers: { [key: string]: boolean } = { "": false };
    if (userProfiles.kind === 'loaded') {
      let tempReceivers: { [key: string]: boolean } = { "": false };
      userProfiles.data.forEach(x => {
        tempReceivers = { ...tempReceivers, [x.user_id]: false };
      })
      const tempFiltered = Object.keys(tempReceivers).filter(x => x === "");
      tempFiltered.forEach(key => delete tempReceivers[key]);
      return tempReceivers;
    } else {
      return tempReceivers;
    }
  }

  const onUserSelectChange = (selectedUserProfile: string[]) => {
    if (selectedUserProfile !== undefined) {
      setNewReceivers(selectedUserProfile);
    }
  }

  const onAnnouncementFieldChange = (name: keyof Announcement) => (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (newAnnouncement !== undefined) {
      setNewAnnouncement({ ...newAnnouncement, [name]: event.target.value });
    }
  }

  const onOldAnnouncementFieldChange = (name: keyof Announcement) => (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (oldAnnouncement !== undefined) {
      setOldAnnouncement({ ...oldAnnouncement, [name]: event.target.value });
    }
  }

  const submitAnnouncementButtonClicked = async () => {
    if (props.announcement === undefined) {
      if (newAnnouncement !== undefined) {
        if (newAnnouncement.title === "") {
          setTitleError(true);
        }
        if (newAnnouncement.message === "") {
          setMessageError(true);
        }
        if (!titleError && !messageError) {
          try {
            if (newAnnouncement !== undefined) {
              const res = await postAnnouncement({ ...newAnnouncement, createdAt: moment().valueOf() });
              props.setSnackbar({ ...props.snackBar, open: true, message: '成功發出公告。' })
            }
          } catch (e) {
            props.setSnackbar({ ...props.snackBar, open: true, message: e.response.message })
          }
        }
      }
    } else {
      try {
        if (oldAnnouncement !== undefined) {
          await updateAnnouncement(oldAnnouncement);
          props.setSnackbar({ ...props.snackBar, open: true, message: '成功更新公告。' })
        }
      } catch (e) {
        props.setSnackbar({ ...props.snackBar, open: true, message: e.response.message })
      }

    }
  }

  return (
    <div>
      <StyledLoginCard>
        <Grid container>
          <Grid item sm={6} md={4} xl={4} xs={12}>
            {props.announcement === undefined &&
              <SelectUserDropdown onUserSelectChange={onUserSelectChange} multiple={true} />}
          </Grid>
        </Grid>
        {props.announcement !== undefined ?
          <>
            {oldAnnouncement !== undefined &&
              <>
                <TextField
                  label="標題"
                  variant="outlined"
                  onChange={onOldAnnouncementFieldChange("title")}
                  fullWidth
                  error={titleError}
                  value={oldAnnouncement.title}
                  style={{ marginTop: 30 }}
                />
                <TextField
                  label="訊息"
                  variant="outlined"
                  onChange={onOldAnnouncementFieldChange("message")}
                  multiline
                  value={oldAnnouncement.message}
                  rows={10}
                  fullWidth
                  error={messageError}
                  style={{ marginTop: 30 }}
                />
              </>}
          </> :
          <>
            <TextField
              label="標題"
              variant="outlined"
              onChange={onAnnouncementFieldChange("title")}
              fullWidth
              error={titleError}
              style={{ marginTop: 30 }}
            />
            <TextField
              label="訊息"
              variant="outlined"
              onChange={onAnnouncementFieldChange("message")}
              multiline
              rows={10}
              fullWidth
              error={messageError}
              style={{ marginTop: 30 }}
            />
          </>}
        <CardActions>
          <Button onClick={submitAnnouncementButtonClicked} color="primary" variant="contained">提交</Button>
        </CardActions>
      </StyledLoginCard>
    </div>
  )
}