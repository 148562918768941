import { Container, Grid, MenuItem, TextField } from "@material-ui/core";
import _ from "lodash";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { AxisDomain, CartesianGrid, Legend, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { countPlaytime, formatCalligraphyPlaytimeData, formatMahjongData, formatMinigameData, formatMinigameData1 } from "../pages/NewReportPage";
import { GridCard } from "../StyledComponents";
import { CalligraphyRecordEntry } from "../types/CalligraphyData";
import { GameLog } from "../types/GameLog";
import { AllMissionDataType } from "../types/MissionData";

const units = ["day", "week", "month", "all"] as const;
type Unit = typeof units[number];
const translationUnit = {
  day: "上星期",
  week: "月份",
  month: "年份",
  all: "全部",
}

export const months = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12] as const;
export type Month = typeof months[number];
export const translationMonth = {
  1: "一月",
  2: "二月",
  3: "三月",
  4: "四月",
  5: "五月",
  6: "六月",
  7: "七月",
  8: "八月",
  9: "九月",
  10: "十月",
  11: "十一月",
  12: "十二月",
}

interface PlaytimeTabProps {
  missionData: AllMissionDataType[] | undefined;
  mahjongData: GameLog[] | undefined;
  calligraphyData: CalligraphyRecordEntry[] | undefined;
}

interface PlaytimeChartData {
  date: number,
  minigame: number,
  mahjong: number,
  calligraphy: number
}

export default function PlaytimeTab(props: PlaytimeTabProps) {

  const [playtime, setPlaytime] = useState<PlaytimeChartData[]>();
  const [playtimeChartData, setPlaytimeChartData] = useState<PlaytimeChartData[]>();

  const [timeUnit, setTimeUnit] = useState<{ year: number, month: number }>({ year: parseInt(moment().format("YYYY")), month: parseInt(moment().format("MM")) });

  const [formatedMahjongPlaytime, setFormatedMahjongPlaytime] = useState<{ date: number, playtime: number }[]>();
  const [formatedMissionPlaytime, setFormatedMissionPlaytime] = useState<{ date: number, playtime: number }[]>();
  const [formatedCalligraphyPlaytime, setFormatedCalligraphyPlaytime] = useState<{ date: number, playtime: number }[]>();

  const [customTicks, setCustomTicks] = useState<number[]>([]);
  const [customDomain, setCustomDomain] = useState<[AxisDomain, AxisDomain]>();

  useEffect(() => {
    if (playtime) {
      setPlaytimeChartData(extractDataByTime(playtime));
    }

    setCustomTicks(generateTick());
    setCustomDomain(computeCustomDomain());
  }, [timeUnit])

  useEffect(() => {
    if (props.missionData)
      setFormatedMissionPlaytime(processMissionData(props.missionData));
  }, [props.missionData]);

  useEffect(() => {
    if (props.mahjongData)
      setFormatedMahjongPlaytime(processMahjongData(props.mahjongData));
  }, [props.mahjongData]);

  useEffect(() => {
    if (props.calligraphyData)
      setFormatedCalligraphyPlaytime(processCalligraphyData(props.calligraphyData));
  }, [props.calligraphyData]);

  useEffect(() => {
    if (formatedMahjongPlaytime !== undefined && formatedMissionPlaytime !== undefined && formatedCalligraphyPlaytime !== undefined) {
      setPlaytime(combinePlaytimeData());
    }
  }, [formatedMahjongPlaytime, formatedMissionPlaytime, formatedCalligraphyPlaytime]);

  useEffect(() => {
    setTimeUnit({ year: parseInt(moment().format("YYYY")), month: parseInt(moment().format("MM")) });
  }, [playtime]);

  useEffect(() => {

  }, [playtimeChartData])

  const processMissionData = (missionData: AllMissionDataType[]) => {
    console.log(countPlaytime(formatMinigameData1(missionData)));
    const tempMinigame = countPlaytime(formatMinigameData(missionData)).map(x => {
      return { "date": moment(x.date).valueOf(), "playtime": Math.round(x.playtime / 60 / 10) / 100 };
    });

    return tempMinigame.sort((a, b) => b.date - a.date);
  }

  const processMahjongData = (mahjongData: GameLog[]) => {
    const tempMahjong = countPlaytime(formatMahjongData(mahjongData)).map(x => {
      return { "date": moment(x.date).valueOf(), "playtime": Math.round(x.playtime / 60 / 10) / 100 };
    });

    return tempMahjong.sort((a, b) => b.date - a.date);
  }

  const processCalligraphyData = (calligraphyData: CalligraphyRecordEntry[]) => {
    const tempCalligraphy = countPlaytime(formatCalligraphyPlaytimeData(calligraphyData)).map(x => {
      return { "date": moment(x.date).valueOf(), "playtime": Math.round(x.playtime / 60 * 100) / 100 };
    });

    return tempCalligraphy.sort((a, b) => b.date - a.date);
  }

  const combinePlaytimeData = () => {
    // Minigame iteration
    let playTimeBuffer = formatedMissionPlaytime!.reduce<{ [key: string]: PlaytimeChartData }>((buffer, entry) => {
      (buffer[entry.date] || (buffer[entry.date] = { date: entry.date, minigame: 0, mahjong: 0, calligraphy: 0 }));
      buffer[entry.date].minigame = entry.playtime;
      return buffer;
    }, {});

    // Mahjong iteration
    playTimeBuffer = formatedMahjongPlaytime!.reduce<{ [key: string]: PlaytimeChartData }>((buffer, entry) => {
      (buffer[entry.date] || (buffer[entry.date] = { date: entry.date, minigame: 0, mahjong: 0, calligraphy: 0 }));
      buffer[entry.date].mahjong = entry.playtime;
      return buffer;
    }, playTimeBuffer);

    // Calligraphy iteration
    playTimeBuffer = formatedCalligraphyPlaytime!.reduce<{ [key: string]: PlaytimeChartData }>((buffer, entry) => {
      (buffer[entry.date] || (buffer[entry.date] = { date: entry.date, minigame: 0, mahjong: 0, calligraphy: 0 }));
      buffer[entry.date].calligraphy = entry.playtime;
      return buffer;
    }, playTimeBuffer);

    return Object.values(playTimeBuffer);
  }

  const extractDataByTime = (data: { "date": number, "minigame": number, "mahjong": number, "calligraphy": number }[]) => {
    if (!timeUnit) return;

    let dataInRange: { "date": number, "minigame": number, "mahjong": number, "calligraphy": number }[];

    if (timeUnit.month === -1) {
      const startOfYear = moment(timeUnit!.year.toString()).startOf("year").valueOf();
      const endOfYear = moment(timeUnit!.year.toString()).endOf("year").valueOf();

      console.log(data);
      console.log(startOfYear + " " + endOfYear);
      dataInRange = data.filter(entry => entry.date < endOfYear && entry.date > startOfYear);

      // Sum up entries inside a month

      console.log(dataInRange);

      // Group data to month
      const groupedData = _(dataInRange)
        .map(d => { return { ...d, date: moment(d.date).startOf("month").valueOf() } })
        .groupBy(d => d.date).valueOf() as { [key: number]: { "date": number, "minigame": number, "mahjong": number, "calligraphy": number }[] };

      console.log(groupedData);

      dataInRange = Object.entries(groupedData).map(([monthTimestamp, list]) => {
        return {
          "date": parseInt(monthTimestamp),
          "mahjong": _(list).sumBy(d => d.mahjong),
          "minigame": _(list).sumBy(d => d.minigame),
          "calligraphy": _(list).sumBy(d => d.calligraphy),
        }
      });

      const dummyFiller = _.range(1, 13).map(month => {
        const currentMonth = moment(timeUnit!.year + "-" + month).valueOf();
        if (!dataInRange.find(d => d.date === currentMonth))
          dataInRange.push({ "date": currentMonth, "minigame": 0, "mahjong": 0, "calligraphy": 0 });
      });
    }
    else {
      const startOfMonth = moment(timeUnit!.year + "-" + timeUnit!.month).startOf("month").valueOf();
      const endOfMonth = moment(timeUnit!.year + "-" + timeUnit!.month).endOf("month").valueOf();

      dataInRange = data.filter(entry => entry.date < endOfMonth && entry.date > startOfMonth);

      // Fill 0s
      const dummyFiller = _.range(1, moment(timeUnit!.year + "-" + timeUnit!.month).daysInMonth() + 1).forEach(day => {
        const currentDate = moment(timeUnit!.year + "-" + timeUnit!.month + "-" + day).valueOf();
        if (!dataInRange.find(d => d.date === currentDate))
          dataInRange.push({ "date": currentDate, "minigame": 0, "mahjong": 0, "calligraphy": 0 });
      });
    }

    const final = dataInRange.sort((a, b) => a.date - b.date);
    console.log(final);
    return final;
  }

  const computeCustomDomain = (): [AxisDomain, AxisDomain] => {
    return timeUnit.month !== -1 ?
      [
        moment((timeUnit.year ? timeUnit.year.toString() : moment().format("YYYY")) + "-" + timeUnit.month).startOf("month").valueOf() as AxisDomain,
        moment((timeUnit.year ? timeUnit.year.toString() : moment().format("YYYY")) + "-" + timeUnit.month).endOf("month").valueOf() as AxisDomain
      ] :
      [
        moment(timeUnit.year ? timeUnit.year.toString() : moment().format("YYYY")).startOf("year").valueOf() as AxisDomain,
        moment(timeUnit.year ? timeUnit.year.toString() : moment().format("YYYY")).endOf("year").valueOf() as AxisDomain
      ]
  }

  const generateTick = () => {
    const start: moment.Moment = moment((timeUnit.year ? timeUnit.year.toString() : moment().format("YYYY")) + (timeUnit.month !== -1 ? `-${timeUnit.month}` : ""));
    const resultArray = [];

    if (timeUnit.month === -1) {
      for (const end = start.clone().add(1, 'year'); start.isBefore(end); start.add(1, 'month')) {
        resultArray.push(start.valueOf());
      }
    }
    else {
      for (const end = start.clone().add(1, 'month'); start.isBefore(end); start.add(1, 'day')) {
        resultArray.push(start.valueOf());
      }
    }

    return resultArray;
  }

  return (
    <Container style={{ minHeight: '100px', paddingBottom: 20, marginTop: 40 }}>
      <Grid container spacing={4}>
        <Grid item md={12} sm={12}>
          <GridCard title="各模式遊戲時間" desc="以折線圖顯示用戶在各模式的綜合遊戲時間">
            <Grid container>
              <Grid item>
                <TextField
                  id="year"
                  select
                  label="年份"
                  value={timeUnit?.year}
                  onChange={(e) => setTimeUnit({ "year": parseInt(e.target.value), "month": timeUnit!.month })}
                  margin="normal"
                  style={{ width: 200, justifySelf: "flex-start", display: 'flex', margin: 40 }}
                >
                  <MenuItem key={"2020"} value={"2020"}>2020年</MenuItem>
                  <MenuItem key={"2021"} value={"2021"}>2021年</MenuItem>
                  <MenuItem key={"2022"} value={"2022"}>2022年</MenuItem>
                </TextField>
              </Grid>
              <Grid item >
                <TextField
                  id="month"
                  select
                  label="月份"
                  value={timeUnit?.month}
                  onChange={(e) => setTimeUnit({ "year": timeUnit!.year, "month": parseInt(e.target.value) as Month })}
                  margin="normal"
                  style={{ width: 200, justifySelf: "flex-start", display: 'flex', margin: 40 }}
                >
                  <MenuItem key={-1} value={-1}>全年</MenuItem>
                  {months.map((option) => (
                    <MenuItem key={option} value={option}>
                      {translationMonth[option]}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
            </Grid>

            {(timeUnit !== undefined && playtime !== undefined) &&
              playtime.length !== 0 ?
              <ResponsiveContainer width="100%" height={288}>
                <LineChart
                  width={1}
                  height={250}
                  margin={{
                    top: 15, right: 30, left: 10, bottom: 60,
                  }}
                  data={playtimeChartData}
                >
                  <CartesianGrid />
                  <XAxis
                    type="number"
                    interval={0}
                    dataKey={'date'}
                    name="Time"
                    domain={customDomain}
                    tickFormatter={(unixTime) => timeUnit?.month !== -1 ? moment(unixTime).format('MMM Do') : moment(unixTime).format('YYYY年 MMM')}
                    ticks={customTicks}
                    angle={-35}
                    textAnchor="end"
                  />
                  <YAxis />
                  <Tooltip labelFormatter={value => moment(value).format(timeUnit?.month !== -1 ? "YYYY年 MMM Do" : "YYYY-MM")} cursor={{ strokeDasharray: '3 3' }} formatter={value => { return value + '分鐘' }} />
                  <Legend wrapperStyle={{ bottom: 20 }} />
                  <Line name={"任務"} type="monotone" dataKey={'minigame'} stroke="#1f4785" dot={true} />
                  <Line name={"麻雀"} type="monotone" dataKey={'mahjong'} stroke="#19b521" dot={true} />
                  <Line name={"書法"} type="monotone" dataKey={'calligraphy'} stroke="#ff6188" dot={true} />
                </LineChart>
              </ResponsiveContainer>
              : "沒有遊玩紀錄"}
          </GridCard>
        </Grid>
      </Grid>
    </Container >
  )
}