import { Collapse, Grid, IconButton, TableBody, TableCell, TableHead, TablePagination, TableRow, Tooltip } from "@material-ui/core";
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { MahjongBehaviorAverageData } from "../api";
import { AppState } from "../reducers";
import { LoadableProfileData } from "../reducers/userProfiles";
import { GridCard, StyledTable } from "../StyledComponents";
import { GameBehavior } from "../types/GameBehaviorLog";
import { GameLog, windChinese } from "../types/GameLog";
import { MahjongGameRecord } from "./MahjongGameRecord";

interface MahjongHistoryTableProps {
  mahjongData: GameLog[] | undefined,
  behaviorData: GameBehavior[],
  mahjongBehaviorAverage: MahjongBehaviorAverageData | undefined
}
interface RowProps {
  data: GameLog,
  behavior: GameBehavior | undefined,
  mahjongBehaviorAverage: MahjongBehaviorAverageData | undefined
}

function Row(props: RowProps) {
  const [open, setOpen] = useState<boolean>(false);
  const userProfiles = useSelector<AppState, LoadableProfileData>(state => state.userProfiles);

  const handleRowOpen = () => {
    setOpen(!open);
  }
  const getWinner = (winner: string) => {
    if (winner.startsWith('npc')) {
      return '電腦' + winner.slice(-1);
    } else {
      if (userProfiles.kind === 'loaded') {
        const name = userProfiles.data.find(x => x.user_id === winner);
        if (name !== undefined) {
          return name.name;
        } else {
          return winner;
        }
      }
    }
  }

  return (
    <>
      <TableRow>
        <TableCell style={{ width: 30 }} >
          <IconButton size="small" onClick={() => handleRowOpen()} >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell style={{ width: 90 }} >
          <Tooltip title={"賽局編號：" + props.data.id} placement="top" arrow interactive>
            <div>
              {moment.utc(props.data.game_start_time).local().format('YYYY年 MMM Do')}
            </div>
          </Tooltip>
        </TableCell>
        <TableCell style={{ width: 90 }} >{windChinese[props.data.wind]}風</TableCell>
        <TableCell style={{ width: 90 }} > {props.data.winner === -1 ? '和局' : getWinner(props.data.players[props.data.winner]?.user_id ?? "unknown")}</TableCell>
        <TableCell style={{ width: 90 }} >{props.data.score}番</TableCell>
      </TableRow >
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto">
            <div style={{ width: "100%" }}>
              <MahjongGameRecord match={props.data} opened={open} />
            </div>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  )
}

export default function MahjongHistoryTable(props: MahjongHistoryTableProps) {

  const rowsPerPage = 10;

  const [page, setPage] = useState<number>(0);

  const [sortedData, setSortedData] = useState<GameLog[]>();

  const handleChangePage = (evt: React.MouseEvent<HTMLButtonElement, MouseEvent> | null, page: number) => {
    setPage(page);
  }

  useEffect(() => {
    if (props.mahjongData)
      setSortedData(props.mahjongData.sort((a, b) => b.game_start_time - a.game_start_time));
  }, [props.mahjongData]);

  return (
    <Grid item md={12} sm={12}>
      <GridCard title="麻雀遊玩歷史" desc="以表格顯示用戶每局遊戲的詳細資料">
        <StyledTable>
          <TableHead>
            <TableRow >
              <TableCell style={{ width: 30 }} />
              <TableCell style={{ fontSize: 18 }}>日期</TableCell>
              <TableCell style={{ fontSize: 18 }}>風位</TableCell>
              <TableCell style={{ fontSize: 18 }}>贏家</TableCell>
              <TableCell style={{ fontSize: 18 }}>番數</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedData !== undefined && sortedData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(x =>
            (
              <Row
                data={x}
                behavior={props.behaviorData.find(y => y.id === x.id)}
                mahjongBehaviorAverage={props.mahjongBehaviorAverage}
              />
            ))
            }
          </TableBody>
        </StyledTable >
        <TablePagination 
        component="div" 
        count = {props.mahjongData ? props.mahjongData.length : 0}
        onPageChange = {handleChangePage}
        page = {page}
        rowsPerPage = {rowsPerPage}
        />
      </GridCard>
    </Grid>
  )
}

const dummyData = [
  {
    type: "摸牌",
    accuracy: undefined,
    avgTime: undefined,
    all_player_accuracy: undefined,
    all_player_avgTime: undefined
  },
  {
    type: "打牌",
    accuracy: undefined,
    avgTime: undefined,
    all_player_accuracy: undefined,
    all_player_avgTime: undefined
  },
  {
    type: "上",
    accuracy: undefined,
    avgTime: undefined,
    all_player_accuracy: undefined,
    all_player_avgTime: undefined
  },
  {
    type: "碰",
    accuracy: undefined,
    avgTime: undefined,
    all_player_accuracy: undefined,
    all_player_avgTime: undefined
  },
  {
    type: "槓",
    accuracy: undefined,
    avgTime: undefined,
    all_player_accuracy: undefined,
    all_player_avgTime: undefined
  },
  {
    type: "暗槓",
    accuracy: undefined,
    avgTime: undefined,
    all_player_accuracy: undefined,
    all_player_avgTime: undefined
  },
  {
    type: "食糊",
    accuracy: undefined,
    avgTime: undefined,
    all_player_accuracy: undefined,
    all_player_avgTime: undefined
  },
  {
    type: "自摸",
    accuracy: undefined,
    avgTime: undefined,
    all_player_accuracy: undefined,
    all_player_avgTime: undefined
  },
]